




















import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { CurrencyDropdownOption } from "@/models/swine/dropdown.model";
import { PleurisyCalculatorModalData } from "@/models/swine/economic-calculation.model";
import { CurrencyExchangeRateDetails } from "@/models/swine/report/report.model";
import { ReportStores } from "@/store/swine/reports";
import { UserSessionStore } from "@/store/swine/user-session";
import CurrencyExchangeDropdown from "./CurrencyExchangeDropdown.vue";
import { ProfileStores } from "@/store/swine/profile/index";

@Component({
  components: {
    CurrencyExchangeDropdown,
  },
})
export default class EconomicReportTabs extends Vue {
  selectedCurrencyIndex = 2; // 2 is just a placeholder
  store = ReportStores.detail;
  currencyExchangeRates: CurrencyExchangeRateDetails | null = null;
  currentCountry = "";
  userSessionDetails = UserSessionStore.detail;
  userProfileStore = ProfileStores.profile;

  @Prop({})
  calculatorModalInputDataRetrieve!: PleurisyCalculatorModalData;

  currencies: CurrencyDropdownOption[] = [
    { uniqueKey: "USD", text: "USD", value: 1.0 },
    { uniqueKey: "IDR", text: "IDR", value: 14613.35 },
    { uniqueKey: "MYR", text: "MYR", value: 4.04 },
    { uniqueKey: "PHP", text: "PHP", value: 48.55 },
    { uniqueKey: "THB", text: "THB", value: 31.44 },
    { uniqueKey: "VND", text: "VND", value: 23085.5 },
    { uniqueKey: "CNY", text: "CNY", value: 6.89 },
    { uniqueKey: "BND", text: "BND", value: 1.33 },
    { uniqueKey: "NTD", text: "NTD", value: 30.95 },
  ];

  get currentSelectedCurrency() {
    return this.currencies[this.selectedCurrencyIndex];
  }

  get baseCurrency() {
    return this.currencies.find((currency) => currency.uniqueKey === "USD");
  }

  mounted() {
    /*
    The reason why we can do this is that this response was retrieved during the first call 
    in ReportDetails.vue
    */
    if (this.userProfileStore.userProfile) {
      this.store.getCurrencyExchangeRates();
    }
  }

  @Watch(`store.currencyExchangeRates`) //Deals with replacing the values in currencies to be equal to the API data
  updateCurrencyExchangeRates() {
    if (this.store.currencyExchangeRates) {
      for (const [key, value] of Object.entries(this.store.currencyExchangeRates)) {
        const found = this.currencies.find((currency) => currency.uniqueKey === key);
        if (found) {
          found.value = value as number;
        } else {
          this.currencies.push({
            uniqueKey: key,
            text: key,
            value: value as number,
          });
        }
      }
    }
  }

  @Watch("calculatorModalInputDataRetrieve", { deep: true, immediate: true })
  changeSelectedCurrencyIndex() {
    // set the selected currency to be the last saved currency from the DB
    if (this.calculatorModalInputDataRetrieve.currencySymbol) {
      this.selectedCurrencyIndex = this.currencies.findIndex((currency) => {
        return currency.text === this.calculatorModalInputDataRetrieve.currencySymbol;
      });
    } else {
      if (this.userProfileStore.userProfile) {
        this.currentCountry = this.userProfileStore.userProfile.country
          ? this.userProfileStore.userProfile.country.code
          : "";
        this.store.getCurrencyExchangeRates();
        // If first time making economic calculation, set the selected currency to be of the user's locality
        let selectedCurrency = "";
        // After data population, we will match their preference
        switch (this.currentCountry) {
          case "MY": {
            selectedCurrency = "MYR";
            break;
          }
          case "ID": {
            selectedCurrency = "IDR";
            break;
          }
          case "VN": {
            selectedCurrency = "VND";
            break;
          }
          case "PH": {
            selectedCurrency = "PHP";
            break;
          }
          case "TH": {
            selectedCurrency = "THB";
            break;
          }
          case "CN": {
            selectedCurrency = "CNY";
            break;
          }
          case "BN": {
            selectedCurrency = "BND";
            break;
          }
          case "TW": {
            selectedCurrency = "NTD";
            break;
          }
          default: {
            selectedCurrency = "USD";
            break;
          }
        }
        const newCurrencyIndex = this.currencies.findIndex((data) => {
          return data.text === selectedCurrency;
        });
        this.currencyChanger(
          newCurrencyIndex > -1 ? this.currencies[newCurrencyIndex] : this.currencies[0]
        );
      }
    }
  }

  // will set the initial currency if new report or last saved currency for saved report
  currencyChanger({ uniqueKey }: CurrencyDropdownOption) {
    if (!this.calculatorModalInputDataRetrieve.currencySymbol) {
      this.selectedCurrencyIndex = this.currencies.findIndex((currency) => {
        return currency.uniqueKey === uniqueKey;
      });
      const oldCurrencyData = this.currencies[this.selectedCurrencyIndex];
      const newCurrencyData = this.currencies[this.selectedCurrencyIndex];
      this.$emit("currencyUpdate", oldCurrencyData, newCurrencyData);
      this.store.setCurrentCurrencyDetails({
        baseCurrency: this.baseCurrency as CurrencyDropdownOption,
        currentSelectedCurrency: this.currentSelectedCurrency,
      });
    } else {
      const oldCurrencyData = this.currencies[this.selectedCurrencyIndex];
      this.selectedCurrencyIndex = this.currencies.findIndex((currency) => {
        return currency.uniqueKey === uniqueKey;
      });
      const newCurrencyData = this.currencies[this.selectedCurrencyIndex];
      this.$emit("currencyUpdate", oldCurrencyData, newCurrencyData);
      this.store.setCurrentCurrencyDetails({
        baseCurrency: this.baseCurrency as CurrencyDropdownOption,
        currentSelectedCurrency: this.currentSelectedCurrency,
      });
    }
  }
}
