












































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import CalculatorModal from './CalculatorModal.vue';
import CurrencyExchangeBar from './economicReports/EconomicCalculation/CurrencyExchangeBar.vue';
import BronchopneunomiaEconomicLossReport from './economicReports/EconomicCalculation/BronchopneumoniaEconomicLossReport.vue';
import PleurisyEconomicLossReport from './economicReports/EconomicCalculation/PleurisyEconomicLossReport.vue';
import MedicationProgramReport from './economicReports/EconomicCalculation/MedicationProgramReport.vue';
import MultiAssumptionReport from './economicReports/EconomicCalculation/MultiAssumptionReport.vue';
import { EconomicCalculationModalDataType } from '@/models/swine/economic-calculation.model';
import {
  AuditReportUpdateQueries,
  EconomicCalculationWithReportData,
} from '@/models/swine/report/report.model';
import {
  EconomicCalculationType,
  ECONOMIC_CALCULATION_MEDICATION,
} from '@/utils/constants';
import { CurrencyDropdownOption } from '@/models/swine/dropdown.model';
import { ChartOptions } from 'chart.js';
import { ReportStores } from '@/store/swine/reports';
import { downloadEconomicCalculationPDF } from '@/utils/download-pdf/download-pdf';
import FarmProductionPerformance from './economicReports/PulmotilCalculation/FarmProductionPerformance/FarmProductionPerformance.vue';
import {
  calculateNumberOfGrowerPigs,
  calculateNumberOfPigletsAtNursery,
  calculateNumberOfPigletsBornPerMonth,
  calculateNumberOfSowsFarrowingPerMonth,
} from '@/utils/economic-calculation/pulmotil/formula';

@Component({
  components: {
    CalculatorModal,
    CurrencyExchangeBar,
    BronchopneunomiaEconomicLossReport,
    PleurisyEconomicLossReport,
    MedicationProgramReport,
    MultiAssumptionReport,
    FarmProductionPerformance,
  },
})
export default class EconomicCalculator extends Vue {
  store = ReportStores.detail;
  calculatorModalStatus = false;
  calculatorModalData: EconomicCalculationModalDataType | null = null;
  computedChartOptions: ChartOptions | null = null;

  @Prop({
    type: Boolean,
    default: true,
  })
  isEditAllowed!: boolean;

  @Prop({
    type: Object,
    default: null,
  })
  currentReportDetails!: EconomicCalculationWithReportData;

  @Prop({ required: true })
  isCurrentUserViewer!: boolean;

  isPDFButtonShown = false;

  get isCurrentEconomicTypePulmotil() {
    if (this.calculatorModalData === null) {
      return false;
    }
    return (
      this.calculatorModalData.economicCalculationType ===
      EconomicCalculationType.pulmotil
    );
  }

  get currentEconomicType() {
    if (this.isCurrentEconomicTypePulmotil) {
      return `Economic Value Calculator (Standard)`;
    }
    return `Economic Value Calculator (Malaysia)`;
  }

  mounted() {
    if (this.currentReportDetails.economicCalculationDetails) {
      this.calculatorModalData = this.currentReportDetails.economicCalculationDetails;
      this.isPDFButtonShown = true;
    }
  }

  openResetDataModal() {
    this.$buefy.dialog.confirm({
      title: 'Reset Data?',
      message:
        'All data entered will be lost once you reset. Would you still like to reset?',
      confirmText: 'Reset',
      onConfirm: () => {
        this.handleResetEconomicCalculationDetails();
        this.$buefy.toast.open('Economic calculation has been reset.');
      },
    });
  }

  handleResetEconomicCalculationDetails() {
    const params: AuditReportUpdateQueries = {
      calculatorModalData: null,
    };
    this.calculatorModalData = null;
    this.isPDFButtonShown = false;
    this.store.setCalculatorModalInputObject(this.calculatorModalData);
    this.store.resetEconomicCalculationDetails(params);
  }

  handleSaveEconomicCalculationDetails() {
    const modalDetails: EconomicCalculationModalDataType | null = this
      .calculatorModalData;
    const params: AuditReportUpdateQueries = {
      calculatorModalData: modalDetails,
    };
    this.store.updateReportEconomicCalculationDetails(params);
    this.store.setCalculatorModalInputObject(this.calculatorModalData);
  }

  @Watch('store.reportResponse')
  enableExportPdfButton() {
    if (this.store.reportResponse !== null) {
      this.isPDFButtonShown = true;
    }
  }

  updateCurrentDetails() {
    if (this.currentReportDetails && this.calculatorModalData) {
      this.calculatorModalData.p3AndP4Prevalence = this.currentReportDetails.p3AndP4Prevalence;
      this.calculatorModalData.appPleurisyPrevalence = this.currentReportDetails.appPleurisyPrevalence;
      this.calculatorModalData.pneumoniaSeverityDict = this.currentReportDetails.pneumoniaSeverityDict;
      this.store.setCalculatorModalInputObject(this.calculatorModalData);
    }
  }

  presetFarmProductionPerformanceDetails() {
    if (
      this.calculatorModalData &&
      'sowLevel' in this.calculatorModalData &&
      this.calculatorModalData.sowLevel !== null &&
      this.calculatorModalData.sowIndex !== null
    ) {
      this.calculatorModalData.numberOfSowsFarrowingPerMonth = calculateNumberOfSowsFarrowingPerMonth(
        this.calculatorModalData.sowLevel,
        this.calculatorModalData.sowIndex,
      );
    }
    if (
      this.calculatorModalData &&
      'sowLevel' in this.calculatorModalData &&
      this.calculatorModalData.numberOfSowsFarrowingPerMonth !== null &&
      this.calculatorModalData.averageLitterSizeBornAlive !== null
    ) {
      this.calculatorModalData.numberOfPigletsBornPerMonth = calculateNumberOfPigletsBornPerMonth(
        this.calculatorModalData.numberOfSowsFarrowingPerMonth,
        this.calculatorModalData.averageLitterSizeBornAlive,
      );
    }
    if (
      this.calculatorModalData &&
      'sowLevel' in this.calculatorModalData &&
      this.calculatorModalData.numberOfPigletsBornPerMonth !== null &&
      this.calculatorModalData.preWeaningMortality !== null
    ) {
      this.calculatorModalData.numberOfPigletsAtNursery = calculateNumberOfPigletsAtNursery(
        this.calculatorModalData.numberOfPigletsBornPerMonth,
        this.calculatorModalData.preWeaningMortality,
      );
    }
    if (
      this.calculatorModalData &&
      'sowLevel' in this.calculatorModalData &&
      this.calculatorModalData.numberOfPigletsAtNursery !== null &&
      this.calculatorModalData.nurseryMortality !== null
    ) {
      this.calculatorModalData.numberOfGrowerPigs = calculateNumberOfGrowerPigs(
        this.calculatorModalData.numberOfPigletsAtNursery,
        this.calculatorModalData.nurseryMortality,
      );
    }
  }

  presetMedicationProgramDetails() {
    if (
      this.calculatorModalData &&
      this.calculatorModalData.economicCalculationType ===
        EconomicCalculationType.pleurisy &&
      'numberOfAnimalHeads' in this.calculatorModalData
    ) {
      this.calculatorModalData.cleanSowFeedIntake =
        ECONOMIC_CALCULATION_MEDICATION.CLEAN_SOW_FEED_INTAKE;
      this.calculatorModalData.cleanSowDuration =
        ECONOMIC_CALCULATION_MEDICATION.CLEAN_SOW_DURATION;
      this.calculatorModalData.cleanSowPSRatio =
        ECONOMIC_CALCULATION_MEDICATION.CLEAN_SOW_PS_RATIO;
      this.calculatorModalData.cleanSowDosage = null;
      this.calculatorModalData.cleanSowMedicinePrice = null;
      this.calculatorModalData.cleanSowMedicineBrand = null;
      this.calculatorModalData.startStrongFeedIntake =
        ECONOMIC_CALCULATION_MEDICATION.START_STRONG_FEED_INTAKE;
      this.calculatorModalData.startStrongDuration =
        ECONOMIC_CALCULATION_MEDICATION.START_STRONG_DURATION;
      this.calculatorModalData.startStrongDosage = null;
      this.calculatorModalData.startStrongMedicinePrice = null;
      this.calculatorModalData.startStrongMedicineBrand = null;
      this.calculatorModalData.strategicProgramFeedIntake =
        ECONOMIC_CALCULATION_MEDICATION.STRATEGIC_FEED_INTAKE;
      this.calculatorModalData.strategicProgramDuration =
        ECONOMIC_CALCULATION_MEDICATION.STRATEGIC_DURATION;
      this.calculatorModalData.strategicProgramDosage = null;
      this.calculatorModalData.strategicProgramMedicinePrice = null;
      this.calculatorModalData.strategicProgramMedicineBrand = null;
    }
  }

  handleDefaultCurrency() {
    if (this.calculatorModalData) {
      this.calculatorModalData.currencySymbol = '';
    }
  }

  openCalculatorModal() {
    this.calculatorModalStatus = !this.calculatorModalStatus;
  }

  reportPresent(calculatorModalInputObject: EconomicCalculationModalDataType) {
    this.calculatorModalStatus = !this.calculatorModalStatus;
    this.calculatorModalData = calculatorModalInputObject;
    this.calculatorModalData.postWeaningMortality = 3.3;
    this.updateCurrentDetails();
    this.presetFarmProductionPerformanceDetails();
    this.presetMedicationProgramDetails();
    this.handleDefaultCurrency();
  }

  handleUpdate(updateModalInputObject: EconomicCalculationModalDataType) {
    this.calculatorModalData = {
      ...this.calculatorModalData,
      ...updateModalInputObject,
    };
    this.isPDFButtonShown = false;
    this.store.setCalculatorModalInputObject(this.calculatorModalData);
  }

  updateValueCurrency(
    oldCurrencyData: CurrencyDropdownOption,
    newCurrencyData: CurrencyDropdownOption,
  ) {
    if (this.calculatorModalData) {
      this.isPDFButtonShown = false;
      this.calculatorModalData.currencySymbol = newCurrencyData.text;
      if (this.calculatorModalData.additionalFeedCostPigRate) {
        this.calculatorModalData.additionalFeedCostPigRate = Number(
          (
            (this.calculatorModalData.additionalFeedCostPigRate /
              oldCurrencyData.value) *
            newCurrencyData.value
          ).toFixed(5),
        );
      }

      if (this.calculatorModalData.liveWeightPrice) {
        this.calculatorModalData.liveWeightPrice = Number(
          (
            (this.calculatorModalData.liveWeightPrice / oldCurrencyData.value) *
            newCurrencyData.value
          ).toFixed(5),
        );
      }

      if (this.calculatorModalData.averageFeedPrice) {
        this.calculatorModalData.averageFeedPrice = Number(
          (
            (this.calculatorModalData.averageFeedPrice /
              oldCurrencyData.value) *
            newCurrencyData.value
          ).toFixed(5),
        );
      }

      // Pleurisy Calculation
      if (
        'cleanSowMedicinePrice' in this.calculatorModalData &&
        this.calculatorModalData.cleanSowMedicinePrice &&
        !this.isCurrentEconomicTypePulmotil
      ) {
        this.calculatorModalData.cleanSowMedicinePrice = Number(
          (
            (this.calculatorModalData.cleanSowMedicinePrice /
              oldCurrencyData.value) *
            newCurrencyData.value
          ).toFixed(5),
        );
      }

      if (
        'cleanSowMedicinePrice' in this.calculatorModalData &&
        this.calculatorModalData.startStrongMedicinePrice &&
        !this.isCurrentEconomicTypePulmotil
      ) {
        this.calculatorModalData.startStrongMedicinePrice = Number(
          (
            (this.calculatorModalData.startStrongMedicinePrice /
              oldCurrencyData.value) *
            newCurrencyData.value
          ).toFixed(5),
        );
      }

      if (
        'cleanSowMedicinePrice' in this.calculatorModalData &&
        this.calculatorModalData.strategicProgramMedicinePrice &&
        !this.isCurrentEconomicTypePulmotil
      ) {
        this.calculatorModalData.strategicProgramMedicinePrice = Number(
          (
            (this.calculatorModalData.strategicProgramMedicinePrice /
              oldCurrencyData.value) *
            newCurrencyData.value
          ).toFixed(5),
        );
      }

      if (
        this.isCurrentEconomicTypePulmotil &&
        'numberOfDaysFedInGrower' in this.calculatorModalData &&
        this.calculatorModalData.costOfMedicationPerKiloInCleanSowProgram
      ) {
        this.calculatorModalData.costOfMedicationPerKiloInCleanSowProgram = Number(
          (
            (this.calculatorModalData.costOfMedicationPerKiloInCleanSowProgram /
              oldCurrencyData.value) *
            newCurrencyData.value
          ).toFixed(5),
        );
      }

      if (
        this.isCurrentEconomicTypePulmotil &&
        'numberOfDaysFedInGrower' in this.calculatorModalData &&
        this.calculatorModalData
          .costOfMedicationPerKiloInEarlyInterventionProgram
      ) {
        this.calculatorModalData.costOfMedicationPerKiloInEarlyInterventionProgram = Number(
          (
            (this.calculatorModalData
              .costOfMedicationPerKiloInEarlyInterventionProgram /
              oldCurrencyData.value) *
            newCurrencyData.value
          ).toFixed(5),
        );
      }

      if (
        this.isCurrentEconomicTypePulmotil &&
        'numberOfDaysFedInGrower' in this.calculatorModalData &&
        this.calculatorModalData.costOfMedicationPerKiloInGrowerProgram
      ) {
        this.calculatorModalData.costOfMedicationPerKiloInGrowerProgram = Number(
          (
            (this.calculatorModalData.costOfMedicationPerKiloInGrowerProgram /
              oldCurrencyData.value) *
            newCurrencyData.value
          ).toFixed(5),
        );
      }
    }
  }

  downloadEconomicCalculationPDF() {
    downloadEconomicCalculationPDF(this.$route.params.reportId);
  }
}
