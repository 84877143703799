





















































import { Component, Vue, Prop } from 'vue-property-decorator';
@Component({
  components: {},
})
export default class CalculatorInputField extends Vue {
  labelAdjustments = 'margin-top: 10px; margin-right: 18px;';
  inputValue: number | null = null;

  @Prop()
  value!: number;

  @Prop({ default: false })
  isDisabled!: boolean;

  @Prop({ default: false })
  horizontalLabel!: boolean;

  @Prop({ default: '' })
  inputLabel!: string;

  @Prop({})
  inputBackTag!: string;

  @Prop({
    type: Number,
    default: 0,
  })
  private setinputWidth!: number;

  @Prop({
    type: Boolean,
    default: false,
  })
  private setFontColor!: boolean;

  valueDetected(value: number | string) {
    this.$emit('input', value);
  }

  get customStyle() {
    if (this.setinputWidth > 0) {
      return `width:${this.setinputWidth}px`;
    }
    return '';
  }

  get inputCustomStyle() {
    if (this.setFontColor === true) {
      return `  color: #FF4C4B;,`;
    }
    return '';
  }
}
