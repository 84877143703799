var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"width":680,"active":_vm.isActive,"can-cancel":['outside', 'escape'],"on-cancel":_vm.resetForm}},[_c('FormTemplate',{staticClass:"form-container",attrs:{"formTitle":_vm.formTitle},on:{"closeForm":_vm.resetForm}},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_c('CalculatorInputField',{attrs:{"inputLabel":("Live Weight Price /kg (" + _vm.currentCurrencySymbol + ")")},model:{value:(_vm.updateMedicationModalData.liveWeightPrice),callback:function ($$v) {_vm.$set(_vm.updateMedicationModalData, "liveWeightPrice", _vm._n($$v))},expression:"updateMedicationModalData.liveWeightPrice"}}),_c('CalculatorInputField',{staticClass:"margin-top",attrs:{"inputLabel":("Ave Feed Price /kg (" + _vm.currentCurrencySymbol + ")")},model:{value:(_vm.updateMedicationModalData.averageFeedPrice),callback:function ($$v) {_vm.$set(_vm.updateMedicationModalData, "averageFeedPrice", _vm._n($$v))},expression:"updateMedicationModalData.averageFeedPrice"}})],1),_c('div',{staticClass:"column"},[_c('CalculatorInputField',{attrs:{"inputLabel":("Target Market Wt (kg) (" + _vm.currentCurrencySymbol + ")")},model:{value:(_vm.updateMedicationModalData.targetMarketWeight),callback:function ($$v) {_vm.$set(_vm.updateMedicationModalData, "targetMarketWeight", _vm._n($$v))},expression:"updateMedicationModalData.targetMarketWeight"}})],1)]),_c('MedicationModalSectionCollapseTemplate',{attrs:{"title":'Clean Sow Program (Lactation feed)',"isActive":_vm.isCleanSowProgramActive},on:{"checked":_vm.updateCleanSowModal}},[_c('div',[_c('div',{staticClass:"columns is-multiline"},[_c('div',{staticClass:"column is-half"},[_c('CalculatorInputField',{attrs:{"inputLabel":"No.of Days Fed Pre Farrowing (days)"},model:{value:(
                _vm.updateMedicationModalData.numberOfDaysPreFarrowing
              ),callback:function ($$v) {_vm.$set(_vm.updateMedicationModalData, "numberOfDaysPreFarrowing", _vm._n($$v))},expression:"\n                updateMedicationModalData.numberOfDaysPreFarrowing\n              "}}),_c('CalculatorInputField',{staticClass:"margin-top",attrs:{"inputLabel":"No.of Days Fed Post Farrowing (days)"},model:{value:(
                _vm.updateMedicationModalData.numberOfDaysPostFarrowing
              ),callback:function ($$v) {_vm.$set(_vm.updateMedicationModalData, "numberOfDaysPostFarrowing", _vm._n($$v))},expression:"\n                updateMedicationModalData.numberOfDaysPostFarrowing\n              "}})],1),_c('div',{staticClass:"column is-half"},[_c('CalculatorInputField',{attrs:{"inputLabel":"ADFI at Gestation (kg)"},model:{value:(_vm.updateMedicationModalData.adfiAtGestation),callback:function ($$v) {_vm.$set(_vm.updateMedicationModalData, "adfiAtGestation", _vm._n($$v))},expression:"updateMedicationModalData.adfiAtGestation"}}),_c('CalculatorInputField',{staticClass:"margin-top",attrs:{"inputLabel":"ADFI at Lactation (kg)"},model:{value:(_vm.updateMedicationModalData.adfiAtLactation),callback:function ($$v) {_vm.$set(_vm.updateMedicationModalData, "adfiAtLactation", _vm._n($$v))},expression:"updateMedicationModalData.adfiAtLactation"}})],1),_c('div',{staticClass:"column is-half"},[_c('CalculatorInputField',{attrs:{"inputLabel":"Medication Dose (kg/ton)"},model:{value:(
                _vm.updateMedicationModalData.medicationDoseInCleanSowProgram
              ),callback:function ($$v) {_vm.$set(_vm.updateMedicationModalData, "medicationDoseInCleanSowProgram", _vm._n($$v))},expression:"\n                updateMedicationModalData.medicationDoseInCleanSowProgram\n              "}}),_c('div',{staticClass:"medicine-dropdown"},[_c('h6',{staticClass:"medicine-text"},[_vm._v("Price of Medicine (Brand)")]),_c('PriceOfMedicineDropdown',{attrs:{"disabled":false,"fullWidth":true,"placeholder":_vm.medicineBrandPlaceholder,"options":_vm.medicine,"selectedOption":_vm.updateMedicationModalData.cleanSowMedicineBrand},on:{"select":_vm.changeCleanSowDefaultMessage}})],1)],1),_c('div',{staticClass:"column is-half"},[_c('CalculatorInputField',{attrs:{"inputLabel":"Cost of Medicine /kg"},model:{value:(
                _vm.updateMedicationModalData.costOfMedicationPerKiloInCleanSowProgram
              ),callback:function ($$v) {_vm.$set(_vm.updateMedicationModalData, "costOfMedicationPerKiloInCleanSowProgram", _vm._n($$v))},expression:"\n                updateMedicationModalData.costOfMedicationPerKiloInCleanSowProgram\n              "}})],1)])])]),_c('MedicationModalSectionCollapseTemplate',{attrs:{"title":'Early Intervention (Prestarter)',"isActive":_vm.isEarlyInterventionProgramActive},on:{"checked":_vm.updateEarlyInterventionModal}},[_c('div',[_c('div',{staticClass:"columns is-multiline"},[_c('div',{staticClass:"column is-half"},[_c('CalculatorInputField',{attrs:{"inputLabel":"No.of Day Fed (days)"},model:{value:(
                _vm.updateMedicationModalData.numberOfDaysFedInNursery
              ),callback:function ($$v) {_vm.$set(_vm.updateMedicationModalData, "numberOfDaysFedInNursery", _vm._n($$v))},expression:"\n                updateMedicationModalData.numberOfDaysFedInNursery\n              "}}),_c('CalculatorInputField',{staticClass:"margin-top",attrs:{"inputLabel":"Feed Consumption /day (kg)"},model:{value:(
                _vm.updateMedicationModalData.feedConsumptionPerDayInNursery
              ),callback:function ($$v) {_vm.$set(_vm.updateMedicationModalData, "feedConsumptionPerDayInNursery", _vm._n($$v))},expression:"\n                updateMedicationModalData.feedConsumptionPerDayInNursery\n              "}})],1),_c('div',{staticClass:"column is-half"},[_c('CalculatorInputField',{attrs:{"inputLabel":"Medication Dose (kg/ton)"},model:{value:(
                _vm.updateMedicationModalData.medicationDoseInEarlyInterventionProgram
              ),callback:function ($$v) {_vm.$set(_vm.updateMedicationModalData, "medicationDoseInEarlyInterventionProgram", _vm._n($$v))},expression:"\n                updateMedicationModalData.medicationDoseInEarlyInterventionProgram\n              "}}),_c('CalculatorInputField',{staticClass:"margin-top",attrs:{"inputLabel":"Cost of Medicine /kg"},model:{value:(
                _vm.updateMedicationModalData.costOfMedicationPerKiloInEarlyInterventionProgram
              ),callback:function ($$v) {_vm.$set(_vm.updateMedicationModalData, "costOfMedicationPerKiloInEarlyInterventionProgram", _vm._n($$v))},expression:"\n                updateMedicationModalData.costOfMedicationPerKiloInEarlyInterventionProgram\n              "}})],1),_c('div',{staticClass:"column is-half"},[_c('h6',{staticClass:"medicine-text"},[_vm._v("Price of Medicine (Brand)")]),_c('PriceOfMedicineDropdown',{attrs:{"disabled":false,"fullWidth":true,"placeholder":_vm.medicineBrandPlaceholder,"options":_vm.medicine,"selectedOption":_vm.updateMedicationModalData.earlyInterventionMedicineBrand},on:{"select":_vm.changeEarlyInterventionDefaultMessage}})],1)])])]),_c('MedicationModalSectionCollapseTemplate',{attrs:{"title":'Starter / Grower',"isActive":_vm.isGrowerProgramActive},on:{"checked":_vm.updateGrowerModal}},[_c('div',[_c('div',{staticClass:"columns is-multiline"},[_c('div',{staticClass:"column is-half"},[_c('CalculatorInputField',{attrs:{"inputLabel":"No.of Day Fed (days)"},model:{value:(
                _vm.updateMedicationModalData.numberOfDaysFedInGrower
              ),callback:function ($$v) {_vm.$set(_vm.updateMedicationModalData, "numberOfDaysFedInGrower", _vm._n($$v))},expression:"\n                updateMedicationModalData.numberOfDaysFedInGrower\n              "}}),_c('CalculatorInputField',{staticClass:"margin-top",attrs:{"inputLabel":"Feed Consumption /day (kg)"},model:{value:(
                _vm.updateMedicationModalData.feedConsumptionPerDayInGrower
              ),callback:function ($$v) {_vm.$set(_vm.updateMedicationModalData, "feedConsumptionPerDayInGrower", _vm._n($$v))},expression:"\n                updateMedicationModalData.feedConsumptionPerDayInGrower\n              "}})],1),_c('div',{staticClass:"column is-half"},[_c('CalculatorInputField',{attrs:{"inputLabel":"Medication Dose (kg/ton)"},model:{value:(
                _vm.updateMedicationModalData.medicationDoseInGrowerProgram
              ),callback:function ($$v) {_vm.$set(_vm.updateMedicationModalData, "medicationDoseInGrowerProgram", _vm._n($$v))},expression:"\n                updateMedicationModalData.medicationDoseInGrowerProgram\n              "}}),_c('CalculatorInputField',{staticClass:"margin-top",attrs:{"inputLabel":"Cost of Medicine /kg"},model:{value:(
                _vm.updateMedicationModalData.costOfMedicationPerKiloInGrowerProgram
              ),callback:function ($$v) {_vm.$set(_vm.updateMedicationModalData, "costOfMedicationPerKiloInGrowerProgram", _vm._n($$v))},expression:"\n                updateMedicationModalData.costOfMedicationPerKiloInGrowerProgram\n              "}})],1),_c('div',{staticClass:"column is-half"},[_c('h6',{staticClass:"medicine-text"},[_vm._v("Price of Medicine (Brand)")]),_c('PriceOfMedicineDropdown',{attrs:{"disabled":false,"fullWidth":true,"placeholder":_vm.medicineBrandPlaceholder,"options":_vm.medicine,"selectedOption":_vm.updateMedicationModalData.growerMedicineBrand},on:{"select":_vm.changeGrowerDefaultMessage}})],1)])])]),_c('button',{staticClass:"elanco primary generate",attrs:{"disabled":!_vm.isFormValid},on:{"click":_vm.updateMedicationData}},[_vm._v(" Update ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }