






































































































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import Page from '@/components/swine/layout/Page.vue';
import InnerPageTemplate from '@/components/swine/layout/InnerPageTemplate.vue';
import TotalLungsObserved from '@/components/swine/reports/TotalLungsObserved.vue';
import DetailsCard from '@/components/swine/reports/DetailsCard.vue';
import FarmResultSummary from './summary/FarmResultSummary.vue';
import BronchopneumoniaScore from './summary/BronchopneumoniaScore.vue';
import { ReportStores } from '@/store/swine/reports';
import {
  AbscessScarringAndPericarditisWithReportData,
  AppIndexAndPrevalenceWithReportData,
  BronchopneumoniaScoreWithReportData,
  EconomicCalculationWithReportData,
  EditReportData,
  EditReportDataModal,
  FarmResultSummaryWithReportData,
  FetchReportDetails,
  LungLesionScoreParseAttachmentDetails,
  PatchAuditReportGeneralDetails,
  PleurisyScoreWithReportData,
  ReportSummaryData,
  SpawnAttachmentPage,
} from '@/models/swine/report/report.model';
import { AuditReportSections, Role } from '@/utils/constants';
import Pleurisy from './summary/Pleurisy.vue';
import AbscessScarringAndPericarditis from './summary/AbscessScarringAndPericarditis.vue';
import EconomicCalculator from './summary/EconomicCalculator.vue';
import AppIndexAndPrevalence from './summary/AppIndexAndPrevalence.vue';
import AttachmentSelection from '@/components/swine/reports/AttachmentSelection.vue';
import DeleteSpecifiedReportModal from '@/components/swine/reports/DeleteSpecifiedReportModal.vue';
import { downloadPdf } from '@/utils/download-pdf/download-pdf';
import { UserSessionStore } from '@/store/swine/user-session';
import { ProfileStores } from '@/store/swine/profile/index';
import EditSpecifiedReportModal from '@/components/swine/reports/EditSpecifiedReportModal.vue';
import { HousingTypeStores } from '@/store/swine/housing-type/index';
import { SeasonStores } from '@/store/swine/seasons/index';
import { FlooringTypeStores } from '@/store/swine/flooring-type/index';
import { AnimalTypeStores } from '@/store/swine/animal-type/index';
import { OperationTypeStores } from '@/store/swine/operation-type/index';
import { DropdownOption } from '@/models/swine/dropdown.model';
import { ToastProgrammatic } from 'buefy';
@Component({
  components: {
    Page,
    InnerPageTemplate,
    TotalLungsObserved,
    DetailsCard,
    FarmResultSummary,
    BronchopneumoniaScore,
    Pleurisy,
    AbscessScarringAndPericarditis,
    EconomicCalculator,
    AppIndexAndPrevalence,
    AttachmentSelection,
    DeleteSpecifiedReportModal,
    EditSpecifiedReportModal,
  },
})
export default class ReportDetails extends Vue {
  store = ReportStores.detail;
  generalDetails: ReportSummaryData | null = null;
  farmResultSummaryDetails: FarmResultSummaryWithReportData | null = null;
  bronchopneumoniaScoreDetails: BronchopneumoniaScoreWithReportData | null = null;
  pleurisyDetails: PleurisyScoreWithReportData | null = null;
  abscessScarringAndPericarditisDetails: AbscessScarringAndPericarditisWithReportData | null = null;
  appIndexAndPrevalenceDetails: AppIndexAndPrevalenceWithReportData | null = null;
  economicCalculationDetails: EconomicCalculationWithReportData | null = null;
  currentTab = 0;
  displayAttachmentPage = false;
  displayReportDetailsPage = true;
  sectionAttachmentCallOrigin = '';
  bronchopneumoniaScoreAttachments:
    | LungLesionScoreParseAttachmentDetails[]
    | null = null;
  pleurisyScoreAttachments:
    | LungLesionScoreParseAttachmentDetails[]
    | null = null;
  abscessScarringPericarditisScoreAttachments:
    | LungLesionScoreParseAttachmentDetails[]
    | null = null;
  showDeleteButton = false;
  isModalActive = false;
  allowEditReport = false;
  auditReportLungData: any;
  currentActiveTab: string | null = null;
  currentRole = '';
  currentCountry = '';
  currentEmail = '';
  userSessionDetails = UserSessionStore.detail;
  isCurrentRoleNotAViewer = false;
  userProfileStore = ProfileStores.profile;
  showEditReportButton = false
  isEditModalActive = false;
  // Edit Report Dropdowns
  housingTypeListStore = HousingTypeStores.list;
  animalTypeListStore = AnimalTypeStores.list;
  flooringTypeListStore = FlooringTypeStores.list;
  operationTypeListStore = OperationTypeStores.list;
  seasonListStore = SeasonStores.list;
  housingTypeItems: DropdownOption[] = [];
  animalTypeItems: DropdownOption[] = [];
  flooringTypeItems: DropdownOption[] = [];
  operationTypeItems: DropdownOption[] = [];
  seasonItems: DropdownOption[] = [];
  currentSection = AuditReportSections.FARM_RESULT_SUMMARY;

  mounted() {
    this.updatePermissions();
  }

  updatePermissions() {
    if (this.userProfileStore.userProfile) {
      this.currentRole = this.userProfileStore.userProfile.role;
      this.currentCountry = this.userProfileStore.userProfile.country
        ? this.userProfileStore.userProfile.country.code
        : '';
      this.currentEmail = this.userProfileStore.userProfile.email;
      this.isCurrentRoleNotAViewer =
        this.currentRole === Role.Viewer ? false : true;
      this.getSectionDataWithReportId(this.currentSection);
      this.housingTypeListStore.getHousingTypes();
      this.animalTypeListStore.getAnimalTypes();
      this.flooringTypeListStore.getFlooringTypes();
      this.operationTypeListStore.getOperationTypes();
      this.seasonListStore.getSeasons();
    }
  }

  deleteSpecifiedReport() {
    this.isModalActive = false;
    this.store.deleteSpecifiedReports([parseInt(this.$route.params.reportId)]);
  }

  @Watch('store.deleteResponse')
  rerouteToReportManagement() {
    this.$router.push({
      path: '/swine/reports'
    });
  }

  updateSpecifiedReport(currentData: {[key in keyof EditReportData]: EditReportDataModal[key] | null}) {
    this.isEditModalActive = false;

    const output: {[key in keyof EditReportData]: EditReportData[key] | null} = {
        date: currentData.date,
        weaningDate: currentData.weaningDate,
        marketDate: currentData.marketDate,
        averageWeaningWT: currentData.averageWeaningWT,
        averageMarketWT: currentData.averageMarketWT,
        adg: currentData.adg,
        fcr: currentData.fcr,
        marketAgeDays: currentData.marketAgeDays,
        medicationProgram: currentData.medicationProgram,
        estMedicationCost: currentData.estMedicationCost,
        mHyoVaccine: currentData.mHyoVaccine,
        appVaccine: currentData.appVaccine,
        prrsVaccine: currentData.prrsVaccine,
        pcv2Vaccine: currentData.pcv2Vaccine,
        animalType: null,
        housingType: null,
        flooringType: null,
        operationType: null,
        season: null
    }

    if (currentData.animalType && currentData.animalType.value > -1) {
      const currentIndex = this.animalTypeListStore.response.findIndex((item) => {
        return item.id === Number(currentData.animalType!.value);
      });
      output.animalType = currentIndex > -1 ? this.animalTypeListStore.response[currentIndex] : null;
    }

    if (currentData.housingType && currentData.housingType.value > -1) {
      const currentIndex = this.housingTypeListStore.response.findIndex((item) => {
        return item.id === Number(currentData.housingType!.value);
      });
      output.housingType = currentIndex > -1 ? this.housingTypeListStore.response[currentIndex] : null;
    }

    if (currentData.flooringType && currentData.flooringType.value > -1) {
      const currentIndex = this.flooringTypeListStore.response.findIndex((item) => {
        return item.id === Number(currentData.flooringType!.value);
      });
      output.flooringType = currentIndex > -1 ? this.flooringTypeListStore.response[currentIndex] : null;
    }

    if (currentData.operationType && currentData.operationType.value > -1) {
    const currentIndex = this.operationTypeListStore.response.findIndex((item) => {
        return item.id === Number(currentData.operationType!.value);
      });
      output.operationType = currentIndex > -1 ? this.operationTypeListStore.response[currentIndex] : null;
    }

    if (currentData.season && currentData.season.value > -1) {
      const currentIndex = this.seasonListStore.response.findIndex((item) => {
        return item.id === Number(currentData.season!.value);
      });
      output.season = currentIndex > -1 ? this.seasonListStore.response[currentIndex] : null;
    }

    const payload: PatchAuditReportGeneralDetails = {
      id: parseInt(this.$route.params.reportId),
      data: output
    };
    this.store.patchSpecifiedReportGeneralDetails(payload);
  }

  getSectionDataWithReportId(requestedSection: string) {
    const params: FetchReportDetails = {
      reportId: parseInt(this.$route.params.reportId),
      section: requestedSection,
    };
    this.store.getReportDetails(params);
  }

  @Watch(`store.patchResponse`)
  updateCurrentReport() {
    if (this.store.patchResponse) {
      this.getSectionDataWithReportId(this.currentSection);
    }
  }

  @Watch(`store.error`)
  redirectToReportListingPage() {
    if (
      this.store.error &&
      JSON.parse(this.store.error).message === `Request failed with status code 403`
    ) {
      ToastProgrammatic.open({
        type: `is-info`,
        duration: 3000,
        message: `You do not have sufficient permission to access this resource. Redirecting back`,
      });
      this.$router.push({
        path: `/swine/reports`,
      });
    }

    if (
      this.store.error &&
      JSON.parse(this.store.error).message === `Request failed with status code 404`
    ) {
      ToastProgrammatic.open({
        type: `is-info`,
        duration: 3000,
        message: `Report cannot be found. Redirecting back`,
      });
      this.$router.push({
        path: `/swine/reports`,
      });
    }
  }

  @Watch(`store.reportResponse`)
  showReportDetails() {
    if (this.store.reportResponse) {
      this.farmResultSummaryDetails = null;
      this.bronchopneumoniaScoreDetails = null;
      this.pleurisyDetails = null;
      this.abscessScarringAndPericarditisDetails = null;
      this.appIndexAndPrevalenceDetails = null;
      this.economicCalculationDetails = null;
      this.currentActiveTab = null;

      this.generalDetails = {
        ...this.store.reportResponse,
      };

      if (this.generalDetails) {
        this.allowEditReport =
          this.currentRole === Role.Regional ||
          this.generalDetails.auditor.email === this.currentEmail;

        this.showEditReportButton = this.generalDetails.auditor.email === this.currentEmail;

        this.showDeleteButton =
          (this.currentRole === Role.Admin &&
            this.generalDetails.auditor.country &&
            typeof this.generalDetails.auditor.country !== 'string' &&
            this.generalDetails.auditor.country.code === this.currentCountry) ||
          this.currentRole === Role.Regional;

        switch (this.store.section) {
          case AuditReportSections.FARM_RESULT_SUMMARY: {
            this.farmResultSummaryDetails = this.store.reportResponse;
            break;
          }
          case AuditReportSections.BRONCHOPNEUMONIA_SCORE: {
            this.bronchopneumoniaScoreDetails = this.store.reportResponse;
            this.currentActiveTab = AuditReportSections.BRONCHOPNEUMONIA_SCORE;
            break;
          }
          case AuditReportSections.PLEURISY: {
            this.pleurisyDetails = this.store.reportResponse;
            this.currentActiveTab = AuditReportSections.PLEURISY;
            break;
          }
          case AuditReportSections.ABSCESS_SCARRING_PERICARDITIS: {
            this.abscessScarringAndPericarditisDetails = this.store.reportResponse;
            this.currentActiveTab =
              AuditReportSections.ABSCESS_SCARRING_PERICARDITIS;
            break;
          }
          case AuditReportSections.APP_INDEX_AND_PREVALENCE: {
            this.appIndexAndPrevalenceDetails = this.store.reportResponse;
            break;
          }
          case AuditReportSections.ECONOMIC_CALCULATION: {
            this.economicCalculationDetails = this.store.reportResponse;
            break;
          }
          default: {
            this.farmResultSummaryDetails = this.store.reportResponse;
            break;
          }
        }
      }
    }
  }

  @Watch(`housingTypeListStore.response`)
  updateHousingTypeList() {
    if (this.housingTypeListStore.response.length > 0) {
      this.housingTypeItems.splice(0, this.housingTypeItems.length);
      this.housingTypeItems.push({
          text: `None`,
          value: -1,
          uniqueKey: `none_-1`
        });
      this.housingTypeListStore.response.forEach((item) => {
        this.housingTypeItems.push({
          text: item.name,
          value: item.id,
          uniqueKey: `${item.name}_${item.id}`
        });
      });
    }
  }

  @Watch(`animalTypeListStore.response`)
  updateAnimalTypeList() {
    if (this.animalTypeListStore.response.length > 0) {
      this.animalTypeItems.splice(0, this.animalTypeItems.length);
      this.animalTypeItems.push({
          text: `None`,
          value: -1,
          uniqueKey: `none_-2`
        });
      this.animalTypeListStore.response.forEach((item) => {
        this.animalTypeItems.push({
          text: item.name,
          value: item.id,
          uniqueKey: `${item.name}_${item.id}`
        });
      });
    }
  }

  @Watch(`flooringTypeListStore.response`)
  updateFlooringTypeList() {
    if (this.flooringTypeListStore.response.length > 0) {
      this.flooringTypeItems.splice(0, this.flooringTypeItems.length);
      this.flooringTypeItems.push({
          text: `None`,
          value: -1,
          uniqueKey: `none_-3`
        });
      this.flooringTypeListStore.response.forEach((item) => {
        this.flooringTypeItems.push({
          text: item.name,
          value: item.id,
          uniqueKey: `${item.name}_${item.id}`
        });
      });
    }
  }

  @Watch(`operationTypeListStore.response`)
  updateOperationTypeList() {
    if (this.operationTypeListStore.response.length > 0) {
      this.operationTypeItems.splice(0, this.operationTypeItems.length);
      this.operationTypeItems.push({
          text: `None`,
          value: -1,
          uniqueKey: `none_-4`
        });
      this.operationTypeListStore.response.forEach((item) => {
        this.operationTypeItems.push({
          text: item.name,
          value: item.id,
          uniqueKey: `${item.name}_${item.id}`
        });
      });
    }
  }

  @Watch(`seasonListStore.response`)
  updateSeasonList() {
    if (this.seasonListStore.response.length > 0) {
      this.seasonItems.splice(0, this.seasonItems.length);
      this.seasonItems.push({
          text: `None`,
          value: -1,
          uniqueKey: `none_-5`
        });
      this.seasonListStore.response.forEach((item) => {
        this.seasonItems.push({
          text: item.name,
          value: item.id,
          uniqueKey: `${item.name}_${item.id}`
        });
      });
    }
  }

  getNewSectionDataForTab() {
    switch (this.currentTab) {
      case 0: {
        this.currentSection = AuditReportSections.FARM_RESULT_SUMMARY;
        this.getSectionDataWithReportId(
          this.currentSection
        );
        break;
      }

      case 1: {
        this.currentSection = AuditReportSections.BRONCHOPNEUMONIA_SCORE;
        this.getSectionDataWithReportId(
          this.currentSection
        );
        break;
      }

      case 2: {
        this.currentSection = AuditReportSections.PLEURISY;
        this.getSectionDataWithReportId(this.currentSection);
        break;
      }

      case 3: {
        this.currentSection = AuditReportSections.APP_INDEX_AND_PREVALENCE;
        this.getSectionDataWithReportId(
          this.currentSection
        );
        break;
      }

      case 4: {
        this.currentSection = AuditReportSections.ABSCESS_SCARRING_PERICARDITIS;
        this.getSectionDataWithReportId(
          this.currentSection
        );
        break;
      }

      case 5: {
        this.currentSection = AuditReportSections.ECONOMIC_CALCULATION;
        this.getSectionDataWithReportId(
          this.currentSection
        );
        break;
      }

      default: {
        this.currentSection = AuditReportSections.FARM_RESULT_SUMMARY;
        this.getSectionDataWithReportId(
          this.currentSection
        );
        break;
      }
    }
  }

  spawnSelectAttachmentsComponent(params: SpawnAttachmentPage) {
    const { isAccepted, section } = params;
    if (isAccepted === true) {
      this.displayReportDetailsPage = false;
      this.displayAttachmentPage = true;
      this.sectionAttachmentCallOrigin = section;
    }
  }

  setupAttachments(details: LungLesionScoreParseAttachmentDetails[]) {
    this.displayAttachmentPage = false;
    this.displayReportDetailsPage = true;
    if (details) {
      switch (this.sectionAttachmentCallOrigin) {
        case AuditReportSections.BRONCHOPNEUMONIA_SCORE: {
          this.store.setBronchopneumoniaScoreAttachments(details);
          break;
        }
        case AuditReportSections.PLEURISY: {
          this.store.setPleurisyScoreAttachments(details);
          break;
        }
        case AuditReportSections.ABSCESS_SCARRING_PERICARDITIS: {
          this.store.setAbscessScarringPericarditisScoreAttachments(details);
          break;
        }
        default: {
          break;
        }
      }
    }
  }

  @Watch('store.bronchopneumoniaScoreAttachments')
  updateBronchopneumoniaScoreAttachments() {
    this.bronchopneumoniaScoreAttachments = this.store.bronchopneumoniaScoreAttachments;
  }

  @Watch('store.pleurisyScoreAttachments')
  updatePleurisyScoreAttachments() {
    this.pleurisyScoreAttachments = this.store.pleurisyScoreAttachments;
  }

  @Watch('store.abscessScarringPericarditisScoreAttachments')
  updateAbscessScarringPericarditisScoreAttachments() {
    this.abscessScarringPericarditisScoreAttachments = this.store.abscessScarringPericarditisScoreAttachments;
  }

  _downloadPdf() {
    this.$buefy.toast.open({
      message: `Downloading report...`,
      type: `is-dark`,
      duration: 3000,
    });
    downloadPdf(this.$route.params.reportId);
  }
}
