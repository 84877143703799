import { imgElancoLogo, imgLungLeson } from '../../images';
import moment from 'moment';
import { ReportSummaryData } from '@/models/swine/report/report.model';

export function getIntro(generalDetails: ReportSummaryData) {
  let reportTitle: string | undefined = 'Report Title';

  if (generalDetails.farmUnit)
    reportTitle = generalDetails.farmUnit.farmUnitCodeName;
  else if (generalDetails.reportName) reportTitle = generalDetails.reportName;

  function currentDate() {
    return moment(generalDetails.date).local().format('DD/MM/YYYY hh:mm A');
  }

  function currentWeaningDate() {
    let output = '-';

    if (generalDetails.weaningDate) {
      output = moment(generalDetails.weaningDate)
        .local()
        .format('DD/MM/YYYY hh:mm A');
    }
    return output;
  }

  function currentMarketDate() {
    let output = '-';

    if (generalDetails.weaningDate) {
      output = moment(generalDetails.marketDate)
        .local()
        .format('DD/MM/YYYY hh:mm A');
    }
    return output;
  }

  const content = [
    {
      columns: [
        {
          svg: imgElancoLogo,
          width: 100,
          margin: [-20, 0, 0, 0],
        },
        {
          svg: imgLungLeson,
          width: 50,
          margin: [100, 0, 15, 0],
        },
        {
          text: 'Lung Lesion Scoring Report',
          color: '#333',
          bold: true,
          margin: [115, 15, 0, 0],
          fontSize: 20,
        },
      ],
      margin: [25, 10, 25, 25],
    },
    {
      canvas: [
        {
          type: 'line',
          x1: 0,
          y1: 5,
          x2: 765,
          y2: 5,
          lineWidth: 1,
          lineColor: 'lightgrey',
        },
      ],
      margin: [0, 0, 0, 25],
    },
    {
      text: `By ${generalDetails.auditor.userProfile.name} (${generalDetails.auditor.email})`,
      color: '#7b7b7b',
    },
    {
      text: `${reportTitle}`,
      color: '#262626',
      fontSize: 30,
      bold: true,
    },
    {
      columns: [
        {
          style: 'tableNoOfLungsExamined',
          table: {
            body: [
              [
                {
                  text: `${generalDetails.totalLungsObserved}`,
                  color: '#0072D5',
                  fontSize: 60,
                  bold: true,
                  margin: [0, 80, 0, 0],
                },
              ],
              [
                {
                  text: 'No. of lungs',
                  color: '#808080',
                  fontSize: 20,
                  margin: [15, 0, 15, 0],
                },
              ],
              [
                {
                  text: 'examined',
                  color: '#808080',
                  fontSize: 20,
                  margin: [15, 0, 15, 84],
                },
              ],
            ],
          },
          layout: 'noBorders',
        },
        {
          layout: 'allGreyBorder',
          pageBreak: 'after',
          margin: [0, 25, 25, 0],
          width: '600',
          table: {
            widths: ['*', '*'],
            body: [
              [
                {
                  text: 'Audit Date and Time',
                  fillColor: '#f2f2f2',
                  bold: true,
                  style: 'cellPadding',
                  border: [false, false, false, true],
                },
                {
                  text: `${currentDate()}`,
                  style: 'cellPaddingLeftTable',
                  border: [false, false, false, true],
                },
              ],
              [
                {
                  text: 'Weaning Date',
                  fillColor: '#f2f2f2',
                  bold: true,
                  style: 'cellPadding',
                  border: [false, false, false, true],
                },
                {
                  text: `${currentWeaningDate()}`,
                  style: 'cellPaddingLeftTable',
                  border: [false, false, false, true],
                },
              ],
              [
                {
                  text: 'Market Date',
                  fillColor: '#f2f2f2',
                  bold: true,
                  style: 'cellPadding',
                  border: [false, false, false, true],
                },
                {
                  text: `${currentMarketDate()}`,
                  style: 'cellPaddingLeftTable',
                  border: [false, false, false, true],
                },
              ],
              [
                {
                  text: 'Avg. Weaning WT (kg)',
                  fillColor: '#f2f2f2',
                  bold: true,
                  style: 'cellPadding',
                  border: [false, false, false, true],
                },
                {
                  text: `${
                    generalDetails.averageWeaningWT
                      ? generalDetails.averageWeaningWT
                      : '-'
                  }`,
                  style: 'cellPaddingLeftTable',
                  border: [false, false, false, true],
                },
              ],
              [
                {
                  text: 'Avg. Market WT (kg)',
                  fillColor: '#f2f2f2',
                  bold: true,
                  style: 'cellPadding',
                  border: [false, false, false, true],
                },
                {
                  text: `${
                    generalDetails.averageMarketWT
                      ? generalDetails.averageMarketWT
                      : '-'
                  }`,
                  style: 'cellPaddingLeftTable',
                  border: [false, false, false, true],
                },
              ],
              [
                {
                  text: 'ADG (kg)',
                  fillColor: '#f2f2f2',
                  bold: true,
                  style: 'cellPadding',
                  border: [false, false, false, true],
                },
                {
                  text: `${generalDetails.adg ? generalDetails.adg : '-'}`,
                  style: 'cellPaddingLeftTable',
                  border: [false, false, false, true],
                },
              ],
              [
                {
                  text: 'FCR',
                  fillColor: '#f2f2f2',
                  bold: true,
                  style: 'cellPadding',
                  border: [false, false, false, true],
                },
                {
                  text: `${generalDetails.fcr ? generalDetails.fcr : '-'}`,
                  style: 'cellPaddingLeftTable',
                  border: [false, false, false, true],
                },
              ],
              [
                {
                  text: 'Source of Animal',
                  fillColor: '#f2f2f2',
                  bold: true,
                  style: 'cellPadding',
                  border: [false, false, false, true],
                },
                {
                  text: `${
                    generalDetails.animalType
                      ? generalDetails.animalType.name
                      : '-'
                  }`,
                  style: 'cellPaddingLeftTable',
                  border: [false, false, false, true],
                },
              ],
              [
                {
                  text: 'Market Age (days)',
                  fillColor: '#f2f2f2',
                  bold: true,
                  style: 'cellPadding',
                  border: [false, false, false, false],
                },
                {
                  text: `${
                    generalDetails.marketAgeDays
                      ? generalDetails.marketAgeDays
                      : '-'
                  }`,
                  style: 'cellPaddingLeftTable',
                  border: [false, false, false, false],
                },
              ],
              [
                {
                  text: '',
                  fillColor: '',
                  bold: true,
                  style: 'cellPadding',
                  border: [false, false, false, false],
                },
                {
                  text: ``,
                  style: 'cellPadding',
                  border: [false, false, false, false],
                },
              ],
            ],
          },
        },
      ],
    },
    {
      columns: [
        {
          style: 'tableNoOfLungsExamined',
          table: {
            body: [
              [
                {
                  text: `${generalDetails.totalLungsObserved}`,
                  color: '#0072D5',
                  fontSize: 60,
                  bold: true,
                  margin: [0, 80, 0, 0],
                },
              ],
              [
                {
                  text: 'No. of lungs',
                  color: '#808080',
                  fontSize: 20,
                  margin: [15, 0, 15, 0],
                },
              ],
              [
                {
                  text: 'examined',
                  color: '#808080',
                  fontSize: 20,
                  margin: [15, 0, 15, 84],
                },
              ],
            ],
          },
          layout: 'noBorders',
        },
        {
          layout: 'allGreyBorder',
          margin: [0, 25, 25, 0],
          width: '600',
          pageBreak: 'after',
          table: {
            widths: ['*', '*'],
            body: [
              [
                {
                  text: 'Season',
                  fillColor: '#f2f2f2',
                  bold: true,
                  style: 'cellPadding',
                  border: [false, false, false, true],
                },
                {
                  text: `${
                    generalDetails.season ? generalDetails.season.name : '-'
                  }`,
                  style: 'cellPadding',
                  border: [false, false, false, true],
                },
              ],
              [
                {
                  text: 'Type Of Housing',
                  fillColor: '#f2f2f2',
                  bold: true,
                  style: 'cellPadding',
                  border: [false, false, false, true],
                },
                {
                  text: `${
                    generalDetails.housingType
                      ? generalDetails.housingType.name
                      : '-'
                  }`,
                  style: 'cellPadding',
                  border: [false, false, false, true],
                },
              ],
              [
                {
                  text: 'Type Of Flooring',
                  fillColor: '#f2f2f2',
                  bold: true,
                  style: 'cellPadding',
                  border: [false, false, false, true],
                },
                {
                  text: `${
                    generalDetails.flooringType
                      ? generalDetails.flooringType.name
                      : '-'
                  }`,
                  style: 'cellPadding',
                  border: [false, false, false, true],
                },
              ],
              [
                {
                  text: 'Type Of Operation',
                  fillColor: '#f2f2f2',
                  bold: true,
                  style: 'cellPadding',
                  border: [false, false, false, true],
                },
                {
                  text: `${
                    generalDetails.operationType
                      ? generalDetails.operationType.name
                      : '-'
                  }`,
                  style: 'cellPadding',
                  border: [false, false, false, true],
                },
              ],
              [
                {
                  text: `Medication Program`,
                  fillColor: '#f2f2f2',
                  bold: true,
                  style: 'cellPadding',
                  border: [false, false, false, true],
                },
                {
                  text: `${
                    generalDetails.medicationProgram
                      ? generalDetails.medicationProgram
                      : '-'
                  }`,
                  style: 'cellPadding',
                  border: [false, false, false, true],
                },
              ],
              [
                {
                  text: 'Est. Medication Cost (USD)',
                  fillColor: '#f2f2f2',
                  bold: true,
                  style: 'cellPadding',
                  border: [false, false, false, true],
                },
                {
                  text: `${
                    generalDetails.estMedicationCost
                      ? generalDetails.estMedicationCost
                      : '-'
                  }`,
                  style: 'cellPadding',
                  border: [false, false, false, true],
                },
              ],
              [
                {
                  text: 'M. Hyo Vaccine',
                  fillColor: '#f2f2f2',
                  bold: true,
                  style: 'cellPadding',
                  border: [false, false, false, true],
                },
                {
                  text: `${
                    generalDetails.mHyoVaccine
                      ? generalDetails.mHyoVaccine
                      : '-'
                  }`,
                  style: 'cellPadding',
                  border: [false, false, false, true],
                },
              ],
              [
                {
                  text: 'App Vaccine',
                  fillColor: '#f2f2f2',
                  bold: true,
                  style: 'cellPadding',
                  border: [false, false, false, true],
                },
                {
                  text: `${
                    generalDetails.appVaccine ? generalDetails.appVaccine : '-'
                  }`,
                  style: 'cellPadding',
                  border: [false, false, false, true],
                },
              ],
              [
                {
                  text: 'PRRS Vaccine',
                  fillColor: '#f2f2f2',
                  bold: true,
                  style: 'cellPadding',
                  border: [false, false, false, true],
                },
                {
                  text: `${
                    generalDetails.prrsVaccine
                      ? generalDetails.prrsVaccine
                      : '-'
                  }`,
                  style: 'cellPadding',
                  border: [false, false, false, true],
                },
              ],
              [
                {
                  text: 'PCV2 Vaccine',
                  fillColor: '#f2f2f2',
                  bold: true,
                  style: 'cellPadding',
                  border: [false, false, false, false],
                },
                {
                  text: `${
                    generalDetails.pcv2Vaccine
                      ? generalDetails.pcv2Vaccine
                      : '-'
                  }`,
                  style: 'cellPadding',
                  border: [false, false, false, false],
                },
              ],
            ],
          },
        },
      ],
    },
  ];
  return content;
}
