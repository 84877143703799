









































import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import FormTemplate from '@/components/swine/layout/FormTemplate.vue';
import { FormStyling } from '@/models/swine/form/form.model';
import { ToastProgrammatic } from 'buefy';
import { Attachment } from '@/models/swine/report/report.model';
@Component({
  components: {
    FormTemplate,
  },
})
export default class AttachmentImageModalForm extends Vue {
  formTitle = 'Upload Attachment';
  formStyling: FormStyling = {
    height: '487px',
    borderRadius: '20px',
    width: '680px',
  };
  currentFiles: File[] = [];
  isFileInvalid = false;
  allowedMimeTypes = ['image/png', 'image/jpeg'];
  megabyte = Math.pow(1024, 2);
  maximumDefaultAttachments = 3;
  allowedAttachmentLimit = 0;

  @Prop({
    type: Array,
    default: [],
  })
  attachments!: Attachment[];

  mounted() {
    const counts = this.attachments.filter((item) => {
      return item !== null;
    });
    this.allowedAttachmentLimit =
      this.maximumDefaultAttachments - counts.length;
  }

  setImageURL(file: File) {
    return URL.createObjectURL(file);
  }

  validateFileSelection(files: File[]) {
    this.currentFiles = [...files];
    this.isFileInvalid = false;
    let totalSize = 0;

    if (files.length > this.allowedAttachmentLimit) {
      ToastProgrammatic.open({
        type: 'is-danger',
        duration: 3000,
        message: `Total number of files cannot be more than ${this.allowedAttachmentLimit}`,
      });
      this.isFileInvalid = true;
    }

    if (!this.isFileInvalid) {
      this.currentFiles.forEach((value) => {
        totalSize += value.size;

        if (!this.allowedMimeTypes.includes(value.type)) {
          this.isFileInvalid = true;
          ToastProgrammatic.open({
            type: 'is-danger',
            duration: 1500,
            message: `${value.name} is not a PNG or JPEG Image`,
          });
        }

        if (value.size > this.megabyte * 5) {
          this.isFileInvalid = true;
          ToastProgrammatic.open({
            type: 'is-danger',
            duration: 1500,
            message: `${value.name} has exceeded 5MB `,
          });
        }

        if (totalSize > this.megabyte * 15) {
          this.isFileInvalid = true;
          ToastProgrammatic.open({
            type: 'is-danger',
            duration: 1500,
            message: 'Total file size has exceeded 15MB.',
          });
        }
      });
    }

    if (!this.isFileInvalid) {
      ToastProgrammatic.open({
        type: 'is-success',
        duration: 3000,
        message: 'Files have been added',
      });
    } else {
      this.currentFiles = [];
    }
  }

  @Emit('upload')
  uploadImages() {
    return this.currentFiles;
  }

  @Emit('close')
  closeForm(value: boolean) {
    return value;
  }
}
