




































import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import BasicBarChart from '@/components/swine/reports/BasicBarChart.vue';
import PleurisyTable from './PleurisyTable.vue';
import {
  LungLesionScoreParseAttachmentDetails,
  PleurisyScoreWithReportData,
  SpawnAttachmentPage,
} from '@/models/swine/report/report.model';
import { AuditReportSections, ReportType } from '@/utils/constants';
import PleurisyAttachmentsTable from './PleurisyAttachmentsTable.vue';

@Component({
  components: {
    BasicBarChart,
    PleurisyTable,
    PleurisyAttachmentsTable,
  },
})
export default class Pleurisy extends Vue {
  @Prop({
    type: Object,
    default: null,
  })
  currentReportDetails!: PleurisyScoreWithReportData;

  @Prop({
    type: Array,
    default: null,
  })
  attachmentDetails!: LungLesionScoreParseAttachmentDetails[];

  @Prop({ required: true })
  isAttachmentSelectionAllowed!: boolean;

  chartData: Chart.ChartData | null = null;
  options: Chart.ChartOptions | null = null;
  reportType = ReportType;

  mounted() {
    if (this.currentReportDetails) {
      let maxValue = 0;
      Object.values(this.currentReportDetails.pleurisyPercentageDict).find(
        (detail) => {
          if (typeof detail === 'number' && detail >= maxValue) {
            maxValue = detail;
          }
        },
      );

      // Generate ChartData and Options
      this.options = {
        maintainAspectRatio: false,
        legend: {
          display: false,
        },
        scales: {
          yAxes: [
            {
              ticks: {
                min: 0,
                max: 120,
                beginAtZero: true,
                callback: function (tick) {
                  return tick.toString() + '%';
                },
              },
            },
          ],
        },
        plugins: {
          datalabels: {
            anchor: 'end',
            align: 'end',
            formatter: function (value) {
              return `${value}%`;
            },
          },
        },
      };

      this.chartData = {
        labels: [
          `Pleuritis 0`,
          `Pleuritis 1`,
          `Pleuritis 2`,
          `Pleuritis 3`,
          `Pleuritis 4`,
        ],
        datasets: [
          {
            data: [
              this.currentReportDetails.pleurisyPercentageDict[`P0`],
              this.currentReportDetails.pleurisyPercentageDict[`P1`],
              this.currentReportDetails.pleurisyPercentageDict[`P2`],
              this.currentReportDetails.pleurisyPercentageDict[`P3`],
              this.currentReportDetails.pleurisyPercentageDict[`P4`],
            ],
            backgroundColor: [
              `#D6FFA4`,
              `#FFF5C9`,
              `#FFEAEA`,
              `#FFD4D4`,
              `#FFC1C1`,
            ],
            borderColor: [
              `#6C9D31`,
              `#B99913`,
              `#F15453`,
              `#F15453`,
              `#F15453`,
            ],
            borderWidth: 1,
            barPercentage: 0.2,
          },
        ],
      };
    }
  }

  @Emit(`requestAttachments`)
  spawnAttachmentSelection() {
    const params: SpawnAttachmentPage = {
      section: AuditReportSections.PLEURISY,
      isAccepted: true,
    };
    return params;
  }
}
