




























































import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import { DropdownOption } from '@/models/swine/dropdown.model';

@Component({})
export default class PriceOfMedicineDropdown extends Vue {
  isActive = false;
  selected: DropdownOption | null = null;

  dropdownStyle =
    'padding-bottom: 0; padding-top: 0; border-radius: 10px; margin-top: 10px;';

  itemFont =
    'font-size: 16px;line-height: 19px;text-align: left; text-transform: initial; font-weight: 100 ';

  @Prop({
    type: Boolean,
    default: false,
  })
  fullWidth!: boolean;

  @Prop({
    type: Number,
    default: 0,
  })
  private setWidth!: number;

  @Prop({
    type: Array,
    default: [],
    required: true,
  })
  private options!: DropdownOption[];

  @Prop()
  private selectedOption!: DropdownOption | string;

  @Prop() disabled!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  error!: boolean;

  @Prop({
    type: String,
    default: 'Select',
  })
  placeholder!: string;

  @Watch('selectedOption')
  updateSelectedOption() {
    if (!this.selectedOption) {
      this.selected = {
        text: this.placeholder,
        value: null,
        uniqueKey: `placeholder_null`,
      };
    } else if (typeof this.selectedOption !== 'string') {
      this.selected = this.selectedOption;
      this.handleSelected(this.selected);
    } else {
      const selectedOption = this.options.filter(
        (option) => option.value === this.selectedOption,
      );
      if (selectedOption[0]) {
        this.selected = selectedOption[0];
        this.handleSelected(this.selected);
      }
    }
  }

  @Emit('select')
  handleSelected(data: DropdownOption) {
    this.selected = data;
    return data;
  }

  created() {
    if (this.selectedOption && this.selectedOption !== '') {
      this.updateSelectedOption();
    } else {
      this.initializeDefaultOption();
    }
  }

  initializeDefaultOption() {
    if (this.selectedOption && this.selectedOption.constructor === Object) {
      this.selected = this.selectedOption as DropdownOption;
    } else {
      const option = this.options.find((el) => {
        el.value === this.selectedOption;
      });

      if (option) {
        this.selected = option;
      } else {
        this.selected = {
          text: this.placeholder,
          value: null,
          uniqueKey: `placeholder_null`,
        };
      }
    }
  }

  get customStyle() {
    if (this.setWidth > 0) {
      return `width:${this.setWidth}px`;
    }
    return '';
  }
}
