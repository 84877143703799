var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{staticClass:"calculator-modal",attrs:{"width":680,"active":_vm.isActive,"can-cancel":['outside', 'escape'],"on-cancel":_vm.handleAssumptionModalClosed}},[_c('FormTemplate',{staticClass:"cal-container",attrs:{"formTitle":_vm.formTitle,"isTitleCenter":false},on:{"closeForm":_vm.handleAssumptionModalClosed}},[_c('div',{staticClass:"calculator-form__header"},[_c('div',{staticClass:"sub-head__blue"},[_vm._v("Bronchopneumonia Economic Loss")])]),_c('div',{staticClass:"calculator-form__header"},[_c('div',{staticClass:"sub-head__black"},[_vm._v("% of Animals")])]),_c('div',{staticClass:"general-field"},[_c('div',{staticClass:"row-field columns"},[_c('div',{staticClass:"left-field column"},[_c('AssumptionInputField',{attrs:{"inputLabel":"Normal Lungs (0%)","displayValue":_vm.bronchopneumoniaNormalLungsLowPercentage},model:{value:(
              _vm.assumptionModalInputObject.bronchopneumoniaAssumptionNormalLungsLowPercentage
            ),callback:function ($$v) {_vm.$set(_vm.assumptionModalInputObject, "bronchopneumoniaAssumptionNormalLungsLowPercentage", $$v)},expression:"\n              assumptionModalInputObject.bronchopneumoniaAssumptionNormalLungsLowPercentage\n            "}})],1),_c('div',{staticClass:"column"},[_c('AssumptionInputField',{attrs:{"inputLabel":"Normal Lungs (1-10%)","displayValue":_vm.bronchopneumoniaNormalLungsHighPercentage},model:{value:(
              _vm.assumptionModalInputObject.bronchopneumoniaAssumptionNormalLungsHighPercentage
            ),callback:function ($$v) {_vm.$set(_vm.assumptionModalInputObject, "bronchopneumoniaAssumptionNormalLungsHighPercentage", $$v)},expression:"\n              assumptionModalInputObject.bronchopneumoniaAssumptionNormalLungsHighPercentage\n            "}})],1)]),_c('div',{staticClass:"row-field columns"},[_c('div',{staticClass:"left-field column"},[_c('AssumptionInputField',{attrs:{"inputLabel":"Pneumonia (11-20%)","displayValue":_vm.bronchopneumoniaLowPneumonia},model:{value:(
              _vm.assumptionModalInputObject.bronchopneumoniaAssumptionLowPneumonia
            ),callback:function ($$v) {_vm.$set(_vm.assumptionModalInputObject, "bronchopneumoniaAssumptionLowPneumonia", $$v)},expression:"\n              assumptionModalInputObject.bronchopneumoniaAssumptionLowPneumonia\n            "}})],1),_c('div',{staticClass:"column"},[_c('AssumptionInputField',{attrs:{"inputLabel":"Pneumonia (21-30%)","displayValue":_vm.bronchopneumoniaNormalPneumonia},model:{value:(
              _vm.assumptionModalInputObject.bronchopneumoniaAssumptionNormalPneumonia
            ),callback:function ($$v) {_vm.$set(_vm.assumptionModalInputObject, "bronchopneumoniaAssumptionNormalPneumonia", $$v)},expression:"\n              assumptionModalInputObject.bronchopneumoniaAssumptionNormalPneumonia\n            "}})],1)]),_c('div',{staticClass:"row-field columns"},[_c('div',{staticClass:"left-field column"},[_c('AssumptionInputField',{attrs:{"inputLabel":"Pneumonia (31-40%)","displayValue":_vm.bronchopneumoniaHighPneumonia},model:{value:(
              _vm.assumptionModalInputObject.bronchopneumoniaAssumptionHighPneumonia
            ),callback:function ($$v) {_vm.$set(_vm.assumptionModalInputObject, "bronchopneumoniaAssumptionHighPneumonia", $$v)},expression:"\n              assumptionModalInputObject.bronchopneumoniaAssumptionHighPneumonia\n            "}})],1),_c('div',{staticClass:"column"},[_c('AssumptionInputField',{attrs:{"inputLabel":"Severe Pneumonia (41%-50%)","displayValue":_vm.bronchopneumoniaLowSeverePneumonia},model:{value:(
              _vm.assumptionModalInputObject.bronchopneumoniaAssumptionLowSeverePneumonia
            ),callback:function ($$v) {_vm.$set(_vm.assumptionModalInputObject, "bronchopneumoniaAssumptionLowSeverePneumonia", $$v)},expression:"\n              assumptionModalInputObject.bronchopneumoniaAssumptionLowSeverePneumonia\n            "}})],1)]),_c('div',{staticClass:"row-field columns"},[_c('div',{staticClass:"left-field column is-6"},[_c('AssumptionInputField',{attrs:{"inputLabel":"Severe Pneumonia (>50%)","displayValue":_vm.bronchopneumoniaHighSeverePneumonia},model:{value:(
              _vm.assumptionModalInputObject.bronchopneumoniaAssumptionHighSeverePneumonia
            ),callback:function ($$v) {_vm.$set(_vm.assumptionModalInputObject, "bronchopneumoniaAssumptionHighSeverePneumonia", $$v)},expression:"\n              assumptionModalInputObject.bronchopneumoniaAssumptionHighSeverePneumonia\n            "}})],1)]),_c('div',{staticClass:"row-field columns"},[_c('div',{staticClass:"column"},[_c('AssumptionInputField',{attrs:{"inputLabel":"Total % of Animals","isDisabled":true,"setFontColor":this.animalPercentageFalse,"displayValue":_vm.originalPercentageAnimalSum},model:{value:(this.percentageAnimalSum),callback:function ($$v) {_vm.$set(this, "percentageAnimalSum", $$v)},expression:"this.percentageAnimalSum"}})],1)]),_c('div',{class:{
          requirement_notification: _vm.animalPercentageTrue,
          not_one_hundred: _vm.animalPercentageFalse,
        }},[_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")]),_c('span',[_vm._v(" Total percentage must not be more or less than 100%. ")])])]),_c('div',{staticClass:"calculator-form__header"},[_c('div',{staticClass:"sub-head__blue"},[_vm._v("Pleurisy Economic Loss")])]),_c('div',{staticClass:"row-field columns"},[_c('div',{staticClass:"left-field column"},[_c('AssumptionInputField',{attrs:{"inputLabel":"Pleurisy (% Prevalence)","displayValue":_vm.appPleurisyPrevalence},model:{value:(_vm.assumptionModalInputObject.appPleurisyPrevalenceAssumption),callback:function ($$v) {_vm.$set(_vm.assumptionModalInputObject, "appPleurisyPrevalenceAssumption", $$v)},expression:"assumptionModalInputObject.appPleurisyPrevalenceAssumption"}})],1),_c('div',{staticClass:"column"},[_c('AssumptionInputField',{attrs:{"inputLabel":"P3 and P4 * (% Prevalence)","displayValue":_vm.p3AndP4Prevalence},model:{value:(_vm.assumptionModalInputObject.p3AndP4PrevalenceAssumption),callback:function ($$v) {_vm.$set(_vm.assumptionModalInputObject, "p3AndP4PrevalenceAssumption", $$v)},expression:"assumptionModalInputObject.p3AndP4PrevalenceAssumption"}})],1)]),_c('div',{staticClass:"row-field columns"},[_c('div',{staticClass:"column is-6"},[_c('AssumptionInputField',{attrs:{"inputLabel":"% of Post Weaning Mortality","displayValue":_vm.postWeaningMortality},model:{value:(_vm.assumptionModalInputObject.postWeaningMortalityAssumption),callback:function ($$v) {_vm.$set(_vm.assumptionModalInputObject, "postWeaningMortalityAssumption", $$v)},expression:"assumptionModalInputObject.postWeaningMortalityAssumption"}})],1)]),(_vm.firstAssumption)?_c('button',{staticClass:"elanco primary generate",attrs:{"disabled":_vm.formHasError},on:{"click":_vm.createAssumptionPage}},[_vm._v(" ADD ")]):_c('button',{staticClass:"elanco primary generate",attrs:{"disabled":_vm.formHasError},on:{"click":_vm.createAssumptionPage}},[_vm._v(" UPDATE ")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }