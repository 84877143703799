






































import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import BasicBarChart from '@/components/swine/reports/BasicBarChart.vue';
import {
  AbscessScarringAndPericarditisWithReportData,
  LungLesionScoreParseAttachmentDetails,
  SpawnAttachmentPage,
} from '@/models/swine/report/report.model';
import AbscessScarringAndPericarditisTable from './AbscessScarringAndPericarditisTable.vue';
import { AuditReportSections, ReportType } from '@/utils/constants';
import AbscessScarringPericarditisAttachmentsTable from './AbscessScarringAndPericarditisAttachmentsTable.vue';
@Component({
  components: {
    BasicBarChart,
    AbscessScarringAndPericarditisTable,
    AbscessScarringPericarditisAttachmentsTable,
  },
})
export default class AbscessScarringAndPericarditis extends Vue {
  @Prop({
    type: Object,
    default: null,
  })
  currentReportDetails!: AbscessScarringAndPericarditisWithReportData;

  @Prop({
    type: Array,
    default: null,
  })
  attachmentDetails!: LungLesionScoreParseAttachmentDetails[];

  @Prop({ required: true })
  isAttachmentSelectionAllowed!: boolean;

  chartData: Chart.ChartData | null = null;
  options: Chart.ChartOptions | null = null;
  reportType = ReportType;

  mounted() {
    if (this.currentReportDetails) {
      let maxValue = 0;
      if (
        this.currentReportDetails.abscess &&
        this.currentReportDetails.abscess['percentage'] &&
        maxValue < this.currentReportDetails.abscess['percentage']
      ) {
        maxValue = this.currentReportDetails.abscess['percentage'];
      }
      if (
        this.currentReportDetails.scarring &&
        this.currentReportDetails.scarring['percentage'] &&
        maxValue < this.currentReportDetails.scarring['percentage']
      ) {
        maxValue = this.currentReportDetails.scarring['percentage'];
      }
      if (
        this.currentReportDetails.pericarditis &&
        this.currentReportDetails.pericarditis['percentage'] &&
        maxValue < this.currentReportDetails.pericarditis['percentage']
      ) {
        maxValue = this.currentReportDetails.pericarditis['percentage'];
      }
      // Generate ChartData and Options
      this.options = {
        maintainAspectRatio: false,
        legend: {
          display: false,
        },
        scales: {
          yAxes: [
            {
              ticks: {
                min: 0,
                max: 120,
                beginAtZero: true,
                callback: function (tick) {
                  return tick.toString() + '%';
                },
              },
            },
          ],
        },
        plugins: {
          datalabels: {
            anchor: 'end',
            align: 'end',
            formatter: function (value) {
              return `${value}%`;
            },
          },
        },
      };

      this.chartData = {
        labels: [`Abscess`, `Scarring`, `Pericarditis`],
        datasets: [
          {
            data: [
              this.currentReportDetails.abscess[`percentage`],
              this.currentReportDetails.scarring[`percentage`],
              this.currentReportDetails.pericarditis[`percentage`],
            ],
            backgroundColor: [`#D6FFA4`, `#FFF5C9`, `#FFEAEA`],
            borderColor: [`#6C9D31`, `#B99913`, `#F15453`],
            borderWidth: 1,
            barPercentage: 0.2,
          },
        ],
      };
    }
  }

  @Emit(`requestAttachments`)
  spawnAttachmentSelection() {
    const params: SpawnAttachmentPage = {
      section: AuditReportSections.ABSCESS_SCARRING_PERICARDITIS,
      isAccepted: true,
    };
    return params;
  }
}
