import {
  BronchopneumoniaScoreWithReportData,
  ReportSummaryData,
} from '@/models/swine/report/report.model';
import { getBase64ImageFromURL } from '@/utils/base64ImageFromUrl';
import { addDecimalPointToNumbers } from '@/utils/formats';
import { GenerateChartImage } from '@/utils/generate-chart/generate-chart-image';
import { blue, brown, lightGrey, pink, red } from '../../colors';
import { imgIcDashAppi, imgIcIndex, imgIcPrevalence } from '../../images';
import { getPneumoniaIndexColor } from '../../utils/utils';

export async function getBronchopneumoniaScoreAndAttachments(
  generalDetails: ReportSummaryData,
  bronchopneumoniaScore: BronchopneumoniaScoreWithReportData,
  brochopneumoniaDataArray: any,
) {
  const chartData = {
    labels: [
      `Normal Lung 0%`,
      `Normal Lung 1-10%`,
      `Pneumonia 11-20%`,
      `Pneumonia 21-30%`,
      `Pneumonia 31-40%`,
      `Severe 41-50%`,
      `Severe > 50%`,
      `NE`,
    ],
    datasets: [
      {
        data: [
          bronchopneumoniaScore.pneumoniaPercentageDict[`0`],
          bronchopneumoniaScore.pneumoniaPercentageDict[`1`],
          bronchopneumoniaScore.pneumoniaPercentageDict[`2`],
          bronchopneumoniaScore.pneumoniaPercentageDict[`3`],
          bronchopneumoniaScore.pneumoniaPercentageDict[`4`],
          bronchopneumoniaScore.pneumoniaPercentageDict[`5`],
          bronchopneumoniaScore.pneumoniaPercentageDict[`6`],
          bronchopneumoniaScore.pneumoniaPercentageDict[`NE`],
        ],
        backgroundColor: [
          `#D6FFA4`,
          `#99E895`,
          `#FFF5C9`,
          `#FFF084`,
          `#FFD16A`,
          `#FFEAEA`,
          `#FFC1C1`,
          `#D9F2FF`,
        ],
        borderColor: [
          `#6C9D31`,
          `#6C9D31`,
          `#B99913`,
          `#B99913`,
          `#B99913`,
          `#F15453`,
          `#F15453`,
          `#00A5E1`,
        ],
        borderWidth: 1,
        barPercentage: 0.2,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: true,
    legend: {
      display: false,
    },
    plugins: {
      datalabels: {
        display: false,
      },
    },
    scales: {
      yAxes: [
        {
          stacked: true,
          ticks: {
            min: 0,
            max: 120,
            beginAtZero: true,
            callback: function (tick: string | number) {
              return tick.toString() + `%`;
            },
          },
        },
      ],
      xAxes: [
        {
          stacked: true,
          offset: true,
        },
      ],
    },
    animation: false
  };
  const gci = new GenerateChartImage(chartData, options);

  const brochopneumoniaDataLayout: any[] = [];

  if (brochopneumoniaDataArray.length) {
    for (let brochopneumoniaData of brochopneumoniaDataArray) {
      let attachments = brochopneumoniaData.pop();

      brochopneumoniaDataLayout.push({
        text: 'Lungs Breakdown',
        bold: true,
      })
      brochopneumoniaDataLayout.push({
        layout: 'regularTable',
        margin: [0, 30, 0, 0],
        style: ['fontSize8', 'textCenter'],
        table: {
          widths: ['auto', '*'],
          body: [
            [
              {
                text: 'Lung ID',
                bold: true,
                margin: [30, 25, 30, 0],
                border: [false, false, true, false],
                fillColor: '#f2f2f2',
                rowSpan: 2,
              },
              {
                text: 'Total Bronchopneumonia Score',
                bold: true,
                margin: [0, 10, 0, 10],
                border: [false, false, false, false],
                fillColor: '#f2f2f2',
              },
            ],
            [
              '',
              {
                text: '100%',
                margin: [0, 5, 0, 5],
                border: [false, true, false, false],
                fillColor: '#f2f2f2',
              },
            ],
            brochopneumoniaData,
          ],
        },
      })

      let attachmentLayout: any[] = []
      let attachmentBase64Function: any[] = []

      for (let attachment of attachments)  {
        attachmentBase64Function.push(getBase64ImageFromURL(attachment.link))
      }

      const attachmentBase64 = await Promise.all(attachmentBase64Function)

      for (let index = 0; index < attachments.length; index++)  {
        let _attachment: any[] = []

        _attachment.push({
          width: 200,
          height: 200,
          image: attachmentBase64[index],
        })
        _attachment.push({
          color: '#1697db',
          text: `Attachment ${index + 1}`,
          link: attachments[index].link,
          margin: [0, 10, 0, 0],
        })

        attachmentLayout.push({
          stack: _attachment,
          alignment: 'center',
          width: '33%',
        })
      }

      brochopneumoniaDataLayout.push({
        columns: attachmentLayout,
        margin: [0, 50, 0, 0],
      })
      brochopneumoniaDataLayout.push({
        text: '',
        pageBreak: 'after',
      })
    }
  } else {
    brochopneumoniaDataLayout.push({
      text: '',
      pageBreak: 'after',
    })
  }
  
  const content = [
    {
      text: 'Bronchopneumonia Score',
      style: 'sectionHeader',
    },
    {
      canvas: [
        {
          type: `rect`,
          w: 380,
          h: 60,
          r: 10,
          x: 0,
          y: 0,
          lineColor: `#E3E3E3`,
        },
      ],
      absolutePosition: { x: 40, y: 85 },
    },
    {
      canvas: [
        {
          type: `rect`,
          w: 370,
          h: 60,
          r: 10,
          x: 0,
          y: 0,
          lineColor: `#E3E3E3`,
        },
      ],
      absolutePosition: { x: 428, y: 85 },
    },
    {
      columns: [
        {
          style: 'fontSize8',
          table: {
            widths: ['auto', 'auto', 'auto'],
            body: [
              [
                {
                  svg: imgIcDashAppi,
                  width: 41,
                  border: [false, false, false, false],
                },
                {
                  text: 'Pneumonia Index Interpretation',
                  bold: true,
                  border: [false, false, false, false],
                  fontSize: 10,
                  marginTop: 11,
                },
                {
                  style: ['fontSize8'],
                  table: {
                    widths: ['auto', 'auto'],
                    body: [
                      [
                        {
                          text: '0.1 to 0.5',
                          color: '#5D7D38',
                          fillColor: '#D6FFA4',
                          border: [false, false, false, false],
                        },
                        {
                          text: 'Impercitible Pneumonia',
                          color: '#5D7D38',
                          fillColor: '#D6FFA4',
                          border: [false, false, false, false],
                        },
                      ],
                      [
                        {
                          text: '0.56 to 0.89',
                          color: '#836D14',
                          fillColor: '#FFF084',
                          border: [false, false, false, false],
                        },
                        {
                          text: 'Presence of Pneumonia',
                          color: '#836D14',
                          fillColor: '#FFF084',
                          border: [false, false, false, false],
                        },
                      ],
                      [
                        {
                          text: '> 0.9',
                          color: '#FF8180',
                          fillColor: '#FFEAEA',
                          border: [false, false, false, false],
                        },
                        {
                          text: 'Serious Pneumonia',
                          color: '#FF8180',
                          fillColor: '#FFEAEA',
                          border: [false, false, false, false],
                        },
                      ],
                    ],
                  },
                  border: [false, false, false, false],
                  width: 200,
                },
              ],
            ],
          },
          layout: {
            paddingTop: function () {
              return 6;
            },
            paddingLeft: function () {
              return 6;
            },
          },
        },
        {
          table: {
            widths: ['auto', '*', 'auto', '*'],
            body: [
              [
                {
                  svg: imgIcIndex,
                  width: 41,
                  border: [false, false, false, false],
                },
                {
                  stack: [
                    {
                      text: 'Pneumonia Index',
                      bold: true,
                      fontSize: 8,
                      marginTop: 5,
                    },
                    {
                      text: `${generalDetails.pneumoniaIndex}`,
                      bold: true,
                      color: getPneumoniaIndexColor(generalDetails),
                      fontSize: 20,
                    },
                  ],
                  border: [false, false, false, false],
                },
                {
                  svg: imgIcPrevalence,
                  width: 41,
                  border: [false, false, false, false],
                },
                {
                  stack: [
                    {
                      text: 'Pneumonia Prevalence',
                      bold: true,
                      fontSize: 8,
                      marginTop: 5,
                    },
                    {
                      text: `${generalDetails.pneumoniaPrevalence}%`,
                      bold: true,
                      color: '#FF4C4B',
                      fontSize: 20,
                    },
                  ],
                  border: [false, false, false, false],
                },
              ],
            ],
          },
          layout: 'card',
        },
      ],
      columnGap: 15,
      margin: [0, 0, 0, 15],
    },
    {
      table: {
        widths: ['*', '*', '*'],
        body: [
          [
            {
              stack: [
                {
                  text: '% of Bronchopneumonic Lungs (Mild to Severe)',
                  color: '#ACACAC',
                  fontSize: 8,
                  alignment: 'center',
                  fillColor: '#F5F5F5',
                  border: [false, false, true, false],
                },
                {
                  text: `${addDecimalPointToNumbers(
                    bronchopneumoniaScore.bronchopneumoniaLungsMildToSevere,
                  )}%`,
                  color: '#FF4C4B',
                  alignment: 'center',
                  fontSize: 16,
                  fillColor: '#F5F5F5',
                  border: [false, false, true, false],
                  bold: true,
                },
              ],
              fillColor: `${lightGrey}`,
              border: [false, false, false, false],
            },
            {
              stack: [
                {
                  text: 'Average % Affected Surface out of All Lungs',
                  color: '#ACACAC',
                  fontSize: 8,
                  alignment: 'center',
                  fillColor: '#F5F5F5',
                  border: [false, false, true, false],
                },
                {
                  text: `${addDecimalPointToNumbers(
                    bronchopneumoniaScore.averagePercentageAffectedLungsSurface,
                  )}%`,
                  color: '#FF4C4B',
                  alignment: 'center',
                  fontSize: 16,
                  fillColor: '#F5F5F5',
                  border: [false, false, true, false],
                  bold: true,
                },
              ],
              fillColor: `${lightGrey}`,
              border: [false, false, false, false],
            },
            {
              stack: [
                {
                  text:
                    'Average % of Affected Surface out of Lungs with Active Pneumonia',
                  color: '#ACACAC',
                  fontSize: 8,
                  alignment: 'center',
                  fillColor: '#F5F5F5',
                  border: [false, false, false, false],
                },
                {
                  text: `${addDecimalPointToNumbers(
                    bronchopneumoniaScore.averagePercentageAffectedLungsSurfaceWithActivePneumonia,
                  )}%`,
                  color: '#FF4C4B',
                  alignment: 'center',
                  fontSize: 16,
                  fillColor: '#F5F5F5',
                  border: [false, false, false, false],
                  bold: true,
                },
              ],
              fillColor: `${lightGrey}`,
              border: [false, false, false, false],
            },
          ],
        ],
      },
      layout: 'card',
      margin: [0, 0, 0, 15],
    },
    {
      image: gci.generateImage(),
      width: 741,
      height: 200,
      alignment: 'center',
      marginBottom: 5,
    },
    {
      style: ['textCenter'],
      table: {
        widths: ['*', '*', '*', '*', '*', '*', '*', '*', '*', '*'],
        body: [
          [
            {
              text: 'Lung Classification',
              bold: true,
              fillColor: '#F5F5F5',
              fontSize: 8,
              border: [false, false, false, true],
            },
            {
              text: 'Normal Lung',
              bold: true,
              fillColor: '#D6FFA4',
              color: '#557532',
              colSpan: 2,
              fontSize: 8,
              border: [false, false, false, true],
            },
            '',
            {
              text: 'Pneumonia',
              bold: true,
              fillColor: '#FFF5C9',
              color: '#745E04',
              colSpan: 3,
              fontSize: 8,
              border: [false, false, false, true],
            },
            '',
            '',
            {
              text: 'Severe Pneumonia',
              bold: true,
              fillColor: '#FFEAEA',
              color: '#FF4C4B',
              colSpan: 2,
              fontSize: 8,
              border: [false, false, false, true],
            },
            '',
            {
              text: 'NE',
              bold: true,
              fillColor: '#DAF2FF',
              color: '#01A5E1',
              fontSize: 8,
              border: [false, false, false, true],
            },
            {
              text: 'Total Lung Scored',
              bold: true,
              fillColor: '#F5F5F5',
              fontSize: 8,
              border: [false, false, false, true],
            },
          ],
          [
            {
              text: 'Category',
              fillColor: '#F5F5F5',
              bold: true,
              rowSpan: 2,
              fontSize: 8,
              border: [false, false, false, true],
            },
            {
              text: '0',
              color: '#557532',
              fillColor: '#D6FFA4',
              fontSize: 8,
              border: [false, false, false, true],
            },
            {
              text: '1',
              color: '#557532',
              fillColor: '#99E895',
              fontSize: 8,
              border: [false, false, false, true],
            },
            {
              text: '2',
              color: '#557532',
              fillColor: '#FFF5C9',
              fontSize: 8,
              border: [false, false, false, true],
            },
            {
              text: '3',
              color: '#557532',
              fillColor: '#FFF084',
              fontSize: 8,
              border: [false, false, false, true],
            },
            {
              text: '4',
              color: '#557532',
              fillColor: '#FFD16A',
              fontSize: 8,
              border: [false, false, false, true],
            },
            {
              text: '5',
              color: `${red}`,
              fillColor: `${pink}`,
              fontSize: 8,
              border: [false, false, false, true],
            },
            {
              text: '6',
              color: `${red}`,
              fillColor: '#FFC1C1',
              fontSize: 8,
              border: [false, false, false, true],
            },
            {
              text: '',
              color: '#01A5E1',
              fillColor: '#DAF2FF',
              fontSize: 8,
              border: [false, false, false, true],
            },
            {
              text: '',
              fillColor: '#F5F5F5',
              fontSize: 8,
              border: [false, false, false, true],
            },
          ],
          [
            '',
            {
              text: '0 %',
              color: '#557532',
              fillColor: '#D6FFA4',
              fontSize: 8,
              border: [false, false, false, true],
            },
            {
              text: '1-10 %',
              color: '#557532',
              fillColor: '#99E895',
              fontSize: 8,
              border: [false, false, false, true],
            },
            {
              text: '11-20 %',
              color: '#557532',
              fillColor: '#FFF5C9',
              fontSize: 8,
              border: [false, false, false, true],
            },
            {
              text: '21-30 %',
              color: '#557532',
              fillColor: '#FFF084',
              fontSize: 8,
              border: [false, false, false, true],
            },
            {
              text: '31-40 %',
              color: '#557532',
              fillColor: '#FFD16A',
              fontSize: 8,
              border: [false, false, false, true],
            },
            {
              text: '41-50 %',
              color: `${red}`,
              fillColor: `${pink}`,
              fontSize: 8,
              border: [false, false, false, true],
            },
            {
              text: '> 50 %',
              color: `${red}`,
              fillColor: '#FFC1C1',
              fontSize: 8,
              border: [false, false, false, true],
            },
            {
              text: 'NA',
              color: '#01A5E1',
              fillColor: '#DAF2FF',
              fontSize: 8,
              border: [false, false, false, true],
            },
            {
              text: '',
              fillColor: '#F5F5F5',
              border: [false, false, false, true],
            },
          ],
          [
            {
              text: 'No. of Lung',
              bold: true,
              fillColor: '#F5F5F5',
              fontSize: 8,
              border: [false, false, false, true],
            },
            {
              text: `${bronchopneumoniaScore.pneumoniaCountDict['0']}`,
              color: '#557532',
              fillColor: '#D6FFA4',
              fontSize: 8,
              border: [false, false, false, true],
            },
            {
              text: `${bronchopneumoniaScore.pneumoniaCountDict['1']}`,
              color: '#557532',
              fillColor: '#99E895',
              fontSize: 8,
              border: [false, false, false, true],
            },
            {
              text: `${bronchopneumoniaScore.pneumoniaCountDict['2']}`,
              color: '#557532',
              fillColor: '#FFF5C9',
              fontSize: 8,
              border: [false, false, false, true],
            },
            {
              text: `${bronchopneumoniaScore.pneumoniaCountDict['3']}`,
              color: '#557532',
              fillColor: '#FFF084',
              fontSize: 8,
              border: [false, false, false, true],
            },
            {
              text: `${bronchopneumoniaScore.pneumoniaCountDict['4']}`,
              color: '#557532',
              fillColor: '#FFD16A',
              fontSize: 8,
              border: [false, false, false, true],
            },
            {
              text: `${bronchopneumoniaScore.pneumoniaCountDict['5']}`,
              color: `${red}`,
              fillColor: `${pink}`,
              fontSize: 8,
              border: [false, false, false, true],
            },
            {
              text: `${bronchopneumoniaScore.pneumoniaCountDict['6']}`,
              color: `${red}`,
              fillColor: '#FFC1C1',
              fontSize: 8,
              border: [false, false, false, true],
            },
            {
              text: `${bronchopneumoniaScore.pneumoniaCountDict['NE']}`,
              color: `${blue}`,
              fillColor: '#DAF2FF',
              fontSize: 8,
              border: [false, false, false, true],
            },
            {
              text: `${bronchopneumoniaScore.totalLungsScored}`,
              fillColor: 'white',
              fontSize: 8,
              border: [false, false, false, true],
            },
          ],
          [
            {
              text: '% of Lung',
              bold: true,
              fillColor: '#F5F5F5',
              fontSize: 8,
              border: [false, false, false, true],
            },
            {
              text: `${addDecimalPointToNumbers(
                bronchopneumoniaScore.pneumoniaPercentageDict['0'],
              )}%`,
              color: '#557532',
              fillColor: '#D6FFA4',
              fontSize: 8,
              border: [false, false, false, true],
            },
            {
              text: `${addDecimalPointToNumbers(
                bronchopneumoniaScore.pneumoniaPercentageDict['1'],
              )}%`,
              color: '#557532',
              fillColor: '#99E895',
              fontSize: 8,
              border: [false, false, false, true],
            },
            {
              text: `${addDecimalPointToNumbers(
                bronchopneumoniaScore.pneumoniaPercentageDict['2'],
              )}%`,
              color: '#557532',
              fillColor: '#FFF5C9',
              fontSize: 8,
              border: [false, false, false, true],
            },
            {
              text: `${addDecimalPointToNumbers(
                bronchopneumoniaScore.pneumoniaPercentageDict['3'],
              )}%`,
              color: '#557532',
              fillColor: '#FFF084',
              fontSize: 8,
              border: [false, false, false, true],
            },
            {
              text: `${addDecimalPointToNumbers(
                bronchopneumoniaScore.pneumoniaPercentageDict['4'],
              )}%`,
              color: '#557532',
              fillColor: '#FFD16A',
              fontSize: 8,
              border: [false, false, false, true],
            },
            {
              text: `${addDecimalPointToNumbers(
                bronchopneumoniaScore.pneumoniaPercentageDict['5'],
              )}`,
              color: `${red}`,
              fillColor: `${pink}`,
              fontSize: 8,
              border: [false, false, false, true],
            },
            {
              text: `${addDecimalPointToNumbers(
                bronchopneumoniaScore.pneumoniaPercentageDict['6'],
              )}%`,
              color: `${red}`,
              fillColor: '#FFC1C1',
              fontSize: 8,
              border: [false, false, false, true],
            },
            {
              text: `${addDecimalPointToNumbers(
                bronchopneumoniaScore.pneumoniaPercentageDict['NE'],
              )}%`,
              color: '#01A5E1',
              fillColor: '#DAF2FF',
              fontSize: 8,
              border: [false, false, false, true],
            },
            {
              text: '',
              fillColor: '#F5F5F5',
              fontSize: 8,
              border: [false, false, false, true],
            },
          ],
          [
            {
              text: '',
              border: [false, false, false, false],
            },
            {
              text: `${addDecimalPointToNumbers(
                bronchopneumoniaScore.pneumoniaTotalPercentageDict[
                  'Normal Lung'
                ],
              )}%`,
              color: '#557532',
              fillColor: '#D6FFA4',
              fontSize: 8,
              colSpan: 2,
              border: [false, false, false, false],
            },
            '',
            {
              text: `${addDecimalPointToNumbers(
                bronchopneumoniaScore.pneumoniaTotalPercentageDict['Pneumonia'],
              )}%`,
              color: `${brown}`,
              fillColor: '#FFF5C9',
              fontSize: 8,
              colSpan: 3,
              border: [false, false, false, false],
            },
            '',
            '',
            {
              text: `${addDecimalPointToNumbers(
                bronchopneumoniaScore.pneumoniaTotalPercentageDict[
                  'Severe Pneumonia'
                ],
              )}%`,
              color: `${red}`,
              fillColor: `${pink}`,
              fontSize: 8,
              colSpan: 2,
              border: [false, false, false, false],
            },
            '',
            {
              text: `${addDecimalPointToNumbers(
                bronchopneumoniaScore.pneumoniaTotalPercentageDict['NE'],
              )}%`,
              color: '#01A5E1',
              fillColor: '#DAF2FF',
              fontSize: 8,
              border: [false, false, false, false],
            },
            {
              text: '',
              border: [false, false, false, false],
              fillColor: `${lightGrey}`,
            },
          ],
        ],
      },
      layout: 'allGreyBorder',
      pageBreak: 'after',
    },
    {
      text: 'Bronchopneumonia Score Attachments',
      style: 'sectionHeader',
    },
    ...brochopneumoniaDataLayout,
  ];

  gci.clear();
  return content;
}
