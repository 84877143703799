






















































import { Component, Prop, Vue } from 'vue-property-decorator';
import { ReportType } from '@/utils/constants';
import { AppIndexAndPrevalenceWithReportData } from '@/models/swine/report/report.model';
@Component({})
export default class AppIndexAndPrevalenceTable extends Vue {
  @Prop({
    type: Object,
    default: null,
  })
  currentReportDetails!: AppIndexAndPrevalenceWithReportData;

  reportType = ReportType;

  get setTableColor() {
    let output = ``;
    if (this.currentReportDetails.appIndexDetails) {
      if (
        this.currentReportDetails.appIndexDetails.appIndexBackgroundColor !==
          undefined &&
        !this.currentReportDetails.appIndexDetails.appIndexBackgroundColor.includes(
          '#FF4D4D',
        )
      ) {
        output = `color: ${this.currentReportDetails.appIndexDetails.appIndexColor};`;
      } else {
        output = `color: white;`;
      }
      output += ` background-color: ${this.currentReportDetails.appIndexDetails.appIndexBackgroundColor}`;
    }
    return output;
  }
}
