

















import { Vue, Component, Prop, Emit } from 'vue-property-decorator';
import { EconomicCalculationModalDataType } from '@/models/swine/economic-calculation.model';
import { EconomicCalculationWithReportData } from '@/models/swine/report/report.model';
import { EconomicCalculationType } from '@/utils/constants';
import PleurisyAssumptionReport from './PleurisyAssumptionReport.vue';
import PulmotilAssumptionReport from './PulmotilAssumptionReport.vue';
@Component({
  components: {
    PleurisyAssumptionReport,
    PulmotilAssumptionReport,
  },
})
export default class MultiAssumptionReport extends Vue {
  @Prop({
    type: Object,
    default: null,
  })
  currentReportDetails!: EconomicCalculationWithReportData;

  @Prop({
    type: Object,
    required: true,
  })
  calculatorModalData!: EconomicCalculationModalDataType;

  get isCurrentEconomicTypePulmotil() {
    return (
      this.calculatorModalData.economicCalculationType ===
      EconomicCalculationType.pulmotil
    );
  }

  @Emit(`update`)
  handleUpdate(payload: EconomicCalculationModalDataType) {
    return payload;
  }
}
