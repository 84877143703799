


























import { Component, Prop, Vue } from 'vue-property-decorator';
import { AppIndexAndPrevalenceWithReportData } from '@/models/swine/report/report.model';
import AppIndexCard from './AppIndexCard.vue';
import BarAndLineChart from '@/components/swine/reports/BarAndLineChart.vue';
import AppIndexAndPrevalenceTable from './AppIndexAndPrevalenceTable.vue';
import IndustryAppIndexChart from '@/components/swine/reports/IndustryAppIndexChart.vue';
import { ReportType } from '@/utils/constants';

@Component({
  components: {
    AppIndexCard,
    BarAndLineChart,
    AppIndexAndPrevalenceTable,
    IndustryAppIndexChart,
  },
})
export default class AppIndexAndPrevalence extends Vue {
  @Prop({
    type: Object,
    default: null,
  })
  currentReportDetails!: AppIndexAndPrevalenceWithReportData;
  chartData: Chart.ChartData | null = null;
  options: Chart.ChartOptions | null = null;
  reportType = ReportType;

  mounted() {
    if (this.currentReportDetails) {
      this.options = {
        elements: {
          point: {
            radius: 6,
          },
        },
        maintainAspectRatio: false,
        legend: {
          display: false,
        },
        plugins: {
          datalabels: {
            display: false,
          },
        },
        scales: {
          yAxes: [
            {
              ticks: {
                min: 0,
                max: 4,
                beginAtZero: true,
                callback: function (tick) {
                  return tick.toString();
                },
              },
              position: `left`,
              id: `AppIndex`,
            },
            {
              ticks: {
                min: 0,
                max: 120,
                beginAtZero: true,
                callback: function (tick) {
                  return tick.toString() + `%`;
                },
              },
              position: `right`,
              id: `Prevalence`,
              gridLines: {
                display: false,
              },
            },
          ],
          xAxes: [
            {
              offset: true,
            },
            {
              offset: true,
              display: false,
            },
          ],
        },
      };

      this.chartData = {
        labels: [`Dorsal Pleurisy Index`],
        datasets: [
          {
            data: [this.currentReportDetails.appIndexDetails.appIndex],
            type: `bar`,
            order: 2,
            backgroundColor: [
              this.currentReportDetails.reportType === this.reportType.NORMAL &&
              this.currentReportDetails.appIndexDetails
                .appIndexBackgroundColor !== undefined
                ? this.currentReportDetails.appIndexDetails
                    .appIndexBackgroundColor
                : `#f5f5f5`,
            ],
            borderColor: [
              this.currentReportDetails.reportType === this.reportType.NORMAL
                ? this.currentReportDetails.appIndexDetails.appIndexColor
                : `#333333`,
            ],
            borderWidth: 1,
            barPercentage: 0.2,
            yAxisID: `AppIndex`,
          },
          {
            data: [this.currentReportDetails.appPleurisyPrevalence],
            type: 'line',
            order: 1,
            backgroundColor: [`#ECD9FF`],
            borderColor: [`#6B3C97`],
            borderWidth: 1,
            barPercentage: 0.2,
            yAxisID: `Prevalence`,
          },
          {
            data: [this.currentReportDetails.pleurisyPrevalence],
            type: 'line',
            backgroundColor: [`#0072CE`],
            borderColor: [`#0072CE`],
            borderWidth: 3,
            barPercentage: 0.2,
            yAxisID: `Prevalence`,
            pointStyle: `crossRot`,
          },
        ],
      };
    }
  }
}
