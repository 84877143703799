var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.displayDataAssumption)?_c('div',{staticClass:"columns"},_vm._l((_vm.displayDataAssumption),function(data,index){return _c('div',{key:index,staticClass:"column"},[_c('div',{staticClass:"content",class:{
        'captured-opportunity': data.headerText === _vm.capturedOpportunityLoss,
      }},[_c('h5',{class:{
          'captured-opportunity-title':
            data.headerText === _vm.capturedOpportunityLoss,
          'medication-program-title': data.headerText === _vm.medicationProgram,
          'extra-margin-top': data.assumptionValue,
        }},[_vm._v(" "+_vm._s(data.headerText)+" ")]),(data.assumptionValue)?_c('div',{staticClass:"arrow-display"},[_c('div',{class:{
            'medication-program': data.headerText === _vm.medicationProgram,
          }},[_c('h2',{class:{
              'captured-opportunity-value':
                data.headerText === _vm.capturedOpportunityLoss,
            }},[_vm._v(" "+_vm._s(data.headerValue)+" ")])]),_c('b-icon',{staticClass:"arrow-margin",attrs:{"icon":"arrow-right","type":"is-primary"}}),_c('div',{staticClass:"assumption-value"},[_vm._v(" "+_vm._s(data.assumptionValue)+" ")])],1):_c('div',[_c('div',{staticClass:"assumption-value"},[_vm._v(" "+_vm._s(data.assumptionValue)+" ")]),_c('div',{class:{
            'medication-program': data.headerText === _vm.medicationProgram,
          }},[_c('h2',{class:{
              'captured-opportunity-value':
                data.headerText === _vm.capturedOpportunityLoss,
            }},[_vm._v(" "+_vm._s(data.headerValue)+" ")])])]),_c('h6',[_vm._v(_vm._s(data.subText))]),(data.subAssumptionValue)?_c('div',{staticClass:"sub-arrow-display"},[_c('h4',[_vm._v(_vm._s(data.subValue))]),_c('b-icon',{staticClass:"arrow-margin",attrs:{"icon":"arrow-right","type":"is-primary"}}),_c('h4',{staticClass:"sub-assumption-value"},[_vm._v(_vm._s(data.subAssumptionValue))])],1):[_c('h4',{class:{
            'sub-assumption-value':
              data.headerText === _vm.capturedOpportunityLoss,
            'medication-sub-value': data.headerText === _vm.medicationProgram,
          }},[_vm._v(" "+_vm._s(data.subValue)+" ")])]],2)])}),0):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }