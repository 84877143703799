








































































































































































































































































































































































































































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { PulmotilCalculationModalData } from '@/models/swine/economic-calculation.model';
import { DisplayData } from '@/models/swine/report/report.model';
import SectionLayoutCard from '@/components/swine/reports/SectionLayoutCard.vue';
import {
  calculateAdditionalCostPerKiloOfFeedsInCleanSowProgram,
  calculateAdditionalCostPerKiloOfFeedsInEarlyIntervention,
  calculateAdditionalCostPerKiloOfFeedsInGrowerProgram,
  calculateConsumptionPerLactactingSow,
  calculateCostOfMedicationPerGrowerPigInGrowerProgram,
  calculateCostOfMedicationPerNurseryPigletsInEarlyIntervention,
  calculateCostOfMedicationPerPigletsInCleanSowProgram,
  calculateFeedConsumptionPerGrowerPig,
  calculateFeedConsumptionPerNurseryPiglet,
  calculateTotalConsumptionOfAllLactactingSows,
  calculateTotalCostOfMedicationAtGrowerInGrowerProgram,
  calculateTotalCostOfMedicationAtNurseryInEarlyIntervention,
  calculateTotalCostOfMedicationInLactationInCleanSowProgram,
  calculateTotalCostOfMedicationPerLactactingSowsInCleanSowProgram,
  calculateTotalFeedConsumptionOfNurseryPiglets,
  calculateTotalFeedConsumptionPerGrowerPig,
  calculateTotalInvestmentForGrowerPigsProduced,
  calculateTotalMedicationInvestmentPerPig,
  calculateTotalMedicationUsageInCleanSowProgram,
  calculateTotalMedicationUsageInEarlyIntervention,
  calculateTotalMedicationUsageInGrowerProgram,
} from '@/utils/economic-calculation/pulmotil/formula';
import MedicationCollapseTemplate from '@/components/swine/economicCalculation/MedicationCollapseTemplate.vue';
import { addCommas } from '@/utils/formats';
import { roundTo } from 'round-to';
@Component({
  components: { SectionLayoutCard, MedicationCollapseTemplate },
})
export default class PulmotilMedicationReportForm extends Vue {
  @Prop({ required: true })
  calculatorModalInputDataRetrieve!: PulmotilCalculationModalData;

  get lactationFeedCollapseTitle() {
    return `Lactation Feed ~ ${roundTo(
      this.calculatorModalInputDataRetrieve.numberOfSowsFarrowingPerMonth,
      0,
    )} heads`;
  }

  get nurseryFeedCollapseTitle() {
    return `Nursery Feed ~ ${roundTo(
      this.calculatorModalInputDataRetrieve.numberOfPigletsAtNursery,
      0,
    )} heads`;
  }

  get growerFeedCollapseTitle() {
    return `Grower Feed ~ ${roundTo(
      this.calculatorModalInputDataRetrieve.numberOfGrowerPigs,
      0,
    )} heads`;
  }

  get cleanSowMedicineBrand() {
    return this.calculatorModalInputDataRetrieve.cleanSowMedicineBrand || '-';
  }

  get earlyInterventionMedicineBrand() {
    return (
      this.calculatorModalInputDataRetrieve.earlyInterventionMedicineBrand ||
      '-'
    );
  }

  get growerMedicineBrand() {
    return this.calculatorModalInputDataRetrieve.growerMedicineBrand || '-';
  }

  get growerHeads() {
    return roundTo(this.calculatorModalInputDataRetrieve.numberOfGrowerPigs, 0);
  }

  get costOfMedicationPerPigletsHeads() {
    return this.calculatorModalInputDataRetrieve.averageLitterSizeBornAlive;
  }

  get currentCurrencySymbol() {
    return this.calculatorModalInputDataRetrieve.currencySymbol;
  }

  get sectionCardDetails(): DisplayData[] {
    return [
      {
        headerText: `Total Medication Investment / pig`,
        subText: ``,
        value: `${this.currentCurrencySymbol} ${this.addComma(
          this.totalMedicationInvestmentPerPig,
          2,
        )}`,
      },
      {
        headerText: `Total Investment for ${this.growerHeads} Grower Produced`,
        subText: ``,
        value: `${this.currentCurrencySymbol} ${this.addComma(
          this.totalInvestmentForGrowerProduced,
          2,
        )}`,
      },
    ];
  }
  // Feed Consumption
  get consumptionPerLactactingSow() {
    const adfiAtGestation = this.calculatorModalInputDataRetrieve
      .adfiAtGestation
      ? this.calculatorModalInputDataRetrieve.adfiAtGestation
      : 0;
    const numberOfDaysPreFarrowing = this.calculatorModalInputDataRetrieve
      .numberOfDaysPreFarrowing
      ? this.calculatorModalInputDataRetrieve.numberOfDaysPreFarrowing
      : 0;
    const adfiAtLactation = this.calculatorModalInputDataRetrieve
      .adfiAtLactation
      ? this.calculatorModalInputDataRetrieve.adfiAtLactation
      : 0;
    const numberOfDaysPostFarrowing = this.calculatorModalInputDataRetrieve
      .numberOfDaysPostFarrowing
      ? this.calculatorModalInputDataRetrieve.numberOfDaysPostFarrowing
      : 0;

    return calculateConsumptionPerLactactingSow(
      adfiAtGestation,
      numberOfDaysPreFarrowing,
      adfiAtLactation,
      numberOfDaysPostFarrowing,
    );
  }

  get totalConsumptionOfAllLactactingSows() {
    return calculateTotalConsumptionOfAllLactactingSows(
      this.consumptionPerLactactingSow,
      this.calculatorModalInputDataRetrieve.numberOfSowsFarrowingPerMonth,
    );
  }

  // Medication program - Clean Sow Program
  get totalMedicationUsageInCleanSowProgram() {
    if (this.calculatorModalInputDataRetrieve.medicationDoseInCleanSowProgram) {
      return calculateTotalMedicationUsageInCleanSowProgram(
        this.totalConsumptionOfAllLactactingSows,
        this.calculatorModalInputDataRetrieve.medicationDoseInCleanSowProgram,
      );
    }
    return 0;
  }

  get totalCostOfMedicationInLactation() {
    if (
      this.calculatorModalInputDataRetrieve
        .costOfMedicationPerKiloInCleanSowProgram
    ) {
      return calculateTotalCostOfMedicationInLactationInCleanSowProgram(
        this.calculatorModalInputDataRetrieve
          .costOfMedicationPerKiloInCleanSowProgram,
        this.totalMedicationUsageInCleanSowProgram,
      );
    }
    return 0;
  }

  get additionalCostPerKiloOfFeedsInCleanSowProgram() {
    return calculateAdditionalCostPerKiloOfFeedsInCleanSowProgram(
      this.totalCostOfMedicationInLactation,
      this.totalConsumptionOfAllLactactingSows,
    );
  }

  get totalCostOfMedicationPerLactactingSowsInCleanSowProgram() {
    return calculateTotalCostOfMedicationPerLactactingSowsInCleanSowProgram(
      this.totalCostOfMedicationInLactation,
      this.calculatorModalInputDataRetrieve.numberOfSowsFarrowingPerMonth,
    );
  }

  get costOfMedicationPerPiglets() {
    if (this.calculatorModalInputDataRetrieve.averageLitterSizeBornAlive) {
      return calculateCostOfMedicationPerPigletsInCleanSowProgram(
        this.totalCostOfMedicationPerLactactingSowsInCleanSowProgram,
        this.calculatorModalInputDataRetrieve.averageLitterSizeBornAlive,
      );
    }
    return 0;
  }

  // Nursery Feed
  get feedConsumptionPerNurseryPiglet() {
    if (
      this.calculatorModalInputDataRetrieve.numberOfDaysFedInNursery &&
      this.calculatorModalInputDataRetrieve.feedConsumptionPerDayInNursery
    ) {
      return calculateFeedConsumptionPerNurseryPiglet(
        this.calculatorModalInputDataRetrieve.numberOfDaysFedInNursery,
        this.calculatorModalInputDataRetrieve.feedConsumptionPerDayInNursery,
      );
    }
    return 0;
  }

  get totalFeedConsumptionOfNurseryPiglets() {
    return calculateTotalFeedConsumptionOfNurseryPiglets(
      this.feedConsumptionPerNurseryPiglet,
      this.calculatorModalInputDataRetrieve.numberOfPigletsAtNursery,
    );
  }

  // Medication Program - Early Intervention
  get totalMedicationUsageInEarlyIntervention() {
    if (
      this.calculatorModalInputDataRetrieve
        .medicationDoseInEarlyInterventionProgram
    ) {
      return calculateTotalMedicationUsageInEarlyIntervention(
        this.totalFeedConsumptionOfNurseryPiglets,
        this.calculatorModalInputDataRetrieve
          .medicationDoseInEarlyInterventionProgram,
      );
    }
    return 0;
  }

  get totalCostOfMedicationAtNurseryInEarlyIntervention() {
    if (
      this.calculatorModalInputDataRetrieve
        .costOfMedicationPerKiloInEarlyInterventionProgram
    ) {
      return calculateTotalCostOfMedicationAtNurseryInEarlyIntervention(
        this.totalMedicationUsageInEarlyIntervention,
        this.calculatorModalInputDataRetrieve
          .costOfMedicationPerKiloInEarlyInterventionProgram,
      );
    }
    return 0;
  }

  get additionalCostPerKiloOfFeedsInEarlyIntervention() {
    return calculateAdditionalCostPerKiloOfFeedsInEarlyIntervention(
      this.totalCostOfMedicationAtNurseryInEarlyIntervention,
      this.totalFeedConsumptionOfNurseryPiglets,
    );
  }

  get costOfMedicationPerNurseryPiglets() {
    return calculateCostOfMedicationPerNurseryPigletsInEarlyIntervention(
      this.totalCostOfMedicationAtNurseryInEarlyIntervention,
      this.calculatorModalInputDataRetrieve.numberOfPigletsAtNursery,
    );
  }

  // Grower Feed
  get feedConsumptionPerGrowerPig() {
    if (
      this.calculatorModalInputDataRetrieve.numberOfDaysFedInGrower &&
      this.calculatorModalInputDataRetrieve.feedConsumptionPerDayInGrower
    ) {
      return calculateFeedConsumptionPerGrowerPig(
        this.calculatorModalInputDataRetrieve.numberOfDaysFedInGrower,
        this.calculatorModalInputDataRetrieve.feedConsumptionPerDayInGrower,
      );
    }
    return 0;
  }

  get totalFeedConsumptionPerGrowerPig() {
    if (
      this.calculatorModalInputDataRetrieve.numberOfDaysFedInGrower &&
      this.calculatorModalInputDataRetrieve.feedConsumptionPerDayInGrower &&
      this.calculatorModalInputDataRetrieve.numberOfGrowerPigs
    ) {
      return calculateTotalFeedConsumptionPerGrowerPig(
        this.calculatorModalInputDataRetrieve.numberOfDaysFedInGrower,
        this.calculatorModalInputDataRetrieve.feedConsumptionPerDayInGrower,
        this.calculatorModalInputDataRetrieve.numberOfGrowerPigs,
      );
    }
    return 0;
  }

  // Medication Program - Starter / Grower
  get totalMedicationUsageInGrowerProgram() {
    if (!this.calculatorModalInputDataRetrieve.medicationDoseInGrowerProgram) {
      return 0;
    }
    return calculateTotalMedicationUsageInGrowerProgram(
      this.totalFeedConsumptionPerGrowerPig,
      this.calculatorModalInputDataRetrieve.medicationDoseInGrowerProgram,
    );
  }

  get totalCostOfMedicationAtGrower() {
    if (
      !this.calculatorModalInputDataRetrieve
        .costOfMedicationPerKiloInGrowerProgram
    ) {
      return 0;
    }

    return calculateTotalCostOfMedicationAtGrowerInGrowerProgram(
      this.totalMedicationUsageInGrowerProgram,
      this.calculatorModalInputDataRetrieve
        .costOfMedicationPerKiloInGrowerProgram,
    );
  }

  get additionalCostPerKiloOfFeedsInGrower() {
    return calculateAdditionalCostPerKiloOfFeedsInGrowerProgram(
      this.totalCostOfMedicationAtGrower,
      this.totalFeedConsumptionPerGrowerPig,
    );
  }

  get costOfMedicationPerGrowerPigs() {
    return calculateCostOfMedicationPerGrowerPigInGrowerProgram(
      this.totalCostOfMedicationAtGrower,
      this.calculatorModalInputDataRetrieve.numberOfGrowerPigs,
    );
  }

  // Medical Investment Section
  get totalMedicationInvestmentPerPig() {
    return calculateTotalMedicationInvestmentPerPig(
      this.costOfMedicationPerPiglets,
      this.costOfMedicationPerNurseryPiglets,
      this.costOfMedicationPerGrowerPigs,
    );
  }

  get totalInvestmentForGrowerProduced() {
    return calculateTotalInvestmentForGrowerPigsProduced(
      this.totalMedicationInvestmentPerPig,
      this.calculatorModalInputDataRetrieve.numberOfGrowerPigs,
    );
  }

  addComma(targetNumber: number, decimalPoint: number) {
    return targetNumber ? addCommas(targetNumber.toFixed(decimalPoint)) : '-';
  }
}
