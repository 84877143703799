















import { Vue, Component, Prop, Emit, Watch } from 'vue-property-decorator';
@Component({})
export default class MedicationModalSectionCollapseTemplate extends Vue {
  @Prop({ required: true })
  title!: string;

  @Prop()
  isActive!: boolean;

  isCheckboxSelected = false;

  mounted() {
    this.updateDropdownState();
  }

  @Watch('isActive')
  updateDropdownState() {
    this.isCheckboxSelected = this.isActive;
  }

  @Emit('checked')
  updateState() {
    return this.isCheckboxSelected;
  }
}
