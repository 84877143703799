


































import { Component, Prop, Vue } from 'vue-property-decorator';
import ReferenceType from './ReferenceType.vue';
import SectionLayoutCard from '@/components/swine/reports/SectionLayoutCard.vue';
import { DisplayData } from '@/models/swine/report/report.model';
import BronchopneumoniaReportTables from './BronchopneumoniaReportTables.vue';
import { PleurisyCalculatorModalData } from '@/models/swine/economic-calculation.model';
import UpdateBronchopneumoniaTableModal from './UpdateBronchopneumoniaTableModal.vue';
import { BronchopneumoniaSectionLayoutResults } from '@/models/swine/bronchopneumoniasectionlayout';
import { addCommas } from '@/utils/formats';

@Component({
  components: {
    ReferenceType,
    SectionLayoutCard,
    BronchopneumoniaReportTables,
    UpdateBronchopneumoniaTableModal,
  },
})
export default class BronchopneunomiaEconomicLossReport extends Vue {
  updateModalStatus = false;

  updatedModalData: PleurisyCalculatorModalData | null = null;

  sectionCardDetails: DisplayData[] = [
    {
      headerText: `Additional Feed /pig`,
      subText: ``,
      value: ``,
    },
    {
      headerText: `Additional Feed Cost /pig`,
      subText: ``,
      value: ``,
    },
    {
      headerText: `Additional Feed Cost /noValue heads`,
      subText: ``,
      value: '',
    },
    {
      headerText: `Additional Feed Cost /year`,
      subText: ``,
      value: '',
    },
  ];

  @Prop({ required: true })
  calculatorModalInputDataRetrieve!: PleurisyCalculatorModalData;

  openUpdateBronchopneumoniaModal() {
    this.updateModalStatus = !this.updateModalStatus;
  }

  updateBronchopneumoniaTable(
    updateModalInputObject: PleurisyCalculatorModalData,
  ) {
    this.$emit('update', updateModalInputObject);
    this.updateModalStatus = !this.updateModalStatus;
  }

  handleUpdateReportTable(updateModalInputObject: PleurisyCalculatorModalData) {
    this.$emit('update', updateModalInputObject);
  }

  receivedSectionLayoutResults(
    sectionLayoutResults: BronchopneumoniaSectionLayoutResults,
  ) {
    if (sectionLayoutResults) {
      if (
        this.addComma(sectionLayoutResults.additionalFeedPig, 2, false) ===
          '-' &&
        this.addComma(sectionLayoutResults.additionalFeedPig, 2, false) !==
          undefined
      ) {
        this.sectionCardDetails[0].value =
          this.addComma(sectionLayoutResults.additionalFeedPig, 2, false) || '';
      } else {
        this.sectionCardDetails[0].value = `${this.addComma(
          sectionLayoutResults.additionalFeedPig,
          2,
          false,
        )} kg`;
      }

      if (
        this.addComma(sectionLayoutResults.additionalFeedCostPig, 2, false) ===
        '-'
      ) {
        this.sectionCardDetails[1].value = `${this.addComma(
          sectionLayoutResults.additionalFeedCostPig,
          2,
          false,
        )}`;
      } else {
        this.sectionCardDetails[1].value = `${
          this.calculatorModalInputDataRetrieve.currencySymbol
        } ${this.addComma(
          sectionLayoutResults.additionalFeedCostPig,
          2,
          false,
        )}`;
      }

      if (
        this.addComma(
          sectionLayoutResults.additionalFeedCostThousand,
          2,
          false,
        ) === '-' ||
        this.calculatorModalInputDataRetrieve.numberOfAnimalHeads == 0
      ) {
        this.sectionCardDetails[2].value = '-';
      } else {
        this.sectionCardDetails[2].value = `${
          this.calculatorModalInputDataRetrieve.currencySymbol
        } ${this.addComma(
          sectionLayoutResults.additionalFeedCostThousand,
          2,
          false,
        )}`;
      }

      if (
        this.addComma(sectionLayoutResults.additionalFeedCostYear, 2, false) ===
          '-' ||
        this.calculatorModalInputDataRetrieve.numberOfAnimalHeads == 0
      ) {
        this.sectionCardDetails[3].value = '-';
      } else {
        this.sectionCardDetails[3].value = `${
          this.calculatorModalInputDataRetrieve.currencySymbol
        } ${this.addComma(
          sectionLayoutResults.additionalFeedCostYear,
          2,
          false,
        )}`;
      }
    }
  }

  receivedSectionLayoutAnimalNumber(numberOfAnimalHeadsValue: number) {
    this.sectionCardDetails[2].headerText = `Additional Feed Cost /${numberOfAnimalHeadsValue} heads`;
  }

  addComma(targetNumber: number, toFixedNumber: number, onlyToString: boolean) {
    if (isNaN(targetNumber)) {
      return '-';
    } else if (!onlyToString) {
      const roundedNumber = targetNumber.toFixed(toFixedNumber);
      return addCommas(roundedNumber);
    } else if (onlyToString) {
      const numberToString = targetNumber.toString();
      return addCommas(numberToString);
    }
  }
}
