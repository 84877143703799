


















































import { Component, Prop, Vue } from 'vue-property-decorator';
import { AbscessScarringAndPericarditisWithReportData } from '@/models/swine/report/report.model';
import { addDecimalPointToNumbers } from '@/utils/formats';
@Component({})
export default class AbscessScarringPericarditisTable extends Vue {
  @Prop({
    type: Object,
    default: null,
  })
  currentReportDetails!: AbscessScarringAndPericarditisWithReportData;

  standardizeValue(data: number) {
    return addDecimalPointToNumbers(data);
  }
}
