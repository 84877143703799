



















































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import FormTemplate from '@/components/swine/layout/FormTemplate.vue';
import ReferenceTypeDropdown from '../../ReferenceTypeDropdown.vue';
import CalculatorInputField from '../../CalculatorInputField.vue';
import {
  PleurisyCalculatorModalData,
  ReferenceType,
} from '@/models/swine/economic-calculation.model';
import { validateInputNumber } from '@/utils/validation';
import { DropdownOption } from '@/models/swine/dropdown.model';

@Component({
  components: {
    FormTemplate,
    ReferenceTypeDropdown,
    CalculatorInputField,
  },
})
export default class UpdateBronchopneumoniaTableModal extends Vue {
  formTitle = 'Update Bronchopneumonia Economic Loss';

  isDisabled = true;

  references = [
    { text: ReferenceType.E_STERVERMER, value: ReferenceType.E_STERVERMER },
    {
      text: ReferenceType.PIG_SITE,
      value: ReferenceType.PIG_SITE,
    },
  ];

  updateModalInputObject: Partial<PleurisyCalculatorModalData> = {
    referenceType: this.references[0].value,
    adgValue: null,
    additionalFeedCostPigRate: null,
    adfiValue: null,
  };

  @Prop({ default: false })
  isActive!: boolean;

  @Prop()
  calculatorModalInputDataRetrieve!: PleurisyCalculatorModalData;

  selectedReferenceType({ value }: DropdownOption) {
    this.updateModalInputObject.referenceType = value as ReferenceType;
  }

  handleUpdateModalClosed() {
    this.$emit('close');
    this.updateInputObject();
  }

  transferBronchopneumoniaUpdate() {
    this.$buefy.toast.open({
      message: `Bronchopneumonia Economic Loss has been updated.`,
      type: `is-dark`,
      duration: 3000,
    });
    const ignoredKeys = ['referenceType'];
    let newData: Partial<PleurisyCalculatorModalData> | {} = {};
    for (const [key, value] of Object.entries(this.updateModalInputObject)) {
      if (!ignoredKeys.includes(key)) {
        newData = { ...newData, [key]: Number(value) };
      }
    }
    this.updateModalInputObject = {
      ...this.updateModalInputObject,
      ...newData,
    };
    this.$emit('update', this.updateModalInputObject);
  }

  @Watch('updateModalInputObject', { deep: true })
  disableHandler() {
    let hasError = false;
    for (const detail in this.updateModalInputObject) {
      const newKey = detail as keyof Partial<PleurisyCalculatorModalData>;
      if (
        newKey !== 'referenceType' &&
        !validateInputNumber(String(this.updateModalInputObject[newKey]))
      ) {
        // If validation fails
        hasError = true;
      }
    }
    this.isDisabled = hasError;
  }

  @Watch('calculatorModalInputDataRetrieve', { deep: true, immediate: true })
  updateInputObject() {
    this.updateModalInputObject = {
      referenceType: this.calculatorModalInputDataRetrieve.referenceType,
      adgValue: this.calculatorModalInputDataRetrieve.adgValue,
      additionalFeedCostPigRate: this.calculatorModalInputDataRetrieve
        .additionalFeedCostPigRate,
      adfiValue: this.calculatorModalInputDataRetrieve.adfiValue,
    };
  }
}
