/*
NOTE: There is an issue with how S3 works with Chrome due to S3 lacking some properties. Adding ?fetch=true is required to bypass this issue
https://stackoverflow.com/questions/20253472/cors-problems-with-amazon-s3-on-the-latest-chomium-and-google-canary
*/
export const getBase64ImageFromURL = (url: string) => new Promise((resolve, reject) => {
	const img = new Image();
	img.crossOrigin = "Anonymous";
	img.src = `${url}?fetch=true`;
	img.onload = () => {
	const canvas: HTMLCanvasElement = document.createElement('canvas'),
	ctx = canvas.getContext('2d') as CanvasRenderingContext2D;

  const height: number = 300;
  const width: number = 300;

	canvas.height = height;
	canvas.width = width;
	ctx.drawImage(img, 0, 0, width, height);
	resolve(canvas.toDataURL('image/jpeg'));
	};
	img.onerror = error => reject(error);
})