




























import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import FormTemplate from '@/components/swine/layout/FormTemplate.vue';
import { FormStyling } from '@/models/swine/form/form.model';
import { ReportSummaryData } from '@/models/swine/report/report.model';
@Component({
  components: {
    FormTemplate,
  },
})
export default class DeleteSpecifiedReportModal extends Vue {
  formTitle = '';
  bodyDescription = '';
  formStyling: FormStyling = {
    height: '270px',
    borderRadius: '20px',
    width: '680px',
  };

  @Prop({
    type: Object,
    default: null,
  })
  selectedReport!: ReportSummaryData;

  mounted() {
    if (this.selectedReport) {
      this.formTitle = `Delete Report ${this.selectedReport.farmUnit.farmUnitCodeName} ?`;
      this.bodyDescription = `Report ${this.selectedReport.farmUnit.farmUnitCodeName} will be
          permanently removed. This action cannot be undone.`;
    }
  }

  @Emit('close')
  closeForm(value: boolean) {
    return value;
  }

  @Emit('close')
  closeModal() {
    return false;
  }

  @Emit('delete')
  deleteReports() {
    return false;
  }
}
