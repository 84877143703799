import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";
import store from '@/store/swine';
import { axios } from "@/utils/axios";
import { Endpoints } from "@/utils/endpoints";
import { HousingType } from "@/models/swine/housingTypes/housingTypes.model";

@Module({
    namespaced: true,
    dynamic: true,
    name: 'housing-types.list',
    store,
})
class HousingTypesListStore extends VuexModule {
    response: HousingType[] = [];
    isLoading = false;
    error: any | null = null;

    @Mutation
    updateLoadingStatus(payload: boolean) {
        this.isLoading = payload;
    }

    @Mutation
    setError(error: any) {
        this.error = error;
    }

    @Mutation
    setResponse(payload: HousingType[]) {
        this.response.splice(0, this.response.length);
        payload.forEach((item) => {
            this.response.push(item);
        });
    }

    @Action
    getHousingTypes() {
        this.context.commit('updateLoadingStatus', true);
        this.context.commit('setError', null);
        try {
            axios.get(`${Endpoints.HousingTypes}`)
                .then((response) => {
                    this.context.commit('setResponse', response.data);
                })
        } catch (error) {
            this.context.commit('setError', error);
        }
        this.context.commit('updateLoadingStatus', false);
    }

}

export default getModule(HousingTypesListStore);