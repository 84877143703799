




































































import { Vue, Component, Emit, Prop, Watch } from 'vue-property-decorator';
import FormTemplate from '@/components/swine/layout/FormTemplate.vue';
import {
  FarmProductionPerformance,
  PulmotilCalculationModalData,
} from '@/models/swine/economic-calculation.model';
import CalculatorInputField from '../../../CalculatorInputField.vue';
@Component({
  components: { FormTemplate, CalculatorInputField },
})
export default class FarmProductionPerformanceForm extends Vue {
  calculatorModalInputObject: FarmProductionPerformance = {
    sowLevel: null,
    sowIndex: null,
    averageLitterSizeBornAlive: null,
    preWeaningMortality: null,
    nurseryMortality: null,
  };

  isDisabled = true;

  get formTitle() {
    return `Update Farm Production Performance`;
  }

  mounted() {
    this.updateValues();
  }

  @Prop({ required: true })
  calculatorModalData!: PulmotilCalculationModalData;

  @Watch(`calculatorModalData`, { deep: true })
  updateValues() {
    if (this.calculatorModalData) {
      this.calculatorModalInputObject.sowLevel = this.calculatorModalData.sowLevel;
      this.calculatorModalInputObject.sowIndex = this.calculatorModalData.sowIndex;
      this.calculatorModalInputObject.averageLitterSizeBornAlive = this.calculatorModalData.averageLitterSizeBornAlive;
      this.calculatorModalInputObject.preWeaningMortality = this.calculatorModalData.preWeaningMortality;
      this.calculatorModalInputObject.nurseryMortality = this.calculatorModalData.nurseryMortality;
      this.validateForm();
    }
  }

  validateForm() {
    this.isDisabled = false;
    for (const key in this.calculatorModalInputObject) {
      const currentKey = key as keyof FarmProductionPerformance;
      this.calculatorModalInputObject[currentKey] =
        Number(this.calculatorModalInputObject[currentKey]) || 0;
    }

    if (this.calculatorModalInputObject.sowLevel !== null && !this.isDisabled) {
      this.isDisabled = this.calculatorModalInputObject.sowLevel <= 0;
    }

    if (this.calculatorModalInputObject.sowIndex !== null && !this.isDisabled) {
      this.isDisabled = this.calculatorModalInputObject.sowIndex <= 0;
    }

    if (
      this.calculatorModalInputObject.averageLitterSizeBornAlive !== null &&
      !this.isDisabled
    ) {
      this.isDisabled =
        this.calculatorModalInputObject.averageLitterSizeBornAlive <= 0;
    }

    if (
      this.calculatorModalInputObject.preWeaningMortality !== null &&
      !this.isDisabled
    ) {
      this.isDisabled =
        this.calculatorModalInputObject.preWeaningMortality <= 0;
    }

    if (
      this.calculatorModalInputObject.nurseryMortality !== null &&
      !this.isDisabled
    ) {
      this.isDisabled = this.calculatorModalInputObject.nurseryMortality <= 0;
    }
  }

  @Emit('update')
  updateModalValue() {
    return this.calculatorModalInputObject;
  }

  @Emit(`close`)
  closeModal(payload: boolean) {
    return payload;
  }
}
