











import { Vue, Component, Prop } from 'vue-property-decorator';
@Component({})
export default class EconomicCalculationSection extends Vue {
  @Prop({ default: '' })
  title!: string;

  @Prop({ default: false })
  isTitleBlue!: boolean;
}
