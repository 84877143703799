/*
  This page contains the calculation formulas for Pulmotil Economic Calculation
*/

// Farm Production Performance
export function calculateNumberOfSowsFarrowingPerMonth(
  sowLevel: number,
  sowIndex: number,
) {
  return (sowLevel * sowIndex) / 12;
}

// Need to calculate numberOfSowsFarrowingPerMonth before this formula is used
export function calculateNumberOfPigletsBornPerMonth(
  numberOfSowsFarrowingPerMonth: number,
  averageLitterSizeBornAlive: number,
) {
  return numberOfSowsFarrowingPerMonth * averageLitterSizeBornAlive;
}

// Need to calculate numberOfPigletsBornPerMonth before this formula is used
export function calculateNumberOfPigletsAtNursery(
  numberOfPigletsBornPerMonth: number,
  preWeaningMortality: number,
) {
  return (
    numberOfPigletsBornPerMonth -
    numberOfPigletsBornPerMonth * (preWeaningMortality / 100)
  );
}

// Need to calculate numberOfPigletsAtNursery before this formula is used
export function calculateNumberOfGrowerPigs(
  numberOfPigletsAtNursery: number,
  nurseryMortality: number,
) {
  return (
    numberOfPigletsAtNursery -
    numberOfPigletsAtNursery * (nurseryMortality / 100)
  );
}

/*
  Feed Consumption Formulas
*/
export function calculateConsumptionPerLactactingSow(
  adfiAtGestation: number,
  numberOfDaysFedPreFarrowing: number,
  adfiAtLactation: number,
  numberOfDaysFedPostFarrowing: number,
) {
  return (
    adfiAtGestation * numberOfDaysFedPreFarrowing +
    adfiAtLactation * numberOfDaysFedPostFarrowing
  );
}

export function calculateTotalConsumptionOfAllLactactingSows(
  consumptionPerLactactingSow: number,
  numberOfSowsFarrowingPerMonth: number,
) {
  return (consumptionPerLactactingSow * numberOfSowsFarrowingPerMonth) / 1000;
}

/*
Medication program - Clean Sow Program
*/
export function calculateTotalMedicationUsageInCleanSowProgram(
  totalConsumptionOfAllLactactingSows: number,
  medicationDose: number,
) {
  return totalConsumptionOfAllLactactingSows * medicationDose;
}

export function calculateTotalCostOfMedicationInLactationInCleanSowProgram(
  costOfMedicationPerKilo: number,
  totalMedicationUsage: number,
) {
  return costOfMedicationPerKilo * totalMedicationUsage;
}

// We need to convert Tons back to kg for calculation
export function calculateAdditionalCostPerKiloOfFeedsInCleanSowProgram(
  totalCostOfMedicationInLactation: number,
  totalConsumptionOfAllLactactingSows: number,
) {
  return (
    totalCostOfMedicationInLactation /
    (totalConsumptionOfAllLactactingSows * 1000)
  );
}

export function calculateTotalCostOfMedicationPerLactactingSowsInCleanSowProgram(
  totalCostOfMedicationInLactation: number,
  numberOfSowsFarrowingPerMonth: number,
) {
  return totalCostOfMedicationInLactation / numberOfSowsFarrowingPerMonth;
}

export function calculateCostOfMedicationPerPigletsInCleanSowProgram(
  totalCostOfMedicationPerLactactingSows: number,
  averageLitterSizeBornAlive: number,
) {
  return totalCostOfMedicationPerLactactingSows / averageLitterSizeBornAlive;
}

/*
  Nursery Feed
*/
export function calculateFeedConsumptionPerNurseryPiglet(
  numberOfDaysFedInNursery: number,
  feedConsumptionPerDayInNursery: number,
) {
  return numberOfDaysFedInNursery * feedConsumptionPerDayInNursery;
}

// Value returned is in tons
export function calculateTotalFeedConsumptionOfNurseryPiglets(
  feedConsumptionPerNurseryPiglet: number,
  numberOfPigletsAtNursery: number,
) {
  return (feedConsumptionPerNurseryPiglet * numberOfPigletsAtNursery) / 1000;
}

/*
 Medication Program - Early Intervention ( Prestarter )
*/
export function calculateTotalMedicationUsageInEarlyIntervention(
  totalFeedConsumptionOfNurseryPiglets: number,
  medicationDose: number,
) {
  return totalFeedConsumptionOfNurseryPiglets * medicationDose;
}

export function calculateTotalCostOfMedicationAtNurseryInEarlyIntervention(
  totalMedicationUsage: number,
  costOfMedicationPerKilo: number,
) {
  return totalMedicationUsage * costOfMedicationPerKilo;
}

export function calculateAdditionalCostPerKiloOfFeedsInEarlyIntervention(
  totalCostOfMedicationAtNursery: number,
  totalFeedConsumptionOfNurseryPiglets: number,
) {
  return (
    totalCostOfMedicationAtNursery /
    (totalFeedConsumptionOfNurseryPiglets * 1000)
  );
}

export function calculateCostOfMedicationPerNurseryPigletsInEarlyIntervention(
  totalCostOfMedicationAtNursery: number,
  numberOfPigletsAtNursery: number,
) {
  return totalCostOfMedicationAtNursery / numberOfPigletsAtNursery;
}

/*
Grower Feed
*/
export function calculateFeedConsumptionPerGrowerPig(
  numberOfDaysFedInGrower: number,
  feedConsumptionPerDayInGrower: number,
) {
  return numberOfDaysFedInGrower * feedConsumptionPerDayInGrower;
}

// Returned value is in Tons
export function calculateTotalFeedConsumptionPerGrowerPig(
  numberOfDaysFedInGrower: number,
  feedConsumptionPerDayInGrower: number,
  numberOfGrowerPigs: number,
) {
  return (
    (numberOfDaysFedInGrower *
      feedConsumptionPerDayInGrower *
      numberOfGrowerPigs) /
    1000
  );
}

/*
Medication Program - Starter / Grower Program
*/
export function calculateTotalMedicationUsageInGrowerProgram(
  totalFeedConsumptionPerGrowerPig: number,
  medicationDose: number,
) {
  return totalFeedConsumptionPerGrowerPig * medicationDose;
}

export function calculateTotalCostOfMedicationAtGrowerInGrowerProgram(
  totalMedicationUsage: number,
  costOfMedicationPerKilo: number,
) {
  return totalMedicationUsage * costOfMedicationPerKilo;
}

export function calculateAdditionalCostPerKiloOfFeedsInGrowerProgram(
  totalCostOfMedicationAtGrower: number,
  totalFeedConsumptionPerGrowerPig: number,
) {
  return (
    totalCostOfMedicationAtGrower / (totalFeedConsumptionPerGrowerPig * 1000)
  );
}

export function calculateCostOfMedicationPerGrowerPigInGrowerProgram(
  totalCostOfMedicationAtGrower: number,
  numberOfGrowerPigs: number,
) {
  return totalCostOfMedicationAtGrower / numberOfGrowerPigs;
}

/*
Medication Program - Medication Investment 
*/

export function calculateTotalMedicationInvestmentPerPig(
  costOfMedicationPerPiglets: number,
  costOfMedicationPerNurseryPiglets: number,
  costOfMedicationPerGrowerPigs: number,
) {
  return (
    costOfMedicationPerGrowerPigs +
    costOfMedicationPerNurseryPiglets +
    costOfMedicationPerPiglets
  );
}

export function calculateTotalInvestmentForGrowerPigsProduced(
  totalMedicationInvestmentPerPig: number,
  numberOfGrowerPigs: number,
) {
  return totalMedicationInvestmentPerPig * numberOfGrowerPigs;
}
