























import { Component, Prop, Vue } from 'vue-property-decorator';
import BreakdownTable from './BreakdownTable.vue';
import AppIndexCard from './AppIndexCard.vue';
import PneumoniaCard from '@/components/swine/reports/PneumoniaCard.vue';
import PercentageTable from '@/components/swine/reports/PercentageTable.vue';
import BasicBarChart from '@/components/swine/reports/BasicBarChart.vue';
import { FarmResultSummaryWithReportData } from '@/models/swine/report/report.model';
import { addDecimalPointToNumbers } from '@/utils/formats';

@Component({
  components: {
    BreakdownTable,
    AppIndexCard,
    PneumoniaCard,
    PercentageTable,
    BasicBarChart,
  },
})
export default class FarmResultSummary extends Vue {
  @Prop({
    type: Object,
    default: null,
  })
  currentReportDetails!: FarmResultSummaryWithReportData;
  chartData: Chart.ChartData | null = null;
  options: Chart.ChartOptions | null = null;

  standardizeValues(data: number) {
    return addDecimalPointToNumbers(data);
  }

  mounted() {
    if (this.currentReportDetails) {
      // Find the maximum
      let maxValue = 0;
      Object.values(this.currentReportDetails).find((detail) => {
        if (typeof detail === 'number' && detail >= maxValue) {
          maxValue = detail;
        }
      });

      this.options = {
        maintainAspectRatio: false,
        legend: {
          display: false,
        },
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                min: 0,
                max: 120,
                callback: function (tick) {
                  return tick.toString() + '%';
                },
              },
            },
          ],
        },
        plugins: {
          datalabels: {
            anchor: 'end',
            align: 'end',
            formatter: function (value) {
              return `${value}%`;
            },
          },
        },
      };

      this.chartData = {
        labels: [`B.L.`, `A.P.`, `C.P.`, `D.P.`, `Absc.`, `Scar.`, `Peri.`],
        datasets: [
          {
            data: [
              this.currentReportDetails.bronchopneumoniaLungsPercentage,
              this.currentReportDetails
                .averageOfAffectedSurfaceOutOfLungsWithActivePneumoniaPercentage,
              this.currentReportDetails.pigsWithCranialPleurisyPercentage,
              this.currentReportDetails.pigsWithDorsocaudalPleurisyPercentage,
              this.currentReportDetails.totalPigsWithAbscess,
              this.currentReportDetails.totalPigsWithScarring,
              this.currentReportDetails.totalPigsWithPericarditis,
            ],
            backgroundColor: [
              `#D6FFA4`,
              `#99E895`,
              `#FFF5C9`,
              `#FFD16A`,
              `#FFEAEA`,
              `#FFC1C1`,
              `#D9F2FF`,
            ],
            borderColor: [
              `#6C9D31`,
              `#6C9D31`,
              `#B99913`,
              `#B99913`,
              `#F15453`,
              `#F15453`,
              `#00A5E1`,
            ],
            borderWidth: 1,
            barPercentage: 0.2,
          },
        ],
      };
    }
  }
}
