






















































































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { BronchopneumoniaScoreWithReportData } from '@/models/swine/report/report.model';
import { addDecimalPointToNumbers } from '@/utils/formats';
@Component({})
export default class PneumoniaTable extends Vue {
  @Prop({
    type: Object,
    default: null,
  })
  currentReportDetails!: BronchopneumoniaScoreWithReportData;

  standardizeValue(data: number) {
    return addDecimalPointToNumbers(data);
  }
}
