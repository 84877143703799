import {
  getFarmResultSummary,
  getAuditReportLungData,
  getPleurisyData,
  getBronchopneumoniaScore,
  getAppIndexAndPrevalence,
  getAbscessScarringPericarditis,
  getEconomicCalculationData,
} from './get';
import { ReportStores } from '@/store/swine/reports';
import { lightGrey } from './colors';
import { getIntro } from './sections/intro/intro';
import { getReportDetails } from './sections/report-details/report-details';
import { getLungLesionScoreSummary } from './sections/lung-lesion-score-summary/lung-lesion-score-summary';
import { getBronchopneumoniaScoreAndAttachments } from './sections/bronchopneumonia-score-and-attachments/bronchopneumonia-score-and-attachments';
import { getPleurisyAndAttachments } from './sections/pleurisy-and-attachments/pleurisy-and-attachments';
import { getAppIndexAndPrevalenceSection } from './sections/app-index-and-prevalence/app-index-and-prevalence';
import { getAbscessScarringPericarditisAndAttachments } from './sections/asp-and-attachments/asp-and-attachments';
import { getEconomicCalculation } from './sections/economic-calculation/economic-calculation';
import {
  AbscessScarringAndPericarditisWithReportData,
  AppIndexAndPrevalenceWithReportData,
  BronchopneumoniaScoreWithReportData,
  EconomicCalculationWithReportData,
  PleurisyScoreWithReportData,
  ReportSummaryData,
} from '@/models/swine/report/report.model';
import { CurrencyDropdownOption } from '@/models/swine/dropdown.model';
import { EconomicCalculationType } from '../constants';

// * Pdfmake
/*
eslint-disable @typescript-eslint/no-var-requires
*/
const pdfMake = require('pdfmake/build/pdfmake');
const pdfFonts = require('pdfmake/build/vfs_fonts');
(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;

export async function downloadPdf(reportId: string) {
  // * Get data for each section
  const generalDetails: ReportSummaryData = await getFarmResultSummary(
    reportId
  );
  const lungData: any = await getAuditReportLungData(reportId);
  const pleurisyScore: PleurisyScoreWithReportData = await getPleurisyData(
    reportId
  );

  const auditReportLungDataArray = lungData.auditReportLungDataArray;
  const brochopneumoniaDataArray = lungData.brochopneumoniaDataArray;
  const pleurisyAttachments = lungData.pleurisyAttachments;
  const abscessScarringPericarditisAttachments =
    lungData.abscessScarringPericarditisAttachments;

  const [
    bronchoPneumoniaScore,
    appIndexAndPrevalence,
    abscessScarringPericarditis,
  ] = await Promise.all([
    getBronchopneumoniaScore(reportId),
    getAppIndexAndPrevalence(reportId),
    getAbscessScarringPericarditis(reportId),
  ]);

  pdfMake.tableLayouts = {
    reportDetailsHeaderLayout: {
      paddingLeft: function () {
        return 0;
      },
      paddingRight: function () {
        return 0;
      },
      paddingTop: function () {
        return 0;
      },
      paddingBottom: function () {
        return 0;
      },
      hLineColor: function () {
        return '#dcdcdc';
      },
    },
    allGreyBorder: {
      vLineColor: function () {
        return '#dcdcdc';
      },
      hLineColor: function () {
        return '#dcdcdc';
      },
    },
    card: {
      paddingLeft: function () {
        return 10;
      },
      paddingRight: function () {
        return 10;
      },
      paddingTop: function () {
        return 6;
      },
      paddingBottom: function () {
        return 6;
      },
      vLineColor: function () {
        return '#dcdcdc';
      },
      hLineColor: function () {
        return '#dcdcdc';
      },
    },
    regularTable: {
      paddingLeft: function () {
        return 5;
      },
      paddingRight: function () {
        return 5;
      },
      paddingTop: function () {
        return 3;
      },
      paddingBottom: function () {
        return 3;
      },
      vLineColor: function () {
        return '#dcdcdc';
      },
      hLineColor: function () {
        return '#dcdcdc';
      },
    },
  };

  const docDefinition = {
    pageOrientation: 'landscape',
    content: [
      ...getIntro(generalDetails),
      ...getReportDetails(auditReportLungDataArray),
      ...getLungLesionScoreSummary(generalDetails),
      ...(await Promise.all([
        getBronchopneumoniaScoreAndAttachments(
          generalDetails,
          bronchoPneumoniaScore,
          brochopneumoniaDataArray
        ),
        getPleurisyAndAttachments(pleurisyScore, pleurisyAttachments),
        getAbscessScarringPericarditisAndAttachments(
          abscessScarringPericarditis,
          abscessScarringPericarditisAttachments
        ),
      ])),
      ...getAppIndexAndPrevalenceSection(appIndexAndPrevalence),
    ],
    styles: {
      sectionHeader: {
        bold: true,
        fontSize: 20,
        alignment: 'center',
        margin: [0, 0, 0, 25],
      },
      tableNoOfLungsExamined: {
        fillColor: '#f2f2f2',
        alignment: 'center',
        width: 200,
        margin: [0, 25, 25, 0],
      },
      cellPadding: {
        margin: 8,
        fontSize: 10,
      },
      cellPaddingLeftTable: {
        margin: [8, 8, 15, 8],
        fontSize: 10,
      },
      reportDetailsHeader: {
        fontSize: 8,
        fillColor: '#f2f2f2',
        alignment: 'center',
      },
      reportDetails: {
        fontSize: 8,
      },
      noBorder: {
        border: [false, false, false, false],
      },
      allBorder: {
        border: [true, true, true, true],
      },
      fontSize8: {
        fontSize: 8,
      },
      fontBold: {
        bold: true,
      },
      lightGreyBg: {
        fillColor: `${lightGrey}`,
      },
      textCenter: {
        alignment: 'center',
      },
      marginY10: {
        marginTop: 10,
        marginBottom: 10,
      },
      marginB10: {
        marginBottom: 10,
      },
    },
  };
  return pdfMake.createPdf(docDefinition).download(`Report ${reportId}`);
}

export async function downloadEconomicCalculationPDF(reportId: string) {
  // * Defaults
  const defaultCurrentCurrencyDetails = {
    baseCurrency: { text: 'USD', uniqueKey: 'USD', value: 1 },
    currentSelectedCurrency: { text: 'MYR', uniqueKey: 'MYR', value: 4.04 },
  };

  const currencies: CurrencyDropdownOption[] = [
    { uniqueKey: 'USD', text: 'USD', value: 1.0 },
    { uniqueKey: 'IDR', text: 'IDR', value: 14613.35 },
    { uniqueKey: 'MYR', text: 'MYR', value: 4.04 },
    { uniqueKey: 'PHP', text: 'PHP', value: 48.55 },
    { uniqueKey: 'THB', text: 'THB', value: 31.44 },
    { uniqueKey: 'VND', text: 'VND', value: 23085.5 },
    { uniqueKey: 'CNY', text: 'CNY', value: 6.89 },
    { uniqueKey: 'BND', text: 'BND', value: 1.33},
    { uniqueKey: 'NTD', text: 'NTD', value: 30.95},
  ];

  // * Store
  const store = ReportStores.detail;
  let currentCurrencyDetails = store.currentCurrencyDetails;
  currentCurrencyDetails = defaultCurrentCurrencyDetails;
  const currencyExchangeRates = store.currencyExchangeRates;
  const economicCalculationDetails: EconomicCalculationWithReportData =
    await getEconomicCalculationData(reportId);
  const transferredModalData =
    economicCalculationDetails.economicCalculationDetails;

  if (economicCalculationDetails) {
    if (currencyExchangeRates) {
      for (const [key, value] of Object.entries(currencyExchangeRates)) {
        const found = currencies.find((currency) => currency.uniqueKey === key);
        if (found) {
          found.value = value as number;
        } else {
          currencies.push({
            uniqueKey: key,
            text: key,
            value: value as number,
          });
        }
      }
    }

    currentCurrencyDetails = {
      baseCurrency: { text: 'USD', uniqueKey: 'USD', value: 1 },
      currentSelectedCurrency: { text: 'USD', uniqueKey: 'USD', value: 1 },
    };
    const index = currencies.findIndex((data) => {
      return (
        data.text ===
        economicCalculationDetails.economicCalculationDetails?.currencySymbol
      );
    });
    if (index > -1) {
      currentCurrencyDetails.currentSelectedCurrency = currencies[index];
    } else {
      currentCurrencyDetails = defaultCurrentCurrencyDetails;
    }
  }

  pdfMake.tableLayouts = {
    reportDetailsHeaderLayout: {
      paddingLeft: function () {
        return 0;
      },
      paddingRight: function () {
        return 0;
      },
      paddingTop: function () {
        return 0;
      },
      paddingBottom: function () {
        return 0;
      },
      hLineColor: function () {
        return '#dcdcdc';
      },
    },
    allGreyBorder: {
      vLineColor: function () {
        return '#dcdcdc';
      },
      hLineColor: function () {
        return '#dcdcdc';
      },
    },
    card: {
      paddingLeft: function () {
        return 10;
      },
      paddingRight: function () {
        return 10;
      },
      paddingTop: function () {
        return 6;
      },
      paddingBottom: function () {
        return 6;
      },
      vLineColor: function () {
        return '#dcdcdc';
      },
      hLineColor: function () {
        return '#dcdcdc';
      },
    },
    regularTable: {
      paddingLeft: function () {
        return 5;
      },
      paddingRight: function () {
        return 5;
      },
      paddingTop: function () {
        return 3;
      },
      paddingBottom: function () {
        return 3;
      },
      vLineColor: function () {
        return '#dcdcdc';
      },
      hLineColor: function () {
        return '#dcdcdc';
      },
    },
  };

  if (transferredModalData) {
    const docDefinition = {
      pageOrientation: 'landscape',
      content: getEconomicCalculation(
        transferredModalData,
        currentCurrencyDetails
      ),
      styles: {
        sectionHeader: {
          bold: true,
          fontSize: 20,
          alignment: 'center',
          margin: [0, 0, 0, 25],
        },
        tableNoOfLungsExamined: {
          fillColor: '#f2f2f2',
          alignment: 'center',
          width: 300,
          margin: [0, 25, 25, 0],
        },
        cellPadding: {
          margin: 8,
        },
        reportDetailsHeader: {
          fontSize: 8,
          fillColor: '#f2f2f2',
          alignment: 'center',
        },
        reportDetails: {
          fontSize: 8,
        },
        noBorder: {
          border: [false, false, false, false],
        },
        allBorder: {
          border: [true, true, true, true],
        },
        fontSize8: {
          fontSize: 8,
        },
        fontBold: {
          bold: true,
        },
        lightGreyBg: {
          fillColor: `${lightGrey}`,
        },
        textCenter: {
          alignment: 'center',
        },
        marginY10: {
          marginTop: 10,
          marginBottom: 10,
        },
        marginB10: {
          marginBottom: 10,
        },
      },
    };

    const economicCalculationType =
      transferredModalData.economicCalculationType ===
      EconomicCalculationType.pleurisy
        ? 'Malaysia'
        : 'Standard';

    return pdfMake
      .createPdf(docDefinition)
      .download(
        `Report ${reportId} ${economicCalculationType} Economic Calculation`
      );
  }
}
