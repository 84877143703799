

























































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import FormTemplate from '@/components/swine/layout/FormTemplate.vue';
import CalculatorInputField from '../../CalculatorInputField.vue';
import {
  PleurisyCalculatorModalData,
  UpdatePleurisyData,
} from '@/models/swine/economic-calculation.model';
import { validateInputNumber } from '@/utils/validation';

@Component({
  components: {
    FormTemplate,
    CalculatorInputField,
  },
})
export default class PleurisyUpdateModal extends Vue {
  formTitle = 'Update Pleurisy Economic Loss';

  isDisabled = true;

  updatePleurisyModalData: UpdatePleurisyData = {
    liveWeightPrice: null,
    targetMarketWeight: null,
    averageFeedPrice: null,
    postWeaningMortality: null,
  };

  @Prop({ default: false })
  isActive!: boolean;

  @Prop({ default: true })
  calculatorModalInputDataRetrieve!: PleurisyCalculatorModalData;

  handlePleurisyUpdateModalClose() {
    this.$emit('close');
    this.updateInputObject();
  }

  updatePleurisyData() {
    this.$buefy.toast.open({
      message: `Pleurisy Economic Loss has been updated.`,
      type: `is-dark`,
      duration: 3000,
    });
    const ignoredKeys = ['referenceType'];
    let newData: UpdatePleurisyData | {} = {};
    for (const [key, value] of Object.entries(this.updatePleurisyModalData)) {
      if (!ignoredKeys.includes(key)) {
        newData = { ...newData, [key]: Number(value) };
      }
      this.updatePleurisyModalData = {
        ...this.updatePleurisyModalData,
        ...newData,
      };
    }
    this.$emit('update', this.updatePleurisyModalData);
  }

  @Watch('updatePleurisyModalData', { deep: true })
  disableHandler() {
    let hasError = false;
    for (const detail in this.updatePleurisyModalData) {
      const newKey = detail as keyof UpdatePleurisyData;
      if (!validateInputNumber(String(this.updatePleurisyModalData[newKey]))) {
        // If validation fails
        hasError = true;
      }
    }
    this.isDisabled = hasError;
  }

  @Watch('calculatorModalInputDataRetrieve', { deep: true, immediate: true })
  updateInputObject() {
    this.updatePleurisyModalData = {
      liveWeightPrice: this.calculatorModalInputDataRetrieve.liveWeightPrice,
      targetMarketWeight: this.calculatorModalInputDataRetrieve
        .targetMarketWeight,
      averageFeedPrice: this.calculatorModalInputDataRetrieve.averageFeedPrice,
      postWeaningMortality: this.calculatorModalInputDataRetrieve
        .postWeaningMortality,
    };
  }
}
