




















































import { Component, Vue, Prop } from 'vue-property-decorator';
import FormTemplate from '@/components/swine/layout/FormTemplate.vue';
import ReferenceTypeDropdown from './ReferenceTypeDropdown.vue';
import {
  EconomicCalculationModalDataType,
  ReferenceType,
} from '@/models/swine/economic-calculation.model';
import { DropdownOption } from '@/models/swine/dropdown.model';
import { validateInputNumber } from '@/utils/validation';
import { ReportStores } from '@/store/swine/reports';
import { UserSessionStore } from '@/store/swine/user-session';
import { EconomicCalculationType } from '@/utils/constants';
import EconomicCalculationForm from './economicReports/EconomicCalculation/EconomicCalculationForm.vue';
import PulmotilCalculationForm from './economicReports/PulmotilCalculation/PulmotilCalculationForm.vue';
import { ProfileStores } from '@/store/swine/profile/index';

@Component({
  components: {
    FormTemplate,
    ReferenceTypeDropdown,
    EconomicCalculationForm,
    PulmotilCalculationForm,
  },
})
export default class CalculatorModal extends Vue {
  store = ReportStores.detail;
  formTitle = 'Create New Economic Calculation';
  isDisabled = true;
  calculatorModalInputObject: Partial<EconomicCalculationModalDataType> = {
    numberOfAnimalHeads: null,
    adgValue: null,
    weanMarketDays: null,
    weekNumber: null,
    additionalFeedCostPigRate: null,
    adfiValue: null,
    liveWeightPrice: null,
    targetMarketWeight: null,
    averageFeedPrice: null,
    referenceType: null,
    postWeaningMortality: null,
    economicCalculationType: null,
    ageOfWeaning: null,
  };
  currentCountry = '';
  currentCurrency = '';
  userSessionDetails = UserSessionStore.detail;
  userProfileStore = ProfileStores.profile;

  get EconomicType() {
    const data = Object.values(EconomicCalculationType).map((item) => {
      return {
        text: item,
        value: item,
        uniqueKey: item,
      };
    });
    return data;
  }

  get references() {
    const data = Object.values(ReferenceType).map((item) => {
      return {
        text: item,
        value: item,
        uniqueKey: item,
      };
    });
    return data;
  }

  get isEconomicCalculationTypeSelected() {
    return (
      this.calculatorModalInputObject.economicCalculationType ===
      EconomicCalculationType.pleurisy
    );
  }

  get isPulmotilValueCalculationTypeSelected() {
    return (
      this.calculatorModalInputObject.economicCalculationType ===
      EconomicCalculationType.pulmotil
    );
  }

  @Prop({ default: false })
  isActive!: boolean;

  mounted() {
    if (this.userProfileStore.userProfile) {
      this.currentCountry = this.userProfileStore.userProfile.country
        ? this.userProfileStore.userProfile.country.code
        : '';
      let selectedCurrency = '';
      // After data population, we will match their preference
      switch (this.currentCountry) {
        case 'MY': {
          selectedCurrency = 'MYR';
          break;
        }
        case 'ID': {
          selectedCurrency = 'IDR';
          break;
        }
        case 'VN': {
          selectedCurrency = 'VND';
          break;
        }
        case 'PH': {
          selectedCurrency = 'PHP';
          break;
        }
        case 'TH': {
          selectedCurrency = 'THB';
          break;
        }
        case 'CN': {
          selectedCurrency = 'CNY';
          break;
        }
        case 'BN': {
          selectedCurrency = 'BND';
          break;
        }
        case 'TW': {
          selectedCurrency = 'NTD';
          break;
        }
        default: {
          selectedCurrency = 'USD';
          break;
        }
      }
      this.currentCurrency = selectedCurrency;
    }
  }

  handleCalculatorModalClosed() {
    this.$emit('close');
  }

  createEconomicCalculation() {
    const ignoredKeys = ['referenceType', `economicCalculationType`];
    let newData: EconomicCalculationModalDataType | {} = {};

    if (
      this.isPulmotilValueCalculationTypeSelected &&
      this.calculatorModalInputObject.adgValue &&
      this.calculatorModalInputObject.weanMarketDays
    ) {
      this.calculatorModalInputObject.targetMarketWeight =
        (this.calculatorModalInputObject.adgValue *
          this.calculatorModalInputObject.weanMarketDays) /
        1000;
    }

    for (const [key, value] of Object.entries(
      this.calculatorModalInputObject
    )) {
      if (!ignoredKeys.includes(key)) {
        newData = {
          ...newData,
          [key]: Number(value),
        };
      }
    }

    this.calculatorModalInputObject = {
      ...this.calculatorModalInputObject,
      ...newData,
    };
    this.store.setCalculatorModalInputObject(this.calculatorModalInputObject);
    this.$emit('create', this.calculatorModalInputObject);
  }

  selectedEconomicType({ value }: DropdownOption) {
    this.calculatorModalInputObject.economicCalculationType = value;
    this.disableHandler();
  }
  updateCurrentBaseForm(payload: EconomicCalculationModalDataType) {
    this.calculatorModalInputObject = payload;
    this.disableHandler();
  }

  disableHandler() {
    let isInvalid = false;

    // When you change the type, we need to remove the unused variables from the other type
    if (
      this.isEconomicCalculationTypeSelected &&
      !this.isPulmotilValueCalculationTypeSelected &&
      'averageLitterSizeBornAlive' in this.calculatorModalInputObject
    ) {
      delete this.calculatorModalInputObject.averageLitterSizeBornAlive;
      delete this.calculatorModalInputObject.nurseryMortality;
      delete this.calculatorModalInputObject.sowLevel;
      delete this.calculatorModalInputObject.sowIndex;
      delete this.calculatorModalInputObject.preWeaningMortality;
    }

    for (const key in this.calculatorModalInputObject) {
      const currentKey = key as keyof EconomicCalculationModalDataType;

      // Pleurisy Validation
      if (
        !this.isPulmotilValueCalculationTypeSelected &&
        currentKey !== 'referenceType' &&
        currentKey !== 'economicCalculationType' &&
        currentKey !== 'postWeaningMortality' &&
        !validateInputNumber(
          String(this.calculatorModalInputObject[currentKey])
        )
      ) {
        isInvalid = true;
      }

      // Pulmotil Validation
      if (
        this.isPulmotilValueCalculationTypeSelected &&
        currentKey !== 'targetMarketWeight' &&
        currentKey !== 'referenceType' &&
        currentKey !== 'economicCalculationType' &&
        !validateInputNumber(
          String(this.calculatorModalInputObject[currentKey])
        )
      ) {
        // If validation fails
        isInvalid = true;
      }
    }
    this.isDisabled = isInvalid;
  }
}
