import { CurrencyDropdownOption } from '@/models/swine/dropdown.model';
import { EconomicCalculationModalData } from '@/models/swine/report/report.model';
import { EconomicCalculationType } from '@/utils/constants';
import { MalaysiaEconomicCalculationAssumption } from './assumption/malaysia-assumption';
import { StandardEconomicCalculationAssumption } from './assumption/standard-assumption';
import { BronchopneumoniaEconomicLoss } from './bronchopneumonia-economic-loss/bronchopneumonia-economic-loss';
import { FarmProductionPerformance } from './farm-production-performance/farm-production-performance';
import { MedicationProgram } from './medication-program/medication-program';
import { PleurisyEconomicLoss } from './pleurisy-economic-loss/pleurisy-economic-loss';

export function getEconomicCalculation(
  transferredModalData: EconomicCalculationModalData,
  currentCurrencyDetails: {
    baseCurrency: CurrencyDropdownOption;
    currentSelectedCurrency: CurrencyDropdownOption;
  },
) {
  if (!transferredModalData) return [];

  if (
    'numberOfAnimalHeads' in transferredModalData &&
    transferredModalData.economicCalculationType ===
      EconomicCalculationType.pleurisy
  ) {
    const bronchopneumoniaEconomicLoss = new BronchopneumoniaEconomicLoss(
      transferredModalData,
      currentCurrencyDetails,
    );
    const pleurisyEconomicLoss = new PleurisyEconomicLoss(
      transferredModalData,
      currentCurrencyDetails,
    );
    const medicationProgram = new MedicationProgram(
      transferredModalData,
      currentCurrencyDetails,
    );

    const assumption = new MalaysiaEconomicCalculationAssumption(
      transferredModalData,
      currentCurrencyDetails,
    );

    return [
      ...bronchopneumoniaEconomicLoss.getPleurisyBronchopneumoniaEconomicLoss(),
      ...pleurisyEconomicLoss.getPleurisyEconomicLoss(),
      ...medicationProgram.getMedicationProgram(),
      ...assumption.getAssumption(),
    ];
  }

  if (
    'numberOfGrowerPigs' in transferredModalData &&
    transferredModalData.economicCalculationType ===
      EconomicCalculationType.pulmotil
  ) {
    const farmProductionPerformance = new FarmProductionPerformance(
      transferredModalData,
      currentCurrencyDetails,
    );
    const bronchopneumoniaEconomicLoss = new BronchopneumoniaEconomicLoss(
      transferredModalData,
      currentCurrencyDetails,
    );
    const pleurisyEconomicLoss = new PleurisyEconomicLoss(
      transferredModalData,
      currentCurrencyDetails,
    );
    const medicationProgram = new MedicationProgram(
      transferredModalData,
      currentCurrencyDetails,
    );

    const assumption = new StandardEconomicCalculationAssumption(
      transferredModalData,
      currentCurrencyDetails,
    );

    return [
      ...farmProductionPerformance.generateContent(),
      ...bronchopneumoniaEconomicLoss.getPleurisyBronchopneumoniaEconomicLoss(),
      ...pleurisyEconomicLoss.getPleurisyEconomicLoss(),
      ...medicationProgram.getMedicationProgram(),
      ...assumption.getAssumption(),
    ];
  }
}
