import { CurrencyDropdownOption } from '@/models/swine/dropdown.model';
import { PulmotilCalculationModalData } from '@/models/swine/economic-calculation.model';
import { blue, darkGrey, lightGrey, pink } from '@/utils/download-pdf/colors';
import {
  calculateNumberOfSowsFarrowingPerMonth,
  calculateNumberOfPigletsBornPerMonth,
  calculateNumberOfPigletsAtNursery,
  calculateNumberOfGrowerPigs,
} from '@/utils/economic-calculation/pulmotil/formula';
import { addCommas } from '@/utils/formats';
import { roundTo } from 'round-to';
import { imgElancoLogo } from '@/utils/download-pdf/images';

export class FarmProductionPerformance {
  // Variables
  transferredModalData: PulmotilCalculationModalData;
  sowLevelRaw = 0;
  sowIndexRaw = 0;
  averageLitterSizeBornAliveRaw = 0;
  preWeaningMortalityRaw = 0;
  nurseryMortalityRaw = 0;
  numberOfSowsFarrowingPerMonthRaw = 0;
  numberOfPigletsBornPerMonthRaw = 0;
  numberOfPigletsAtNurseryRaw = 0;
  numberOfGrowerPigsRaw = 0;
  currentCurrencyDetails: {
    baseCurrency: CurrencyDropdownOption;
    currentSelectedCurrency: CurrencyDropdownOption;
  };

  constructor(
    transferredModalData: PulmotilCalculationModalData,
    currentCurrencyDetails: {
      baseCurrency: CurrencyDropdownOption;
      currentSelectedCurrency: CurrencyDropdownOption;
    }
  ) {
    this.transferredModalData = transferredModalData;
    this.currentCurrencyDetails = currentCurrencyDetails;
  }

  // Display Values
  get sowLevel() {
    return roundTo(this.sowLevelRaw, 2);
  }

  get sowIndex() {
    return roundTo(this.sowIndexRaw, 2);
  }

  get averageLitterSizeBornAlive() {
    return roundTo(this.averageLitterSizeBornAliveRaw, 2);
  }

  get preWeaningMortality() {
    return roundTo(this.preWeaningMortalityRaw, 2);
  }

  get nurseryMortality() {
    return roundTo(this.nurseryMortalityRaw, 2);
  }

  get numberOfSowsFarrowingPerMonth() {
    this.numberOfSowsFarrowingPerMonthRaw =
      calculateNumberOfSowsFarrowingPerMonth(
        this.sowLevelRaw,
        this.sowIndexRaw
      );
    return addCommas(
      roundTo(this.numberOfSowsFarrowingPerMonthRaw, 0).toString()
    );
  }

  get numberOfPigletsBornPerMonth() {
    this.numberOfPigletsBornPerMonthRaw = calculateNumberOfPigletsBornPerMonth(
      this.numberOfSowsFarrowingPerMonthRaw,
      this.averageLitterSizeBornAliveRaw
    );

    return addCommas(
      roundTo(this.numberOfPigletsBornPerMonthRaw, 0).toString()
    );
  }

  get numberOfPigletsAtNursery() {
    this.numberOfPigletsAtNurseryRaw = calculateNumberOfPigletsAtNursery(
      this.numberOfPigletsBornPerMonthRaw,
      this.preWeaningMortalityRaw
    );
    return addCommas(roundTo(this.numberOfPigletsAtNurseryRaw, 0).toString());
  }

  get numberOfGrowerPigs() {
    this.numberOfGrowerPigsRaw = calculateNumberOfGrowerPigs(
      this.numberOfPigletsAtNurseryRaw,
      this.nurseryMortalityRaw
    );
    return addCommas(roundTo(this.numberOfGrowerPigsRaw, 0).toString());
  }

  private syncVariables() {
    this.sowLevelRaw = this.transferredModalData.sowLevel || 0;
    this.sowIndexRaw = this.transferredModalData.sowIndex || 0;
    this.averageLitterSizeBornAliveRaw =
      this.transferredModalData.averageLitterSizeBornAlive || 0;
    this.preWeaningMortalityRaw =
      this.transferredModalData.preWeaningMortality || 0;
    this.nurseryMortalityRaw = this.transferredModalData.nurseryMortality || 0;
  }

  private generateLeftColumnContent() {
    // Generate Left column items
    return [
      [
        {
          text: 'Sow level (heads)',
          bold: true,
          fillColor: `${lightGrey}`,
          border: [false, false, false, true],
        },
        {
          text: this.sowLevel,
          fillColor: `${pink}`,
          border: [false, false, false, true],
        },
      ],
      [
        {
          text: `Sow Index`,
          bold: true,
          fillColor: `${lightGrey}`,
          border: [false, false, false, true],
        },
        {
          text: this.sowIndex,
          fillColor: `${pink}`,
          border: [false, false, false, true],
        },
      ],
      [
        {
          text: 'Average litter size born alive',
          bold: true,
          fillColor: `${lightGrey}`,
          border: [false, false, false, true],
        },
        {
          text: this.averageLitterSizeBornAlive,
          fillColor: `${pink}`,
          border: [false, false, false, true],
        },
      ],
      [
        {
          text: `Pre-weaning mortality (%)`,
          bold: true,
          fillColor: `${lightGrey}`,
          border: [false, false, false, true],
        },
        {
          text: this.preWeaningMortality,
          fillColor: `${pink}`,
          border: [false, false, false, true],
        },
      ],
      [
        {
          text: `Nursery mortality (%)`,
          bold: true,
          fillColor: `${lightGrey}`,
          border: [false, false, false, true],
        },
        {
          text: this.nurseryMortality,
          fillColor: `${pink}`,
          border: [false, false, false, true],
        },
      ],
    ];
  }

  private generateRightColumnContent() {
    return [
      [
        {
          text: 'No .of Sows Farrowing / month',
          bold: true,
          fillColor: `${lightGrey}`,
          border: [false, false, false, true],
        },
        {
          text: this.numberOfSowsFarrowingPerMonth,
          border: [false, false, false, true],
        },
        {
          text: `heads`,
          color: `${darkGrey}`,
          border: [false, false, false, true],
        },
      ],
      [
        {
          text: 'No. of Piglets Born / month',
          bold: true,
          fillColor: `${lightGrey}`,
          border: [false, false, false, true],
        },
        {
          text: this.numberOfPigletsBornPerMonth,
          border: [false, false, false, true],
        },
        {
          text: `heads`,
          color: `${darkGrey}`,
          border: [false, false, false, true],
        },
      ],
      [
        {
          text: 'No .of Piglets at Nursery (Weaned Piglets)',
          bold: true,
          fillColor: `${lightGrey}`,
          border: [false, false, false, true],
        },
        {
          text: this.numberOfPigletsAtNursery,
          border: [false, false, false, true],
        },
        {
          text: `heads`,
          color: `${darkGrey}`,
          border: [false, false, false, true],
        },
      ],
      [
        {
          text: `No .of Grower Pigs`,
          bold: true,
          fillColor: `${lightGrey}`,
          border: [false, false, false, true],
        },
        {
          text: this.numberOfGrowerPigs,
          border: [false, false, false, true],
        },
        {
          text: `heads`,
          color: `${darkGrey}`,
          border: [false, false, false, true],
        },
      ],
    ];
  }

  private getCurrentExchangeRateBar() {
    return {
      style: ['lightGreyBg', 'fontSize8'],
      table: {
        widths: ['auto', 'auto', '*'],
        body: [
          [
            {
              text: 'Current Exchange Rate:',
              color: `${darkGrey}`,
              border: [false, false, false, false],
              margin: 5,
            },
            {
              text: `${this.currentCurrencyDetails.baseCurrency.text} 1 = ${
                this.currentCurrencyDetails.currentSelectedCurrency.text
              } ${
                this.currentCurrencyDetails.currentSelectedCurrency.value /
                this.currentCurrencyDetails.baseCurrency.value
              }`,
              color: `${blue}`,
              border: [false, false, false, false],
              margin: 5,
            },
            {
              text: `${this.currentCurrencyDetails.currentSelectedCurrency.text}`,
              color: `${darkGrey}`,
              alignment: 'right',
              border: [false, false, false, false],
              margin: 5,
            },
          ],
        ],
      },
      marginBottom: 5,
    };
  }

  generateContent() {
    this.syncVariables();
    return [
      {
        svg: imgElancoLogo,
        width: 100,
        margin: [0, 0, 0, -30],
        border: [false, false, false, false],
      },
      {
        text: 'Economic Calculation (Farm Production Performance)',
        style: 'sectionHeader',
      },
      this.getCurrentExchangeRateBar(),
      {
        columnGap: 15,
        pageBreak: 'after',
        columns: [
          {
            style: [`fontSize8`],
            table: {
              widths: [`*`, `*`],
              body: this.generateLeftColumnContent(),
            },
            layout: 'allGreyBorder',
          },
          {
            style: [`fontSize8`],
            table: {
              widths: [`*`, `*`, `*`],
              body: this.generateRightColumnContent(),
            },
            layout: 'allGreyBorder',
          },
        ],
      },
    ];
  }
}
