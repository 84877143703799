















































import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import DeleteModalTemplate from '@/components/swine/layout/DeleteModalTemplate.vue';
import {
  Attachment,
  LungAssessmentIdWithAttachment,
  LungAssessmentIdWithShowModal,
} from '@/models/swine/report/report.model';
@Component({
  components: {
    DeleteModalTemplate
  },
})
export default class AttachmentImageComponent extends Vue {
  @Prop({
    type: Object,
    default: null,
  })
  attachment!: Attachment;

  @Prop({
    type: Number,
    default: null,
  })
  lungAssessmentId!: number;

  @Prop({
    type: Boolean,
    default: false,
  })
  isEditAllowed!: boolean;

  isModalActive = false;
  isDeleteModalActive = false;

  promptDeleteAttachment() {
    this.isDeleteModalActive = true;
  }

  confirmDeleteAttachment(value: boolean) {
    if (value) {
      this.deleteAttachment();
    }
    this.isDeleteModalActive = false;
  }

  @Emit('show')
  showModal() {
    const output: LungAssessmentIdWithShowModal = {
      lungAssessmentId: this.lungAssessmentId,
      showModal: true,
    };
    return output;
  }

  @Emit('delete')
  deleteAttachment() {
    const output: LungAssessmentIdWithAttachment = {
      lungAssessmentId: this.lungAssessmentId,
      attachment: this.attachment,
    };
    return output;
  }
}
