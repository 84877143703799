









import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  components: {},
})
export default class ReferenceType extends Vue {
  @Prop({})
  transferredReferenceType!: string;
}
