import { Country } from '../country/country.model';
import { FarmUnit } from '../farmUnit/farmUnit.model';
import {
  EconomicCalculationModalDataType,
  MedicineBrand,
} from '../economic-calculation.model';
import { User } from '../user/user.model';
import { DropdownOption } from '../dropdown.model';
export const ReportTypeStatus = {
  MOCK: 'mock',
  NORMAL: 'normal',
};

export interface FetchReportDetails {
  reportId: number;
  section: string;
}

export interface FetchLungAttachmentDetails extends FetchReportDetails {
  limit: number;
  offset: number;
}

export interface DisplayData {
  headerText: string;
  subText: string;
  value: string;
}

export interface DisplayDataAssumption {
  headerText: string;
  assumptionValue?: string;
  headerValue: string;
  subText: string;
  subValue: string;
  subAssumptionValue?: string;
}

export interface AppIndexObject {
  appIndex: number;
  totalReports?: number;
  ranking?: number;
  country?: Partial<Country>;
  appIndexColor: string;
  appIndexBackgroundColor?: string;
}
export interface dropDownObject {
  id: number;
  name: string;
}

export interface ReportSummaryData {
  auditor: User;
  farmUnit: Partial<FarmUnit>;
  totalLungsObserved: number;
  date: Date | string;
  weaningDate: Date | string;
  marketDate: Date | string;
  averageWeaningWT: number;
  averageMarketWT: number;
  adg?: number;
  fcr: number;
  marketAgeDays?: number;
  reportType: string;
  reportName?: string;
  bronchopneumoniaLungsPercentage?: number;
  averageOfAffectedSurfaceOutOfLungsWithActivePneumoniaPercentage?: number;
  pigsWithCranialPleurisyPercentage?: number;
  pigsWithDorsocaudalPleurisyPercentage?: number;
  totalPigsWithAbscess?: number;
  totalPigsWithScarring?: number;
  totalPigsWithPericarditis?: number;
  pneumoniaIndex?: number;
  pneumoniaPrevalence?: number;
  appIndex?: { appIndex: number } | Partial<AppIndexObject>;
  normalScore?: number;
  pneumoniaScore?: number;
  severePneumoniaScore?: number;
  p1PleuritisScore?: number;
  otherPleuritisScore?: number;
  abscessCount?: number;
  scarringCount?: number;
  pericarditisCount?: number;
  animalType?: dropDownObject;
  season?: dropDownObject;
  housingType?: dropDownObject;
  flooringType?: dropDownObject;
  operationType?: dropDownObject;
  medicationProgram?: string;
  estMedicationCost?: number;
  mHyoVaccine?: string;
  appVaccine?: string;
  prrsVaccine?: string;
  pcv2Vaccine?: string;
}

export interface FarmResultSummaryWithReportData extends ReportSummaryData {
  pneumoniaIndex: number;
  pneumoniaPrevalence: number;
  bronchopneumoniaLungsPercentage: number;
  averageOfAffectedSurfaceOutOfLungsWithActivePneumoniaPercentage: number;
  pigsWithCranialPleurisyPercentage: number;
  pigsWithDorsocaudalPleurisyPercentage: number;
  totalPigsWithAbscess: number;
  totalPigsWithScarring: number;
  totalPigsWithPericarditis: number;
  normalScore: number;
  pneumoniaScore: number;
  severePneumoniaScore: number;
  p1PleuritisScore: number;
  otherPleuritisScore: number;
  abscessCount: number;
  scarringCount: number;
  pericarditisCount: number;
}

export interface BronchopneumoniaScoreWithReportData extends ReportSummaryData {
  pneumoniaIndex: number;
  pneumoniaPrevalence: number;
  bronchopneumoniaLungsMildToSevere: number;
  averagePercentageAffectedLungsSurface: number;
  averagePercentageAffectedLungsSurfaceWithActivePneumonia: number;
  pneumoniaCountDict: { [key: string]: number };
  pneumoniaPercentageDict: { [key: string]: number };
  pneumoniaTotalPercentageDict: { [key: string]: number };
  totalLungsScored: number;
}

export interface PleurisyScoreWithReportData extends ReportSummaryData {
  pleurisyDict: { [key: string]: number };
  pleurisyPercentageDict: { [key: string]: number };
  totalLungsWithPleurisy: number;
}

export interface AuditReportSearchParams {
  query?: string | null;
  country?: string | null;
  region?: string | null;
  state?: string | null;
  client?: string | null;
  farm?: string | null;
  farmUnit?: string | null;
  month?: string | null;
  year?: string | null;
  auditor?: string | null;
  type?: string | null;
  limit?: number | null;
  offset?: number | null;
  sortBy?: string | null;
}

export interface AuditReportListResponse {
  user: Partial<User>;
  auditorId: number;
  createdDate: Date;
  deletedDate: Date | null;
  id: number;
  index: string;
  publishedDate: Date;
  reportTypeId: number;
  reportValues: any;
  status: string;
  type: string;
  updatedDate: Date;
}

export interface IndividualReportDropdownResponse {
  reports: IndividualReportListResponse[];
  totalCount: number;
}

export interface IndividualReportListResponse {
  id: number;
  auditorId: number;
  createdDate: Date;
  updatedDate: Date;
  publishedDate: Date;
  deletedDate: Date | null;
  reportTypeId: number;
  reportValues: any;
  status: string;
  type: string;
  farmUnitCodeName: string;
}

export interface AuditReportResponseParams {
  totalCount: number;
  filteredCount: number;
  reports: AuditReportListResponse[];
}

export interface AuditReportDisplayValues {
  auditDateTime: string;
  farmUnitCodeName?: string | null;
  id: number;
  index: string;
  totalLungsObserved: number;
  auditorEmail: string;
  auditorName: string;
  reportName?: string;
  country: string | null;
}

export interface GenerateLungDiseasesDict {
  abscess: { [key: string]: number };
  scarring: { [key: string]: number };
  pericarditis: { [key: string]: number };
}

export interface AbscessScarringAndPericarditisWithReportData
  extends ReportSummaryData,
    GenerateLungDiseasesDict {}

export interface Quartiles {
  firstQuartile: number;
  secondQuartile: number;
  thirdQuartile: number;
}

export interface AppIndexAndPrevalence {
  appIndexDetails: AppIndexObject;
  quartiles: Quartiles;
  appPleurisyPrevalence: number;
  pleurisyPrevalence: number;
}

export interface AppIndex {
  reportId: number;
  appIndex: number;
}

export interface AppIndexAndPrevalenceWithReportData
  extends AppIndexAndPrevalence,
    ReportSummaryData {}

export interface GeneralRemarks {
  comment: string;
  attachments: Attachment[];
}

export interface Attachment {
  id: number;
  userId: number;
  originalName: string;
  mimeType: string;
  url: string;
  hasParent?: boolean;
}

export interface ScoreAttachmentObject {
  textColor: string;
  backgroundColor: string;
  value: string | number;
}
export interface LungLesionScoreParseAttachmentDetails {
  id: number;
  rightApical: ScoreAttachmentObject;
  rightCardiac: ScoreAttachmentObject;
  rightDiaphragmatic: ScoreAttachmentObject;
  leftApical: ScoreAttachmentObject;
  leftCardiac: ScoreAttachmentObject;
  leftDiaphragmatic: ScoreAttachmentObject;
  accessory: ScoreAttachmentObject;
  total: ScoreAttachmentObject;
  pleurisy: ScoreAttachmentObject;
  abscess: ScoreAttachmentObject;
  scarring: ScoreAttachmentObject;
  pericarditis: ScoreAttachmentObject;
  generalRemarks: GeneralRemarks;
}

export interface SpawnAttachmentPage {
  section: string;
  isAccepted: boolean;
}

export interface EconomicCalculationDetails {
  pneumoniaSeverityDict?: { [key: string]: number };
  appPleurisyPrevalence?: number;
  p3AndP4Prevalence?: number;
  cleanSowFeedIntake?: number | null;
  cleanSowDuration?: number | null;
  cleanSowPSRatio?: number | null;
  cleanSowDosage?: number | null;
  cleanSowMedicinePrice?: number | null;
  cleanSowMedicineBrand?: MedicineBrand | null;
  startStrongFeedIntake?: number | null;
  startStrongDuration?: number | null;
  startStrongDosage?: number | null;
  startStrongMedicinePrice?: number | null;
  startStrongMedicineBrand?: MedicineBrand | null;
  strategicProgramFeedIntake?: number | null;
  strategicProgramDuration?: number | null;
  strategicProgramDosage?: number | null;
  strategicProgramMedicinePrice?: number | null;
  strategicProgramMedicineBrand?: MedicineBrand | null;
}
export interface CurrencyExchangeRateDetails {
  IDR: number | null;
  MYR: number | null;
  PHP: number | null;
  THB: number | null;
  USD: number | null;
  VND: number | null;
  RMB: number | null;
  BND: number | null;
  NTD: number | null;
}

export interface EconomicCalculationWithReportData extends ReportSummaryData {
  pneumoniaSeverityDict?: { [key: string]: number };
  appPleurisyPrevalence?: number;
  p3AndP4Prevalence?: number;
  economicCalculationDetails: EconomicCalculationModalData | null;
}

export interface AuditReportUpdateQueries {
  lungAssessmentId?: number;
  attachments?: Attachment[];
  calculatorModalData?: EconomicCalculationModalData | null;
  updateType?: string;
}

export type EconomicCalculationModalData = EconomicCalculationModalDataType;

export interface AssumptionModalData {
  bronchopneumoniaAssumptionNormalLungsLowPercentage?: number | null;
  bronchopneumoniaAssumptionNormalLungsHighPercentage?: number | null;
  bronchopneumoniaAssumptionLowPneumonia?: number | null;
  bronchopneumoniaAssumptionNormalPneumonia?: number | null;
  bronchopneumoniaAssumptionHighPneumonia?: number | null;
  bronchopneumoniaAssumptionLowSeverePneumonia?: number | null;
  bronchopneumoniaAssumptionHighSeverePneumonia?: number | null;
  p3AndP4PrevalenceAssumption?: number | null;
  appPleurisyPrevalenceAssumption?: number | null;
  postWeaningMortalityAssumption?: number | null;
}

export interface EconomicCalculationDetails {
  pneumoniaSeverityDict?: { [key: string]: number };
  appPleurisyPrevalence?: number;
  p3AndP4Prevalence?: number;
  cleanSowFeedIntake?: number | null;
  cleanSowDuration?: number | null;
  cleanSowPSRatio?: number | null;
  cleanSowDosage?: number | null;
  cleanSowMedicinePrice?: number | null;
  cleanSowMedicineBrand?: MedicineBrand | null;
  startStrongFeedIntake?: number | null;
  startStrongDuration?: number | null;
  startStrongDosage?: number | null;
  startStrongMedicinePrice?: number | null;
  startStrongMedicineBrand?: MedicineBrand | null;
  strategicProgramFeedIntake?: number | null;
  strategicProgramDuration?: number | null;
  strategicProgramDosage?: number | null;
  strategicProgramMedicinePrice?: number | null;
  strategicProgramMedicineBrand?: MedicineBrand | null;
}

export interface LungAssessmentIdWithShowModal {
  lungAssessmentId: number;
  showModal: boolean;
}

export interface LungAssessmentIdWithAttachment {
  lungAssessmentId: number;
  attachment: Attachment;
}

export enum UpdateType {
  DELETE = 'delete',
  UPDATE = 'update',
}

export interface DeleteReportParams {
  reportIds: number[];
}

export interface ExcelReportData {
  client: Client;
  country: ReportCountry;
  farm: Farm;
  region: Region;
  report: Report;
  state: State;
}

export interface ExportSummaryReportData {
  report: ExcelReportData[];
  pleurisy: AppIndexAndPrevalenceWithReportData;
  summaryReport: ReportSummaryData;
}

export interface Client {
  city_id: number | null;
  client_code: string | null;
  country_id: number | null;
  created_date: string | null;
  deleted_date: string | null;
  id: number | null;
  name: string | null;
  phone_number: string | null;
  postcode: string | null;
  region_id: number | null;
  state_id: number | null;
  street_address: string | null;
  updated_date: string | null;
}

export interface ReportCountry {
  code: string | null;
  created_date: string | null;
  deleted_date: string | null;
  id: number | null;
  name: string | null;
  updated_date: string | null;
}

export interface Farm {
  city_id: number | string | null;
  client_id: number | string | null;
  country_id: number | string | null;
  created_date: string | null;
  deleted_date: string | null;
  farm_code: string | null;
  id: number | null;
  name: string | null;
  phone_number: string | null;
  postcode: string | null;
  region_id: number | string | null;
  state_id: number | string | null;
  street_address: string | null;
  updated_date: string | null;
}

export interface Region {
  code: string | null;
  country_id: number | string | null;
  created_date: string | null;
  deleted_date: number | string | null;
  id: number | string | null;
  name: string | null;
  updated_date: string | null;
}

export interface Report {
  audit_score: number | null;
  auditor_id: number | null;
  created_date: string | null;
  deleted_date: number | string | null;
  id: number | null;
  published_date: string | Date;
  report_type_id: number | null;
  report_values: any;
  status: string | null;
  type: string | null;
  updated_date: string | null;
}

export interface State {
  code: string | null;
  created_date: string | null;
  deleted_date: string | number | null;
  id: number | null;
  name: string | null;
  region_id: number | null;
  updated_date: string | null;
}

export type EditReportData = Pick<
  ReportSummaryData, 
  'date' | 
  'weaningDate' | 
  'marketDate' | 
  'averageWeaningWT' | 
  'averageMarketWT' |
  'adg' |
  'fcr' |
  'animalType' |
  'marketAgeDays' |
  'season' |
  'housingType' |
  'flooringType' |
  'operationType' |
  'medicationProgram' |
  'estMedicationCost' |
  'mHyoVaccine' |
  'appVaccine' |
  'prrsVaccine' |
  'pcv2Vaccine'
>;

export type EditReportDataModal = Omit<EditReportData, 'animalType' | 'housingType' | 'operationType' | 'flooringType' | 'season'> &
  { animalType: DropdownOption, housingType: DropdownOption, operationType: DropdownOption, flooringType: DropdownOption, season: DropdownOption}

export interface PatchAuditReportGeneralDetails {
  id: number;
  data: {[key in keyof EditReportData]: EditReportData[key] | null};
}

export type EditSpecifiedReportModalData = Omit<{[key in keyof EditReportDataModal]: EditReportDataModal[key] | null}, 'date' | 'weaningDate' | 'marketDate'>  & { date: Date, weaningDate: Date, marketDate: Date }