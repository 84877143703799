export const limeGreen = '#D6FFA4';
export const lightGreen = '#99E895';
export const green = '#537330';
export const cream = '#FFF5C9';
export const brown = '#745E04';
export const pink = '#FFEAEA';
export const darkPink = '#FFC1C1';
export const red = '#FF5857';
export const lightBlue = '#DAF2FF';
export const blue = '#01A5E1';
export const lightGrey = '#F5F5F5';
export const darkGrey = '#D8D8D8';
export const yellow = '#FFF084';
export const orange = '#FFD16A';
