import { PleurisyScoreWithReportData } from '@/models/swine/report/report.model';
import { getBase64ImageFromURL } from '@/utils/base64ImageFromUrl';
import { addDecimalPointToNumbers } from '@/utils/formats';
import { GenerateChartImage } from '@/utils/generate-chart/generate-chart-image';

export async function getPleurisyAndAttachments(
  pleurisyScore: PleurisyScoreWithReportData,
  pleurisyAttachments: any,
) {
  const chartData = {
    labels: [
      `Pleuritis 0`,
      `Pleuritis 1`,
      `Pleuritis 2`,
      `Pleuritis 3`,
      `Pleuritis 4`,
    ],
    datasets: [
      {
        data: [
          pleurisyScore.pleurisyPercentageDict[`P0`],
          pleurisyScore.pleurisyPercentageDict[`P1`],
          pleurisyScore.pleurisyPercentageDict[`P2`],
          pleurisyScore.pleurisyPercentageDict[`P3`],
          pleurisyScore.pleurisyPercentageDict[`P4`],
        ],
        backgroundColor: [
          `#D6FFA4`,
          `#FFF5C9`,
          `#FFEAEA`,
          `#FFD4D4`,
          `#FFC1C1`,
        ],
        borderColor: [`#6C9D31`, `#B99913`, `#F15453`, `#F15453`, `#F15453`],
        borderWidth: 1,
        barPercentage: 0.2,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: true,
    legend: {
      display: false,
    },
    plugins: {
      datalabels: {
        display: false,
      },
    },
    scales: {
      yAxes: [
        {
          stacked: true,
          ticks: {
            min: 0,
            max: 120,
            beginAtZero: true,
            callback: function (tick: string | number) {
              return tick.toString() + `%`;
            },
          },
        },
      ],
      xAxes: [
        {
          stacked: true,
          offset: true,
        },
      ],
    },
    animation: false
  };
  const gci = new GenerateChartImage(chartData, options);

  const pleurisyDataLayout: any[] = [];

  if (pleurisyAttachments.length) {
    for (let pleurisyData of pleurisyAttachments) {
      let attachments = pleurisyData.pop();

      pleurisyDataLayout.push({
        text: 'Lungs Breakdown',
        bold: true,
      })
      pleurisyDataLayout.push({
        layout: 'regularTable',
        margin: [0, 30, 0, 0],
        style: ['fontSize8', 'textCenter'],
        table: {
          widths: ['auto', '*'],
          body: [
            [
              {
                text: 'Lung ID',
                bold: true,
                margin: [30, 25, 30, 0],
                border: [false, false, true, false],
                rowSpan: 2,
                fillColor: '#f2f2f2',
              },
              {
                text: 'Pleurisy Score',
                bold: true,
                margin: [0, 10, 0, 10],
                border: [false, false, false, false],
                fillColor: '#f2f2f2',
              },
            ],
            [
              '',
              {
                text: 'P0 - P4',
                bold: true,
                margin: [0, 5, 0, 5],
                border: [false, true, false, false],
                fillColor: '#f2f2f2',
              },
            ],
            pleurisyData,
          ],
        },
      })

      let attachmentLayout: any[] = []
      let attachmentBase64Function: any[] = []

      for (let attachment of attachments)  {
        attachmentBase64Function.push(getBase64ImageFromURL(attachment.link))
      }

      const attachmentBase64 = await Promise.all(attachmentBase64Function)

      for (let index = 0; index < attachments.length; index++)  {
        let _attachment: any[] = []

        _attachment.push({
          width: 200,
          height: 200,
          image: attachmentBase64[index],
        })
        _attachment.push({
          color: '#1697db',
          text: `Attachment ${index + 1}`,
          link: attachments[index].link,
          margin: [0, 10, 0, 0],
        })

        attachmentLayout.push({
          stack: _attachment,
          alignment: 'center',
          width: '33%',
        })
      }

      pleurisyDataLayout.push({
        columns: attachmentLayout,
        margin: [0, 50, 0, 0],
      })
      pleurisyDataLayout.push({
        text: '',
        pageBreak: 'after',
      })
    }
  } else {
    pleurisyDataLayout.push({
      text: '',
      pageBreak: 'after',
    })
  }

  const content = [
    {
      text: 'Pleurisy',
      style: 'sectionHeader',
    },
    {
      image: gci.generateImage(),
      width: 741,
      height: 200,
      alignment: 'center',
      marginBottom: 15,
    },
    {
      style: 'reportDetailsHeader',
      table: {
        widths: '*',
        body: [
          [
            {
              text: 'Pleurisy',
              bold: true,
              fillColor: '#F5F5F5',
              fontSize: 8,
              border: [false, false, false, true],
            },
            {
              text: 'Pleuritis 0',
              bold: true,
              fillColor: '#D6FFA4',
              color: '#557532',
              fontSize: 8,
              border: [false, false, false, true],
            },
            {
              text: 'Pleuritis 1',
              bold: true,
              fillColor: '#FFF5C9',
              color: '#745E04',
              fontSize: 8,
              border: [false, false, false, true],
            },
            {
              text: 'Pleuritis 2',
              bold: true,
              fillColor: '#FFEAEA',
              color: '#FF4C4B',
              fontSize: 8,
              border: [false, false, false, true],
            },
            {
              text: 'Pleuritis 3',
              bold: true,
              fillColor: '#FFD4D4',
              color: '#FF4C4B',
              fontSize: 8,
              border: [false, false, false, true],
            },
            {
              text: 'Pleuritis 4',
              bold: true,
              fillColor: '#FFC1C1',
              color: '#FF4D4C',
              fontSize: 8,
              border: [false, false, false, true],
            },
            {
              text: 'Total Lung',
              bold: true,
              fillColor: '#F5F5F5',
              fontSize: 8,
              border: [false, false, false, true],
            },
          ],
          [
            {
              text: 'No. of Lung',
              bold: true,
              fillColor: '#F5F5F5',
              fontSize: 8,
              border: [false, false, false, true],
            },
            {
              text: `${pleurisyScore.pleurisyDict['P0']}`,
              bold: true,
              fillColor: '#D6FFA4',
              color: '#557532',
              fontSize: 8,
              border: [false, false, false, true],
            },
            {
              text: `${pleurisyScore.pleurisyDict['P1']}`,
              bold: true,
              fillColor: '#FFF5C9',
              color: '#745E04',
              fontSize: 8,
              border: [false, false, false, true],
            },
            {
              text: `${pleurisyScore.pleurisyDict['P2']}`,
              bold: true,
              fillColor: '#FFEAEA',
              color: '#FF4C4B',
              fontSize: 8,
              border: [false, false, false, true],
            },
            {
              text: `${pleurisyScore.pleurisyDict['P3']}`,
              bold: true,
              fillColor: '#FFD4D4',
              color: '#FF4C4B',
              fontSize: 8,
              border: [false, false, false, true],
            },
            {
              text: `${pleurisyScore.pleurisyDict['P4']}`,
              bold: true,
              fillColor: '#FFC1C1',
              color: '#FF4D4C',
              fontSize: 8,
              border: [false, false, false, true],
            },
            {
              text: `${pleurisyScore.totalLungsObserved}`,
              bold: true,
              fillColor: '#F5F5F5',
              fontSize: 8,
              border: [false, false, false, true],
            },
          ],
          [
            {
              text: '% of Lung',
              bold: true,
              fillColor: '#F5F5F5',
              fontSize: 8,
              border: [false, false, false, false],
            },
            {
              text: `${addDecimalPointToNumbers(
                pleurisyScore.pleurisyPercentageDict['P0'],
              )}`,
              bold: true,
              fillColor: '#D6FFA4',
              color: '#557532',
              fontSize: 8,
              border: [false, false, false, false],
            },
            {
              text: `${addDecimalPointToNumbers(
                pleurisyScore.pleurisyPercentageDict['P1'],
              )}`,
              bold: true,
              fillColor: '#FFF5C9',
              color: '#745E04',
              fontSize: 8,
              border: [false, false, false, false],
            },
            {
              text: `${addDecimalPointToNumbers(
                pleurisyScore.pleurisyPercentageDict['P2'],
              )}`,
              bold: true,
              fillColor: '#FFEAEA',
              color: '#FF4C4B',
              fontSize: 8,
              border: [false, false, false, false],
            },
            {
              text: `${addDecimalPointToNumbers(
                pleurisyScore.pleurisyPercentageDict['P3'],
              )}`,
              bold: true,
              fillColor: '#FFD4D4',
              color: '#FF4C4B',
              fontSize: 8,
              border: [false, false, false, false],
            },
            {
              text: `${addDecimalPointToNumbers(
                pleurisyScore.pleurisyPercentageDict['P4'],
              )}`,
              bold: true,
              fillColor: '#FFC1C1',
              color: '#FF4D4C',
              fontSize: 8,
              border: [false, false, false, false],
            },
            {
              text: ``,
              bold: true,
              fillColor: '#F5F5F5',
              fontSize: 8,
              border: [false, false, false, false],
            },
          ],
        ],
      },
      layout: 'regularTable',
      pageBreak: 'after',
    },
    {
      text: 'Pleurisy Attachments',
      style: 'sectionHeader',
    },
    ...pleurisyDataLayout,
  ];

  gci.clear();
  return content;
}
