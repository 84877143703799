






















import { Component, Prop, Vue } from 'vue-property-decorator';
import ReportCardTemplate from '@/components/swine/reports/ReportCardTemplate.vue';
import { AppIndexObject } from '@/models/swine/report/report.model';

@Component({
  components: { ReportCardTemplate },
})
export default class AppIndexCard extends Vue {
  @Prop({
    type: Object,
    default: null,
  })
  reportValues!: AppIndexObject;

  get appIndexColor() {
    if (this.reportValues) {
      return `color: ${this.reportValues.appIndexBackgroundColor}`;
    }
    return ``;
  }

  get appIndex() {
    if (this.reportValues) {
      let output = this.reportValues.appIndex.toString();
      if (output.includes('.')) {
        while (output.split('.')[1].length < 3) {
          output += '0';
        }
        return output;
      } else {
        output += '.000';
        return output;
      }
    }
    return '0.000';
  }

  get reportRanking() {
    if (!this.reportValues.ranking) {
      return '';
    }

    if (this.reportValues.ranking < 21 && this.reportValues.ranking > 10) {
      return `${this.reportValues.ranking}th`;
    }

    const remainder = this.reportValues.ranking % 10;
    switch (remainder) {
      case 1: {
        return `${this.reportValues.ranking}st`;
      }
      case 2: {
        return `${this.reportValues.ranking}nd`;
      }
      case 3: {
        return `${this.reportValues.ranking}rd`;
      }
      default: {
        return `${this.reportValues.ranking}th`;
      }
    }
  }
}
