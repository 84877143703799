

































































































































































































































































































































































































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import SectionLayoutCardAssumptionUpdated from '@/components/swine/reports/SectionLayoutCardAssumptionUpdated.vue';
import {
  AssumptionModalData,
  PleurisyCalculatorModalData,
} from '@/models/swine/economic-calculation.model';
import { DisplayDataAssumption } from '@/models/swine/report/report.model';
import {
  addCommas,
  addDecimalPointToNumbers,
} from '@/utils/formats';
import BasicBarChart from '@/components/swine/reports/BasicBarChart.vue';
import AssumptionReportLegendCardUpdated from './AssumptionReportLegendCardUpdated.vue';
import { ChartData, ChartOptions } from 'chart.js';
import MedicationUpdateModal from './MedicationUpdateModal.vue';
import AssumptionModal from './AssumptionModal.vue';
import { roundTo } from 'round-to';
@Component({
  components: {
    SectionLayoutCardAssumptionUpdated,
    BasicBarChart,
    AssumptionReportLegendCardUpdated,
    MedicationUpdateModal,
    AssumptionModal,
  },
})
export default class AssumptionReportUpdated extends Vue {
  chartData: Chart.ChartData | null = null;
  options: Chart.ChartOptions | null = null;
  updateMedicationModalStatus = false;
  assumptionModalStatus = false;

  @Prop({ required: true })
  calculatorModalInputDataRetrieve!: PleurisyCalculatorModalData;

  @Prop({})
  adgReductionPercentages!: number[];

  @Prop({})
  animalPercentages!: number;

  @Prop({})
  numberOfAnimalHeadsSold!: number;

  @Prop({})
  totalAdditionalFeedCostPer1000!: number;

  @Prop({})
  totalCostOfAPP!: number;

  @Prop({})
  pleurisyPrevalenceBackValue!: number;

  @Prop({})
  p3P4BackValue!: number;

  @Prop({})
  totalInvestment!: number;

  @Prop({})
  medicationInvestmentPig!: number;

  standardizeValue(data: number) {
    const value = roundTo(data, 1);
    return addDecimalPointToNumbers(value);
  }

  get assumptionUpdateObject(): AssumptionModalData {
    return {
      bronchopneumoniaAssumptionNormalLungsLowPercentage: this
        .calculatorModalInputDataRetrieve
        .bronchopneumoniaAssumptionNormalLungsLowPercentage,
      bronchopneumoniaAssumptionNormalLungsHighPercentage: this
        .calculatorModalInputDataRetrieve
        .bronchopneumoniaAssumptionNormalLungsHighPercentage,
      bronchopneumoniaAssumptionLowPneumonia: this
        .calculatorModalInputDataRetrieve
        .bronchopneumoniaAssumptionLowPneumonia,
      bronchopneumoniaAssumptionNormalPneumonia: this
        .calculatorModalInputDataRetrieve
        .bronchopneumoniaAssumptionNormalPneumonia,
      bronchopneumoniaAssumptionHighPneumonia: this
        .calculatorModalInputDataRetrieve
        .bronchopneumoniaAssumptionHighPneumonia,
      bronchopneumoniaAssumptionLowSeverePneumonia: this
        .calculatorModalInputDataRetrieve
        .bronchopneumoniaAssumptionLowSeverePneumonia,
      bronchopneumoniaAssumptionHighSeverePneumonia: this
        .calculatorModalInputDataRetrieve
        .bronchopneumoniaAssumptionHighSeverePneumonia,
      p3AndP4PrevalenceAssumption: this.calculatorModalInputDataRetrieve
        .p3AndP4PrevalenceAssumption,
      appPleurisyPrevalenceAssumption: this.calculatorModalInputDataRetrieve
        .appPleurisyPrevalenceAssumption,
      postWeaningMortalityAssumption: this.calculatorModalInputDataRetrieve
        .postWeaningMortalityAssumption,
    };
  }

  get computedChartOptions(): ChartOptions {
    return {
      maintainAspectRatio: false,
      legend: {
        display: false,
      },
      scales: {
        yAxes: [
          {
            gridLines: {
              drawBorder: false,
            },
            ticks: {
              min: 0,
              beginAtZero: true,
              padding: 16,
              callback: (tick) => {
                return `${
                  this.calculatorModalInputDataRetrieve.currencySymbol
                } ${addCommas(tick.toString())}`;
              },
            },
            stacked: true,
          },
        ],
        xAxes: [
          {
            gridLines: {
              display: false,
              drawBorder: false,
            },
            stacked: true,
          },
        ],
      },
      plugins: {
        datalabels: {
          anchor: 'end',
          align: 'end',
          formatter: function (value) {
            return `${value}`;
          },
          display: false,
        },
      },
    };
  }

  get computedChartData(): ChartData {
    return {
      labels: [
        'Economic Loss',
        'Assume Improvement on Economic Loss',
        'Medication Program',
        'Captured Opportunity Loss',
      ],
      datasets: [
        {
          label: 'Bronchpneumonic Economic Loss',
          data: [Number(this.totalAdditionalFeedCostPer1000.toFixed(2))],
          backgroundColor: [`#FFEAEA`],
          borderColor: [`#FF4C4B`],
          borderWidth: 1,
          barPercentage: 0.2,
        },
        {
          label: 'Pleurisy Economic Loss',
          data: [Number(this.totalCostOfAPP.toFixed(2))],
          backgroundColor: [`#FFC1C1`],
          borderColor: [`#FF4C4B`],
          borderWidth: 1,
          barPercentage: 0.2,
        },
        {
          label: 'Bronchpneumonic Economic Loss',
          data: [
            0,
            Number(this.totalAdditionalFeedCostPerNumberAssumption.toFixed(2)),
          ],
          backgroundColor: `#FFEAEA`,
          borderColor: `#00A5E1`,
          borderWidth: 1,
          barPercentage: 0.2,
        },
        {
          label: 'Pleurisy Economic Loss',
          data: [0, Number(this.totalCostOfAPPAssumption.toFixed(2))],
          backgroundColor: `#FFC1C1`,
          borderColor: `#00A5E1`,
          borderWidth: 1,
          barPercentage: 0.2,
        },
        {
          data: [0, 0, Number(this.totalInvestment)],
          backgroundColor: '#FFF5C9',
          borderColor: '#745E05',
          borderWidth: 1,
          barPercentage: 0.2,
        },
        {
          data: [0, 0, 0, Number(this.capturedOpportunityLoss.toFixed(2))],
          backgroundColor: `#D9F2FF`,
          borderColor: `#00A5E1`,
          borderWidth: 1,
          barPercentage: 0.2,
        },
      ],
    };
  }

  get sectionCardValues() {
    const totalAdditionalFeedCost = `${
      this.addComma(this.totalAdditionalFeedCostPer1000, 2, false) == '-' ||
      this.calculatorModalInputDataRetrieve.numberOfAnimalHeads == 0
        ? '-'
        : this.calculatorModalInputDataRetrieve.currencySymbol +
          ' ' +
          this.addComma(this.totalAdditionalFeedCostPer1000, 2, false)
    }`;
    const totalAdditionalFeedCostAssumption = `${
      this.addComma(
        this.totalAdditionalFeedCostPerNumberAssumption,
        2,
        false,
      ) == '-' || this.calculatorModalInputDataRetrieve.numberOfAnimalHeads == 0
        ? '-'
        : this.calculatorModalInputDataRetrieve.currencySymbol +
          ' ' +
          this.addComma(
            this.totalAdditionalFeedCostPerNumberAssumption,
            2,
            false,
          )
    }`;
    const totalInvestment = this.totalInvestment
      ? `${
          this.calculatorModalInputDataRetrieve.currencySymbol
        } ${this.addComma(this.totalInvestment, 2, false)}`
      : '-';
    const totalCostOfAPP = `${
      this.addComma(this.totalCostOfAPP, 2, false) == '-' ||
      this.calculatorModalInputDataRetrieve.numberOfAnimalHeads == 0
        ? '-'
        : this.calculatorModalInputDataRetrieve.currencySymbol +
          ' ' +
          this.addComma(this.totalCostOfAPP, 2, false)
    }`;
    const totalCostOfAPPAssumption = `${
      this.addComma(this.totalCostOfAPPAssumption, 2, false) == '-' ||
      this.calculatorModalInputDataRetrieve.numberOfAnimalHeads == 0
        ? '-'
        : this.calculatorModalInputDataRetrieve.currencySymbol +
          ' ' +
          this.addComma(this.totalCostOfAPPAssumption, 2, false)
    }`;
    const capturedOpportunityLoss = `${
      this.addComma(this.capturedOpportunityLoss, 2, false) == '-' ||
      this.calculatorModalInputDataRetrieve.numberOfAnimalHeads == 0
        ? '-'
        : this.calculatorModalInputDataRetrieve.currencySymbol +
          ' ' +
          this.addComma(this.capturedOpportunityLoss, 2, false)
    }`;
    return {
      totalAdditionalFeedCost,
      totalAdditionalFeedCostAssumption,
      totalCostOfAPPAssumption,
      totalCostOfAPP,
      totalInvestment,
      capturedOpportunityLoss,
    };
  }

  get sectionCardDetails(): DisplayDataAssumption[] {
    let sectionCardDetailsResults: DisplayDataAssumption[] = [];
    if (this.calculatorModalInputDataRetrieve.numberOfAnimalHeads !== null) {
      sectionCardDetailsResults = [
        {
          headerText: 'Bronchopneumonia Economic Loss',
          assumptionValue: this.sectionCardValues
            .totalAdditionalFeedCostAssumption,
          headerValue: this.sectionCardValues.totalAdditionalFeedCost,
          subText: 'Additional Feed Cost /pig',
          subValue: `${
            this.addComma(
              this.totalAdditionalFeedCostPer1000 /
                this.calculatorModalInputDataRetrieve.numberOfAnimalHeads,
              2,
              false,
            ) == '-'
              ? ''
              : this.calculatorModalInputDataRetrieve.currencySymbol
          } ${this.addComma(
            this.totalAdditionalFeedCostPer1000 /
              this.calculatorModalInputDataRetrieve.numberOfAnimalHeads,
            2,
            false,
          )}`,
          subAssumptionValue: `${
            this.addComma(
              this.totalAdditionalFeedCostPerNumberAssumption /
                this.calculatorModalInputDataRetrieve.numberOfAnimalHeads,
              2,
              false,
            ) == '-'
              ? ''
              : this.calculatorModalInputDataRetrieve.currencySymbol
          } ${this.addComma(
            this.totalAdditionalFeedCostPerNumberAssumption /
              this.calculatorModalInputDataRetrieve.numberOfAnimalHeads,
            2,
            false,
          )}`,
        },
        {
          headerText: 'Pleurisy Economic Loss',
          assumptionValue: this.sectionCardValues.totalCostOfAPPAssumption,
          headerValue: this.sectionCardValues.totalCostOfAPP,
          subText: 'Cost of Pleurisy /pig',
          subValue: `${
            this.addComma(
              this.totalCostOfAPP /
                this.calculatorModalInputDataRetrieve.numberOfAnimalHeads,
              2,
              false,
            ) == '-'
              ? ''
              : this.calculatorModalInputDataRetrieve.currencySymbol
          } ${this.addComma(
            this.totalCostOfAPP /
              this.calculatorModalInputDataRetrieve.numberOfAnimalHeads,
            2,
            false,
          )}`,
          subAssumptionValue: `${
            this.addComma(
              this.totalCostOfAPPAssumption /
                this.calculatorModalInputDataRetrieve.numberOfAnimalHeads,
              2,
              false,
            ) == '-'
              ? ''
              : this.calculatorModalInputDataRetrieve.currencySymbol
          } ${this.addComma(
            this.totalCostOfAPPAssumption /
              this.calculatorModalInputDataRetrieve.numberOfAnimalHeads,
            2,
            false,
          )}`,
        },
        {
          headerText: 'Medication Program',
          headerValue: this.sectionCardValues.totalInvestment,
          subText: 'Medication Investment /pig',
          subValue: `${this.medicationInvestmentPig}`,
        },
        {
          headerText: 'Captured Opportunity Loss',
          headerValue: this.sectionCardValues.capturedOpportunityLoss,
          subText: 'Captured Opportunity Loss /Pig',
          subValue:
            this.calculatorModalInputDataRetrieve.currencySymbol +
            ' ' +
            this.addComma(
              this.capturedOpportunityLoss /
                this.calculatorModalInputDataRetrieve.numberOfAnimalHeads,
              2,
              false,
            ),
        },
      ];
    }
    return sectionCardDetailsResults;
  }

  // Broncho Related

  get adgFormula() {
    let adgFormulaResult: number[] = [];

    adgFormulaResult = this.adgReductionPercentages.map((adgPercentage) => {
      if (
        this.calculatorModalInputDataRetrieve &&
        this.calculatorModalInputDataRetrieve.adgValue
      ) {
        return (
          this.calculatorModalInputDataRetrieve.adgValue -
          this.calculatorModalInputDataRetrieve.adgValue * adgPercentage
        );
      } else {
        return 0;
      }
    });
    return adgFormulaResult;
  }

  firstFinalWeight(adgValueSet: number | null, weanToMarket: number | null) {
    if (adgValueSet && weanToMarket) {
      return (adgValueSet * weanToMarket) / 1000;
    } else {
      return 0;
    }
  }

  get animalPercentagesAssumptions() {
    return Object.values(this.assumptionUpdateObject)
      .slice(0, 7)
      .map((number) => {
        try {
          return number / 100;
        } catch {
          return 0;
        }
      });
  }

  get numberOfAnimalHeadsSoldAssumptions() {
    let numberOfAnimalHeadsSoldAssumptionsResult: number[] = [];
    numberOfAnimalHeadsSoldAssumptionsResult = this.animalPercentagesAssumptions.map(
      (percentage) => {
        if (
          this.calculatorModalInputDataRetrieve &&
          this.calculatorModalInputDataRetrieve.numberOfAnimalHeads
        ) {
          return (
            this.calculatorModalInputDataRetrieve.numberOfAnimalHeads *
            percentage
          );
        } else {
          return 0;
        }
      },
    );
    return numberOfAnimalHeadsSoldAssumptionsResult;
  }

  get daysToReach() {
    let daysToReachResult: number[] = [];
    if (this.calculatorModalInputDataRetrieve.adgValue !== null) {
      daysToReachResult = this.adgFormula.map((adg) => {
        try {
          return (
            (this.firstFinalWeight(
              this.calculatorModalInputDataRetrieve.adgValue,
              this.calculatorModalInputDataRetrieve.weanMarketDays,
            ) /
              adg) *
            1000
          );
        } catch {
          return 0;
        }
      });
    }
    return daysToReachResult;
  }

  get extraDaysToReach() {
    return this.daysToReach.map((days) => {
      try {
        return days - this.daysToReach[0];
      } catch {
        return 0;
      }
    });
  }

  get extraFeedConsumed() {
    let extraFeedConsumedResult: number[] = [];
    extraFeedConsumedResult = this.extraDaysToReach.map((extraDay) => {
      if (
        this.calculatorModalInputDataRetrieve &&
        this.calculatorModalInputDataRetrieve.adfiValue
      ) {
        try {
          return extraDay * this.calculatorModalInputDataRetrieve.adfiValue;
        } catch {
          return 0;
        }
      } else {
        return 0;
      }
    });
    return extraFeedConsumedResult;
  }

  get additionalFeedCost() {
    let additionalFeedCostResult: number[] = [];
    additionalFeedCostResult = this.extraFeedConsumed.map((extraFeed) => {
      if (
        this.calculatorModalInputDataRetrieve &&
        this.calculatorModalInputDataRetrieve.additionalFeedCostPigRate
      ) {
        try {
          return (
            extraFeed *
            this.calculatorModalInputDataRetrieve.additionalFeedCostPigRate
          );
        } catch {
          return 0;
        }
      } else {
        return 0;
      }
    });
    return additionalFeedCostResult;
  }

  get additionalFeedCostPerNumberAssumptions() {
    return this.additionalFeedCost.map((addFeedCost, index) => {
      return addFeedCost * this.numberOfAnimalHeadsSoldAssumptions[index];
    });
  }

  get totalAdditionalFeedCostPerNumberAssumption() {
    function reducer(total: number, value: number) {
      return total + value;
    }
    return this.additionalFeedCostPerNumberAssumptions.reduce(reducer);
  }

  // Pleurisy Related

  get pleurisyPrevalenceBackValueAssumption() {
    let pleurisyPrevalenceBackValueAssumptionResult = 0;
    if (
      this.calculatorModalInputDataRetrieve.appPleurisyPrevalenceAssumption !==
        null &&
      this.calculatorModalInputDataRetrieve.appPleurisyPrevalenceAssumption !==
        undefined &&
      this.calculatorModalInputDataRetrieve.numberOfAnimalHeads !== null
    ) {
      pleurisyPrevalenceBackValueAssumptionResult =
        (this.calculatorModalInputDataRetrieve.appPleurisyPrevalenceAssumption *
          this.calculatorModalInputDataRetrieve.numberOfAnimalHeads) /
        100;
    }
    return pleurisyPrevalenceBackValueAssumptionResult;
  }

  get p3P4BackValueAssumption() {
    let p3P4BackValueAssumptionResult = 0;
    if (
      this.calculatorModalInputDataRetrieve.p3AndP4PrevalenceAssumption !==
        null &&
      this.calculatorModalInputDataRetrieve.p3AndP4PrevalenceAssumption !==
        undefined &&
      this.calculatorModalInputDataRetrieve.numberOfAnimalHeads !== null
    ) {
      p3P4BackValueAssumptionResult =
        (this.calculatorModalInputDataRetrieve.p3AndP4PrevalenceAssumption *
          this.calculatorModalInputDataRetrieve.numberOfAnimalHeads) /
        100;
    }
    return p3P4BackValueAssumptionResult;
  }

  get postWeaningMortalityBackValue() {
    let postWeaningMortalityBackValueResult = 0;
    if (
      this.calculatorModalInputDataRetrieve.postWeaningMortality !== null &&
      this.calculatorModalInputDataRetrieve.numberOfAnimalHeads !== null
    ) {
      postWeaningMortalityBackValueResult =
        (this.calculatorModalInputDataRetrieve.postWeaningMortality *
          this.calculatorModalInputDataRetrieve.numberOfAnimalHeads) /
        100;
    }
    return postWeaningMortalityBackValueResult;
  }

  get postWeaningMortalityAssumptionBackValue() {
    let postWeaningMortalityAssumptionBackValueResult = 0;
    if (
      this.calculatorModalInputDataRetrieve.postWeaningMortalityAssumption !==
        null &&
      this.calculatorModalInputDataRetrieve.postWeaningMortalityAssumption !==
        undefined &&
      this.calculatorModalInputDataRetrieve.numberOfAnimalHeads !== null
    ) {
      postWeaningMortalityAssumptionBackValueResult =
        (this.calculatorModalInputDataRetrieve.postWeaningMortalityAssumption *
          this.calculatorModalInputDataRetrieve.numberOfAnimalHeads) /
        100;
    }
    return postWeaningMortalityAssumptionBackValueResult;
  }

  get weaningMortalityPercentageAssumption() {
    return this.calculatorModalInputDataRetrieve.postWeaningMortalityAssumption;
  }

  get weaningMortalityHeadsAssumption() {
    let weaningMortalityHeadsAssumptionResult = 0;
    if (
      this.weaningMortalityPercentageAssumption !== null &&
      this.weaningMortalityPercentageAssumption !== undefined &&
      this.calculatorModalInputDataRetrieve.numberOfAnimalHeads !== null
    ) {
      weaningMortalityHeadsAssumptionResult =
        (this.weaningMortalityPercentageAssumption *
          this.calculatorModalInputDataRetrieve.numberOfAnimalHeads) /
        100;
    }
    return weaningMortalityHeadsAssumptionResult;
  }

  get totalCostPostWeaningAssumption() {
    let totalCostPostWeaningAssumptionResult = 0;
    if (
      this.calculatorModalInputDataRetrieve.liveWeightPrice !== null &&
      this.calculatorModalInputDataRetrieve.targetMarketWeight !== null
    ) {
      totalCostPostWeaningAssumptionResult =
        this.weaningMortalityHeadsAssumption *
        this.calculatorModalInputDataRetrieve.liveWeightPrice *
        this.calculatorModalInputDataRetrieve.targetMarketWeight;
    }
    return totalCostPostWeaningAssumptionResult;
  }

  get effectADGPigs() {
    let effectADGPigsResult = 0;
    if (
      this.assumptionUpdateObject.p3AndP4PrevalenceAssumption !== null &&
      this.assumptionUpdateObject.p3AndP4PrevalenceAssumption !== undefined &&
      this.calculatorModalInputDataRetrieve.weanMarketDays !== null
    ) {
      effectADGPigsResult =
        (this.assumptionUpdateObject.p3AndP4PrevalenceAssumption *
          2 *
          this.calculatorModalInputDataRetrieve.weanMarketDays) /
        1000;
    }
    return effectADGPigsResult;
  }

  get totalCostADGPigsAssumption() {
    let totalCostADGPigsAssumptionResult = 0;
    if (this.calculatorModalInputDataRetrieve.liveWeightPrice !== null) {
      totalCostADGPigsAssumptionResult =
        this.p3P4BackValueAssumption *
        this.effectADGPigs *
        this.calculatorModalInputDataRetrieve.liveWeightPrice;
    }
    return totalCostADGPigsAssumptionResult;
  }

  get effectOnSlaughterWeight() {
    let effectOnSlaughterWeightResult = 0;
    if (
      this.assumptionUpdateObject.p3AndP4PrevalenceAssumption !== null &&
      this.assumptionUpdateObject.p3AndP4PrevalenceAssumption !== undefined
    ) {
      effectOnSlaughterWeightResult =
        this.assumptionUpdateObject.p3AndP4PrevalenceAssumption * 0.07;
    }
    return effectOnSlaughterWeightResult;
  }

  get totalEffectOnSlaughterWeight() {
    let totalEffectOnSlaughterWeight = 0;
    if (this.calculatorModalInputDataRetrieve.liveWeightPrice !== null) {
      totalEffectOnSlaughterWeight =
        this.p3P4BackValueAssumption *
        this.effectOnSlaughterWeight *
        this.calculatorModalInputDataRetrieve.liveWeightPrice;
    }
    return totalEffectOnSlaughterWeight;
  }

  get effectOnSlaughterAge() {
    let effectOnSlaughterAgeResult = 0;
    if (
      this.assumptionUpdateObject.p3AndP4PrevalenceAssumption !== null &&
      this.assumptionUpdateObject.p3AndP4PrevalenceAssumption !== undefined
    ) {
      effectOnSlaughterAgeResult =
        this.assumptionUpdateObject.p3AndP4PrevalenceAssumption * 0.26;
    }
    return effectOnSlaughterAgeResult;
  }

  get totalEffectSlaughterAge() {
    let totalEffectSlaughterAgeResult = 0;
    if (this.calculatorModalInputDataRetrieve.averageFeedPrice !== null) {
      totalEffectSlaughterAgeResult =
        this.p3P4BackValueAssumption *
        this.effectOnSlaughterAge *
        2 *
        this.calculatorModalInputDataRetrieve.averageFeedPrice;
    }
    return totalEffectSlaughterAgeResult;
  }

  get totalCostOfAPPAssumption() {
    return (
      this.totalCostPostWeaningAssumption +
      this.totalCostADGPigsAssumption +
      this.totalEffectOnSlaughterWeight +
      this.totalEffectSlaughterAge
    );
  }

  get capturedOpportunityLoss() {
    if (
      !this.totalAdditionalFeedCostPer1000 &&
      !this.totalAdditionalFeedCostPerNumberAssumption
    ) {
      return this.totalCostOfAPP - this.totalCostOfAPPAssumption;
    } else {
      return (
        this.totalAdditionalFeedCostPer1000 +
        this.totalCostOfAPP -
        (this.totalAdditionalFeedCostPerNumberAssumption +
          this.totalCostOfAPPAssumption)
      );
    }
  }

  addComma(targetNumber: number, toFixedNumber: number, onlyToString: boolean) {
    if (isNaN(targetNumber)) {
      return '-';
    } else if (!onlyToString) {
      const roundedNumber = targetNumber.toFixed(toFixedNumber);
      return addCommas(roundedNumber);
    } else if (onlyToString) {
      const numberToString = targetNumber.toString();
      return addCommas(numberToString);
    }
  }

  toggleUpdateAssumption() {
    this.assumptionModalStatus = !this.assumptionModalStatus;
  }

  toggleUpdateMedicationModal() {
    this.updateMedicationModalStatus = !this.updateMedicationModalStatus;
  }

  updateMedicationProgramReportData(
    updateMedicationModalData: PleurisyCalculatorModalData,
  ) {
    this.$emit('update', updateMedicationModalData);
    this.updateMedicationModalStatus = !this.updateMedicationModalStatus;
  }
}
