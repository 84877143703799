






















































































import { roundTo } from 'round-to';
import { Vue, Component, Prop, Watch, Emit } from 'vue-property-decorator';
import { PulmotilCalculationModalData } from '@/models/swine/economic-calculation.model';
import {
  calculateNumberOfGrowerPigs,
  calculateNumberOfPigletsAtNursery,
  calculateNumberOfPigletsBornPerMonth,
  calculateNumberOfSowsFarrowingPerMonth,
} from '@/utils/economic-calculation/pulmotil/formula';
import { addCommas } from '@/utils/formats';
import FarmProductionPerformanceForm from './FarmProductionPerformanceForm.vue';
@Component({
  components: {
    FarmProductionPerformanceForm,
  },
})
export default class FarmProductionPerformance extends Vue {
  @Prop({ required: true })
  calculatorModalInputDataRetrieve!: PulmotilCalculationModalData;

  // Raw value
  sowLevelRaw = 0;
  sowIndexRaw = 0;
  averageLitterSizeBornAliveRaw = 0;
  preWeaningMortalityRaw = 0;
  nurseryMortalityRaw = 0;
  numberOfSowsFarrowingPerMonthRaw = 0;
  numberOfPigletsBornPerMonthRaw = 0;
  numberOfPigletsAtNurseryRaw = 0;
  numberOfGrowerPigsRaw = 0;
  isModalActivated = false;

  mounted() {
    this.updateCurrentValues();
  }

  @Watch(`calculatorModalInputDataRetrieve`, { deep: true })
  updateCurrentValues() {
    if (
      this.calculatorModalInputDataRetrieve &&
      this.calculatorModalInputDataRetrieve.sowLevel &&
      this.calculatorModalInputDataRetrieve.sowIndex &&
      this.calculatorModalInputDataRetrieve.averageLitterSizeBornAlive &&
      this.calculatorModalInputDataRetrieve.nurseryMortality &&
      this.calculatorModalInputDataRetrieve.preWeaningMortality
    ) {
      this.sowLevelRaw = this.calculatorModalInputDataRetrieve.sowLevel;
      this.sowIndexRaw = this.calculatorModalInputDataRetrieve.sowIndex;
      this.averageLitterSizeBornAliveRaw =
        this.calculatorModalInputDataRetrieve.averageLitterSizeBornAlive;
      this.preWeaningMortalityRaw =
        this.calculatorModalInputDataRetrieve.preWeaningMortality;
      this.nurseryMortalityRaw =
        this.calculatorModalInputDataRetrieve.nurseryMortality;
    }
  }

  // Display Values
  get sowLevel() {
    return roundTo(this.sowLevelRaw, 2);
  }

  get sowIndex() {
    return roundTo(this.sowIndexRaw, 2);
  }

  get averageLitterSizeBornAlive() {
    return roundTo(this.averageLitterSizeBornAliveRaw, 2);
  }

  get preWeaningMortality() {
    return roundTo(this.preWeaningMortalityRaw, 2);
  }

  get nurseryMortality() {
    return roundTo(this.nurseryMortalityRaw, 2);
  }

  get numberOfSowsFarrowingPerMonth() {
    this.numberOfSowsFarrowingPerMonthRaw =
      calculateNumberOfSowsFarrowingPerMonth(
        this.sowLevelRaw,
        this.sowIndexRaw
      );
    return addCommas(
      roundTo(this.numberOfSowsFarrowingPerMonthRaw, 0).toString()
    );
  }

  get numberOfPigletsBornPerMonth() {
    this.numberOfPigletsBornPerMonthRaw = calculateNumberOfPigletsBornPerMonth(
      this.numberOfSowsFarrowingPerMonthRaw,
      this.averageLitterSizeBornAliveRaw
    );

    return addCommas(
      roundTo(this.numberOfPigletsBornPerMonthRaw, 0).toString()
    );
  }

  get numberOfPigletsAtNursery() {
    this.numberOfPigletsAtNurseryRaw = calculateNumberOfPigletsAtNursery(
      this.numberOfPigletsBornPerMonthRaw,
      this.preWeaningMortalityRaw
    );
    return addCommas(roundTo(this.numberOfPigletsAtNurseryRaw, 0).toString());
  }

  get numberOfGrowerPigs() {
    this.numberOfGrowerPigsRaw = calculateNumberOfGrowerPigs(
      this.numberOfPigletsAtNurseryRaw,
      this.nurseryMortalityRaw
    );
    return addCommas(roundTo(this.numberOfGrowerPigsRaw, 0).toString());
  }

  closeModal(payload: boolean) {
    this.isModalActivated = payload;
  }

  @Emit(`update`)
  updateCurrentDataInputs(payload: FarmProductionPerformance) {
    this.closeModal(false);
    // Recalculate everything
    const output: Partial<PulmotilCalculationModalData> = {
      ...this.calculatorModalInputDataRetrieve,
    };
    output.sowLevel = payload.sowLevel;
    output.sowIndex = payload.sowIndex;
    output.averageLitterSizeBornAlive = payload.averageLitterSizeBornAlive;
    output.preWeaningMortality = payload.preWeaningMortality;
    output.nurseryMortality = payload.nurseryMortality;
    // Reset raw values
    this.sowLevelRaw = payload.sowLevel;
    this.sowIndexRaw = payload.sowIndex;
    this.averageLitterSizeBornAliveRaw = payload.averageLitterSizeBornAlive;
    this.preWeaningMortalityRaw = payload.preWeaningMortality;
    this.nurseryMortalityRaw = payload.nurseryMortality;
    this.numberOfSowsFarrowingPerMonthRaw =
      calculateNumberOfSowsFarrowingPerMonth(
        this.sowLevelRaw,
        this.sowIndexRaw
      );
    this.numberOfPigletsBornPerMonthRaw = calculateNumberOfPigletsBornPerMonth(
      this.numberOfSowsFarrowingPerMonthRaw,
      this.averageLitterSizeBornAliveRaw
    );
    this.numberOfPigletsAtNurseryRaw = calculateNumberOfPigletsAtNursery(
      this.numberOfPigletsBornPerMonthRaw,
      this.preWeaningMortalityRaw
    );
    this.numberOfGrowerPigsRaw = calculateNumberOfGrowerPigs(
      this.numberOfPigletsAtNurseryRaw,
      this.nurseryMortalityRaw
    );
    output.numberOfSowsFarrowingPerMonth = roundTo(
      this.numberOfSowsFarrowingPerMonthRaw,
      0
    );
    output.numberOfPigletsBornPerMonth = roundTo(
      this.numberOfPigletsBornPerMonthRaw,
      0
    );
    output.numberOfPigletsAtNursery = roundTo(
      this.numberOfPigletsAtNurseryRaw,
      0
    );
    output.numberOfGrowerPigs = roundTo(this.numberOfGrowerPigsRaw, 0);
    return output;
  }
}
