import { Endpoints } from '@/utils/endpoints';
import { axios } from '@/utils/axios';
import { blue, lightBlue } from './colors';
import { ReportStores } from '@/store/swine/reports';
import { LungLesionScoreParseAttachmentDetails } from '@/models/swine/report/report.model';

function formatLungId(id: number) {
  return `00${id + 1}`;
}

export async function getFarmResultSummary(reportId: string): Promise<any> {
  const response = await axios.get(`${Endpoints.Reports}${Endpoints.Swine}/${reportId}`, {
    params: {
      section: 'farm_result_summary',
    },
  });

  return response.data;
}

export async function getAuditReportLungData(reportId: string): Promise<{}> {
  const store = ReportStores.detail;
  const response = await axios.get(`${Endpoints.Reports}${Endpoints.Swine}/${reportId}`, {
    params: {
      section: 'attachments',
    },
  });
  const auditReportLungData = response.data;
  const auditReportLungDataArray: Array<{}> = [];
  const brochopneumoniaDataArray: Array<{}> = [];
  const pleurisyAttachments: Array<{}> = [];
  const abscessScarringPericarditisAttachments: Array<{}> = [];
  const selectedBronchopneumoniaScoreAttachments:
    | LungLesionScoreParseAttachmentDetails[]
    | null = store.bronchopneumoniaScoreAttachments;
  const selectedPleurisyScoreAttachments:
    | LungLesionScoreParseAttachmentDetails[]
    | null = store.pleurisyScoreAttachments;
  const selectedAbscessScarringPericarditisAttachments:
    | LungLesionScoreParseAttachmentDetails[]
    | null = store.abscessScarringPericarditisScoreAttachments;

  if (
    selectedBronchopneumoniaScoreAttachments &&
    selectedBronchopneumoniaScoreAttachments.length > 0
  ) {
    for (const item of selectedBronchopneumoniaScoreAttachments) {
      const attachments = [];
      if (item.generalRemarks.attachments) {
        for (const attachment of item.generalRemarks.attachments) {
          if (attachment) {
            attachments.push({
              text: `${attachment.originalName ? attachment.originalName : ''}`,
              link: `${attachment.url ? attachment.url : ''}`,
            });
          }
        }
      }

      brochopneumoniaDataArray.push([
        {
          text: formatLungId(item.id),
          alignment: 'center',
          margin: [0, 5, 0, 5],
          border: [false, false, true, false],
        },
        {
          text: item.total.value,
          fillColor: `${item.total.value === 'NE' ? lightBlue : ''}`,
          color: `${item.total.value === 'NE' ? blue : ''}`,
          alignment: 'center',
          margin: [0, 5, 0, 5],
          border: [false, false, false, false],
        },
        attachments,
      ]);
    }
  }

  if (
    selectedPleurisyScoreAttachments &&
    selectedPleurisyScoreAttachments.length > 0
  ) {
    for (const item of selectedPleurisyScoreAttachments) {
      const attachments = [];
      if (item.generalRemarks.attachments) {
        for (const attachment of item.generalRemarks.attachments) {
          if (attachment) {
            attachments.push({
              text: `${attachment.originalName ? attachment.originalName : ''}`,
              link: `${attachment.url ? attachment.url : ''}`,
            });
          }
        }
      }

      pleurisyAttachments.push([
        {
          text: formatLungId(item.id),
          border: [false, false, true, false],
          alignment: 'center',
        },
        {
          text: item.pleurisy.value,
          color: item.pleurisy.textColor,
          fillColor: item.pleurisy.backgroundColor,
          alignment: 'center',
          border: [false, false, false, false],
        },
        attachments,
      ]);
    }
  }

  if (
    selectedAbscessScarringPericarditisAttachments &&
    selectedAbscessScarringPericarditisAttachments.length > 0
  ) {
    for (const item of selectedAbscessScarringPericarditisAttachments) {
      const attachments = [];
      if (item.generalRemarks.attachments) {
        for (const attachment of item.generalRemarks.attachments) {
          if (attachment) {
            attachments.push({
              text: `${attachment.originalName ? attachment.originalName : ''}`,
              link: `${attachment.url ? attachment.url : ''}`,
            });
          }
        }
      }

      abscessScarringPericarditisAttachments.push([
        {
          text: formatLungId(item.id),
          border: [false, false, true, false],
        },
        {
          text: item.abscess.value,
          color: item.abscess.textColor,
          fillColor: item.abscess.backgroundColor,
          alignment: 'center',
          border: [false, false, true, false],
        },
        {
          text: item.scarring.value,
          color: item.scarring.textColor,
          fillColor: item.scarring.backgroundColor,
          alignment: 'center',
          border: [false, false, true, false],
        },
        {
          text: item.pericarditis.value,
          color: item.pericarditis.textColor,
          fillColor: item.pericarditis.backgroundColor,
          alignment: 'center',
          border: [false, false, false, false],
        },
        attachments
      ]);
    }
  }

  for (const item of auditReportLungData) {
    const urlLink: string[] = [];
    for (const attachment of item.generalRemarks.attachments) {
      if (attachment && attachment.url) {
        urlLink.push(attachment.url);
      }
    }
    auditReportLungDataArray.push([
      {
        text: item.index,
        border: [false, false, true, false],
        alignment: 'center',
      },
      {
        text: item.rightApical.value,
        color: item.rightApical.textColor,
        fillColor: item.rightApical.backgroundColor,
        alignment: 'center',
      },
      {
        text: item.rightCardiac.value,
        color: item.rightCardiac.textColor,
        fillColor: item.rightCardiac.backgroundColor,
        alignment: 'center',
      },
      {
        text: item.rightDiaphragmatic.value,
        color: item.rightDiaphragmatic.textColor,
        fillColor: item.rightDiaphragmatic.backgroundColor,
        alignment: 'center',
      },
      {
        text: item.leftApical.value,
        color: item.leftApical.textColor,
        fillColor: item.leftApical.backgroundColor,
        alignment: 'center',
      },
      {
        text: item.leftCardiac.value,
        color: item.leftCardiac.textColor,
        fillColor: item.leftCardiac.backgroundColor,
        alignment: 'center',
      },
      {
        text: item.leftDiaphragmatic.value,
        color: item.leftDiaphragmatic.textColor,
        fillColor: item.leftDiaphragmatic.backgroundColor,
        alignment: 'center',
      },
      {
        text: item.accessory.value,
        color: item.accessory.textColor,
        fillColor: item.accessory.backgroundColor,
        alignment: 'center',
      },
      {
        text: item.total.value,
        fillColor: `${item.total.value === 'NE' ? lightBlue : ''}`,
        color: `${item.total.value === 'NE' ? blue : ''}`,
        alignment: 'center',
      },
      {
        text: item.pleurisy.value,
        color: item.pleurisy.textColor,
        fillColor: item.pleurisy.backgroundColor,
        alignment: 'center',
        border: [true, false, true, false],
      },
      {
        text: item.abscess.value,
        color: item.abscess.textColor,
        fillColor: item.abscess.backgroundColor,
        alignment: 'center',
      },
      {
        text: item.scarring.value,
        color: item.scarring.textColor,
        fillColor: item.scarring.backgroundColor,
        alignment: 'center',
      },
      {
        text: item.pericarditis.value,
        color: item.pericarditis.textColor,
        fillColor: item.pericarditis.backgroundColor,
        alignment: 'center',
      },
      {
        stack: [
          {
            text: urlLink[0] ? 'Attachment 1' : '',
            link: urlLink[0] ? urlLink[0] : '',
          },
          {
            text: urlLink[1] ? 'Attachment 2' : '',
            link: urlLink[1] ? urlLink[1] : '',
          },
          {
            text: urlLink[2] ? 'Attachment 3' : '',
            link: urlLink[2] ? urlLink[2] : '',
          },
        ],
        color: '#1697db',
        alignment: 'center',
        border: [true, false, false, true],
      },
      {
        text: item.generalRemarks.comment,
        alignment: 'center',
        color: '#1697db',
        border: [true, false, false, true],
      },
    ]);
  }

  return {
    auditReportLungDataArray,
    brochopneumoniaDataArray,
    pleurisyAttachments,
    abscessScarringPericarditisAttachments,
  };
}

export async function getBronchopneumoniaScore(reportId: string): Promise<any> {
  const response = await axios.get(`${Endpoints.Reports}${Endpoints.Swine}/${reportId}`, {
    params: {
      section: 'bronchopneumonia_score',
    },
  });

  return response.data;
}

export async function getPleurisyData(reportId: string): Promise<any> {
  const response = await axios.get(`${Endpoints.Reports}${Endpoints.Swine}/${reportId}`, {
    params: {
      section: 'pleurisy',
    },
  });

  return response.data;
}

export async function getAppIndexAndPrevalence(reportId: string): Promise<any> {
  const response = await axios.get(`${Endpoints.Reports}${Endpoints.Swine}/${reportId}`, {
    params: {
      section: 'app_index_and_prevalence',
    },
  });

  return response.data;
}

export async function getAbscessScarringPericarditis(
  reportId: string,
): Promise<any> {
  const response = await axios.get(`${Endpoints.Reports}${Endpoints.Swine}/${reportId}`, {
    params: {
      section: 'abscess_scarring_pericarditis',
    },
  });

  return response.data;
}

export async function getEconomicCalculationData(reportId: string) {
  const response = await axios.get(`${Endpoints.Reports}${Endpoints.Swine}/${reportId}`, {
    params: {
      section: 'economic_calculation',
    },
  });

  return response.data;
}
