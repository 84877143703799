












































































































































































































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import FormTemplate from '@/components/swine/layout/FormTemplate.vue';
import CalculatorInputField from '../../CalculatorInputField.vue';
import {
  PleurisyCalculatorModalData,
  MedicineBrand,
} from '@/models/swine/economic-calculation.model';
import { validateInputNumber } from '@/utils/validation';
import PriceOfMedicineDropdown from '../../PriceOfMedicineDropdown.vue';
import { DropdownOption } from '@/models/swine/dropdown.model';
import { ECONOMIC_CALCULATION_MEDICATION } from '@/utils/constants';

@Component({
  components: {
    FormTemplate,
    CalculatorInputField,
    PriceOfMedicineDropdown,
  },
})
export default class MedicationUpdateModal extends Vue {
  formTitle = 'Update Medication Program';

  isDisabled = true;

  updateMedicationModalData: Partial<PleurisyCalculatorModalData> = {
    liveWeightPrice: null,
    targetMarketWeight: null,
    averageFeedPrice: null,

    cleanSowFeedIntake: null,
    cleanSowDuration: null,
    cleanSowPSRatio: null,
    cleanSowDosage: null,
    cleanSowMedicinePrice: null,
    cleanSowMedicineBrand: null,

    startStrongFeedIntake: null,
    startStrongDuration: null,
    startStrongDosage: null,
    startStrongMedicinePrice: null,
    startStrongMedicineBrand: null,

    strategicProgramFeedIntake: null,
    strategicProgramDuration: null,
    strategicProgramDosage: null,
    strategicProgramMedicinePrice: null,
    strategicProgramMedicineBrand: null,
  };

  medicine = [
    { text: MedicineBrand.DENEGARD, value: MedicineBrand.DENEGARD },
    {
      text: MedicineBrand.PULMOTIL,
      value: MedicineBrand.PULMOTIL,
    },
  ];

  cleanSowProgramOpen = false;

  startStrongProgramOpen = false;

  strategicProgramOpen = false;

  medicineBrandPlaceholder = 'Select Brand';

  @Prop({ default: false })
  isActive!: boolean;

  @Prop()
  calculatorModalInputDataRetrieve!: PleurisyCalculatorModalData;

  mounted() {
    this.programOpener();
  }

  handleMedicationUpdateModalClose() {
    this.$emit('close');
    this.exitModalResetCleanSow();
    this.exitModalResetStartStrong();
    this.exitModalResetStrategic();
    this.updateInputObject();
  }

  updateMedicationData() {
    this.$buefy.toast.open({
      message: `Medication Program has been updated.`,
      type: `is-dark`,
      duration: 3000,
    });
    this.$emit('update', this.updateMedicationModalData);
  }

  handleCleanSowProgramCollapseToggle() {
    if (!this.cleanSowProgramOpen) {
      this.resetCleanSowProgram();
    }
  }

  handleStartStrongProgramCollapseToggle() {
    if (!this.startStrongProgramOpen) {
      this.resetStartStrongProgram();
    }
  }

  handleStrategicProgramCollapseToggle() {
    if (!this.strategicProgramOpen) {
      this.resetStrategicProgram();
    }
  }

  resetCleanSowProgram() {
    this.updateMedicationModalData.cleanSowFeedIntake =
      ECONOMIC_CALCULATION_MEDICATION.CLEAN_SOW_FEED_INTAKE;
    this.updateMedicationModalData.cleanSowDuration =
      ECONOMIC_CALCULATION_MEDICATION.CLEAN_SOW_DURATION;
    this.updateMedicationModalData.cleanSowPSRatio =
      ECONOMIC_CALCULATION_MEDICATION.CLEAN_SOW_PS_RATIO;
    this.updateMedicationModalData.cleanSowDosage = null;
    this.updateMedicationModalData.cleanSowMedicinePrice = null;
    this.updateMedicationModalData.cleanSowMedicineBrand = null;
  }

  resetStartStrongProgram() {
    this.updateMedicationModalData.startStrongFeedIntake =
      ECONOMIC_CALCULATION_MEDICATION.START_STRONG_FEED_INTAKE;
    this.updateMedicationModalData.startStrongDuration =
      ECONOMIC_CALCULATION_MEDICATION.START_STRONG_DURATION;
    this.updateMedicationModalData.startStrongDosage = null;
    this.updateMedicationModalData.startStrongMedicinePrice = null;
    this.updateMedicationModalData.startStrongMedicineBrand = null;
  }

  resetStrategicProgram() {
    this.updateMedicationModalData.strategicProgramFeedIntake =
      ECONOMIC_CALCULATION_MEDICATION.STRATEGIC_FEED_INTAKE;
    this.updateMedicationModalData.strategicProgramDuration =
      ECONOMIC_CALCULATION_MEDICATION.STRATEGIC_DURATION;
    this.updateMedicationModalData.strategicProgramDosage = null;
    this.updateMedicationModalData.strategicProgramMedicinePrice = null;
    this.updateMedicationModalData.strategicProgramMedicineBrand = null;
  }

  changeCleanSowDefaultMessage(data: DropdownOption) {
    this.updateMedicationModalData.cleanSowMedicineBrand = data.value as MedicineBrand;
  }

  changeStartStrongDefaultMessage(data: DropdownOption) {
    this.updateMedicationModalData.startStrongMedicineBrand = data.value as MedicineBrand;
  }

  changeStrategicProgramDefaultMessage(data: DropdownOption) {
    this.updateMedicationModalData.strategicProgramMedicineBrand = data.value as MedicineBrand;
  }

  exitModalResetCleanSow() {
    const {
      cleanSowFeedIntake,
      cleanSowDuration,
      cleanSowPSRatio,
      cleanSowDosage,
      cleanSowMedicinePrice,
    } = this.calculatorModalInputDataRetrieve;
    const valuesExist = [
      cleanSowFeedIntake,
      cleanSowDuration,
      cleanSowPSRatio,
      cleanSowDosage,
      cleanSowMedicinePrice,
    ];
    if (
      this.cleanSowProgramOpen == true &&
      valuesExist.every((value) => value !== null)
    ) {
      this.cleanSowProgramOpen = true;
    }
    if (
      this.cleanSowProgramOpen == false &&
      valuesExist.every((value) => value !== null)
    ) {
      this.cleanSowProgramOpen = true;
    }
    if (
      this.cleanSowProgramOpen == true &&
      !valuesExist.every((value) => value !== null)
    ) {
      this.cleanSowProgramOpen = false;
    }
  }

  exitModalResetStartStrong() {
    const {
      startStrongFeedIntake,
      startStrongDuration,
      startStrongDosage,
      startStrongMedicinePrice,
    } = this.calculatorModalInputDataRetrieve;
    const valuesExist = [
      startStrongFeedIntake,
      startStrongDuration,
      startStrongDosage,
      startStrongMedicinePrice,
    ];
    if (
      this.startStrongProgramOpen == true &&
      valuesExist.every((value) => value !== null)
    ) {
      this.startStrongProgramOpen = true;
    }
    if (
      this.startStrongProgramOpen == false &&
      valuesExist.every((value) => value !== null)
    ) {
      this.startStrongProgramOpen = true;
    }
    if (
      this.startStrongProgramOpen == true &&
      !valuesExist.every((value) => value !== null)
    ) {
      this.startStrongProgramOpen = false;
    }
  }

  exitModalResetStrategic() {
    const {
      strategicProgramFeedIntake,
      strategicProgramDuration,
      strategicProgramDosage,
      strategicProgramMedicinePrice,
    } = this.calculatorModalInputDataRetrieve;
    const valuesExist = [
      strategicProgramFeedIntake,
      strategicProgramDuration,
      strategicProgramDosage,
      strategicProgramMedicinePrice,
    ];
    if (
      this.strategicProgramOpen == true &&
      valuesExist.every((value) => value !== null)
    ) {
      this.strategicProgramOpen = true;
    }
    if (
      this.strategicProgramOpen == false &&
      valuesExist.every((value) => value !== null)
    ) {
      this.strategicProgramOpen = true;
    }
    if (
      this.strategicProgramOpen == true &&
      !valuesExist.every((value) => value !== null)
    ) {
      this.strategicProgramOpen = false;
    }
  }

  get generalFormHasError() {
    let hasError = true;
    const {
      liveWeightPrice,
      targetMarketWeight,
      averageFeedPrice,
    } = this.updateMedicationModalData;
    const valuesExist = [liveWeightPrice, targetMarketWeight, averageFeedPrice];
    if (valuesExist.every((value) => value !== null)) {
      hasError = !(
        validateInputNumber(String(liveWeightPrice)) &&
        validateInputNumber(String(targetMarketWeight)) &&
        validateInputNumber(String(averageFeedPrice))
      );
    }
    return hasError;
  }

  get cleanSowFormHasError() {
    let hasError = true;
    if (!this.cleanSowProgramOpen) {
      hasError = false;
    } else if (this.cleanSowProgramOpen) {
      const {
        cleanSowFeedIntake,
        cleanSowDuration,
        cleanSowPSRatio,
        cleanSowDosage,
        cleanSowMedicinePrice,
      } = this.updateMedicationModalData;
      const valuesExist = [
        cleanSowFeedIntake,
        cleanSowDuration,
        cleanSowPSRatio,
        cleanSowDosage,
        cleanSowMedicinePrice,
      ];
      if (
        valuesExist.every((value) => value !== null) &&
        valuesExist.slice(2, 3).every((value) => value !== 0) &&
        this.updateMedicationModalData.cleanSowMedicineBrand !== null
      ) {
        hasError = !(
          validateInputNumber(String(cleanSowFeedIntake)) &&
          validateInputNumber(String(cleanSowDuration)) &&
          validateInputNumber(String(cleanSowPSRatio)) &&
          validateInputNumber(String(cleanSowDosage)) &&
          validateInputNumber(String(cleanSowMedicinePrice))
        );
      }
    }
    return hasError;
  }

  get startStrongFormHasError() {
    let hasError = true;
    if (!this.startStrongProgramOpen) {
      hasError = false;
    } else if (this.startStrongProgramOpen) {
      const {
        startStrongFeedIntake,
        startStrongDuration,
        startStrongDosage,
        startStrongMedicinePrice,
      } = this.updateMedicationModalData;
      const valuesExist = [
        startStrongFeedIntake,
        startStrongDuration,
        startStrongDosage,
        startStrongMedicinePrice,
      ];
      if (
        valuesExist.every((value) => value !== null) &&
        this.updateMedicationModalData.startStrongMedicineBrand !== null
      ) {
        hasError = !(
          validateInputNumber(String(startStrongFeedIntake)) &&
          validateInputNumber(String(startStrongDuration)) &&
          validateInputNumber(String(startStrongDosage)) &&
          validateInputNumber(String(startStrongMedicinePrice))
        );
      }
    }
    return hasError;
  }

  get strategicFormHasError() {
    let hasError = true;
    if (!this.strategicProgramOpen) {
      hasError = false;
    } else if (this.strategicProgramOpen) {
      const {
        strategicProgramFeedIntake,
        strategicProgramDuration,
        strategicProgramDosage,
        strategicProgramMedicinePrice,
      } = this.updateMedicationModalData;
      const valuesExist = [
        strategicProgramFeedIntake,
        strategicProgramDuration,
        strategicProgramDosage,
        strategicProgramMedicinePrice,
      ];
      if (
        valuesExist.every((value) => value !== null) &&
        this.updateMedicationModalData.strategicProgramMedicineBrand !== null
      ) {
        hasError = !(
          validateInputNumber(String(strategicProgramFeedIntake)) &&
          validateInputNumber(String(strategicProgramDuration)) &&
          validateInputNumber(String(strategicProgramDosage)) &&
          validateInputNumber(String(strategicProgramMedicinePrice))
        );
      }
    }
    return hasError;
  }

  get fullFormValidity() {
    const hasError =
      this.generalFormHasError ||
      this.cleanSowFormHasError ||
      this.startStrongFormHasError ||
      this.strategicFormHasError;
    return hasError;
  }

  @Watch('calculatorModalInputDataRetrieve', { deep: true, immediate: true })
  updateInputObject() {
    this.updateMedicationModalData = {
      liveWeightPrice: this.calculatorModalInputDataRetrieve.liveWeightPrice,
      targetMarketWeight: this.calculatorModalInputDataRetrieve
        .targetMarketWeight,
      averageFeedPrice: this.calculatorModalInputDataRetrieve.averageFeedPrice,
      cleanSowFeedIntake: this.calculatorModalInputDataRetrieve
        .cleanSowFeedIntake,
      cleanSowDuration: this.calculatorModalInputDataRetrieve.cleanSowDuration,
      cleanSowPSRatio: this.calculatorModalInputDataRetrieve.cleanSowPSRatio,
      cleanSowDosage: this.calculatorModalInputDataRetrieve.cleanSowDosage,
      cleanSowMedicinePrice: this.calculatorModalInputDataRetrieve
        .cleanSowMedicinePrice,
      cleanSowMedicineBrand: this.calculatorModalInputDataRetrieve
        .cleanSowMedicineBrand,
      startStrongFeedIntake: this.calculatorModalInputDataRetrieve
        .startStrongFeedIntake,
      startStrongDuration: this.calculatorModalInputDataRetrieve
        .startStrongDuration,
      startStrongDosage: this.calculatorModalInputDataRetrieve
        .startStrongDosage,
      startStrongMedicinePrice: this.calculatorModalInputDataRetrieve
        .startStrongMedicinePrice,
      startStrongMedicineBrand: this.calculatorModalInputDataRetrieve
        .startStrongMedicineBrand,
      strategicProgramFeedIntake: this.calculatorModalInputDataRetrieve
        .strategicProgramFeedIntake,
      strategicProgramDuration: this.calculatorModalInputDataRetrieve
        .strategicProgramDuration,
      strategicProgramDosage: this.calculatorModalInputDataRetrieve
        .strategicProgramDosage,
      strategicProgramMedicinePrice: this.calculatorModalInputDataRetrieve
        .strategicProgramMedicinePrice,
      strategicProgramMedicineBrand: this.calculatorModalInputDataRetrieve
        .strategicProgramMedicineBrand,
    };
  }
  @Watch('calculatorModalInputDataRetrieve', { deep: true })
  programOpener() {
    this.cleanSowProgramOpen = Boolean(
      this.calculatorModalInputDataRetrieve.cleanSowMedicineBrand,
    );
    this.startStrongProgramOpen = Boolean(
      this.calculatorModalInputDataRetrieve.startStrongMedicineBrand,
    );
    this.strategicProgramOpen = Boolean(
      this.calculatorModalInputDataRetrieve.strategicProgramMedicineBrand,
    );
  }
}
