

































import { Component, Prop, Vue } from 'vue-property-decorator';
import { DisplayData } from '@/models/swine/report/report.model';

@Component({})
export default class AssumptionReportLegendCard extends Vue {
  @Prop({})
  sectionCardValues!: DisplayData[];
}
