import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule,
} from 'vuex-module-decorators';
import store from '@/store/swine';
import { axios } from '@/utils/axios';
import { Endpoints } from '@/utils/endpoints';
import {
  AuditReportResponseParams,
  AuditReportSearchParams,
} from '@/models/swine/report/report.model';
import { ToastProgrammatic } from 'buefy';

@Module({
  namespaced: true,
  dynamic: true,
  name: 'audit-report.list',
  store,
})
class AuditReportList extends VuexModule {
  normalResponse: AuditReportResponseParams | null = null;
  mockResponse: AuditReportResponseParams | null = null;
  isLoading = false;
  error: any | null = null;
  mockReportParams: Partial<AuditReportSearchParams> | null = null;
  normalReportParams: Partial<AuditReportSearchParams> | null = null;
  deleteResponse: string | null = null;

  @Mutation
  updateLoadingStatus(payload: boolean) {
    this.isLoading = payload;
  }

  @Mutation
  setError(payload: any) {
    this.error = payload;
  }

  @Mutation
  setNormalParams(payload: Partial<AuditReportSearchParams>) {
    this.normalReportParams = payload;
  }

  @Mutation
  setMockParams(payload: Partial<AuditReportSearchParams>) {
    this.mockReportParams = payload;
  }

  @Mutation
  setNormalResponse(payload: AuditReportResponseParams) {
    this.normalResponse = payload;
  }

  @Mutation
  setMockResponse(payload: AuditReportResponseParams) {
    this.mockResponse = payload;
  }

  @Mutation
  setDeleteResponse(payload: string | null) {
    this.deleteResponse = payload;
  }

  @Action
  getAllNormalAuditReports(params: Partial<AuditReportSearchParams>) {
    this.context.commit('updateLoadingStatus', true);
    this.context.commit('setError', null);
    this.context.commit(`setNormalParams`, params);
    try {
      if (this.normalReportParams) {
        axios
          .get(`${Endpoints.Reports}/swine`, {
            params: {
              ...this.normalReportParams,
            },
          })
          .then((response) =>
            this.context.commit('setNormalResponse', response.data),
          );
      } else {
        axios
          .get(`${Endpoints.Reports}/swine`)
          .then((response) =>
            this.context.commit('setNormalResponse', response.data),
          );
      }
    } catch (error) {
      this.context.commit('setError', error);
    }
    this.context.commit('updateLoadingStatus', false);
  }

  @Action
  getAllMockAuditReports(params: Partial<AuditReportSearchParams>) {
    this.context.commit('updateLoadingStatus', true);
    this.context.commit('setError', null);
    this.context.commit(`setMockParams`, params);
    try {
      if (this.mockReportParams) {
        axios
          .get(`${Endpoints.Reports}/swine`, {
            params: {
              ...this.mockReportParams,
            },
          })
          .then((response) =>
            this.context.commit('setMockResponse', response.data),
          );
      } else {
        axios
          .get(`${Endpoints.Reports}/swine`)
          .then((response) =>
            this.context.commit('setMockResponse', response.data),
          );
      }
    } catch (error) {
      this.context.commit('setError', error);
    }
    this.context.commit('updateLoadingStatus', false);
  }

  @Action
  resetState() {
    this.context.commit(`setNormalResponse`, null);
    this.context.commit(`setNormalParams`, null);
    this.context.commit(`setMockResponse`, null);
    this.context.commit(`setMockParams`, null);
  }

  @Action
  deleteSpecifiedReports(reportIds: number[]) {
    this.context.commit('updateLoadingStatus', true);
    this.context.commit('setError', null);
    this.context.commit('setDeleteResponse', null);
    try {
      const reportIdsString = reportIds.join(',');
      axios
        .delete(`${Endpoints.Reports}/swine`, {
          params: {
            report: reportIdsString,
          },
        })
        .then((response) => {
          if (response.status === 204) {
            ToastProgrammatic.open({
              type: `is-dark`,
              duration: 3000,
              message: `Audit Reports Deleted.`,
            });
          } else {
            ToastProgrammatic.open({
              type: `is-dark`,
              duration: 3000,
              message: `Selected reports are not owned by current user.`,
            });
          }
          this.context.commit('setDeleteResponse', response.data);
        })
    } catch (error) {
      this.context.commit('setError', error);
    }
    this.context.commit('updateLoadingStatus', false);
  }
}
export default getModule(AuditReportList);
