



















































































































































































































import { Vue, Component, Emit, Prop, Watch } from 'vue-property-decorator';
import FormTemplate from '@/components/swine/layout/FormTemplate.vue';
import CalculatorInputField from '../../CalculatorInputField.vue';
import MedicationModalSectionCollapseTemplate from '@/components/swine/economicCalculation/MedicationModalSectionCollapseTemplate.vue';
import {
  MedicineBrand,
  PulmotilCalculationModalData,
  PulmotilMedicationCalculationDetails,
} from '@/models/swine/economic-calculation.model';
import PriceOfMedicineDropdown from '../../PriceOfMedicineDropdown.vue';
import { DropdownOption } from '@/models/swine/dropdown.model';
import { validateInputNumber } from '@/utils/validation';
@Component({
  components: {
    FormTemplate,
    CalculatorInputField,
    MedicationModalSectionCollapseTemplate,
    PriceOfMedicineDropdown,
  },
})
export default class PulmotilMedicationUpdateModal extends Vue {
  formTitle = 'Update Medication Program';
  medicineBrandPlaceholder = 'Select Brand';
  isCleanSowProgramActive = false;
  isEarlyInterventionProgramActive = false;
  isGrowerProgramActive = false;
  updateMedicationModalData: PulmotilMedicationCalculationDetails = {
    //General
    liveWeightPrice: null,
    targetMarketWeight: null,
    averageFeedPrice: null,
    // Lactation Feed
    numberOfDaysPreFarrowing: null,
    numberOfDaysPostFarrowing: null,
    adfiAtGestation: null,
    adfiAtLactation: null,
    // Nursery Feed
    numberOfDaysFedInNursery: null,
    feedConsumptionPerDayInNursery: null,
    // Grower Feed
    numberOfDaysFedInGrower: null,
    feedConsumptionPerDayInGrower: null,
    // Clean Sow Program
    cleanSowMedicineBrand: null,
    medicationDoseInCleanSowProgram: null,
    costOfMedicationPerKiloInCleanSowProgram: null,
    // Early Intervention Program
    earlyInterventionMedicineBrand: null,
    medicationDoseInEarlyInterventionProgram: null,
    costOfMedicationPerKiloInEarlyInterventionProgram: null,
    // Grower Program
    growerMedicineBrand: null,
    medicationDoseInGrowerProgram: null,
    costOfMedicationPerKiloInGrowerProgram: null,
  };

  @Prop({ required: true })
  calculatorModalInputDataRetrieve!: PulmotilCalculationModalData;

  @Prop({ default: false })
  isActive!: boolean;

  get currentCurrencySymbol() {
    return this.calculatorModalInputDataRetrieve.currencySymbol;
  }

  get isGeneralFormValid() {
    let isValid = false;
    const { liveWeightPrice, targetMarketWeight, averageFeedPrice } =
      this.updateMedicationModalData;
    const valuesExist = [liveWeightPrice, targetMarketWeight, averageFeedPrice];
    if (valuesExist.every((value) => value !== null)) {
      isValid =
        validateInputNumber(String(liveWeightPrice)) &&
        validateInputNumber(String(targetMarketWeight)) &&
        validateInputNumber(String(averageFeedPrice));
    }
    return isValid;
  }

  // Check for Lactation Feed and Clean Sow
  get isCleanSowValid() {
    if (!this.isCleanSowProgramActive) {
      return true;
    }

    let isValid = false;
    const {
      numberOfDaysPreFarrowing,
      numberOfDaysPostFarrowing,
      adfiAtGestation,
      adfiAtLactation,
      medicationDoseInCleanSowProgram,
      costOfMedicationPerKiloInCleanSowProgram,
      cleanSowMedicineBrand,
    } = this.updateMedicationModalData;
    const valuesExist = [
      numberOfDaysPreFarrowing,
      numberOfDaysPostFarrowing,
      adfiAtGestation,
      adfiAtLactation,
      medicationDoseInCleanSowProgram,
      costOfMedicationPerKiloInCleanSowProgram,
    ];
    if (valuesExist.every((value) => value !== null) && cleanSowMedicineBrand) {
      isValid =
        validateInputNumber(String(numberOfDaysPreFarrowing)) &&
        validateInputNumber(String(numberOfDaysPostFarrowing)) &&
        validateInputNumber(String(adfiAtGestation)) &&
        validateInputNumber(String(adfiAtLactation)) &&
        validateInputNumber(String(medicationDoseInCleanSowProgram)) &&
        validateInputNumber(String(costOfMedicationPerKiloInCleanSowProgram)) &&
        cleanSowMedicineBrand.length > 0;
    }
    return isValid;
  }

  get isEarlyInterventionValid() {
    if (!this.isEarlyInterventionProgramActive) {
      return true;
    }

    let isValid = false;
    const {
      numberOfDaysFedInNursery,
      feedConsumptionPerDayInNursery,
      earlyInterventionMedicineBrand,
      medicationDoseInEarlyInterventionProgram,
      costOfMedicationPerKiloInEarlyInterventionProgram,
    } = this.updateMedicationModalData;
    const valuesExist = [
      numberOfDaysFedInNursery,
      feedConsumptionPerDayInNursery,
      medicationDoseInEarlyInterventionProgram,
      costOfMedicationPerKiloInEarlyInterventionProgram,
    ];
    if (
      valuesExist.every((value) => value !== null) &&
      earlyInterventionMedicineBrand
    ) {
      isValid =
        validateInputNumber(String(numberOfDaysFedInNursery)) &&
        validateInputNumber(String(feedConsumptionPerDayInNursery)) &&
        validateInputNumber(String(medicationDoseInEarlyInterventionProgram)) &&
        validateInputNumber(
          String(costOfMedicationPerKiloInEarlyInterventionProgram)
        ) &&
        earlyInterventionMedicineBrand.length > 0;
    }
    return isValid;
  }

  get isGrowerValid() {
    if (!this.isGrowerProgramActive) {
      return true;
    }
    let isValid = false;
    const {
      numberOfDaysFedInGrower,
      feedConsumptionPerDayInGrower,
      growerMedicineBrand,
      medicationDoseInGrowerProgram,
      costOfMedicationPerKiloInGrowerProgram,
    } = this.updateMedicationModalData;
    const valuesExist = [
      numberOfDaysFedInGrower,
      feedConsumptionPerDayInGrower,
      medicationDoseInGrowerProgram,
      costOfMedicationPerKiloInGrowerProgram,
    ];
    if (valuesExist.every((value) => value !== null) && growerMedicineBrand) {
      isValid =
        validateInputNumber(String(numberOfDaysFedInGrower)) &&
        validateInputNumber(String(feedConsumptionPerDayInGrower)) &&
        validateInputNumber(String(medicationDoseInGrowerProgram)) &&
        validateInputNumber(String(costOfMedicationPerKiloInGrowerProgram)) &&
        growerMedicineBrand.length > 0;
    }
    return isValid;
  }

  get isFormValid() {
    return (
      this.isGeneralFormValid &&
      this.isCleanSowValid &&
      this.isEarlyInterventionValid &&
      this.isGrowerValid
    );
  }

  get medicine() {
    return [
      { text: MedicineBrand.DENEGARD, value: MedicineBrand.DENEGARD },
      {
        text: MedicineBrand.PULMOTIL,
        value: MedicineBrand.PULMOTIL,
      },
    ];
  }

  mounted() {
    this.updateModalData();
  }

  @Watch(`calculatorModalInputDataRetrieve`, { deep: true })
  updateModalData() {
    if (this.calculatorModalInputDataRetrieve) {
      this.updateMedicationModalData.liveWeightPrice =
        this.calculatorModalInputDataRetrieve.liveWeightPrice;
      this.updateMedicationModalData.targetMarketWeight =
        this.calculatorModalInputDataRetrieve.targetMarketWeight;
      this.updateMedicationModalData.averageFeedPrice =
        this.calculatorModalInputDataRetrieve.averageFeedPrice;
      this.updateMedicationModalData.numberOfDaysPreFarrowing =
        this.calculatorModalInputDataRetrieve.numberOfDaysPreFarrowing;
      this.updateMedicationModalData.numberOfDaysPostFarrowing =
        this.calculatorModalInputDataRetrieve.numberOfDaysPostFarrowing;
      this.updateMedicationModalData.adfiAtGestation =
        this.calculatorModalInputDataRetrieve.adfiAtGestation;
      this.updateMedicationModalData.adfiAtLactation =
        this.calculatorModalInputDataRetrieve.adfiAtLactation;
      this.updateMedicationModalData.numberOfDaysFedInNursery =
        this.calculatorModalInputDataRetrieve.numberOfDaysFedInNursery;
      this.updateMedicationModalData.feedConsumptionPerDayInNursery =
        this.calculatorModalInputDataRetrieve.feedConsumptionPerDayInNursery;
      this.updateMedicationModalData.numberOfDaysFedInGrower =
        this.calculatorModalInputDataRetrieve.numberOfDaysFedInGrower;
      this.updateMedicationModalData.feedConsumptionPerDayInGrower =
        this.calculatorModalInputDataRetrieve.feedConsumptionPerDayInGrower;
      this.updateMedicationModalData.cleanSowMedicineBrand =
        this.calculatorModalInputDataRetrieve.cleanSowMedicineBrand;
      this.updateMedicationModalData.medicationDoseInCleanSowProgram =
        this.calculatorModalInputDataRetrieve.medicationDoseInCleanSowProgram;
      this.updateMedicationModalData.costOfMedicationPerKiloInCleanSowProgram =
        this.calculatorModalInputDataRetrieve.costOfMedicationPerKiloInCleanSowProgram;
      this.updateMedicationModalData.earlyInterventionMedicineBrand =
        this.calculatorModalInputDataRetrieve.earlyInterventionMedicineBrand;
      this.updateMedicationModalData.medicationDoseInEarlyInterventionProgram =
        this.calculatorModalInputDataRetrieve.medicationDoseInEarlyInterventionProgram;
      this.updateMedicationModalData.costOfMedicationPerKiloInEarlyInterventionProgram =
        this.calculatorModalInputDataRetrieve.costOfMedicationPerKiloInEarlyInterventionProgram;
      this.updateMedicationModalData.growerMedicineBrand =
        this.calculatorModalInputDataRetrieve.growerMedicineBrand;
      this.updateMedicationModalData.medicationDoseInGrowerProgram =
        this.calculatorModalInputDataRetrieve.medicationDoseInGrowerProgram;
      this.updateMedicationModalData.costOfMedicationPerKiloInGrowerProgram =
        this.calculatorModalInputDataRetrieve.costOfMedicationPerKiloInGrowerProgram;
      this.openModalSections();
    }
  }

  openModalSections() {
    this.isCleanSowProgramActive = Boolean(
      this.updateMedicationModalData.cleanSowMedicineBrand
    );
    this.isEarlyInterventionProgramActive = Boolean(
      this.updateMedicationModalData.earlyInterventionMedicineBrand
    );
    this.isGrowerProgramActive = Boolean(
      this.updateMedicationModalData.growerMedicineBrand
    );
  }

  updateCleanSowModal(payload: boolean) {
    this.isCleanSowProgramActive = payload;
    if (!payload) {
      this.resetCleanSowModal();
    }
  }

  resetCleanSowModal() {
    this.updateMedicationModalData.numberOfDaysPreFarrowing = null;
    this.updateMedicationModalData.numberOfDaysPostFarrowing = null;
    this.updateMedicationModalData.adfiAtGestation = null;
    this.updateMedicationModalData.adfiAtLactation = null;
    this.updateMedicationModalData.cleanSowMedicineBrand = null;
    this.updateMedicationModalData.medicationDoseInCleanSowProgram = null;
    this.updateMedicationModalData.costOfMedicationPerKiloInCleanSowProgram =
      null;
  }

  updateEarlyInterventionModal(payload: boolean) {
    this.isEarlyInterventionProgramActive = payload;
    if (!payload) {
      this.resetEarlyInterventionModal();
    }
  }

  resetEarlyInterventionModal() {
    this.updateMedicationModalData.numberOfDaysFedInNursery = null;
    this.updateMedicationModalData.feedConsumptionPerDayInNursery = null;
    this.updateMedicationModalData.earlyInterventionMedicineBrand = null;
    this.updateMedicationModalData.medicationDoseInEarlyInterventionProgram =
      null;
    this.updateMedicationModalData.costOfMedicationPerKiloInEarlyInterventionProgram =
      null;
  }

  updateGrowerModal(payload: boolean) {
    this.isGrowerProgramActive = payload;
    if (!payload) {
      this.resetGrowerModal();
    }
  }

  resetGrowerModal() {
    this.updateMedicationModalData.numberOfDaysFedInGrower = null;
    this.updateMedicationModalData.feedConsumptionPerDayInGrower = null;
    this.updateMedicationModalData.growerMedicineBrand = null;
    this.updateMedicationModalData.medicationDoseInGrowerProgram = null;
    this.updateMedicationModalData.costOfMedicationPerKiloInGrowerProgram =
      null;
  }

  changeCleanSowDefaultMessage(data: DropdownOption) {
    this.updateMedicationModalData.cleanSowMedicineBrand = data.value;
  }

  changeEarlyInterventionDefaultMessage(data: DropdownOption) {
    this.updateMedicationModalData.earlyInterventionMedicineBrand = data.value;
  }

  changeGrowerDefaultMessage(data: DropdownOption) {
    this.updateMedicationModalData.growerMedicineBrand = data.value;
  }

  @Emit(`update`)
  updateMedicationData() {
    this.$buefy.toast.open({
      message: `Medication Program has been updated.`,
      type: `is-dark`,
      duration: 3000,
    });
    return this.updateMedicationModalData;
  }

  resetForm() {
    this.resetGrowerModal();
    this.resetEarlyInterventionModal();
    this.resetCleanSowModal();
    this.updateModalData();
    this.closeModal();
  }

  @Emit(`close`)
  closeModal() {
    return false;
  }
}
