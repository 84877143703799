























import { Component, Prop, Vue } from 'vue-property-decorator';
import ReportCardTemplate from '@/components/swine/reports/ReportCardTemplate.vue';
import {
  BronchopneumoniaScoreWithReportData,
  FarmResultSummaryWithReportData,
} from '@/models/swine/report/report.model';

@Component({
  components: { ReportCardTemplate },
})
export default class PnuemoniaCard extends Vue {
  @Prop({
    type: Object,
    default: null,
  })
  reportValues!:
    | BronchopneumoniaScoreWithReportData
    | FarmResultSummaryWithReportData;

  get pneumoniaIndex() {
    if (this.reportValues) {
      let output = this.reportValues.pneumoniaIndex.toString();
      if (output.includes('.')) {
        while (output.split('.')[1].length < 3) {
          output += '0';
        }
        return output;
      } else {
        output += '.000';
        return output;
      }
    }
    return '0.000';
  }

  get pneumoniaIndexColor() {
    if (this.reportValues) {
      if (this.reportValues.pneumoniaIndex >= 0.9) {
        return `color: #ff4c4b !important`;
      } else if (
        this.reportValues.pneumoniaIndex >= 0.56 &&
        this.reportValues.pneumoniaIndex <= 0.89
      ) {
        return `color: #745e05 !important`;
      } else {
        return `color: #547230 !important`;
      }
    }
    return ``;
  }
}
