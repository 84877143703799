




































































































































































































































































































































































































































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import {
  EconomicCalculationType,
  ReferenceTypeAdgReductionPercentages,
} from '@/utils/constants';
import {
  EconomicCalculationModalDataType,
  ReferenceType,
} from '@/models/swine/economic-calculation.model';
import UpdateAnimalNumberModal from './UpdateAnimalNumberModal.vue';
import { BronchopneumoniaSectionLayoutResults } from '@/models/swine/bronchopneumoniasectionlayout';
import { addCommas, addDecimalPointToNumbers } from '@/utils/formats';

@Component({
  components: {
    UpdateAnimalNumberModal,
  },
})
export default class BronchopneumoniaReportTables extends Vue {
  updateAnimalModalStatus = false;

  updateWeanModalStatus = false;

  @Prop({ required: true })
  transferredModalData!: EconomicCalculationModalDataType;

  standardizeValue(data: number) {
    return addDecimalPointToNumbers(data);
  }

  get adgReductionPercentages() {
    return ReferenceTypeAdgReductionPercentages[
      this.transferredModalData.referenceType || ReferenceType.E_STERVERMER
    ];
  }

  get adgFormula() {
    let adgFormulaResult: number[] = [];
    adgFormulaResult = this.adgReductionPercentages.map(
      (adgPercentage: number) => {
        if (this.transferredModalData.adgValue) {
          return (
            this.transferredModalData.adgValue -
            this.transferredModalData.adgValue * adgPercentage
          );
        }
        return 0;
      }
    );
    return adgFormulaResult;
  }

  firstFinalWeight(adgValueSet: number | null, weanToMarket: number | null) {
    if (adgValueSet && weanToMarket) {
      return (adgValueSet * weanToMarket) / 1000;
    } else {
      return 0;
    }
  }

  get normalLungFinalWeight() {
    return this.firstFinalWeight(
      this.transferredModalData.adgValue,
      this.transferredModalData.weanMarketDays
    );
  }

  get finalWeight() {
    return this.adgFormula.map((adg: number) => {
      if (
        this.transferredModalData &&
        this.transferredModalData.weanMarketDays
      ) {
        return (adg * this.transferredModalData.weanMarketDays) / 1000;
      } else {
        return 0;
      }
    });
  }

  get animalPercentages() {
    if (this.transferredModalData.pneumoniaSeverityDict) {
      return Object.values(this.transferredModalData.pneumoniaSeverityDict).map(
        (number) => {
          try {
            return Number((number / this.animalTotal).toFixed(4));
          } catch {
            return 0;
          }
        }
      );
    }
    return [];
  }

  get animalTotal() {
    function reducer(total: number, value: number) {
      return total + value;
    }
    if (this.transferredModalData.pneumoniaSeverityDict) {
      return Object.values(this.transferredModalData.pneumoniaSeverityDict)
        .slice(0, 7)
        .reduce(reducer);
    }
    return 0;
  }

  get numberOfAnimalHeadsSold() {
    let numberOfAnimalHeadsSoldResult: number[] = [];
    // Number of Animal Heads in Pleurisy is the same as Number of Grower Pigs in Pulmotil
    numberOfAnimalHeadsSoldResult = this.animalPercentages.map((percentage) => {
      if (
        this.transferredModalData &&
        this.transferredModalData.economicCalculationType ===
          EconomicCalculationType.pleurisy &&
        'numberOfAnimalHeads' in this.transferredModalData &&
        this.transferredModalData.numberOfAnimalHeads
      ) {
        return this.transferredModalData.numberOfAnimalHeads * percentage;
      }

      if (
        this.transferredModalData &&
        this.transferredModalData.economicCalculationType ===
          EconomicCalculationType.pulmotil &&
        'numberOfGrowerPigs' in this.transferredModalData &&
        this.transferredModalData.numberOfGrowerPigs
      ) {
        return this.transferredModalData.numberOfGrowerPigs * percentage;
      }

      return 0;
    });
    return numberOfAnimalHeadsSoldResult;
  }

  get daysToReach() {
    return this.adgFormula.map((adg: number) => {
      try {
        return (
          (this.firstFinalWeight(
            this.transferredModalData.adgValue,
            this.transferredModalData.weanMarketDays
          ) /
            adg) *
          1000
        );
      } catch {
        return 0;
      }
    });
  }

  get extraDaysToReach() {
    return this.daysToReach.map((days: number) => {
      try {
        return days - this.daysToReach[0];
      } catch {
        return 0;
      }
    });
  }

  get extraFeedConsumed() {
    let extraFeedConsumedResult: number[] = [];
    extraFeedConsumedResult = this.extraDaysToReach.map((extraDay: number) => {
      if (this.transferredModalData && this.transferredModalData.adfiValue) {
        try {
          return extraDay * this.transferredModalData.adfiValue;
        } catch {
          return 0;
        }
      } else {
        return 0;
      }
    });
    return extraFeedConsumedResult;
  }

  get additionalFeedCost() {
    let additionalFeedCostResult: number[] = [];
    additionalFeedCostResult = this.extraFeedConsumed.map(
      (extraFeed: number) => {
        if (this.transferredModalData.additionalFeedCostPigRate !== null) {
          try {
            return (
              extraFeed * this.transferredModalData.additionalFeedCostPigRate
            );
          } catch {
            return 0;
          }
        } else {
          return 0;
        }
      }
    );
    return additionalFeedCostResult;
  }

  get additionalFeedCostPer1000() {
    return this.additionalFeedCost.map((addFeedCost: number, index: number) => {
      return addFeedCost * this.numberOfAnimalHeadsSold[index];
    });
  }

  get totalAdditionalFeedCostPer1000() {
    function reducer(total: number, value: number) {
      return total + value;
    }
    return this.additionalFeedCostPer1000.reduce(reducer);
  }

  get additionalFeedCostPig() {
    let additionalFeedCostPigResult = 0;
    if (
      !this.isPulmotilValueCalculationTypeSelected &&
      'numberOfAnimalHeads' in this.transferredModalData &&
      this.transferredModalData.numberOfAnimalHeads !== null
    ) {
      try {
        additionalFeedCostPigResult =
          this.totalAdditionalFeedCostPer1000 /
          this.transferredModalData.numberOfAnimalHeads;
      } catch {
        return 0;
      }
    }

    if (
      this.isPulmotilValueCalculationTypeSelected &&
      'sowLevel' in this.transferredModalData &&
      this.transferredModalData.numberOfGrowerPigs !== null
    ) {
      try {
        additionalFeedCostPigResult =
          this.totalAdditionalFeedCostPer1000 /
          this.transferredModalData.numberOfGrowerPigs;
      } catch {
        return 0;
      }
    }
    return additionalFeedCostPigResult;
  }

  get additionalFeedPig() {
    let additionalFeedPigResult = 0;
    if (this.transferredModalData.additionalFeedCostPigRate !== null) {
      try {
        additionalFeedPigResult =
          this.additionalFeedCostPig /
          this.transferredModalData.additionalFeedCostPigRate;
      } catch {
        return 0;
      }
    }
    return additionalFeedPigResult;
  }

  get additionalFeedCostThousand() {
    return this.totalAdditionalFeedCostPer1000;
  }

  get additionalFeedCostYear() {
    return this.totalAdditionalFeedCostPer1000 * 12;
  }

  get numberOfAnimalHeadsText() {
    return this.isPulmotilValueCalculationTypeSelected &&
      'sowLevel' in this.transferredModalData
      ? this.transferredModalData.numberOfGrowerPigs.toFixed(0)
      : 'numberOfAnimalHeads' in this.transferredModalData &&
        !this.isPulmotilValueCalculationTypeSelected
      ? this.transferredModalData.numberOfAnimalHeads
      : '';
  }

  mounted() {
    this.transferSectionLayoutResults();
    this.sectionLayoutAnimalNumber();
  }

  get isPulmotilValueCalculationTypeSelected() {
    return (
      this.transferredModalData.economicCalculationType ===
      EconomicCalculationType.pulmotil
    );
  }

  get sectionCardObject(): BronchopneumoniaSectionLayoutResults {
    return {
      additionalFeedCostPig: this.additionalFeedCostPig,
      additionalFeedPig: this.additionalFeedPig,
      additionalFeedCostThousand: this.additionalFeedCostThousand,
      additionalFeedCostYear: this.additionalFeedCostYear,
    };
  }

  transferSectionLayoutResults() {
    this.$emit('transferSectionLayoutResults', this.sectionCardObject);
  }

  sectionLayoutAnimalNumber() {
    this.isPulmotilValueCalculationTypeSelected &&
    'sowLevel' in this.transferredModalData
      ? this.$emit(
          'sectionLayoutAnimalNumber',
          this.transferredModalData.numberOfGrowerPigs.toFixed(0)
        )
      : 'numberOfAnimalHeads' in this.transferredModalData &&
        !this.isPulmotilValueCalculationTypeSelected
      ? this.$emit(
          'sectionLayoutAnimalNumber',
          this.transferredModalData.numberOfAnimalHeads
        )
      : null;
  }

  @Watch('transferredModalData', { deep: true })
  onTransferredModalDataUpdated() {
    this.transferSectionLayoutResults();
    this.sectionLayoutAnimalNumber();
  }

  openAnimalUpdateModal() {
    this.updateAnimalModalStatus = !this.updateAnimalModalStatus;
  }

  updateAnimalNumber({
    numberOfAnimalHeads,
    weanMarketDays,
    weekNumber,
  }: {
    numberOfAnimalHeads: number;
    weanMarketDays: number;
    weekNumber: number;
  }) {
    this.$emit('update', { numberOfAnimalHeads, weanMarketDays, weekNumber });
    this.updateAnimalModalStatus = !this.updateAnimalModalStatus;
  }

  openWeanUpdateModal() {
    this.updateWeanModalStatus = !this.updateWeanModalStatus;
  }

  updateWeanNumber({
    numberOfAnimalHeads,
    weanMarketDays,
    weekNumber,
    ageOfWeaning,
  }: {
    numberOfAnimalHeads: number;
    weanMarketDays: number;
    weekNumber: number;
    ageOfWeaning: number;
  }) {
    this.$emit('update', {
      numberOfAnimalHeads,
      weanMarketDays,
      weekNumber,
      ageOfWeaning,
    });
    this.updateWeanModalStatus = !this.updateWeanModalStatus;
  }

  addComma(targetNumber: number, toFixedNumber: number, onlyToString: boolean) {
    if (isNaN(targetNumber)) {
      return '-';
    } else if (!onlyToString) {
      const roundedNumber = targetNumber.toFixed(toFixedNumber);
      return addCommas(roundedNumber);
    } else if (onlyToString) {
      const numberToString = targetNumber.toString();
      return addCommas(numberToString);
    }
  }
}
