






































































































































import { Vue, Component, Prop, Emit, Watch } from 'vue-property-decorator';
import { DropdownOption } from '@/models/swine/dropdown.model';
import { PleurisyCalculatorModalData } from '@/models/swine/economic-calculation.model';
import CalculatorInputField from '../../CalculatorInputField.vue';
import ReferenceTypeDropdown from '../../ReferenceTypeDropdown.vue';
@Component({
  components: {
    CalculatorInputField,
    ReferenceTypeDropdown,
  },
})
export default class EconomicCalculationForm extends Vue {
  calculatorModalInputObject: PleurisyCalculatorModalData = {
    numberOfAnimalHeads: null,
    adgValue: null,
    weanMarketDays: null,
    weekNumber: null,
    additionalFeedCostPigRate: null,
    adfiValue: null,
    liveWeightPrice: null,
    targetMarketWeight: null,
    averageFeedPrice: null,
    referenceType: null,
    postWeaningMortality: null,
    economicCalculationType: null,
    ageOfWeaning: null,
  };

  @Prop()
  previousInput!: PleurisyCalculatorModalData;

  @Prop({ required: true })
  references!: DropdownOption[];

  @Prop({ required: true })
  currentCurrency!: string;

  @Watch(`previousInput`)
  updateData() {
    this.calculatorModalInputObject = this.previousInput;
  }

  selectedReferenceType({ value }: DropdownOption) {
    this.calculatorModalInputObject.referenceType = value;
    this.returnForm();
  }

  calculateWeekNumber() {
    let numberOfDays = 0;

    if (
      this.calculatorModalInputObject.weanMarketDays &&
      Number(this.calculatorModalInputObject.weanMarketDays)
    ) {
      numberOfDays += Number(this.calculatorModalInputObject.weanMarketDays);
    }

    if (
      this.calculatorModalInputObject.ageOfWeaning &&
      Number(this.calculatorModalInputObject.ageOfWeaning)
    ) {
      numberOfDays += Number(this.calculatorModalInputObject.ageOfWeaning);
    }

    const remainderDays = numberOfDays % 7;
    const noOfWeeks = Math.floor(numberOfDays / 7);
    if (remainderDays >= 4 || noOfWeeks === 0) {
      this.calculatorModalInputObject.weekNumber = noOfWeeks + 1;
    } else {
      this.calculatorModalInputObject.weekNumber = noOfWeeks;
    }
    this.returnForm();
  }

  @Emit('update')
  returnForm() {
    return this.calculatorModalInputObject;
  }
}
