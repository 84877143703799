











































































































































































































































































































































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { PleurisyCalculatorModalData } from '@/models/swine/economic-calculation.model';
import UpdateAnimalNumberModal from './UpdateAnimalNumberModal.vue';
import { addCommas } from '@/utils/formats';
import { DisplayData } from '@/models/swine/report/report.model';
import SectionLayoutCard from '@/components/swine/reports/SectionLayoutCard.vue';
@Component({
  components: { UpdateAnimalNumberModal, SectionLayoutCard },
})
export default class PleurisyMedicationReportForm extends Vue {
  @Prop({ required: true })
  calculatorModalInputDataRetrieve!: PleurisyCalculatorModalData;

  updateWeanModalStatus = false;

  cleanSowProgramOpen = false;

  startStrongProgramOpen = false;

  strategicProgramOpen = false;

  updateAnimalModalStatus = false;

  mounted() {
    this.onMedicationModalDataUpdated();
  }

  @Watch('calculatorModalInputDataRetrieve', { deep: true })
  onMedicationModalDataUpdated() {
    this.cleanSowProgramOpen = Boolean(
      this.calculatorModalInputDataRetrieve.cleanSowMedicineBrand,
    );
    this.startStrongProgramOpen = Boolean(
      this.calculatorModalInputDataRetrieve.startStrongMedicineBrand,
    );
    this.strategicProgramOpen = Boolean(
      this.calculatorModalInputDataRetrieve.strategicProgramMedicineBrand,
    );
  }

  get sectionCardDetails(): DisplayData[] {
    return [
      {
        headerText: `Medication Investment /pig`,
        subText: ``,
        value: this.medicationInvestmentPig,
      },
      {
        headerText: `Total Investment`,
        subText: ``,
        value: this.totalInvestment,
      },
    ];
  }

  get cleanSowDosageValue() {
    return this.calculatorModalInputDataRetrieve.cleanSowDosage;
  }

  get cleanSowDosage() {
    if (this.cleanSowDosageValue === null) {
      return '-';
    } else {
      return this.addComma(Number(this.cleanSowDosageValue).toFixed(2));
    }
  }

  get cleanSowMedicinePriceValue() {
    return this.calculatorModalInputDataRetrieve.cleanSowMedicinePrice;
  }

  get cleanSowMedicinePrice() {
    if (this.cleanSowMedicinePriceValue === null) {
      return '-';
    } else {
      return this.addComma(Number(this.cleanSowMedicinePriceValue).toFixed(2));
    }
  }

  get cleanSowMedicationCostFeedFormula() {
    let cleanSowMedicationCostFeedFormulaResult = 0;
    if (
      this.cleanSowDosageValue !== null &&
      this.cleanSowDosageValue !== undefined &&
      this.calculatorModalInputDataRetrieve.cleanSowMedicinePrice !== null &&
      this.calculatorModalInputDataRetrieve.cleanSowMedicinePrice !== undefined
    ) {
      cleanSowMedicationCostFeedFormulaResult =
        this.cleanSowDosageValue *
        this.calculatorModalInputDataRetrieve.cleanSowMedicinePrice;
    }
    return cleanSowMedicationCostFeedFormulaResult;
  }

  get cleanSowMedicationCostFeed() {
    if (this.cleanSowMedicationCostFeedFormula === 0) {
      return '-';
    } else {
      return this.addComma(
        Number(this.cleanSowMedicationCostFeedFormula).toFixed(2),
      );
    }
  }

  get cleanSowMedicationCostHeadFormula() {
    let cleanSowMedicationCostHeadFormulaResult = 0;
    if (
      this.calculatorModalInputDataRetrieve.numberOfAnimalHeads !== null &&
      this.calculatorModalInputDataRetrieve.cleanSowPSRatio &&
      this.calculatorModalInputDataRetrieve.cleanSowFeedIntake &&
      this.calculatorModalInputDataRetrieve.cleanSowDuration
    ) {
      cleanSowMedicationCostHeadFormulaResult =
        (((this.calculatorModalInputDataRetrieve.numberOfAnimalHeads /
          this.calculatorModalInputDataRetrieve.cleanSowPSRatio) *
          0.2 *
          this.calculatorModalInputDataRetrieve.cleanSowFeedIntake *
          this.calculatorModalInputDataRetrieve.cleanSowDuration) /
          1000) *
        this.cleanSowMedicationCostFeedFormula;
    }
    return cleanSowMedicationCostHeadFormulaResult;
  }

  get cleanSowMedicationCostHead() {
    if (this.cleanSowMedicationCostHeadFormula === 0) {
      return '-';
    } else {
      return this.addComma(
        Number(this.cleanSowMedicationCostHeadFormula).toFixed(2),
      );
    }
  }

  get cleanSowCostPerPigFormula() {
    let cleanSowCostPerPigFormulaResult = 0;
    if (this.calculatorModalInputDataRetrieve.numberOfAnimalHeads !== null) {
      cleanSowCostPerPigFormulaResult =
        this.cleanSowMedicationCostHeadFormula /
        this.calculatorModalInputDataRetrieve.numberOfAnimalHeads;
    }
    return cleanSowCostPerPigFormulaResult;
  }

  get cleanSowCostPerPig() {
    if (
      this.cleanSowCostPerPigFormula === 0 ||
      isNaN(this.cleanSowCostPerPigFormula)
    ) {
      return '-';
    } else {
      return this.addComma(Number(this.cleanSowCostPerPigFormula).toFixed(2));
    }
  }

  get startStrongDosageValue() {
    return this.calculatorModalInputDataRetrieve.startStrongDosage;
  }

  get startStrongDosage() {
    if (this.startStrongDosageValue === null) {
      return '-';
    } else {
      return this.addComma(Number(this.startStrongDosageValue).toFixed(2));
    }
  }

  get startStrongMedicinePriceValue() {
    return this.calculatorModalInputDataRetrieve.startStrongMedicinePrice;
  }

  get startStrongMedicinePrice() {
    if (this.startStrongMedicinePriceValue === null) {
      return '-';
    } else {
      return this.addComma(
        Number(this.startStrongMedicinePriceValue).toFixed(2),
      );
    }
  }

  get startStrongMedicationCostFeedFormula() {
    let startStrongMedicationCostFeedFormulaResult = 0;
    if (
      this.startStrongDosageValue !== null &&
      this.startStrongDosageValue !== undefined &&
      this.calculatorModalInputDataRetrieve.startStrongMedicinePrice !== null &&
      this.calculatorModalInputDataRetrieve.startStrongMedicinePrice !==
        undefined
    ) {
      startStrongMedicationCostFeedFormulaResult =
        this.startStrongDosageValue *
        this.calculatorModalInputDataRetrieve.startStrongMedicinePrice;
    }
    return startStrongMedicationCostFeedFormulaResult;
  }

  get startStrongMedicationCostFeed() {
    if (this.startStrongMedicationCostFeedFormula === 0) {
      return '-';
    } else {
      return this.addComma(
        Number(this.startStrongMedicationCostFeedFormula).toFixed(2),
      );
    }
  }

  get startStrongMedicationCostHeadFormula() {
    let startStrongMedicationCostHeadFormulaResult = 0;
    if (
      this.calculatorModalInputDataRetrieve.numberOfAnimalHeads !== null &&
      this.calculatorModalInputDataRetrieve.startStrongFeedIntake &&
      this.calculatorModalInputDataRetrieve.startStrongDuration
    ) {
      startStrongMedicationCostHeadFormulaResult =
        ((this.calculatorModalInputDataRetrieve.numberOfAnimalHeads *
          this.calculatorModalInputDataRetrieve.startStrongFeedIntake *
          this.calculatorModalInputDataRetrieve.startStrongDuration) /
          1000) *
        this.startStrongMedicationCostFeedFormula;
    }
    return startStrongMedicationCostHeadFormulaResult;
  }

  get startStrongMedicationCostHead() {
    if (this.startStrongMedicationCostHeadFormula === 0) {
      return '-';
    } else {
      return this.addComma(
        Number(this.startStrongMedicationCostHeadFormula).toFixed(2),
      );
    }
  }

  get startStrongCostPerPigFormula() {
    let startStrongCostPerPigFormulaResult = 0;
    if (this.calculatorModalInputDataRetrieve.numberOfAnimalHeads !== null) {
      startStrongCostPerPigFormulaResult =
        this.startStrongMedicationCostHeadFormula /
        this.calculatorModalInputDataRetrieve.numberOfAnimalHeads;
    }
    return startStrongCostPerPigFormulaResult;
  }

  get startStrongCostPerPig() {
    if (
      this.startStrongCostPerPigFormula === 0 ||
      isNaN(this.startStrongCostPerPigFormula)
    ) {
      return '-';
    } else {
      return this.addComma(
        Number(this.startStrongCostPerPigFormula).toFixed(2),
      );
    }
  }

  get strategicProgramDosageValue() {
    return this.calculatorModalInputDataRetrieve.strategicProgramDosage;
  }

  get strategicProgramDosage() {
    if (this.strategicProgramDosageValue === null) {
      return '-';
    } else {
      return this.addComma(Number(this.strategicProgramDosageValue).toFixed(2));
    }
  }

  get strategicProgramMedicinePriceValue() {
    return this.calculatorModalInputDataRetrieve.strategicProgramMedicinePrice;
  }

  get strategicProgramMedicinePrice() {
    if (this.strategicProgramMedicinePriceValue === null) {
      return '-';
    } else {
      return this.addComma(
        Number(this.strategicProgramMedicinePriceValue).toFixed(2),
      );
    }
  }

  get strategicProgramMedicationCostFeedFormula() {
    const strategicProgramMedicationCostFeedFormula = 0;
    if (
      this.strategicProgramDosageValue !== null &&
      this.strategicProgramDosageValue !== undefined &&
      this.calculatorModalInputDataRetrieve.strategicProgramMedicinePrice !==
        undefined &&
      this.calculatorModalInputDataRetrieve.strategicProgramMedicinePrice !==
        null
    ) {
      return (
        this.strategicProgramDosageValue *
        this.calculatorModalInputDataRetrieve.strategicProgramMedicinePrice
      );
    }
    return strategicProgramMedicationCostFeedFormula;
  }

  get strategicProgramMedicationCostFeed() {
    if (
      this.strategicProgramMedicationCostFeedFormula === 0 ||
      isNaN(this.strategicProgramMedicationCostFeedFormula)
    ) {
      return '-';
    } else {
      return this.addComma(
        Number(this.strategicProgramMedicationCostFeedFormula).toFixed(2),
      );
    }
  }

  get strategicProgramMedicationCostHeadFormula() {
    let strategicProgramMedicationCostHeadFormulaResult = 0;
    if (
      this.calculatorModalInputDataRetrieve.numberOfAnimalHeads !== null &&
      this.calculatorModalInputDataRetrieve.strategicProgramFeedIntake &&
      this.calculatorModalInputDataRetrieve.strategicProgramDuration
    ) {
      strategicProgramMedicationCostHeadFormulaResult =
        ((this.calculatorModalInputDataRetrieve.numberOfAnimalHeads *
          this.calculatorModalInputDataRetrieve.strategicProgramFeedIntake *
          this.calculatorModalInputDataRetrieve.strategicProgramDuration) /
          1000) *
        this.strategicProgramMedicationCostFeedFormula;
    }
    return strategicProgramMedicationCostHeadFormulaResult;
  }

  get strategicProgramMedicationCostHead() {
    if (this.strategicProgramMedicationCostHeadFormula === 0) {
      return '-';
    } else {
      return this.addComma(
        Number(this.strategicProgramMedicationCostHeadFormula).toFixed(2),
      );
    }
  }

  get strategicProgramCostPerPigFormula() {
    let strategicProgramCostPerPigFormulaResult = 0;
    if (this.calculatorModalInputDataRetrieve.numberOfAnimalHeads !== null) {
      strategicProgramCostPerPigFormulaResult =
        this.strategicProgramMedicationCostHeadFormula /
        this.calculatorModalInputDataRetrieve.numberOfAnimalHeads;
    }
    return strategicProgramCostPerPigFormulaResult;
  }

  get strategicProgramCostPerPig() {
    if (this.strategicProgramCostPerPigFormula === 0) {
      return '-';
    } else {
      return this.addComma(
        Number(this.strategicProgramCostPerPigFormula).toFixed(2),
      );
    }
  }

  get totalInvestment() {
    if (
      this.cleanSowMedicationCostHeadFormula +
        this.startStrongMedicationCostHeadFormula +
        this.strategicProgramMedicationCostHeadFormula ===
      0
    ) {
      return '-';
    } else {
      return `${
        this.calculatorModalInputDataRetrieve.currencySymbol
      } ${this.addComma(
        Number(
          this.cleanSowMedicationCostHeadFormula +
            this.startStrongMedicationCostHeadFormula +
            this.strategicProgramMedicationCostHeadFormula,
        ).toFixed(2),
      )}`;
    }
  }

  get medicationInvestmentPig() {
    let medicationInvestmentPigResult = '';
    if (this.calculatorModalInputDataRetrieve.numberOfAnimalHeads !== null) {
      if (
        (this.cleanSowMedicationCostHeadFormula +
          this.startStrongMedicationCostHeadFormula +
          this.strategicProgramMedicationCostHeadFormula) /
          this.calculatorModalInputDataRetrieve.numberOfAnimalHeads ===
          0 ||
        isNaN(
          (this.cleanSowMedicationCostHeadFormula +
            this.startStrongMedicationCostHeadFormula +
            this.strategicProgramMedicationCostHeadFormula) /
            this.calculatorModalInputDataRetrieve.numberOfAnimalHeads,
        )
      ) {
        medicationInvestmentPigResult = '-';
      } else {
        medicationInvestmentPigResult = `${
          this.calculatorModalInputDataRetrieve.currencySymbol
        } ${this.addComma(
          Number(
            (this.cleanSowMedicationCostHeadFormula +
              this.startStrongMedicationCostHeadFormula +
              this.strategicProgramMedicationCostHeadFormula) /
              this.calculatorModalInputDataRetrieve.numberOfAnimalHeads,
          ).toFixed(2),
        )}`;
      }
    }
    return medicationInvestmentPigResult;
  }

  get numberOfAnimalHeadsText() {
    return this.calculatorModalInputDataRetrieve.numberOfAnimalHeads;
  }

  openAnimalUpdateModal() {
    this.updateAnimalModalStatus = !this.updateAnimalModalStatus;
  }

  updateAnimalNumber({
    numberOfAnimalHeads,
    weanMarketDays,
    weekNumber,
  }: {
    numberOfAnimalHeads: number;
    weanMarketDays: number;
    weekNumber: number;
  }) {
    this.$emit('update', { numberOfAnimalHeads, weanMarketDays, weekNumber });
    this.updateAnimalModalStatus = !this.updateAnimalModalStatus;
  }

  openWeanUpdateModal() {
    this.updateWeanModalStatus = !this.updateWeanModalStatus;
  }

  updateWeanNumber({
    numberOfAnimalHeads,
    weanMarketDays,
    weekNumber,
  }: {
    numberOfAnimalHeads: number;
    weanMarketDays: number;
    weekNumber: number;
  }) {
    this.$emit('update', { numberOfAnimalHeads, weanMarketDays, weekNumber });
    this.updateWeanModalStatus = !this.updateWeanModalStatus;
  }

  addComma(targetNumber: string) {
    return addCommas(targetNumber);
  }
}
