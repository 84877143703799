













































import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import InnerPageTemplate from '@/components/swine/layout/InnerPageTemplate.vue';
import AttachmentSelectionTable from './AttachmentSelectionTable.vue';
import { ReportStores } from '@/store/swine/reports';
import {
  FetchLungAttachmentDetails,
  LungLesionScoreParseAttachmentDetails,
} from '@/models/swine/report/report.model';
import { AuditReportSections } from '@/utils/constants';
import { DEFAULT_COUNT_PER_PAGE } from '@/utils/constants';
import Pagination from '@/components/swine/Pagination.vue';
import {
  RowCountChange,
  PageNumberChange,
} from '@/models/swine/pagination.model';
@Component({
  components: {
    InnerPageTemplate,
    AttachmentSelectionTable,
    Pagination,
  },
})
export default class AttachmentSelection extends Vue {
  title = 'Select Attachments';
  selectedAttachments: LungLesionScoreParseAttachmentDetails[] = [];
  store = ReportStores.detail;
  offsetValue = 0;
  attachmentData: LungLesionScoreParseAttachmentDetails[] | null = [];
  totalCount = 0;
  pageNumber = 1;
  rowsPerPage = DEFAULT_COUNT_PER_PAGE;
  searchQueries: FetchLungAttachmentDetails | null = null;
  resetSelectedLungAttachments = false;
  @Prop({
    type: Boolean,
    default: false,
  })
  isEditAllowed!: boolean;

  @Prop({
    type: String,
    default: null,
  })
  currentActiveTab!: string;

  mounted() {
    this.searchQueries = {
      section: AuditReportSections.ATTACHMENTS,
      reportId: parseInt(this.$route.params.reportId),
      limit: this.rowsPerPage,
      offset: this.offsetValue,
    };
    this.getLungAttachmentDetails();
  }

  getLungAttachmentDetails() {
    if (this.searchQueries) {
      this.store.getLungAttachmentDetails(this.searchQueries);
    }
  }

  @Watch(`store.lungAttachmentResponse`)
  getLungAttachments() {
    if (this.store.lungAttachmentResponse) {
      this.attachmentData = this.store.lungAttachmentResponse.paginatedLungData;
      this.totalCount = this.store.lungAttachmentResponse.totalCount;
    }
  }

  updateRowCount(params: RowCountChange) {
    if (this.searchQueries) {
      this.rowsPerPage = params.rowsPerPage;
      this.offsetValue = params.offsetValue;
      this.searchQueries.limit = params.rowsPerPage;
      this.searchQueries.offset = params.offsetValue;
      this.store.getLungAttachmentDetails(this.searchQueries);
    }
  }

  updatePageNumber(params: PageNumberChange) {
    if (this.searchQueries) {
      this.pageNumber = params.pageNumber;
      this.offsetValue = params.offsetValue;
      this.searchQueries.offset = params.offsetValue;
      this.store.getLungAttachmentDetails(this.searchQueries);
    }
  }

  updateAttachments(selectedDetails: LungLesionScoreParseAttachmentDetails[]) {
    this.selectedAttachments = selectedDetails;
  }

  resetAttachments() {
    this.selectedAttachments = [];
    this.resetSelectedLungAttachments = true;
  }

  @Emit(`return`)
  returnBackToOrigin(returnAttachments: boolean) {
    if (returnAttachments) {
      this.$buefy.toast.open({
        message: `Attachments have been added`,
        type: `is-dark`,
        duration: 1000,
      });
      return this.selectedAttachments;
    }
    return null;
  }
}
