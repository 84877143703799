




























































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { DisplayDataAssumption } from '@/models/swine/report/report.model';
@Component({})
export default class SectionLayoutCardAssumptionUpdated extends Vue {
  @Prop({
    type: Array,
    default: null,
  })
  displayDataAssumption!: DisplayDataAssumption[];

  medicationProgram = 'Medication Program';

  capturedOpportunityLoss = 'Captured Opportunity Loss';
}
