

























import { Vue, Component, Prop } from 'vue-property-decorator';
@Component({})
export default class MedicationCollapseTemplate extends Vue {
  @Prop({ required: true })
  title!: string;

  @Prop()
  isImageInHeader!: boolean;

  isActive = false;
}
