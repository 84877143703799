/**
 * This file serves as a utility file for PDFs.
 */

import { AppIndexObject } from "@/models/swine/report/report.model";

/**
 * Return color codes when pneumoniaIndex is available.
 * @param reportValues: Report Body
 * @returns colorCode
 */
export function getPneumoniaIndexColor(reportValues: any) {
    if (reportValues) {
      if (reportValues.pneumoniaIndex >= 0.9) {
        return `#ff4c4b`;
      } else if (
        reportValues.pneumoniaIndex >= 0.56 &&
        reportValues.pneumoniaIndex <= 0.89
      ) {
        return `#745e05`;
      } else {
        return `#547230`;
      }
    }
    return `#ffffff`;
  }

/**
 * Return ranking texts when appIndex is available
 * @param appIndex : App Index
 * @returns text
 */
export function getReportRanking(appIndex: AppIndexObject) {
    if (appIndex && appIndex.ranking) {
  
      if (appIndex.ranking < 21 && appIndex.ranking > 10) {
        return `${appIndex.ranking}th`;
      }
  
      const remainder = appIndex.ranking % 10;
      switch (remainder) {
        case 1: {
          return `${appIndex.ranking}st`;
        }
        case 2: {
          return `${appIndex.ranking}nd`;
        }
        case 3: {
          return `${appIndex.ranking}rd`;
        }
        default: {
          return `${appIndex.ranking}th`;
        }
      }
    } else {
      return 'No ranking';
    }
  }