import { EconomicCalculationModalDataType } from '@/models/swine/economic-calculation.model';
import { EconomicCalculationType } from '@/utils/constants';
import {
  blue,
  darkGrey,
  lightGrey,
  pink,
  red,
} from '@/utils/download-pdf/colors';
import { addCommas } from '@/utils/formats';
import { imgElancoLogo } from '@/utils/download-pdf/images';

class PleurisyEconomicLoss {
  transferredModalData: EconomicCalculationModalDataType;
  currentCurrencyDetails: any;

  constructor(
    transferredModalData: EconomicCalculationModalDataType,
    currentCurrencyDetails?: any
  ) {
    this.transferredModalData = transferredModalData;
    this.currentCurrencyDetails = currentCurrencyDetails;
  }

  // This is important as Standard and Malaysia uses this to perform their calculation
  get numberOfAnimalHeads(): number {
    // Malaysia Calculation
    if (
      'numberOfAnimalHeads' in this.transferredModalData &&
      this.transferredModalData.economicCalculationType ===
        EconomicCalculationType.pleurisy
    ) {
      return this.transferredModalData.numberOfAnimalHeads
        ? this.transferredModalData.numberOfAnimalHeads
        : 0;
    }

    // Standard Calculation
    if (
      'numberOfGrowerPigs' in this.transferredModalData &&
      this.transferredModalData.economicCalculationType ===
        EconomicCalculationType.pulmotil
    ) {
      return this.transferredModalData.numberOfGrowerPigs;
    }

    return 0;
  }

  get pleurisyPrevalenceBackValue() {
    if (this.transferredModalData.appPleurisyPrevalence) {
      return (
        (this.transferredModalData.appPleurisyPrevalence *
          this.numberOfAnimalHeads) /
        100
      );
    }
    return 0;
  }

  get p3P4BackValue() {
    if (this.transferredModalData.p3AndP4Prevalence) {
      return (
        (Number(this.transferredModalData.p3AndP4Prevalence.toFixed(2)) *
          this.numberOfAnimalHeads) /
        100
      );
    }
    return 0;
  }

  get weaningMortalityPercentage() {
    if (this.transferredModalData.postWeaningMortality) {
      return Number(this.transferredModalData.postWeaningMortality);
    } else {
      return (this.transferredModalData.postWeaningMortality = 3.3);
    }
  }

  get weaningMortalityHeads() {
    return (this.weaningMortalityPercentage * this.numberOfAnimalHeads) / 100;
  }

  get totalCostPostWeaning() {
    if (
      this.transferredModalData.liveWeightPrice &&
      this.transferredModalData.targetMarketWeight
    ) {
      return (
        this.weaningMortalityHeads *
        this.transferredModalData.liveWeightPrice *
        this.transferredModalData.targetMarketWeight
      );
    }
    return 0;
  }

  get effectADGPigs() {
    if (
      this.transferredModalData.p3AndP4Prevalence &&
      this.transferredModalData.weanMarketDays
    ) {
      return (
        (Number(this.transferredModalData.p3AndP4Prevalence.toFixed(2)) *
          2 *
          this.transferredModalData.weanMarketDays) /
        1000
      );
    }
    return 0;
  }

  get totalCostADGPigs() {
    if (this.transferredModalData.liveWeightPrice) {
      return (
        this.p3P4BackValue *
        this.effectADGPigs *
        this.transferredModalData.liveWeightPrice
      );
    }
    return 0;
  }

  get effectOnSlaughterWeight() {
    if (this.transferredModalData.p3AndP4Prevalence) {
      return (
        Number(this.transferredModalData.p3AndP4Prevalence.toFixed(2)) * 0.07
      );
    }
    return 0;
  }

  get totalEffectOnSlaughterWeight() {
    if (this.transferredModalData.liveWeightPrice) {
      return (
        this.p3P4BackValue *
        this.effectOnSlaughterWeight *
        this.transferredModalData.liveWeightPrice
      );
    }
    return 0;
  }

  get effectOnSlaughterAge() {
    if (this.transferredModalData.p3AndP4Prevalence) {
      return (
        Number(this.transferredModalData.p3AndP4Prevalence.toFixed(2)) * 0.26
      );
    }
    return 0;
  }

  get totalEffectSlaughterAge() {
    if (this.transferredModalData.averageFeedPrice) {
      return (
        this.p3P4BackValue *
        this.effectOnSlaughterAge *
        2 *
        this.transferredModalData.averageFeedPrice
      );
    }
    return 0;
  }

  get totalCostOfAPP() {
    return (
      this.totalCostPostWeaning +
      this.totalCostADGPigs +
      this.totalEffectOnSlaughterWeight +
      this.totalEffectSlaughterAge
    );
  }

  get sectionCardDetailsPleurisy() {
    return [
      {
        headerText: `Cost of Pleurisy /pig`,
        subText: ``,
        value: `${
          this.currentCurrencyDetails.currentSelectedCurrency.text
        } ${this.addComma(
          (this.totalCostOfAPP / this.numberOfAnimalHeads).toFixed(2)
        )}`,
      },
      {
        headerText: `Total Opportunity Loss due to Pleurisy (${this.numberOfAnimalHeads} heads)`,
        subText: ``,
        value: `${
          this.currentCurrencyDetails.currentSelectedCurrency.text
        } ${this.addComma(this.totalCostOfAPP.toFixed(2))}`,
      },
    ];
  }

  // For number of piglets weaned
  get numberOfPigletsWeaned() {
    // Malaysia Calculation
    if (
      'numberOfAnimalHeads' in this.transferredModalData &&
      this.transferredModalData.economicCalculationType ===
        EconomicCalculationType.pleurisy
    ) {
      return this.transferredModalData.numberOfAnimalHeads
        ? this.transferredModalData.numberOfAnimalHeads
        : 0;
    }

    // Standard Calculation
    if (
      'numberOfGrowerPigs' in this.transferredModalData &&
      this.transferredModalData.economicCalculationType ===
        EconomicCalculationType.pulmotil
    ) {
      return this.transferredModalData.numberOfPigletsAtNursery;
    }

    return 0;
  }

  addComma(targetNumber: string) {
    return addCommas(targetNumber);
  }

  getPleurisyEconomicLoss() {
    // Generate Left column items
    let leftColumnContent = [
      [
        {
          text: '# of Piglets Weaned (heads)',
          bold: true,
          fillColor: `${lightGrey}`,
          border: [false, false, false, true],
        },
        {
          text: `${this.addComma(this.numberOfPigletsWeaned.toFixed(0))}`,
          border: [false, false, false, true],
        },
      ],
      [
        {
          text: `Live Weight Price /kg (${this.currentCurrencyDetails.currentSelectedCurrency.text})`,
          bold: true,
          fillColor: `${lightGrey}`,
          border: [false, false, false, true],
        },
        {
          text: `${this.addComma(
            Number(this.transferredModalData.liveWeightPrice).toFixed(2)
          )}`,
          fillColor: `${pink}`,
          border: [false, false, false, true],
        },
      ],
      [
        {
          text: 'Target Market Wt (kg)',
          bold: true,
          fillColor: `${lightGrey}`,
          border: [false, false, false, true],
        },
        {
          text: this.transferredModalData.targetMarketWeight
            ? `${this.addComma(
                this.transferredModalData.targetMarketWeight.toString()
              )}`
            : '-',
          fillColor: `${pink}`,
          border: [false, false, false, true],
        },
      ],
      [
        {
          text: `Age of Weaning (${this.transferredModalData.ageOfWeaning} days) Wean to Market (${this.transferredModalData.weanMarketDays} days) ~ ${this.transferredModalData.weekNumber} weeks`,
          bold: true,
          fillColor: `${lightGrey}`,
          border: [false, false, false, true],
        },
        {
          text: this.transferredModalData.weanMarketDays
            ? `${this.addComma(
                this.transferredModalData.weanMarketDays.toString()
              )}`
            : '-',
          border: [false, false, false, true],
        },
      ],
      [
        {
          text: `Avg Feed Price /kg (${this.currentCurrencyDetails.currentSelectedCurrency.text})`,
          bold: true,
          fillColor: `${lightGrey}`,
          border: [false, false, false, true],
        },
        {
          text: `${this.addComma(
            Number(this.transferredModalData.averageFeedPrice).toFixed(2)
          )}`,
          fillColor: `${pink}`,
          border: [false, false, false, true],
        },
      ],
    ];

    if (
      this.transferredModalData.economicCalculationType ===
      EconomicCalculationType.pulmotil
    ) {
      const newRows = [
        [
          {
            text: `Post Weaning Mortality (%)`,
            bold: true,
            fillColor: `${lightGrey}`,
            border: [false, false, false, true],
          },
          {
            text:
              'nurseryMortality' in this.transferredModalData &&
              this.transferredModalData.nurseryMortality
                ? `${this.addComma(
                    this.transferredModalData.nurseryMortality.toString()
                  )}`
                : '-',
            border: [false, false, false, true],
          },
        ],
        [
          {
            text: `No. of Grower Pigs`,
            bold: true,
            fillColor: `${lightGrey}`,
            border: [false, false, false, true],
          },
          {
            text: `${this.addComma(this.numberOfAnimalHeads.toFixed(0))}`,
            border: [false, false, false, true],
          },
        ],
      ];
      leftColumnContent = leftColumnContent.concat(newRows);
    }

    const content = [
      {
        svg: imgElancoLogo,
        width: 100,
        margin: [0, 0, 0, -30],
        border: [false, false, false, false],
      },
      {
        text: 'Economic Calculation (Pleurisy Economic Loss)',
        style: 'sectionHeader',
      },
      {
        style: ['lightGreyBg', 'fontSize8'],
        table: {
          widths: ['auto', 'auto', '*'],
          body: [
            [
              {
                text: 'Current Exchange Rate:',
                color: `${darkGrey}`,
                border: [false, false, false, false],
                margin: 5,
              },
              {
                text: `${this.currentCurrencyDetails.baseCurrency.text} 1 = ${
                  this.currentCurrencyDetails.currentSelectedCurrency.text
                } ${
                  this.currentCurrencyDetails.currentSelectedCurrency.value /
                  this.currentCurrencyDetails.baseCurrency.value
                }`,
                color: `${blue}`,
                border: [false, false, false, false],
                margin: 5,
              },
              {
                text: `${this.currentCurrencyDetails.currentSelectedCurrency.text}`,
                color: `${darkGrey}`,
                alignment: 'right',
                border: [false, false, false, false],
                margin: 5,
              },
            ],
          ],
        },
        marginBottom: 5,
      },
      {
        style: ['lightGreyBg', 'textCenter'],
        table: {
          widths: ['*', '*'],
          body: [
            [
              {
                stack: [
                  {
                    text: 'Cost of Pleurisy /pig',
                    color: `${darkGrey}`,
                    fontSize: 8,
                    marginTop: 5,
                  },
                  {
                    text: `${this.sectionCardDetailsPleurisy[0].value}`,
                    color: `${red}`,
                    fontSize: 20,
                    bold: true,
                    marginBottom: 5,
                  },
                ],
                border: [false, false, true, false],
              },
              {
                stack: [
                  {
                    text: `Total Opportunity Loss due to Pleurisy 
                      (${this.numberOfAnimalHeads.toFixed(0)} heads)`,
                    color: `${darkGrey}`,
                    fontSize: 8,
                    marginTop: 5,
                  },
                  {
                    text: `${this.sectionCardDetailsPleurisy[1].value}`,
                    color: `${red}`,
                    fontSize: 20,
                    bold: true,
                    marginBottom: 5,
                  },
                ],
                border: [false, false, false, false],
              },
            ],
          ],
        },
        layout: 'allGreyBorder',
        marginBottom: 5,
      },
      {
        columns: [
          {
            style: ['fontSize8'],
            table: {
              widths: ['*', '*'],
              body: leftColumnContent,
            },
            layout: 'allGreyBorder',
          },
          {
            style: ['fontSize8'],
            table: {
              widths: ['*', '*', '*'],
              body: [
                [
                  {
                    text: 'Pleurisy (% Prevalence)',
                    bold: true,
                    fillColor: `${lightGrey}`,
                    border: [false, false, false, true],
                  },
                  {
                    text: this.transferredModalData.appPleurisyPrevalence
                      ? `${this.addComma(
                          this.transferredModalData.appPleurisyPrevalence.toFixed(
                            2
                          )
                        )}`
                      : '-',
                    border: [false, false, false, true],
                  },
                  {
                    text: `${this.addComma(
                      this.pleurisyPrevalenceBackValue.toFixed(0)
                    )} heads`,
                    color: `${darkGrey}`,
                    border: [false, false, false, true],
                  },
                ],
                [
                  {
                    text: 'P3 and P4 * (% Prevalence)',
                    bold: true,
                    fillColor: `${lightGrey}`,
                    border: [false, false, false, true],
                  },
                  {
                    text: this.transferredModalData.p3AndP4Prevalence
                      ? `${this.addComma(
                          this.transferredModalData.p3AndP4Prevalence.toFixed(2)
                        )}`
                      : '-',
                    border: [false, false, false, true],
                  },
                  {
                    text: `${this.addComma(
                      this.p3P4BackValue.toFixed(0)
                    )} heads`,
                    color: `${darkGrey}`,
                    border: [false, false, false, true],
                  },
                ],
                [
                  {
                    text: '',
                    border: [false, false, false, false],
                  },
                  {
                    text: '',
                    border: [false, false, false, false],
                  },
                  {
                    text: '',
                    border: [false, false, false, false],
                  },
                ],
                [
                  {
                    text: 'Effect on Post Weaning Mortality',
                    bold: true,
                    fillColor: `${lightGrey}`,
                    border: [false, false, false, true],
                  },
                  {
                    text: `${this.addComma(
                      this.weaningMortalityHeads.toFixed(0)
                    )}`,
                    border: [false, false, false, true],
                  },
                  {
                    text: `heads`,
                    color: `${darkGrey}`,
                    border: [false, false, false, true],
                  },
                ],
                [
                  {
                    text: '% Increase in Post Weaning Mortality',
                    bold: true,
                    fillColor: `${lightGrey}`,
                    border: [false, false, false, true],
                  },
                  {
                    text: `${this.addComma(
                      this.weaningMortalityPercentage.toFixed(2)
                    )}`,
                    fillColor: `${pink}`,
                    border: [false, false, false, true],
                  },
                  {
                    text: `heads`,
                    color: `${darkGrey}`,
                    border: [false, false, false, true],
                  },
                ],
                [
                  {
                    text: `Total Cost (${this.currentCurrencyDetails.currentSelectedCurrency.text})`,
                    bold: true,
                    fillColor: `${lightGrey}`,
                    border: [false, false, false, true],
                  },
                  {
                    text: `${this.addComma(
                      this.totalCostPostWeaning.toFixed(2)
                    )}`,
                    border: [false, false, false, true],
                  },
                  {
                    text: ``,
                    border: [false, false, false, false],
                  },
                ],
                [
                  {
                    text: '',
                    border: [false, false, false, false],
                  },
                  {
                    text: '',
                    border: [false, false, false, false],
                  },
                  {
                    text: '',
                    border: [false, false, false, false],
                  },
                ],
                [
                  {
                    text: 'Effect on ADG on Pigs with Pleurisy (Lifetime)',
                    bold: true,
                    fillColor: `${lightGrey}`,
                    border: [false, false, false, true],
                  },
                  {
                    text: `${this.addComma(this.effectADGPigs.toFixed(2))}`,
                    border: [false, false, false, true],
                  },
                  {
                    text: `kg`,
                    color: `${darkGrey}`,
                    border: [false, false, false, true],
                  },
                ],
                [
                  {
                    text: `Total Cost (${this.currentCurrencyDetails.currentSelectedCurrency.text})`,
                    bold: true,
                    fillColor: `${lightGrey}`,
                    border: [false, false, false, true],
                  },
                  {
                    text: `${this.addComma(this.totalCostADGPigs.toFixed(2))}`,
                    border: [false, false, false, true],
                  },
                  {
                    text: `kg`,
                    color: `${darkGrey}`,
                    border: [false, false, false, true],
                  },
                ],
                [
                  {
                    text: '',
                    border: [false, false, false, false],
                  },
                  {
                    text: '',
                    border: [false, false, false, false],
                  },
                  {
                    text: '',
                    border: [false, false, false, false],
                  },
                ],
                [
                  {
                    text: 'Effect on Slaughter Weight on Pigs with Pleurisy',
                    bold: true,
                    fillColor: `${lightGrey}`,
                    border: [false, false, false, true],
                  },
                  {
                    text: `${this.addComma(
                      this.effectOnSlaughterWeight.toFixed(2)
                    )}`,
                    border: [false, false, false, true],
                  },
                  {
                    text: `kg`,
                    color: `${darkGrey}`,
                    border: [false, false, false, true],
                  },
                ],
                [
                  {
                    text: `Total Cost (${this.currentCurrencyDetails.currentSelectedCurrency.text})`,
                    bold: true,
                    fillColor: `${lightGrey}`,
                    border: [false, false, false, true],
                  },
                  {
                    text: `${this.addComma(
                      this.totalEffectOnSlaughterWeight.toFixed(2)
                    )}`,
                    border: [false, false, false, true],
                  },
                  {
                    text: `kg`,
                    color: `${darkGrey}`,
                    border: [false, false, false, true],
                  },
                ],
                [
                  {
                    text: '',
                    border: [false, false, false, false],
                  },
                  {
                    text: '',
                    border: [false, false, false, false],
                  },
                  {
                    text: '',
                    border: [false, false, false, false],
                  },
                ],
                [
                  {
                    text: 'Effect of Slaughter Age on Pigs with Pleurisy',
                    bold: true,
                    fillColor: `${lightGrey}`,
                    border: [false, false, false, true],
                  },
                  {
                    text: `${this.addComma(
                      this.effectOnSlaughterAge.toFixed(2)
                    )}`,
                    border: [false, false, false, true],
                  },
                  {
                    text: `kg`,
                    color: `${darkGrey}`,
                    border: [false, false, false, true],
                  },
                ],
                [
                  {
                    text: `Total Cost (${this.currentCurrencyDetails.currentSelectedCurrency.text})`,
                    bold: true,
                    fillColor: `${lightGrey}`,
                    border: [false, false, false, true],
                  },
                  {
                    text: `${this.addComma(
                      this.totalEffectSlaughterAge.toFixed(2)
                    )}`,
                    border: [false, false, false, true],
                  },
                  {
                    text: `kg DFI`,
                    color: `${darkGrey}`,
                    border: [false, false, false, true],
                  },
                ],
              ],
            },
            layout: 'allGreyBorder',
          },
        ],
        columnGap: 15,
        pageBreak: 'after',
      },
    ];

    return content;
  }
}

export { PleurisyEconomicLoss };
