import {
  EconomicCalculationType,
  ReferenceTypeAdgReductionPercentages,
} from '@/utils/constants';
import {
  blue,
  brown,
  cream,
  darkGrey,
  darkPink,
  green,
  lightGreen,
  lightGrey,
  limeGreen,
  orange,
  pink,
  red,
  yellow,
} from '@/utils/download-pdf/colors';
import { addCommas, addDecimalPointToNumbers } from '@/utils/formats';
import {
  EconomicCalculationModalDataType,
  ReferenceType,
} from '@/models/swine/economic-calculation.model';
import { imgElancoLogo } from '@/utils/download-pdf/images';

class BronchopneumoniaEconomicLoss {
  transferredModalData: EconomicCalculationModalDataType;
  currentCurrencyDetails: any;

  constructor(
    transferredModalData: EconomicCalculationModalDataType,
    currentCurrencyDetails?: any
  ) {
    this.transferredModalData = transferredModalData;
    this.currentCurrencyDetails = currentCurrencyDetails;
  }

  // This is important as Standard and Malaysia uses this to perform their calculation
  get numberOfAnimalHeads(): number {
    // Malaysia Calculation
    if (
      'numberOfAnimalHeads' in this.transferredModalData &&
      this.transferredModalData.economicCalculationType ===
        EconomicCalculationType.pleurisy
    ) {
      return this.transferredModalData.numberOfAnimalHeads
        ? this.transferredModalData.numberOfAnimalHeads
        : 0;
    }

    // Standard Calculation
    if (
      'numberOfGrowerPigs' in this.transferredModalData &&
      this.transferredModalData.economicCalculationType ===
        EconomicCalculationType.pulmotil
    ) {
      return this.transferredModalData.numberOfGrowerPigs;
    }

    return 0;
  }

  get adgReductionPercentages() {
    if (this.transferredModalData.referenceType) {
      return ReferenceTypeAdgReductionPercentages[
        this.transferredModalData.referenceType
      ];
    }
    return ReferenceTypeAdgReductionPercentages[ReferenceType.E_STERVERMER];
  }

  get adgFormula() {
    return this.adgReductionPercentages.map((adgPercentage) => {
      if (this.transferredModalData.adgValue) {
        return (
          this.transferredModalData.adgValue -
          this.transferredModalData.adgValue * adgPercentage
        );
      }
      return 0;
    });
  }

  firstFinalWeight(adgValueSet: number | null, weanToMarket: number | null) {
    if (adgValueSet && weanToMarket) {
      return (adgValueSet * weanToMarket) / 1000;
    }

    return 0;
  }

  get finalWeight() {
    return this.adgFormula.map((adg) => {
      if (this.transferredModalData.weanMarketDays) {
        return (adg * this.transferredModalData.weanMarketDays) / 1000;
      }
      return 0;
    });
  }

  get animalPercentages() {
    if (this.transferredModalData.pneumoniaSeverityDict) {
      return Object.values(this.transferredModalData.pneumoniaSeverityDict).map(
        (number) => {
          try {
            return Number((number / this.animalTotal).toFixed(4));
          } catch {
            return 0;
          }
        }
      );
    }
    return [];
  }

  get animalTotal() {
    function reducer(total: number, value: number) {
      return total + value;
    }
    if (this.transferredModalData.pneumoniaSeverityDict) {
      return Object.values(this.transferredModalData.pneumoniaSeverityDict)
        .slice(0, 7)
        .reduce(reducer);
    }
    return 0;
  }

  get animalNumberSold() {
    return this.animalPercentages.map((percentage) => {
      return this.numberOfAnimalHeads * percentage;
    });
  }

  get daysToReach() {
    return this.adgFormula.map((adg) => {
      if (
        this.transferredModalData.adgValue &&
        this.transferredModalData.weanMarketDays
      ) {
        return (
          (this.firstFinalWeight(
            this.transferredModalData.adgValue,
            this.transferredModalData.weanMarketDays
          ) /
            adg) *
          1000
        );
      }
      return 0;
    });
  }

  get extraDaysToReach() {
    return this.daysToReach.map((days) => {
      try {
        return days - this.daysToReach[0];
      } catch {
        return 0;
      }
    });
  }

  get extraFeedConsumed() {
    return this.extraDaysToReach.map((extraDay) => {
      if (this.transferredModalData.adfiValue) {
        return extraDay * this.transferredModalData.adfiValue;
      }
      return 0;
    });
  }

  get additionalFeedCost() {
    return this.extraFeedConsumed.map((extraFeed) => {
      if (this.transferredModalData.additionalFeedCostPigRate) {
        return extraFeed * this.transferredModalData.additionalFeedCostPigRate;
      }
      return 0;
    });
  }

  get additionalFeedCostPer1000() {
    return this.additionalFeedCost.map((addFeedCost, index) => {
      return addFeedCost * this.animalNumberSold[index];
    });
  }

  get totalAdditionalFeedCostPer1000() {
    function reducer(total: number, value: number) {
      return total + value;
    }
    return this.additionalFeedCostPer1000.reduce(reducer);
  }

  get additionalFeedCostPig() {
    try {
      return this.totalAdditionalFeedCostPer1000 / this.numberOfAnimalHeads;
    } catch {
      return 0;
    }
  }

  get additionalFeedPig() {
    if (this.transferredModalData.additionalFeedCostPigRate) {
      return (
        this.additionalFeedCostPig /
        this.transferredModalData.additionalFeedCostPigRate
      );
    }
    return 0;
  }

  get additionalFeedCostThousand() {
    return this.totalAdditionalFeedCostPer1000;
  }

  get additionalFeedCostYear() {
    return this.totalAdditionalFeedCostPer1000 * 12;
  }

  get additionalFeedCostPerXHeads() {
    return this.numberOfAnimalHeads * this.additionalFeedCostPig;
  }

  addComma(targetNumber: string) {
    return addCommas(targetNumber);
  }

  getPleurisyBronchopneumoniaEconomicLoss() {
    const content = [
      {
        svg: imgElancoLogo,
        width: 100,
        margin: [0, 0, 0, -30],
        border: [false, false, false, false],
      },
      {
        text: 'Economic Calculation (Bronchopneumonia Economic Loss)',
        style: 'sectionHeader',
      },
      {
        style: ['lightGreyBg', 'fontSize8'],
        table: {
          widths: ['auto', 'auto', '*'],
          body: [
            [
              {
                text: 'Current Exchange Rate:',
                color: `${darkGrey}`,
                border: [false, false, false, false],
                margin: 5,
              },
              {
                text: `${this.currentCurrencyDetails.baseCurrency.text} 1 = ${
                  this.currentCurrencyDetails.currentSelectedCurrency.text
                } ${
                  this.currentCurrencyDetails.currentSelectedCurrency.value /
                  this.currentCurrencyDetails.baseCurrency.value
                }`,
                color: `${blue}`,
                border: [false, false, false, false],
                margin: 5,
              },
              {
                text: `${this.currentCurrencyDetails.currentSelectedCurrency.text}`,
                color: `${darkGrey}`,
                alignment: 'right',
                border: [false, false, false, false],
                margin: 5,
              },
            ],
          ],
        },
        marginBottom: 5,
      },
      {
        table: {
          widths: ['*', '*'],
          body: [
            [
              {
                text: 'Reference Type:',
                color: `${darkGrey}`,
                alignment: 'right',
                border: [false, false, false, false],
              },
              {
                text: `${this.transferredModalData.referenceType}`,
                border: [false, false, false, false],
              },
            ],
          ],
        },
        marginBottom: 5,
      },
      {
        style: ['lightGreyBg', 'textCenter'],
        table: {
          widths: ['*', '*', '*', '*'],
          body: [
            [
              {
                stack: [
                  {
                    text: 'Additional Feed /pig',
                    color: `${darkGrey}`,
                    fontSize: 8,
                    marginTop: 5,
                  },
                  {
                    text: `${this.additionalFeedPig.toFixed(2)} kg`,
                    color: `${red}`,
                    fontSize: 20,
                    bold: true,
                    marginBottom: 5,
                  },
                ],
                border: [false, false, true, false],
              },
              {
                stack: [
                  {
                    text: 'Additional Feed /pig',
                    color: `${darkGrey}`,
                    fontSize: 8,
                    marginTop: 5,
                  },
                  {
                    text: `${
                      this.currentCurrencyDetails.currentSelectedCurrency.text
                    } ${this.additionalFeedCostPig.toFixed(2)}`,
                    color: `${red}`,
                    fontSize: 20,
                    bold: true,
                    marginBottom: 5,
                  },
                ],
                border: [false, false, true, false],
              },
              {
                stack: [
                  {
                    text: `Additional Feed Cost /
                      ${this.numberOfAnimalHeads.toFixed(0)} heads`,
                    color: `${darkGrey}`,
                    fontSize: 8,
                    marginTop: 5,
                  },
                  {
                    text: `${
                      this.currentCurrencyDetails.currentSelectedCurrency.text
                    } ${this.totalAdditionalFeedCostPer1000.toFixed(2)}`,
                    color: `${red}`,
                    fontSize: 20,
                    bold: true,
                    marginBottom: 5,
                  },
                ],
                border: [false, false, true, false],
              },
              {
                stack: [
                  {
                    text: 'Additional Feed Cost /year',
                    color: `${darkGrey}`,
                    fontSize: 8,
                    marginTop: 5,
                  },
                  {
                    text: `${
                      this.currentCurrencyDetails.currentSelectedCurrency.text
                    } ${this.addComma(this.additionalFeedCostYear.toFixed(2))}`,
                    color: `${red}`,
                    fontSize: 20,
                    bold: true,
                    marginBottom: 5,
                  },
                ],
                border: [false, false, false, false],
              },
            ],
          ],
        },
        layout: 'allGreyBorder',
        marginBottom: 5,
      },
      {
        style: ['fontSize8', 'textCenter'],
        table: {
          widths: '*',
          body: [
            [
              {
                text: 'Parameters',
                bold: true,
                border: [false, false, false, false],
                rowSpan: 2,
                fillColor: `${lightGrey}`,
              },
              {
                text: 'Feed Cost Opportunity Loss',
                bold: true,
                border: [false, false, true, false],
                alignment: 'center',
                colSpan: 8,
                fillColor: `${lightGrey}`,
              },
              {
                text: '',
              },
              {
                text: '',
              },
              {
                text: '',
              },
              {
                text: '',
              },
              {
                text: '',
              },
              {
                text: '',
              },
              {
                text: '',
              },
            ],
            [
              {
                text: '',
                border: [false, false, false, false],
                fillColor: `${lightGrey}`,
              },
              {
                text: 'Normal Lung',
                bold: true,
                fillColor: `${limeGreen}`,
                color: `${green}`,
                alignment: 'center',
                border: [false, false, false, true],
                colSpan: 2,
              },
              {
                text: '',
              },
              {
                text: 'Pneumonia',
                bold: true,
                fillColor: `${yellow}`,
                color: `${brown}`,
                alignment: 'center',
                border: [false, false, false, true],
                colSpan: 3,
              },
              {
                text: '',
              },
              {
                text: '',
              },
              {
                text: 'Severe Pneumonia',
                bold: true,
                fillColor: `${pink}`,
                color: `${red}`,
                alignment: 'center',
                border: [false, false, false, true],
                colSpan: 2,
              },
              {
                text: '',
              },
              {
                text: 'Total',
                bold: true,
                alignment: 'center',
                border: [false, false, false, true],
                fillColor: `${lightGrey}`,
              },
            ],
            [
              {
                text: '% of Lung Affected',
                fillColor: `${lightGrey}`,
                border: [false, false, false, true],
              },
              {
                text: `0%`,
                fillColor: `${limeGreen}`,
                color: `${brown}`,
                border: [false, false, false, true],
              },
              {
                text: `1-10%`,
                fillColor: `${lightGreen}`,
                color: `${brown}`,
                border: [false, false, false, true],
              },
              {
                text: `11-20%`,
                fillColor: `${cream}`,
                color: `${brown}`,
                border: [false, false, false, true],
              },
              {
                text: `21-30%`,
                fillColor: `${yellow}`,
                color: `${brown}`,
                border: [false, false, false, true],
              },
              {
                text: `31-40%`,
                fillColor: `${orange}`,
                color: `${brown}`,
                border: [false, false, false, true],
              },
              {
                text: `41-50%`,
                fillColor: `${pink}`,
                color: `${red}`,
                border: [false, false, false, true],
              },
              {
                text: `>50%`,
                fillColor: `${darkPink}`,
                color: `${red}`,
                border: [false, false, false, true],
              },
              {
                text: '',
                fillColor: `${lightGrey}`,
                border: [false, false, false, true],
              },
            ],
            [
              {
                text: '% ADG Reduction',
                fillColor: `${lightGrey}`,
                border: [false, false, false, true],
              },
              {
                text: `${addDecimalPointToNumbers(
                  this.adgReductionPercentages[0] * 100
                )}%`,
                fillColor: `${limeGreen}`,
                color: `${brown}`,
                border: [false, false, false, true],
              },
              {
                text: `${addDecimalPointToNumbers(
                  this.adgReductionPercentages[1] * 100
                )}%`,
                fillColor: `${lightGreen}`,
                color: `${brown}`,
                border: [false, false, false, true],
              },
              {
                text: `${addDecimalPointToNumbers(
                  this.adgReductionPercentages[2] * 100
                )}%`,
                fillColor: `${cream}`,
                color: `${brown}`,
                border: [false, false, false, true],
              },
              {
                text: `${addDecimalPointToNumbers(
                  this.adgReductionPercentages[3] * 100
                )}%`,
                fillColor: `${yellow}`,
                color: `${brown}`,
                border: [false, false, false, true],
              },
              {
                text: `${addDecimalPointToNumbers(
                  this.adgReductionPercentages[4] * 100
                )}%`,
                fillColor: `${orange}`,
                color: `${brown}`,
                border: [false, false, false, true],
              },
              {
                text: `${addDecimalPointToNumbers(
                  this.adgReductionPercentages[5] * 100
                )}%`,
                fillColor: `${pink}`,
                color: `${red}`,
                border: [false, false, false, true],
              },
              {
                text: `${addDecimalPointToNumbers(
                  this.adgReductionPercentages[6] * 100
                )}%`,
                fillColor: `${darkPink}`,
                color: `${red}`,
                border: [false, false, false, true],
              },
              {
                text: '',
                fillColor: `${lightGrey}`,
                border: [false, false, false, true],
              },
            ],
            [
              {
                text: 'ADG (g)',
                fillColor: `${lightGrey}`,
                border: [false, false, false, true],
              },
              {
                text: `${
                  this.transferredModalData.adgValue
                    ? this.addComma(
                        this.transferredModalData.adgValue.toString()
                      )
                    : '-'
                }`,
                fillColor: `${pink}`,
                color: `${brown}`,
                border: [false, false, false, true],
              },
              {
                text: `${this.addComma(this.adgFormula[1].toFixed(0))}`,
                fillColor: `${lightGreen}`,
                color: `${brown}`,
                border: [false, false, false, true],
              },
              {
                text: `${this.addComma(this.adgFormula[2].toFixed(0))}`,
                fillColor: `${cream}`,
                color: `${brown}`,
                border: [false, false, false, true],
              },
              {
                text: `${this.addComma(this.adgFormula[3].toFixed(0))}`,
                fillColor: `${yellow}`,
                color: `${brown}`,
                border: [false, false, false, true],
              },
              {
                text: `${this.addComma(this.adgFormula[4].toFixed(0))}`,
                fillColor: `${orange}`,
                color: `${brown}`,
                border: [false, false, false, true],
              },
              {
                text: `${this.addComma(this.adgFormula[5].toFixed(0))}`,
                fillColor: `${pink}`,
                color: `${red}`,
                border: [false, false, false, true],
              },
              {
                text: `${this.addComma(this.adgFormula[6].toFixed(0))}`,
                fillColor: `${darkPink}`,
                color: `${red}`,
                border: [false, false, false, true],
              },
              {
                text: '',
                fillColor: `${lightGrey}`,
                border: [false, false, false, true],
              },
            ],
            [
              {
                text: `Age of Weaning (${this.transferredModalData.ageOfWeaning} days) Wean to Market (${this.transferredModalData.weanMarketDays} days) ~ ${this.transferredModalData.weekNumber} weeks`,
                fillColor: `${lightGrey}`,
                border: [false, false, false, true],
              },
              {
                text: this.transferredModalData.weanMarketDays
                  ? `${this.addComma(
                      this.transferredModalData.weanMarketDays.toString()
                    )}`
                  : '-',
                fillColor: `${pink}`,
                color: `${brown}`,
                border: [false, false, false, true],
              },
              {
                text: this.transferredModalData.weanMarketDays
                  ? `${this.addComma(
                      this.transferredModalData.weanMarketDays.toString()
                    )}`
                  : '-',
                fillColor: `${lightGreen}`,
                color: `${brown}`,
                border: [false, false, false, true],
              },
              {
                text: this.transferredModalData.weanMarketDays
                  ? `${this.addComma(
                      this.transferredModalData.weanMarketDays.toString()
                    )}`
                  : '-',
                fillColor: `${cream}`,
                color: `${brown}`,
                border: [false, false, false, true],
              },
              {
                text: this.transferredModalData.weanMarketDays
                  ? `${this.addComma(
                      this.transferredModalData.weanMarketDays.toString()
                    )}`
                  : '-',
                fillColor: `${yellow}`,
                color: `${brown}`,
                border: [false, false, false, true],
              },
              {
                text: this.transferredModalData.weanMarketDays
                  ? `${this.addComma(
                      this.transferredModalData.weanMarketDays.toString()
                    )}`
                  : '-',
                fillColor: `${orange}`,
                color: `${brown}`,
                border: [false, false, false, true],
              },
              {
                text: this.transferredModalData.weanMarketDays
                  ? `${this.addComma(
                      this.transferredModalData.weanMarketDays.toString()
                    )}`
                  : '-',
                fillColor: `${pink}`,
                color: `${red}`,
                border: [false, false, false, true],
              },
              {
                text: this.transferredModalData.weanMarketDays
                  ? `${this.addComma(
                      this.transferredModalData.weanMarketDays.toString()
                    )}`
                  : '-',
                fillColor: `${darkPink}`,
                color: `${red}`,
                border: [false, false, false, true],
              },
              {
                text: '',
                fillColor: `${lightGrey}`,
                border: [false, false, false, true],
              },
            ],
            [
              {
                text: 'Final Weight (kg)',
                fillColor: `${lightGrey}`,
                border: [false, false, false, true],
              },
              {
                text:
                  this.transferredModalData.adgValue &&
                  this.transferredModalData.weanMarketDays
                    ? `${this.addComma(
                        this.firstFinalWeight(
                          this.transferredModalData.adgValue,
                          this.transferredModalData.weanMarketDays
                        ).toFixed(1)
                      )}`
                    : '-',
                fillColor: `${limeGreen}`,
                color: `${brown}`,
                border: [false, false, false, true],
              },
              {
                text: `${this.addComma(this.finalWeight[1].toFixed(1))}`,
                fillColor: `${lightGreen}`,
                color: `${brown}`,
                border: [false, false, false, true],
              },
              {
                text: `${this.addComma(this.finalWeight[2].toFixed(1))}`,
                fillColor: `${cream}`,
                color: `${brown}`,
                border: [false, false, false, true],
              },
              {
                text: `${this.addComma(this.finalWeight[3].toFixed(1))}`,
                fillColor: `${yellow}`,
                color: `${brown}`,
                border: [false, false, false, true],
              },
              {
                text: `${this.addComma(this.finalWeight[4].toFixed(1))}`,
                fillColor: `${orange}`,
                color: `${brown}`,
                border: [false, false, false, true],
              },
              {
                text: `${this.addComma(this.finalWeight[5].toFixed(1))}`,
                fillColor: `${pink}`,
                color: `${red}`,
                border: [false, false, false, true],
              },
              {
                text: `${this.addComma(this.finalWeight[6].toFixed(1))}`,
                fillColor: `${darkPink}`,
                color: `${red}`,
                border: [false, false, false, true],
              },
              {
                text: '',
                fillColor: `${lightGrey}`,
                border: [false, false, false, true],
              },
            ],
            [
              {
                text: '',
                border: [false, false, false, false],
              },
              {
                text: '',
                border: [false, false, false, false],
              },
              {
                text: '',
                border: [false, false, false, false],
              },
              {
                text: '',
                border: [false, false, false, false],
              },
              {
                text: '',
                border: [false, false, false, false],
              },
              {
                text: '',
                border: [false, false, false, false],
              },
              {
                text: '',
                border: [false, false, false, false],
              },
              {
                text: '',
                border: [false, false, false, false],
              },
              {
                text: '',
                border: [false, false, false, false],
              },
            ],
            [
              {
                text: '# of Animals',
                fillColor: `${lightGrey}`,
                border: [false, false, false, true],
              },
              {
                text: this.transferredModalData.pneumoniaSeverityDict
                  ? `${this.transferredModalData.pneumoniaSeverityDict[0]}`
                  : '-',
                fillColor: `${limeGreen}`,
                color: `${brown}`,
                border: [false, false, false, true],
              },
              {
                text: this.transferredModalData.pneumoniaSeverityDict
                  ? `${this.transferredModalData.pneumoniaSeverityDict[1]}`
                  : '-',
                fillColor: `${lightGreen}`,
                color: `${brown}`,
                border: [false, false, false, true],
              },
              {
                text: this.transferredModalData.pneumoniaSeverityDict
                  ? `${this.transferredModalData.pneumoniaSeverityDict[2]}`
                  : '-',
                fillColor: `${cream}`,
                color: `${brown}`,
                border: [false, false, false, true],
              },
              {
                text: this.transferredModalData.pneumoniaSeverityDict
                  ? `${this.transferredModalData.pneumoniaSeverityDict[3]}`
                  : '-',
                fillColor: `${yellow}`,
                color: `${brown}`,
                border: [false, false, false, true],
              },
              {
                text: this.transferredModalData.pneumoniaSeverityDict
                  ? `${this.transferredModalData.pneumoniaSeverityDict[4]}`
                  : '-',
                fillColor: `${orange}`,
                color: `${brown}`,
                border: [false, false, false, true],
              },
              {
                text: this.transferredModalData.pneumoniaSeverityDict
                  ? `${this.transferredModalData.pneumoniaSeverityDict[5]}`
                  : '-',
                fillColor: `${pink}`,
                color: `${red}`,
                border: [false, false, false, true],
              },
              {
                text: this.transferredModalData.pneumoniaSeverityDict
                  ? `${this.transferredModalData.pneumoniaSeverityDict[6]}`
                  : '-',
                fillColor: `${darkPink}`,
                color: `${red}`,
                border: [false, false, false, true],
              },
              {
                text: '',
                fillColor: `${lightGrey}`,
                border: [false, false, false, true],
              },
            ],
            [
              {
                text: '% of Animals',
                fillColor: `${lightGrey}`,
                border: [false, false, false, true],
              },
              {
                text: `${addDecimalPointToNumbers(
                  this.animalPercentages[0] * 100
                )}%`,
                fillColor: `${limeGreen}`,
                color: `${brown}`,
                border: [false, false, false, true],
              },
              {
                text: `${addDecimalPointToNumbers(
                  this.animalPercentages[1] * 100
                )}%`,
                fillColor: `${lightGreen}`,
                color: `${brown}`,
                border: [false, false, false, true],
              },
              {
                text: `${addDecimalPointToNumbers(
                  this.animalPercentages[2] * 100
                )}%`,
                fillColor: `${cream}`,
                color: `${brown}`,
                border: [false, false, false, true],
              },
              {
                text: `${addDecimalPointToNumbers(
                  this.animalPercentages[3] * 100
                )}%`,
                fillColor: `${yellow}`,
                color: `${brown}`,
                border: [false, false, false, true],
              },
              {
                text: `${addDecimalPointToNumbers(
                  this.animalPercentages[4] * 100
                )}%`,
                fillColor: `${orange}`,
                color: `${brown}`,
                border: [false, false, false, true],
              },
              {
                text: `${addDecimalPointToNumbers(
                  this.animalPercentages[5] * 100
                )}%`,
                fillColor: `${pink}`,
                color: `${red}`,
                border: [false, false, false, true],
              },
              {
                text: `${addDecimalPointToNumbers(
                  this.animalPercentages[6] * 100
                )}%`,
                fillColor: `${darkPink}`,
                color: `${red}`,
                border: [false, false, false, true],
              },
              {
                text: '',
                fillColor: `${lightGrey}`,
                border: [false, false, false, true],
              },
            ],
            [
              {
                text: '',
                border: [false, false, false, false],
              },
              {
                text: '',
                border: [false, false, false, false],
              },
              {
                text: '',
                border: [false, false, false, false],
              },
              {
                text: '',
                border: [false, false, false, false],
              },
              {
                text: '',
                border: [false, false, false, false],
              },
              {
                text: '',
                border: [false, false, false, false],
              },
              {
                text: '',
                border: [false, false, false, false],
              },
              {
                text: '',
                border: [false, false, false, false],
              },
              {
                text: '',
                border: [false, false, false, false],
              },
            ],
            [
              {
                text: 'Assumptions',
                fillColor: `${lightGrey}`,
                border: [false, false, false, false],
              },
              {
                text: '',
                border: [false, false, false, false],
              },
              {
                text: '',
                border: [false, false, false, false],
              },
              {
                text: '',
                border: [false, false, false, false],
              },
              {
                text: '',
                border: [false, false, false, false],
              },
              {
                text: '',
                border: [false, false, false, false],
              },
              {
                text: '',
                border: [false, false, false, false],
              },
              {
                text: '',
                border: [false, false, false, false],
              },
              {
                text: '',
                border: [false, false, false, false],
              },
            ],
            [
              {
                text: '# of Animals Sold /month (heads)',
                fillColor: `${lightGrey}`,
                border: [false, false, false, true],
              },
              {
                text: `${
                  this.animalNumberSold[0].toFixed(0) === '0'
                    ? '-'
                    : this.addComma(this.animalNumberSold[0].toFixed(0))
                }`,
                fillColor: `${limeGreen}`,
                color: `${brown}`,
                border: [false, false, false, true],
              },
              {
                text: `${
                  this.animalNumberSold[1].toFixed(0) === '0'
                    ? '-'
                    : this.addComma(this.animalNumberSold[1].toFixed(0))
                }`,
                fillColor: `${lightGreen}`,
                color: `${brown}`,
                border: [false, false, false, true],
              },
              {
                text: `${
                  this.animalNumberSold[2].toFixed(0) === '0'
                    ? '-'
                    : this.addComma(this.animalNumberSold[2].toFixed(0))
                }`,
                fillColor: `${cream}`,
                color: `${brown}`,
                border: [false, false, false, true],
              },
              {
                text: `${
                  this.animalNumberSold[3].toFixed(0) === '0'
                    ? '-'
                    : this.addComma(this.animalNumberSold[3].toFixed(0))
                }`,
                fillColor: `${yellow}`,
                color: `${brown}`,
                border: [false, false, false, true],
              },
              {
                text: `${
                  this.animalNumberSold[4].toFixed(0) === '0'
                    ? '-'
                    : this.addComma(this.animalNumberSold[4].toFixed(0))
                }`,
                fillColor: `${orange}`,
                color: `${brown}`,
                border: [false, false, false, true],
              },
              {
                text: `${
                  this.animalNumberSold[5].toFixed(0) === '0'
                    ? '-'
                    : this.addComma(this.animalNumberSold[5].toFixed(0))
                }`,
                fillColor: `${pink}`,
                color: `${red}`,
                border: [false, false, false, true],
              },
              {
                text: `${
                  this.animalNumberSold[6].toFixed(0) === '0'
                    ? '-'
                    : this.addComma(this.animalNumberSold[6].toFixed(0))
                }`,
                fillColor: `${darkPink}`,
                color: `${red}`,
                border: [false, false, false, true],
              },
              {
                text: `${this.addComma(this.numberOfAnimalHeads.toFixed(0))}`,
                fillColor: `${lightGrey}`,
                border: [false, false, false, true],
              },
            ],
            [
              {
                text: `Days to Reach ${this.firstFinalWeight(
                  this.transferredModalData.adgValue,
                  this.transferredModalData.weanMarketDays
                )} kg (d)`,
                fillColor: `${lightGrey}`,
                border: [false, false, false, true],
              },
              {
                text: `${this.addComma(this.daysToReach[0].toFixed(0))}`,
                fillColor: `${limeGreen}`,
                color: `${brown}`,
                border: [false, false, false, true],
              },
              {
                text: `${this.addComma(this.daysToReach[1].toFixed(0))}`,
                fillColor: `${lightGreen}`,
                color: `${brown}`,
                border: [false, false, false, true],
              },
              {
                text: `${this.addComma(this.daysToReach[2].toFixed(0))}`,
                fillColor: `${cream}`,
                color: `${brown}`,
                border: [false, false, false, true],
              },
              {
                text: `${this.addComma(this.daysToReach[3].toFixed(0))}`,
                fillColor: `${yellow}`,
                color: `${brown}`,
                border: [false, false, false, true],
              },
              {
                text: `${this.addComma(this.daysToReach[4].toFixed(0))}`,
                fillColor: `${orange}`,
                color: `${brown}`,
                border: [false, false, false, true],
              },
              {
                text: `${this.addComma(this.daysToReach[5].toFixed(0))}`,
                fillColor: `${pink}`,
                color: `${red}`,
                border: [false, false, false, true],
              },
              {
                text: `${this.addComma(this.daysToReach[6].toFixed(0))}`,
                fillColor: `${darkPink}`,
                color: `${red}`,
                border: [false, false, false, true],
              },
              {
                text: '',
                fillColor: `${lightGrey}`,
                border: [false, false, false, true],
              },
            ],
            [
              {
                text: `Extra Days to Reach ${this.firstFinalWeight(
                  this.transferredModalData.adgValue,
                  this.transferredModalData.weanMarketDays
                )} kg (d)`,
                fillColor: `${lightGrey}`,
                border: [false, false, false, true],
              },
              {
                text: `${this.addComma(this.extraDaysToReach[0].toFixed(0))}`,
                fillColor: `${limeGreen}`,
                color: `${brown}`,
                border: [false, false, false, true],
              },
              {
                text: `${this.addComma(this.extraDaysToReach[1].toFixed(0))}`,
                fillColor: `${lightGreen}`,
                color: `${brown}`,
                border: [false, false, false, true],
              },
              {
                text: `${this.addComma(this.extraDaysToReach[2].toFixed(0))}`,
                fillColor: `${cream}`,
                color: `${brown}`,
                border: [false, false, false, true],
              },
              {
                text: `${this.addComma(this.extraDaysToReach[3].toFixed(0))}`,
                fillColor: `${yellow}`,
                color: `${brown}`,
                border: [false, false, false, true],
              },
              {
                text: `${this.addComma(this.extraDaysToReach[4].toFixed(0))}`,
                fillColor: `${orange}`,
                color: `${brown}`,
                border: [false, false, false, true],
              },
              {
                text: `${this.addComma(this.extraDaysToReach[5].toFixed(0))}`,
                fillColor: `${pink}`,
                color: `${red}`,
                border: [false, false, false, true],
              },
              {
                text: `${this.addComma(this.extraDaysToReach[6].toFixed(0))}`,
                fillColor: `${darkPink}`,
                color: `${red}`,
                border: [false, false, false, true],
              },
              {
                text: '',
                fillColor: `${lightGrey}`,
                border: [false, false, false, true],
              },
            ],
            [
              {
                text: 'Extra Feed Consumed /pig (kg)',
                fillColor: `${lightGrey}`,
                border: [false, false, false, true],
              },
              {
                text: `${this.addComma(this.extraFeedConsumed[0].toFixed(1))}`,
                fillColor: `${limeGreen}`,
                color: `${brown}`,
                border: [false, false, false, true],
              },
              {
                text: `${this.addComma(this.extraFeedConsumed[1].toFixed(1))}`,
                fillColor: `${lightGreen}`,
                color: `${brown}`,
                border: [false, false, false, true],
              },
              {
                text: `${this.addComma(this.extraFeedConsumed[2].toFixed(1))}`,
                fillColor: `${cream}`,
                color: `${brown}`,
                border: [false, false, false, true],
              },
              {
                text: `${this.addComma(this.extraFeedConsumed[3].toFixed(1))}`,
                fillColor: `${yellow}`,
                color: `${brown}`,
                border: [false, false, false, true],
              },
              {
                text: `${this.addComma(this.extraFeedConsumed[4].toFixed(1))}`,
                fillColor: `${orange}`,
                color: `${brown}`,
                border: [false, false, false, true],
              },
              {
                text: `${this.addComma(this.extraFeedConsumed[5].toFixed(1))}`,
                fillColor: `${pink}`,
                color: `${red}`,
                border: [false, false, false, true],
              },
              {
                text: `${this.addComma(this.extraFeedConsumed[6].toFixed(1))}`,
                fillColor: `${darkPink}`,
                color: `${red}`,
                border: [false, false, false, true],
              },
              {
                text: '',
                fillColor: `${lightGrey}`,
                border: [false, false, false, true],
              },
            ],
            [
              {
                text: `Additional Feed Cost /pig (${
                  this.currentCurrencyDetails.currentSelectedCurrency.text
                }) at ${this.addComma(
                  (this.transferredModalData.additionalFeedCostPigRate
                    ? this.transferredModalData.additionalFeedCostPigRate
                    : 0
                  ).toString()
                )} ${
                  this.currentCurrencyDetails.currentSelectedCurrency.text
                }/kg and ADFI of ${this.addComma(
                  (this.transferredModalData.adfiValue
                    ? this.transferredModalData.adfiValue
                    : 0
                  ).toString()
                )}`,
                fillColor: `${lightGrey}`,
                border: [false, false, false, true],
              },
              {
                text: `${this.addComma(this.additionalFeedCost[0].toFixed(1))}`,
                fillColor: `${limeGreen}`,
                color: `${brown}`,
                border: [false, false, false, true],
              },
              {
                text: `${this.addComma(this.additionalFeedCost[1].toFixed(1))}`,
                fillColor: `${lightGreen}`,
                color: `${brown}`,
                border: [false, false, false, true],
              },
              {
                text: `${this.addComma(this.additionalFeedCost[2].toFixed(1))}`,
                fillColor: `${cream}`,
                color: `${brown}`,
                border: [false, false, false, true],
              },
              {
                text: `${this.addComma(this.additionalFeedCost[3].toFixed(1))}`,
                fillColor: `${yellow}`,
                color: `${brown}`,
                border: [false, false, false, true],
              },
              {
                text: `${this.addComma(this.additionalFeedCost[4].toFixed(1))}`,
                fillColor: `${orange}`,
                color: `${brown}`,
                border: [false, false, false, true],
              },
              {
                text: `${this.addComma(this.additionalFeedCost[5].toFixed(1))}`,
                fillColor: `${pink}`,
                color: `${red}`,
                border: [false, false, false, true],
              },
              {
                text: `${this.addComma(this.additionalFeedCost[6].toFixed(1))}`,
                fillColor: `${darkPink}`,
                color: `${red}`,
                border: [false, false, false, true],
              },
              {
                text: '',
                fillColor: `${lightGrey}`,
                border: [false, false, false, true],
              },
            ],
            [
              {
                text: `Additional Feed Cost per 
                ${this.numberOfAnimalHeads.toFixed(0)} heads`,
                fillColor: `${lightGrey}`,
                border: [false, false, false, true],
              },
              {
                text: `${
                  this.additionalFeedCostPer1000[0].toFixed(2) === '0.00'
                    ? '-'
                    : this.addComma(
                        this.additionalFeedCostPer1000[0].toFixed(2)
                      )
                }`,
                fillColor: `${limeGreen}`,
                color: `${brown}`,
                border: [false, false, false, true],
              },
              {
                text: `${
                  this.additionalFeedCostPer1000[1].toFixed(2) === '0.00'
                    ? '-'
                    : this.addComma(
                        this.additionalFeedCostPer1000[1].toFixed(2)
                      )
                }`,
                fillColor: `${lightGreen}`,
                color: `${brown}`,
                border: [false, false, false, true],
              },
              {
                text: `${
                  this.additionalFeedCostPer1000[2].toFixed(2) === '0.00'
                    ? '-'
                    : this.addComma(
                        this.additionalFeedCostPer1000[2].toFixed(2)
                      )
                }`,
                fillColor: `${cream}`,
                color: `${brown}`,
                border: [false, false, false, true],
              },
              {
                text: `${
                  this.additionalFeedCostPer1000[3].toFixed(2) === '0.00'
                    ? '-'
                    : this.addComma(
                        this.additionalFeedCostPer1000[3].toFixed(2)
                      )
                }`,
                fillColor: `${yellow}`,
                color: `${brown}`,
                border: [false, false, false, true],
              },
              {
                text: `${
                  this.additionalFeedCostPer1000[4].toFixed(2) === '0.00'
                    ? '-'
                    : this.addComma(
                        this.additionalFeedCostPer1000[4].toFixed(2)
                      )
                }`,
                fillColor: `${orange}`,
                color: `${brown}`,
                border: [false, false, false, true],
              },
              {
                text: `${
                  this.additionalFeedCostPer1000[5].toFixed(2) === '0.00'
                    ? '-'
                    : this.addComma(
                        this.additionalFeedCostPer1000[5].toFixed(2)
                      )
                }`,
                fillColor: `${pink}`,
                color: `${red}`,
                border: [false, false, false, true],
              },
              {
                text: `${
                  this.additionalFeedCostPer1000[6].toFixed(2) === '0.00'
                    ? '-'
                    : this.addComma(
                        this.additionalFeedCostPer1000[6].toFixed(2)
                      )
                }`,
                fillColor: `${darkPink}`,
                color: `${red}`,
                border: [false, false, false, true],
              },
              {
                text: `${this.addComma(
                  this.totalAdditionalFeedCostPer1000.toFixed(2)
                )}`,
                fillColor: `${lightGrey}`,
                border: [false, false, false, true],
              },
            ],
          ],
        },
        layout: 'regularTable',
        pageBreak: 'after',
      },
    ];
    return content;
  }
}

export { BronchopneumoniaEconomicLoss };
