import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule,
} from 'vuex-module-decorators';
import store from '@/store/swine';
import { Endpoints } from '@/utils/endpoints';
import {
  Attachment,
  AuditReportUpdateQueries,
  CurrencyExchangeRateDetails,
  FetchLungAttachmentDetails,
  FetchReportDetails,
  LungLesionScoreParseAttachmentDetails,
  PatchAuditReportGeneralDetails,
  UpdateType,
} from '@/models/swine/report/report.model';
import { axios } from '@/utils/axios';
import { ToastProgrammatic } from 'buefy';
import { AuditReportSections } from '@/utils/constants';
import { EconomicCalculationModalDataType } from '@/models/swine/economic-calculation.model';
import { CurrencyDropdownOption } from '@/models/swine/dropdown.model';

interface OpenExchangeRateResponse {
  timestamp: number;
  base: string;
  rates: CurrencyExchangeRateDetails;
}

@Module({
  namespaced: true,
  dynamic: true,
  name: 'audit-report.detail',
  store,
})
class AuditReportDetail extends VuexModule {
  reportResponse: any | null = null;
  lungAttachmentResponse: any | null = null;
  isLoading = false;
  error: any | null = null;
  reportId: number | null = null;
  limit: number | null = null;
  offset: number | null = null;
  section: string | null = null;
  bronchopneumoniaScoreAttachments:
    | LungLesionScoreParseAttachmentDetails[]
    | null = null;
  pleurisyScoreAttachments:
    | LungLesionScoreParseAttachmentDetails[]
    | null = null;
  abscessScarringPericarditisScoreAttachments:
    | LungLesionScoreParseAttachmentDetails[]
    | null = null;
  fileAttachmentResponse: Attachment[] | null = null;
  auditReportUpdateResponse: string | null = null;
  deleteResponse: string | null = null;
  currencyExchangeRates: CurrencyExchangeRateDetails | null = null;
  calculatorModalInputObject: any = null;
  currentCurrencyDetails: {
    baseCurrency: CurrencyDropdownOption;
    currentSelectedCurrency: CurrencyDropdownOption;
  } | null = null;
  patchResponse: boolean | null = null;

  @Mutation
  updateLoadingStatus(payload: boolean) {
    this.isLoading = payload;
  }

  @Mutation
  setError(payload: any) {
    this.error = payload;
  }

  @Mutation
  setBronchopneumoniaScoreAttachments(
    payload: LungLesionScoreParseAttachmentDetails[],
  ) {
    this.bronchopneumoniaScoreAttachments = payload;
  }

  @Mutation
  setPleurisyScoreAttachments(
    payload: LungLesionScoreParseAttachmentDetails[],
  ) {
    this.pleurisyScoreAttachments = payload;
  }

  @Mutation
  setAbscessScarringPericarditisScoreAttachments(
    payload: LungLesionScoreParseAttachmentDetails[],
  ) {
    this.abscessScarringPericarditisScoreAttachments = payload;
  }

  @Mutation
  setReportParams(payload: FetchReportDetails) {
    const { reportId, section } = payload;
    this.reportId = reportId;
    this.section = section;
  }

  @Mutation
  setLungAttachmentParams(payload: FetchLungAttachmentDetails) {
    const { reportId, section, limit, offset } = payload;
    this.reportId = reportId;
    this.section = section;
    this.limit = limit;
    this.offset = offset;
  }

  @Mutation
  setReportResponse(payload: any) {
    this.reportResponse = payload;
  }

  @Mutation
  setAuditReportUpdateResponse(payload: string | null) {
    this.auditReportUpdateResponse = payload;
  }

  @Mutation
  setLungAttachmentsResponse(payload: any) {
    this.lungAttachmentResponse = payload;
  }

  @Mutation
  setImageAttachmentResponse(payload: Attachment[]) {
    this.fileAttachmentResponse = payload;
  }

  @Mutation
  setDeleteResponse(payload: string | null) {
    this.deleteResponse = payload;
  }

  @Mutation
  setCurrencyExchangeResponse(payload: OpenExchangeRateResponse) {
    this.currencyExchangeRates = payload.rates;
  }

  @Mutation
  setCalculatorModalInputObject(
    payload: any,
  ) {
    this.calculatorModalInputObject = payload;
  }

  @Mutation
  setCurrentCurrencyDetails(payload: {
    baseCurrency: CurrencyDropdownOption;
    currentSelectedCurrency: CurrencyDropdownOption;
  }) {
    this.currentCurrencyDetails = payload;
  }

  @Mutation
  setSpecifiedReportGeneralDetails(payload: boolean | null) {
    this.patchResponse = payload;
  }

  @Action
  getReportDetails(payload: FetchReportDetails) {
    this.context.commit('updateLoadingStatus', true);
    this.context.commit('setError', null);
    this.context.commit(`setReportParams`, payload);
    try {
      axios
        .get(`${Endpoints.Reports}/swine/${this.reportId}`, {
          params: {
            section: this.section,
          },
        })
        .then((response) => {
          if (response.data) {
            this.context.commit('setReportResponse', response.data);
          } else {
            this.context.commit('setError', JSON.stringify(response));
          }
        });
    } catch (error) {
      this.context.commit('setError', error);
    }
    this.context.commit('updateLoadingStatus', false);
  }

  @Action
  getLungAttachmentDetails(payload: FetchLungAttachmentDetails) {
    this.context.commit('updateLoadingStatus', true);
    this.context.commit('setError', null);
    this.context.commit(`setLungAttachmentParams`, payload);
    try {
      axios
        .get(`${Endpoints.Reports}/swine/${this.reportId}`, {
          params: {
            section: this.section,
            limit: this.limit,
            offset: this.offset,
          },
        })
        .then((response) =>
          this.context.commit('setLungAttachmentsResponse', response.data),
        );
    } catch (error) {
      this.context.commit('setError', error);
    }
    this.context.commit('updateLoadingStatus', false);
  }

  @Action
  uploadAttachment(file: FormData) {
    this.context.commit('updateLoadingStatus', true);
    this.context.commit('setError', null);
    try {
      axios
        .post<Attachment[]>(`${Endpoints.Attachments}`, file, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((response) => {
          if (response.status !== 201) {
            ToastProgrammatic.open({
              type: `is-danger`,
              message: `Attachments cannot be added. Try again`,
              duration: 3000,
            });
          }
          this.context.commit('setImageAttachmentResponse', response.data);
        });
    } catch (error) {
      this.context.commit('setError', error);
    }
    this.context.commit('updateLoadingStatus', false);
  }

  @Action
  updateReportAttachmentDetails(params: AuditReportUpdateQueries) {
    this.context.commit('updateLoadingStatus', true);
    this.context.commit('setError', null);
    this.context.commit('setAuditReportUpdateResponse', null);
    try {
      axios
        .put(`${Endpoints.Reports}/swine/${this.reportId}`, params, {
          params: {
            section: AuditReportSections.ATTACHMENTS,
          },
        })
        .then((response) => {
          if (
            response.status === 204 &&
            params.updateType === UpdateType.UPDATE
          ) {
            ToastProgrammatic.open({
              type: `is-dark`,
              message: `Attachments has been updated`,
              duration: 3000,
            });
          }

          if (
            response.status === 204 &&
            params.updateType === UpdateType.DELETE
          ) {
            ToastProgrammatic.open({
              type: `is-danger`,
              message: `Attachment has been deleted`,
              duration: 3000,
            });
          }
          this.context.commit('setAuditReportUpdateResponse', response.data);
        });
    } catch (error) {
      this.context.commit('setError', error);
    }
    this.context.commit('updateLoadingStatus', false);
  }

  @Action
  updateReportEconomicCalculationDetails(params: AuditReportUpdateQueries) {
    this.context.commit('updateLoadingStatus', true);
    this.context.commit('setError', null);
    this.context.commit('setReportResponse', null);
    try {
      axios
        .put(`${Endpoints.Reports}/swine/${this.reportId}`, params, {
          params: {
            section: AuditReportSections.ECONOMIC_CALCULATION,
          },
        })
        .then((response) => {
          if (response.status === 204) {
            ToastProgrammatic.open({
              type: `is-dark`,
              message: `Economic calculation details are saved`,
              duration: 3000,
            });
            this.context.commit('setReportResponse', response.data);
          }
        });
    } catch (error) {
      this.context.commit('setError', error);
    }
    this.context.commit('updateLoadingStatus', false);
  }

  @Action
  resetEconomicCalculationDetails(params: AuditReportUpdateQueries) {
    this.context.commit('updateLoadingStatus', true);
    this.context.commit('setError', null);
    this.context.commit('setReportResponse', null);
    try {
      axios
        .put(`${Endpoints.Reports}/swine/${this.reportId}`, params, {
          params: {
            section: AuditReportSections.ECONOMIC_CALCULATION,
          },
        })
        .then((response) => {
          this.context.commit('setReportResponse', response.data);
        });
    } catch (error) {
      this.context.commit('setError', error);
    }
    this.context.commit('updateLoadingStatus', false);
  }

  @Action
  deleteSpecifiedReports(reportIds: number[]) {
    this.context.commit('updateLoadingStatus', true);
    this.context.commit('setError', null);
    this.context.commit('setDeleteResponse', null);
    try {
      const reportIdsString = reportIds.join(',');
      axios
        .delete(`${Endpoints.Reports}/swine`, {
          params: {
            report: reportIdsString,
          },
        })
        .then((response) => {
          if (response.status === 204) {
            ToastProgrammatic.open({
              type: `is-dark`,
              duration: 3000,
              message: `Audit Report ${reportIds[0]} Deleted.`,
            });
          } else {
            ToastProgrammatic.open({
              type: `is-dark`,
              duration: 3000,
              message: `Selected reports are not owned by current user.`,
            });
          }
          this.context.commit('setDeleteResponse', response.data);
        })
    } catch (error) {
      this.context.commit('setError', error);
    }
    this.context.commit('updateLoadingStatus', false);
  }

  @Action
  async getCurrencyExchangeRates() {
    this.context.commit('updateLoadingStatus', true);
    this.context.commit('setError', null);
    try {
      const openExchangeRateResponse = await axios.get(
        `${Endpoints.OpenExchangeRateLatest}`,
        {
          withCredentials: false, // Remove CORS headers
          baseURL: process.env.VUE_APP_OPEN_EXCHANGE_RATE_BASE_URL,
          params: {
            app_id: process.env.VUE_APP_OPEN_EXCHANGE_RATE_API_KEY,
            symbols: `USD,IDR,MYR,PHP,THB,VND,CNY,BND,NTD`,
          },
        },
      );
      if (openExchangeRateResponse.status === 200) {
        this.context.commit(
          'setCurrencyExchangeResponse',
          openExchangeRateResponse.data,
        );
      } else {
        const fixerResponse = await axios.get(`${Endpoints.FixerLatest}`, {
          transformRequest: (data, headers) => {
            if (headers) {
              delete headers['Authorization'];
            }
            return data;
          },
          withCredentials: false, // Remove CORS headers
          baseURL: process.env.VUE_APP_FIXER_BASE_URL,
          params: {
            access_key: process.env.VUE_APP_FIXER_ACCESS_KEY,
            symbols: `USD,IDR,MYR,PHP,THB,VND,CNY,BND,NTD`,
          },
        });
        if (fixerResponse.status === 200) {
          this.context.commit(
            'setCurrencyExchangeResponse',
            fixerResponse.data,
          );
        }
      }
    } catch (error) {
      this.context.commit('setError', error);
    }
    this.context.commit('updateLoadingStatus', false);
  }

  @Action
  patchSpecifiedReportGeneralDetails(payload: PatchAuditReportGeneralDetails) {
    this.context.commit('updateLoadingStatus', true);
    this.context.commit('setError', null);
    this.context.commit(`setSpecifiedReportGeneralDetails`, null);

    try {
      const { id, data } = payload;
      axios.patch(`${Endpoints.Reports}/swine/${id}`, data)
        .then((response) => {
          if (response.status === 200) {
            ToastProgrammatic.open({
              type: `is-dark`,
              message: `General Details successfully updated`,
              duration: 3000,
            });
            this.context.commit(`setSpecifiedReportGeneralDetails`, true);
          }
        })
        .catch()
    } catch (error) {
      this.context.commit('setError', error);
    }
    this.context.commit('updateLoadingStatus', false);
  }
}
export default getModule(AuditReportDetail);
