import {
  imgIcAnalysisLine1,
  imgIcAnalysisLine2,
  imgIcDashAppi,
  imgIcIndex,
} from '../../images';
import { ReportType } from '../../../../utils/constants';
import { GenerateChartImage } from '@/utils/generate-chart/generate-chart-image';
import {
  AppIndexAndPrevalenceWithReportData
} from '@/models/swine/report/report.model';
import { getReportRanking } from '../../utils/utils';

export function getAppIndexAndPrevalenceSection(
  appIndexAndPrevalence: AppIndexAndPrevalenceWithReportData,
) {
  const reportType = ReportType;
  const options = {
    elements: {
      point: {
        radius: 6,
      },
    },
    responsive: true,
    maintainAspectRatio: true,
    legend: {
      display: false,
    },
    plugins: {
      datalabels: {
        display: false,
      },
    },
    scales: {
      yAxes: [
        {
          ticks: {
            min: 0,
            max: 4,
            beginAtZero: true,
            callback: function (tick: number | string) {
              return tick.toString();
            },
          },
          position: `left`,
          id: `AppIndex`,
        },
        {
          ticks: {
            min: 0,
            max: 120,
            beginAtZero: true,
            callback: function (tick: number | string) {
              return tick.toString() + `%`;
            },
          },
          position: `right`,
          id: `Prevalence`,
          gridLines: {
            display: false,
          },
        },
      ],
      xAxes: [
        {
          offset: true,
        },
        {
          offset: true,
          display: false,
        },
      ],
    },
    animation: false,
    showLines: false,
  };

  const chartData = {
    labels: [`Dorsal Pleurisy index`],
    datasets: [
      {
        data: [appIndexAndPrevalence.appIndexDetails.appIndex],
        type: `bar`,
        order: 2,
        backgroundColor: [
          appIndexAndPrevalence.reportType === reportType.NORMAL
            ? appIndexAndPrevalence.appIndexDetails.appIndexBackgroundColor
            : `#f5f5f5`,
        ],
        borderColor: [
          appIndexAndPrevalence.reportType === reportType.NORMAL
            ? appIndexAndPrevalence.appIndexDetails.appIndexColor
            : `#333333`,
        ],
        borderWidth: 1,
        barPercentage: 0.2,
        yAxisID: `AppIndex`,
      },
      {
        data: [appIndexAndPrevalence.appPleurisyPrevalence],
        type: 'line',
        order: 1,
        backgroundColor: [`#ECD9FF`],
        borderColor: [`#6B3C97`],
        borderWidth: 1,
        barPercentage: 0.2,
        yAxisID: `Prevalence`,
      },
      {
        data: [appIndexAndPrevalence.pleurisyPrevalence],
        type: 'line',
        backgroundColor: [`#0072CE`],
        borderColor: [`#0072CE`],
        borderWidth: 3,
        barPercentage: 0.2,
        yAxisID: `Prevalence`,
        pointStyle: `crossRot`,
      },
    ],
  };

  const gci = new GenerateChartImage(chartData, options);

  const content = [
    {
      text: 'Dorsal Pleurisy Index and Prevalence',
      style: 'sectionHeader',
    },
    {
      canvas: [
        {
          type: `rect`,
          w: 380,
          h: 60,
          r: 10,
          x: 0,
          y: 0,
          lineColor: `#E3E3E3`,
        },
      ],
      absolutePosition: { x: 40, y: 85 },
    },
    {
      canvas: [
        {
          type: `rect`,
          w: 370,
          h: 60,
          r: 10,
          x: 0,
          y: 0,
          lineColor: `#E3E3E3`,
        },
      ],
      absolutePosition: { x: 428, y: 85 },
    },
    {
      columns: [
        {
          style: 'fontSize8',
          table: {
            widths: ['auto', '*', 'auto'],
            body: [
              [
                {
                  svg: imgIcDashAppi,
                  width: 41,
                  border: [false, false, false, false],
                },
                {
                  text: `${appIndexAndPrevalence.appIndexDetails.country?.name} Industry Dorsal Pleurisy Index`,
                  bold: true,
                  border: [false, false, false, false],
                  fontSize: 10,
                  marginTop: 12,
                },
                {
                  style: ['fontSize8'],
                  table: {
                    widths: '*',
                    body: [
                      [
                        {
                          text: appIndexAndPrevalence.quartiles.firstQuartile.toString(),
                          border: [false, false, false, false],
                          alignment: 'right',
                          width: 50,
                        },
                        {
                          text: appIndexAndPrevalence.quartiles.secondQuartile.toString(),
                          border: [false, false, false, false],
                          alignment: 'right',
                          width: 50,
                        },
                        {
                          text: appIndexAndPrevalence.quartiles.thirdQuartile.toString(),
                          border: [false, false, false, false],
                          alignment: 'right',
                          width: 50,
                        },
                        {
                          text: '',
                          border: [false, false, false, false],
                          width: 50,
                        },
                      ],
                      [
                        {
                          text: '',
                          fillColor: '#99E895',
                          border: [false, false, false, false],
                          margin: 5,
                          width: 50,
                        },
                        {
                          text: '',
                          fillColor: '#FFF084',
                          border: [false, false, false, false],
                          margin: 5,
                          width: 50,
                        },
                        {
                          text: '',
                          fillColor: '#FF9227',
                          border: [false, false, false, false],
                          margin: 5,
                          width: 50,
                        },
                        {
                          text: '',
                          fillColor: '#FF4D4D',
                          border: [false, false, false, false],
                          margin: 5,
                          width: 50,
                        },
                      ],
                    ],
                  },
                  border: [false, false, false, false],
                },
              ],
            ],
          },
          layout: 'card',
        },
        {
          style: 'fontSize8',
          table: {
            widths: ['auto', '*', 'auto', '*'],
            body: [
              [
                {
                  svg: imgIcIndex,
                  width: 41,
                  border: [false, false, false, false],
                },
                {
                  stack: [
                    {
                      text: 'Dorsal Pleurisy Index',
                      bold: true,
                      border: [false, false, false, false],
                      margin: [0, 0, 0, 0],
                    },
                    {
                      text: `${appIndexAndPrevalence.appIndexDetails.appIndex}`,
                      bold: true,
                      border: [false, false, false, false],
                      fontSize: 20,
                      color: appIndexAndPrevalence.appIndexDetails ? appIndexAndPrevalence.appIndexDetails.appIndexBackgroundColor : '#FF4C4B',
                    },
                  ],
                  border: [false, false, false, false],
                  margin: [0, 5, 0, 0],
                },
                {
                  text: '',
                  border: [false, false, false, false],
                },
                {
                  stack: [
                    {
                      text: getReportRanking(appIndexAndPrevalence.appIndexDetails) !== 'No Ranking' ? `${getReportRanking(appIndexAndPrevalence.appIndexDetails)} out of ${appIndexAndPrevalence.appIndexDetails.totalReports}` : '',
                      color: '#929292',
                      border: [false, false, false, false],
                      alignment: 'right',
                    },
                    {
                      text: `${appIndexAndPrevalence.appIndexDetails.country?.name} total reports`,
                      color: '#929292',
                      border: [false, false, false, false],
                      alignment: 'right',
                    },
                  ],
                  border: [false, false, false, false],
                  margin: [0, 10, 0, 0],
                },
              ],
            ],
          },
          layout: 'card',
        },
      ],
      columnGap: 15,
      margin: [0, 0, 0, 15],
    },
    {
      image: gci.generateImage(),
      width: 741,
      height: 200,
      alignment: 'center',
      marginBottom: 5,
    },
    {
      style: 'fontSize8',
      table: {
        widths: '*',
        body: [
          [
            {
              text: '',
              color: appIndexAndPrevalence.appIndexDetails ? appIndexAndPrevalence.appIndexDetails.appIndexColor : '#F15453',
              fillColor: appIndexAndPrevalence.appIndexDetails ? appIndexAndPrevalence.appIndexDetails.appIndexBackgroundColor : '#FFEAEA',
              border: [false, false, false, false],
            },
            {
              svg: imgIcAnalysisLine1,
              fillColor: '#ECD9FF',
              width: 15,
              border: [false, false, false, true],
            },
            {
              svg: imgIcAnalysisLine2,
              border: [false, false, false, false],
              width: 15,
            },
          ],
          [
            {
              text: 'Dorsal Pleurisy Index',
              bold: true,
              border: [false, false, false, true],
              color: appIndexAndPrevalence.appIndexDetails ? appIndexAndPrevalence.appIndexDetails.appIndexColor : '#F15453',
              fillColor: appIndexAndPrevalence.appIndexDetails ? appIndexAndPrevalence.appIndexDetails.appIndexBackgroundColor : '#FFEAEA',
            },
            {
              text: 'Dorsal Pleurisy Prevalence (%)',
              bold: true,
              border: [false, false, false, true],
              fillColor: '#ECD9FF',
              color: '#6B3C97',
            },
            {
              text: 'Pleurisy Prevalence (%)',
              bold: true,
              border: [false, false, false, true],
              color: '#0172CE',
            },
          ],
          [
            {
              text: `${appIndexAndPrevalence.appIndexDetails.appIndex}`,
              color: appIndexAndPrevalence.appIndexDetails ? appIndexAndPrevalence.appIndexDetails.appIndexColor : '#F15453',
              fillColor: appIndexAndPrevalence.appIndexDetails ? appIndexAndPrevalence.appIndexDetails.appIndexBackgroundColor : '#FFEAEA',
              border: [false, false, false, false],
            },
            {
              text: `${appIndexAndPrevalence.appPleurisyPrevalence}`,
              fillColor: '#ECD9FF',
              color: '#6B3C97',
              border: [false, false, false, false],
            },
            {
              text: `${appIndexAndPrevalence.pleurisyPrevalence}`,
              color: '#0172CE',
              border: [false, false, false, false],
            },
          ],
        ],
      },
      layout: 'regularTable',
      alignment: 'center',
      pageBreak: 'after',
    },
  ];

  gci.clear();
  return content;
}
