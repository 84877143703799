


















































































































































































import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import FormTemplate from '@/components/swine/layout/FormTemplate.vue';
import { FormStyling } from '@/models/swine/form/form.model';
import { EditReportDataModal, EditSpecifiedReportModalData, ReportSummaryData } from '@/models/swine/report/report.model';
import { DropdownOption } from '@/models/swine/dropdown.model';
import moment from 'moment';
import Dropdown from '../DropdownAnalysisTemplate.vue';

@Component({
    components: {
        FormTemplate,
        Dropdown,
    }
})
export default class EditSpecifiedReportModal extends Vue {
    formTitle = '';
    formStyling: Partial<FormStyling> = {
        borderRadius: '20px',
        width: '800px',
    };
    animalTypeText = '';
    housingTypeText = '';
    flooringTypeText = '';
    operationTypeText = '';
    seasonText = '';
    animalTypeValue = '';
    housingTypeValue = '';
    flooringTypeValue = '';
    operationTypeValue = '';
    seasonValue = '';
    currentData: EditSpecifiedReportModalData = {
        date: new Date(),
        weaningDate: new Date(),
        marketDate: new Date(),
        averageWeaningWT: null,
        averageMarketWT: null,
        adg: null,
        fcr: null,
        animalType: null,
        marketAgeDays: null,
        season: null,
        housingType: null,
        flooringType: null,
        operationType: null,
        medicationProgram: null,
        estMedicationCost: null,
        mHyoVaccine: null,
        appVaccine: null,
        prrsVaccine: null,
        pcv2Vaccine: null
    }
    

    @Prop({
        type: Object,
        default: null,
    })
    selectedReport!: ReportSummaryData;

    @Prop({
        type: Array,
        default: [],
    })
    animalTypeOptions!: DropdownOption[];

    @Prop({
        type: Array,
        default: [],
    })
    seasonOptions!: DropdownOption[];

    @Prop({
        type: Array,
        default: [],
    })
    housingTypeOptions!: DropdownOption[];

    @Prop({
        type: Array,
        default: [],
    })
    flooringTypeOptions!: DropdownOption[];

    @Prop({
        type: Array,
        default: [],
    })
    operationTypeOptions!: DropdownOption[];

    mounted() {
        this.updateDataState();
    }

    updateDataState() {
        if (this.selectedReport) {
            this.formTitle = `Edit Report ${this.selectedReport.farmUnit.farmUnitCodeName}`;
            this.currentData.date = this.selectedReport.date ? moment(this.selectedReport.date).toDate() : new Date();
            this.currentData.weaningDate = this.selectedReport.weaningDate ? moment(this.selectedReport.weaningDate).toDate() : new Date();
            this.currentData.marketDate = this.selectedReport.marketDate? moment(this.selectedReport.marketDate).toDate() : new Date();
            this.currentData.averageWeaningWT = this.selectedReport.averageWeaningWT;
            this.currentData.averageMarketWT = this.selectedReport.averageMarketWT;
            this.currentData.adg = this.selectedReport.adg;
            this.currentData.fcr = this.selectedReport.fcr;
            this.currentData.marketAgeDays = this.selectedReport.marketAgeDays;
            this.currentData.medicationProgram = this.selectedReport.medicationProgram;
            this.currentData.estMedicationCost = this.selectedReport.estMedicationCost;
            this.currentData.mHyoVaccine = this.selectedReport.mHyoVaccine;
            this.currentData.appVaccine = this.selectedReport.appVaccine;
            this.currentData.prrsVaccine = this.selectedReport.prrsVaccine;
            this.currentData.pcv2Vaccine = this.selectedReport.pcv2Vaccine;

            if (this.selectedReport.season) {
                const currentIndex = this.seasonOptions.findIndex((item) => {
                    return item.value === Number(this.selectedReport.season?.id);
                });
                this.currentData.season = currentIndex > -1 ? this.seasonOptions[currentIndex]: this.seasonOptions[0];
                this.seasonText = this.currentData.season.text;
                this.seasonValue = String(this.currentData.season.value);
            }

            if (this.selectedReport.housingType) {
                const currentIndex = this.housingTypeOptions.findIndex((item) => {
                    return item.value === Number(this.selectedReport.housingType?.id);
                });
                this.currentData.housingType = currentIndex > -1 ? this.housingTypeOptions[currentIndex]: this.housingTypeOptions[0];
                this.housingTypeText = this.currentData.housingType.text;
                this.housingTypeValue = String(this.currentData.housingType.value);
            }

            if (this.selectedReport.animalType) {
                const currentIndex = this.animalTypeOptions.findIndex((item) => {
                    return item.value === Number(this.selectedReport.animalType?.id);
                });
                this.currentData.animalType = currentIndex > -1 ? this.animalTypeOptions[currentIndex]: this.animalTypeOptions[0];
                this.animalTypeText = this.currentData.animalType.text;
                this.animalTypeValue = String(this.currentData.animalType.value);
            }

            if (this.selectedReport.operationType) {
                const currentIndex = this.operationTypeOptions.findIndex((item) => {
                    return item.value === Number(this.selectedReport.operationType?.id);
                });
                this.currentData.operationType = currentIndex > -1 ? this.operationTypeOptions[currentIndex]: this.operationTypeOptions[0];
                this.operationTypeText = this.currentData.operationType.text;
                this.operationTypeValue = String(this.currentData.operationType.value);
            }

            if (this.selectedReport.flooringType) {
                const currentIndex = this.flooringTypeOptions.findIndex((item) => {
                    return item.value === Number(this.selectedReport.flooringType?.id);
                });
                this.currentData.flooringType = currentIndex > -1 ? this.flooringTypeOptions[currentIndex]: this.flooringTypeOptions[0];
                this.flooringTypeText = this.currentData.flooringType.text;
                this.flooringTypeValue = String(this.currentData.flooringType.value);
            }
        }
    }

    updateAnimalType(data: DropdownOption) {
        if (data) {
            const currentIndex = this.animalTypeOptions.findIndex((item) => {
                return item.value === Number(data.value);
            });
            this.currentData.animalType = currentIndex > -1 ? this.animalTypeOptions[currentIndex] : null;
            this.animalTypeText = currentIndex > -1 ? this.animalTypeOptions[currentIndex].text : '';
        }
    }

    updateSeason(data: DropdownOption) {
        if (data) {
            const currentIndex = this.seasonOptions.findIndex((item) => {
                return item.value === Number(data.value);
            });
            this.currentData.season = currentIndex > -1 ? this.seasonOptions[currentIndex]: null;
            this.seasonText = currentIndex > -1 ? this.seasonOptions[currentIndex].text : '';
        }
    }

    updateHousingType(data: DropdownOption) {
        if (data) {
            const currentIndex = this.housingTypeOptions.findIndex((item) => {
                return item.value === Number(data.value);
            });
            this.currentData.housingType = currentIndex > -1 ? this.housingTypeOptions[currentIndex] : null;
            this.housingTypeText = currentIndex > -1 ? this.housingTypeOptions[currentIndex].text : '';
        }
    }

    updateFlooringType(data: DropdownOption) {
        if (data) {
            const currentIndex = this.flooringTypeOptions.findIndex((item) => {
                return item.value === Number(data.value);
            });
            this.currentData.flooringType = currentIndex > -1 ? this.flooringTypeOptions[currentIndex] : null;
            this.flooringTypeText = currentIndex > -1 ? this.flooringTypeOptions[currentIndex].text : '';
        }
    }

    updateOperationType(data: DropdownOption) {
        if (data) {
            const currentIndex = this.operationTypeOptions.findIndex((item) => {
                return item.value === Number(data.value);
            });
            this.currentData.operationType = currentIndex > -1 ? this.operationTypeOptions[currentIndex] : null;
            this.operationTypeText = currentIndex > -1 ? this.operationTypeOptions[currentIndex].text : '';
        }
    }

    @Emit('close')
    closeForm(value: boolean) {
        return value;
    }

    @Emit('close')
    closeModal() {
        return false;
    }

    @Emit(`update`)
    updateCurrentReport(){
        // Format output
        this.currentData.date = moment(this.currentData.date).local().toDate();
        this.currentData.weaningDate = moment(this.currentData.weaningDate).local().toDate();
        this.currentData.marketDate = moment(this.currentData.marketDate).local().toDate();
        this.currentData.averageWeaningWT = this.currentData.averageWeaningWT ? Number(this.currentData.averageWeaningWT) : null;
        this.currentData.averageMarketWT = this.currentData.averageMarketWT ? Number(this.currentData.averageMarketWT) : null;
        this.currentData.adg = this.currentData.adg ? Number(this.currentData.adg) : null;
        this.currentData.fcr = this.currentData.fcr ? Number(this.currentData.fcr) : null;
        this.currentData.animalType = this.currentData.animalType && this.currentData.animalType.value > -1 ? this.currentData.animalType : null;
        this.currentData.marketAgeDays = this.currentData.marketAgeDays ? Number(this.currentData.marketAgeDays) : null;
        this.currentData.season = this.currentData.season && this.currentData.season.value > -1 ? this.currentData.season : null;
        this.currentData.housingType = this.currentData.housingType && this.currentData.housingType.value > -1 ? this.currentData.housingType : null;
        this.currentData.flooringType = this.currentData.flooringType && this.currentData.flooringType.value > -1 ? this.currentData.flooringType : null;
        this.currentData.operationType = this.currentData.operationType && this.currentData.operationType.value > -1 ? this.currentData.operationType : null;
        this.currentData.medicationProgram = this.currentData.medicationProgram && this.currentData.medicationProgram.length > 0 ? this.currentData.medicationProgram : null;
        this.currentData.estMedicationCost = this.currentData.estMedicationCost ? Number(this.currentData.estMedicationCost) : null;
        this.currentData.mHyoVaccine = this.currentData.mHyoVaccine && this.currentData.mHyoVaccine.length > 0 ? this.currentData.mHyoVaccine : null;
        this.currentData.appVaccine = this.currentData.appVaccine && this.currentData.appVaccine.length > 0 ? this.currentData.appVaccine : null;
        this.currentData.prrsVaccine = this.currentData.prrsVaccine && this.currentData.prrsVaccine.length > 0 ? this.currentData.prrsVaccine : null;
        this.currentData.pcv2Vaccine = this.currentData.pcv2Vaccine && this.currentData.pcv2Vaccine.length > 0 ? this.currentData.pcv2Vaccine : null;

        return this.currentData
    }
}
