































import { Vue, Prop, Component } from 'vue-property-decorator';
import ReportCardTemplate from '@/components/swine/reports/ReportCardTemplate.vue';
import { Quartiles } from '@/models/swine/report/report.model';

@Component({
  components: {
    ReportCardTemplate,
  },
})
export default class IndustryAppIndexChart extends Vue {
  @Prop({
    type: String,
    default: '',
  })
  country!: string;

  @Prop({
    type: Object,
    default: null,
  })
  quartiles!: Quartiles;

  standardizeAppIndex(data: number) {
    let output = data.toString();
    if (output.includes('.')) {
      while (output.split('.')[1].length < 3) {
        output += '0';
      }
      return output;
    } else {
      output += '.000';
      return output;
    }
  }
}
