







import { Component, Prop, Vue } from 'vue-property-decorator';
@Component({})
export default class TotalLungsObserved extends Vue {
  @Prop({
    type: Number,
    default: 0,
  })
  totalLungsObservedCount!: number;
}
