











































import { Component, Prop, Vue } from 'vue-property-decorator';
import { FarmResultSummaryWithReportData } from '@/models/swine/report/report.model';
import { addDecimalPointToNumbers } from '@/utils/formats';
@Component({})
export default class BreakdownTable extends Vue {
  @Prop({
    type: Object,
    default: null,
  })
  reportValues!: FarmResultSummaryWithReportData;
  normalScore = '';
  pneumoniaScore = '';
  severePneumoniaScore = '';
  p1PleuritisScore = '';
  otherPleuritisScore = '';
  abscessCount = '';
  scarringCount = '';
  pericarditisCount = '';

  created() {
    if (this.reportValues) {
      this.normalScore = addDecimalPointToNumbers(
        this.reportValues.normalScore,
      );
      this.pneumoniaScore = addDecimalPointToNumbers(
        this.reportValues.pneumoniaScore,
      );
      this.severePneumoniaScore = addDecimalPointToNumbers(
        this.reportValues.severePneumoniaScore,
      );

      this.p1PleuritisScore = this.reportValues.p1PleuritisScore.toString();
      this.otherPleuritisScore = this.reportValues.otherPleuritisScore.toString();

      this.abscessCount = this.reportValues.abscessCount.toString();
      this.scarringCount = this.reportValues.scarringCount.toString();
      this.pericarditisCount = this.reportValues.pericarditisCount.toString();
    }
  }
}
