import Chart from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

interface ChartData {
  labels: Array<string>;
  datasets: Array<any>;
}

class GenerateChartImage {
  chartData: ChartData;
  // @ts-ignore
  myChart: Chart;
  defaultOptions: any;

  constructor(chartData: ChartData, customOptions?: any) {
    this.chartData = chartData;
    this.defaultOptions = {
      responsive: true,
      maintainAspectRatio: true,
      scales: {
        y: {
          beginAtZero: true,
        },
        xAxes: [
          {
            gridLines: {
              drawOnChartArea: false,
              display: false,
            },
            barThickness: 50,
          },
        ],
        yAxes: [
          {
            gridLines: {
              drawOnChartArea: true,
            },
          },
        ],
      },
      animation: false,
      legend: false,
      showLines: false,
      plugins: {
        datalabels: {
          anchor: 'end',
          align: 'end',
          formatter: function (value: any) {
            return `${value}%`;
          },
        },
      },
    };

    if (customOptions) {
      this.defaultOptions = customOptions;
    }
  }

  generateImage(): string {
    var canvas = document.createElement('canvas');
    canvas.style.width = "741px";
    canvas.style.height = "200px";
    canvas.style.left = " -10000px";
    canvas.style.position = "absolute";
    document.body.prepend(canvas);
    var ctx = document.querySelector('canvas');
    // @ts-ignore: Object is possibly 'null'.
    this.myChart = new Chart(ctx, {
      type: 'bar',
      plugins: [ChartDataLabels],
      data: {
        labels: this.chartData.labels,
        datasets: this.chartData.datasets,
      },
      options: this.defaultOptions,
    });

    return this.generateChartImage();
  }

  clear() {
    this.myChart.clear();
  }

  generateChartImage() {
    return document.querySelector('canvas')!.toDataURL();
  }
}

export { GenerateChartImage };
