















































import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import PneumoniaIndexInterpretation from '@/components/swine/reports/PneumoniaIndexInterpretation.vue';
import PneumoniaCard from '@/components/swine/reports/PneumoniaCard.vue';
import SectionLayoutCard from '@/components/swine/reports/SectionLayoutCard.vue';
import {
  BronchopneumoniaScoreWithReportData,
  DisplayData,
  LungLesionScoreParseAttachmentDetails,
  SpawnAttachmentPage,
} from '@/models/swine/report/report.model';
import BasicBarChart from '@/components/swine/reports/BasicBarChart.vue';
import PneumoniaTable from './PneumoniaTable.vue';
import { AuditReportSections, ReportType } from '@/utils/constants';
import BronchopneumoniaScoreAttachmentsTable from './BronchopneumoniaScoreAttachmentsTable.vue';
import { addDecimalPointToNumbers } from '@/utils/formats';

@Component({
  components: {
    PneumoniaIndexInterpretation,
    PneumoniaCard,
    SectionLayoutCard,
    BasicBarChart,
    PneumoniaTable,
    BronchopneumoniaScoreAttachmentsTable,
  },
})
export default class BronchopneumoniaScore extends Vue {
  @Prop({
    type: Object,
    default: null,
  })
  currentReportDetails!: BronchopneumoniaScoreWithReportData;

  @Prop({
    type: Array,
    default: null,
  })
  attachmentDetails!: LungLesionScoreParseAttachmentDetails[];

  @Prop({ required: true })
  isAttachmentSelectionAllowed!: boolean;

  sectionCardDetails: DisplayData[] = [
    {
      headerText: `% Bronchopneumonic Lungs`,
      subText: `(Mild to Severe)`,
      value: `${this.standardizeValues(0)}%`,
    },
    {
      headerText: `Average % Affected Surface`,
      subText: `out of All Lungs`,
      value: `${this.standardizeValues(0)}%`,
    },
    {
      headerText: `Average % of Affected Surface out of`,
      subText: `Lungs with Active Pneumonia`,
      value: `${this.standardizeValues(0)}%`,
    },
  ];

  chartData: Chart.ChartData | null = null;
  options: Chart.ChartOptions | null = null;
  reportType = ReportType;

  standardizeValues(data: number) {
    return addDecimalPointToNumbers(data);
  }

  mounted() {
    if (this.currentReportDetails) {
      // Populate section card details
      if (this.currentReportDetails.bronchopneumoniaLungsMildToSevere) {
        this.sectionCardDetails[0].value = `${this.standardizeValues(
          this.currentReportDetails.bronchopneumoniaLungsMildToSevere,
        )}%`;
      }

      if (this.currentReportDetails.averagePercentageAffectedLungsSurface) {
        this.sectionCardDetails[1].value = `${this.standardizeValues(
          this.currentReportDetails.averagePercentageAffectedLungsSurface,
        )}%`;
      }

      if (
        this.currentReportDetails
          .averagePercentageAffectedLungsSurfaceWithActivePneumonia
      ) {
        this.sectionCardDetails[2].value = `${this.standardizeValues(
          this.currentReportDetails
            .averagePercentageAffectedLungsSurfaceWithActivePneumonia,
        )}%`;
      }

      let maxValue = 0;
      Object.values(this.currentReportDetails.pneumoniaPercentageDict).find(
        (detail) => {
          if (typeof detail === 'number' && detail >= maxValue) {
            maxValue = detail;
          }
        },
      );

      // Generate ChartData and Options
      this.options = {
        maintainAspectRatio: false,
        legend: {
          display: false,
        },
        scales: {
          yAxes: [
            {
              ticks: {
                min: 0,
                max: 120,
                beginAtZero: true,
                callback: function (tick) {
                  return tick.toString() + '%';
                },
              },
            },
          ],
        },
        plugins: {
          datalabels: {
            anchor: 'end',
            align: 'end',
            formatter: function (value) {
              return `${value}%`;
            },
          },
        },
      };

      this.chartData = {
        labels: [
          `Normal Lung 0%`,
          `Normal Lung 1-10%`,
          `Pneumonia 11-20%`,
          `Pneumonia 21-30%`,
          `Pneumonia 31-40%`,
          `Severe 41-50%`,
          `Severe > 50%`,
          `NE`,
        ],
        datasets: [
          {
            data: [
              this.currentReportDetails.pneumoniaPercentageDict[`0`],
              this.currentReportDetails.pneumoniaPercentageDict[`1`],
              this.currentReportDetails.pneumoniaPercentageDict[`2`],
              this.currentReportDetails.pneumoniaPercentageDict[`3`],
              this.currentReportDetails.pneumoniaPercentageDict[`4`],
              this.currentReportDetails.pneumoniaPercentageDict[`5`],
              this.currentReportDetails.pneumoniaPercentageDict[`6`],
              this.currentReportDetails.pneumoniaPercentageDict[`NE`],
            ],
            backgroundColor: [
              `#D6FFA4`,
              `#99E895`,
              `#FFF5C9`,
              `#FFF084`,
              `#FFD16A`,
              `#FFEAEA`,
              `#FFC1C1`,
              `#D9F2FF`,
            ],
            borderColor: [
              `#6C9D31`,
              `#6C9D31`,
              `#B99913`,
              `#B99913`,
              `#B99913`,
              `#F15453`,
              `#F15453`,
              `#00A5E1`,
            ],
            borderWidth: 1,
            barPercentage: 0.2,
          },
        ],
      };
    }
  }

  @Emit(`requestAttachments`)
  spawnAttachmentSelection() {
    const params: SpawnAttachmentPage = {
      section: AuditReportSections.BRONCHOPNEUMONIA_SCORE,
      isAccepted: true,
    };
    return params;
  }
}
