












import { Component, Prop, Vue } from 'vue-property-decorator';
import { DisplayData } from '@/models/swine/report/report.model';
@Component({})
export default class SectionLayoutCard extends Vue {
  @Prop({
    type: Array,
    default: null,
  })
  displayData!: DisplayData[];
}
