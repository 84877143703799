import { render, staticRenderFns } from "./BronchopneumoniaScore.vue?vue&type=template&id=cc9e52e8&scoped=true&v-if=currentReportDetails&"
import script from "./BronchopneumoniaScore.vue?vue&type=script&lang=ts&"
export * from "./BronchopneumoniaScore.vue?vue&type=script&lang=ts&"
import style0 from "./BronchopneumoniaScore.vue?vue&type=style&index=0&id=cc9e52e8&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cc9e52e8",
  null
  
)

export default component.exports