












import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  components: {},
})
export default class PleurisyTooltip extends Vue {
  @Prop({})
  headerTooltip!: string;

  @Prop({})
  descriptionTooltip!: string;
}
