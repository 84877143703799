






























































































































































































































































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import SectionLayoutCardAssumption from '@/components/swine/reports/SectionLayoutCardAssumption.vue';
import { PulmotilCalculationModalData } from '@/models/swine/economic-calculation.model';
import {
  DisplayDataAssumption,
  EconomicCalculationWithReportData,
} from '@/models/swine/report/report.model';
import {
  addCommas,
  addDecimalPointToNumbers,
} from '@/utils/formats';
import BasicBarChart from '@/components/swine/reports/BasicBarChart.vue';
import AssumptionReportLegendCard from './AssumptionReportLegendCard.vue';
import { ChartData, ChartOptions } from 'chart.js';
import PulmotilMedicationUpdateModal from './PulmotilMedicationUpdateModal.vue';
import AssumptionModal from './AssumptionModal.vue';
import PulmotilAssumptionModalUpdated from './PulmotilAssumptionModalUpdated.vue';
import { ReferenceTypeAdgReductionPercentages } from '@/utils/constants';
import { roundTo } from 'round-to';
import {
  calculateConsumptionPerLactactingSow,
  calculateTotalConsumptionOfAllLactactingSows,
  calculateTotalMedicationUsageInCleanSowProgram,
  calculateTotalCostOfMedicationInLactationInCleanSowProgram,
  calculateAdditionalCostPerKiloOfFeedsInCleanSowProgram,
  calculateTotalCostOfMedicationPerLactactingSowsInCleanSowProgram,
  calculateCostOfMedicationPerPigletsInCleanSowProgram,
  calculateFeedConsumptionPerNurseryPiglet,
  calculateTotalFeedConsumptionOfNurseryPiglets,
  calculateTotalMedicationUsageInEarlyIntervention,
  calculateTotalCostOfMedicationAtNurseryInEarlyIntervention,
  calculateAdditionalCostPerKiloOfFeedsInEarlyIntervention,
  calculateCostOfMedicationPerNurseryPigletsInEarlyIntervention,
  calculateFeedConsumptionPerGrowerPig,
  calculateTotalFeedConsumptionPerGrowerPig,
  calculateTotalMedicationUsageInGrowerProgram,
  calculateTotalCostOfMedicationAtGrowerInGrowerProgram,
  calculateAdditionalCostPerKiloOfFeedsInGrowerProgram,
  calculateCostOfMedicationPerGrowerPigInGrowerProgram,
  calculateTotalMedicationInvestmentPerPig,
  calculateTotalInvestmentForGrowerPigsProduced,
} from '@/utils/economic-calculation/pulmotil/formula';

@Component({
  components: {
    SectionLayoutCardAssumption,
    BasicBarChart,
    AssumptionReportLegendCard,
    PulmotilMedicationUpdateModal,
    AssumptionModal,
    PulmotilAssumptionModalUpdated,
  },
})
export default class PulmotilAssumptionReport extends Vue {
  noAssumption = true;
  chartData: Chart.ChartData | null = null;
  options: Chart.ChartOptions | null = null;
  updateMedicationModalStatus = false;
  assumptionModalStatus = false;
  addAssumptionButton = true;

  @Prop({
    type: Object,
    required: true,
    default: {},
  })
  calculatorModalInputDataRetrieve!: PulmotilCalculationModalData;

  @Prop({
    type: Object,
    default: null,
  })
  currentReportDetails!: EconomicCalculationWithReportData;

  standardizeValue(data: number) {
    const value = roundTo(data, 1);
    return addDecimalPointToNumbers(value);
  }

  // Number of Grower Pigs represents Number Of Animal Heads
  get numberOfAnimalHeads() {
    return this.calculatorModalInputDataRetrieve.numberOfGrowerPigs;
  }

  get computedChartOptions(): ChartOptions {
    return {
      maintainAspectRatio: false,
      legend: {
        display: false,
      },
      scales: {
        yAxes: [
          {
            gridLines: {
              drawBorder: false,
            },
            ticks: {
              min: 0,
              beginAtZero: true,
              padding: 16,
              callback: (tick) => {
                //arrow function to unscope "this" from this.calculatorModalInputDataRetrieve.currencySymbol
                return `${
                  this.calculatorModalInputDataRetrieve.currencySymbol
                } ${addCommas(tick.toString())}`;
              },
            },
            stacked: true,
          },
        ],
        xAxes: [
          {
            gridLines: {
              display: false,
              drawBorder: false,
            },
            stacked: true,
          },
        ],
      },
      plugins: {
        datalabels: {
          anchor: 'end',
          align: 'end',
          formatter: function (value) {
            return `${value}`;
          },
          display: false,
        },
      },
    };
  }

  get computedChartData(): ChartData {
    return {
      labels: ['Economic Loss', 'Medication Program'],
      datasets: [
        {
          label: 'Bronchpneumonic Economic Loss',
          data: [Number(this.totalAdditionalFeedCostPer1000.toFixed(2))],
          backgroundColor: [`#FFEAEA`],
          borderColor: [`#FF4C4B`],
          borderWidth: 1,
          barPercentage: 0.2,
        },
        {
          label: 'Pleurisy Economic Loss',
          data: [Number(this.totalCostOfAPP.toFixed(2))],
          backgroundColor: [`#FFC1C1`],
          borderColor: [`#FF4C4B`],
          borderWidth: 1,
          barPercentage: 0.2,
        },
        {
          data: [0, Number(this.totalInvestmentForGrowerProduced.toFixed(2))],
          backgroundColor: '#FFF5C9',
          borderColor: '#745E05',
          borderWidth: 1,
          barPercentage: 0.2,
        },
      ],
    };
  }

  get assumptionValuesPresent() {
    const economicCalculationDetails = this.calculatorModalInputDataRetrieve;
    if (economicCalculationDetails) {
      const {
        bronchopneumoniaAssumptionNormalLungsLowPercentage,
        bronchopneumoniaAssumptionNormalLungsHighPercentage,
        bronchopneumoniaAssumptionLowPneumonia,
        bronchopneumoniaAssumptionNormalPneumonia,
        bronchopneumoniaAssumptionHighPneumonia,
        bronchopneumoniaAssumptionLowSeverePneumonia,
        bronchopneumoniaAssumptionHighSeverePneumonia,
        p3AndP4PrevalenceAssumption,
        appPleurisyPrevalenceAssumption,
      } = economicCalculationDetails;
      const valuesExist = [
        bronchopneumoniaAssumptionNormalLungsLowPercentage,
        bronchopneumoniaAssumptionNormalLungsHighPercentage,
        bronchopneumoniaAssumptionLowPneumonia,
        bronchopneumoniaAssumptionNormalPneumonia,
        bronchopneumoniaAssumptionHighPneumonia,
        bronchopneumoniaAssumptionLowSeverePneumonia,
        bronchopneumoniaAssumptionHighSeverePneumonia,
        p3AndP4PrevalenceAssumption,
        appPleurisyPrevalenceAssumption,
      ];
      const assumptionValuesBoolean = valuesExist.every(
        (value) => Boolean(value) === true || value === 0,
      ); //if values are truthy or 0 will return true
      return assumptionValuesBoolean;
    } else {
      return false;
    }
  }

  get firstAssumption() {
    if (this.assumptionValuesPresent) {
      return false;
    } else {
      return true;
    }
  }

  get sectionCardValues() {
    const totalAdditionalFeedCost = `${
      this.addComma(this.totalAdditionalFeedCostPer1000, 2, false) == '-' ||
      this.numberOfAnimalHeads == 0
        ? '-'
        : this.calculatorModalInputDataRetrieve.currencySymbol +
          ' ' +
          this.addComma(this.totalAdditionalFeedCostPer1000, 2, false)
    }`;
    const totalInvestment = this.totalInvestmentForGrowerProduced
      ? `${
          this.calculatorModalInputDataRetrieve.currencySymbol
        } ${this.addComma(this.totalInvestmentForGrowerProduced, 2, false)}`
      : '-';
    const totalCostOfAPP = `${
      this.addComma(this.totalCostOfAPP, 2, false) == '-' ||
      this.numberOfAnimalHeads == 0
        ? '-'
        : this.calculatorModalInputDataRetrieve.currencySymbol +
          ' ' +
          this.addComma(this.totalCostOfAPP, 2, false)
    }`;
    return {
      totalAdditionalFeedCost,
      totalCostOfAPP,
      totalInvestment,
    };
  }

  get sectionCardDetails(): DisplayDataAssumption[] {
    let sectionCardDetailsList: DisplayDataAssumption[] = [];
    if (this.numberOfAnimalHeads !== null) {
      sectionCardDetailsList = [
        {
          headerText: 'Bronchopneumonia Economic Loss',
          headerValue: this.sectionCardValues.totalAdditionalFeedCost,
          subText: 'Additional Feed Cost /pig',
          subValue: `${
            this.addComma(
              this.totalAdditionalFeedCostPer1000 / this.numberOfAnimalHeads,
              2,
              false,
            ) == '-'
              ? ''
              : this.calculatorModalInputDataRetrieve.currencySymbol
          } ${this.addComma(
            this.totalAdditionalFeedCostPer1000 / this.numberOfAnimalHeads,
            2,
            false,
          )}`,
        },
        {
          headerText: 'Pleurisy Economic Loss',
          headerValue: this.sectionCardValues.totalCostOfAPP,
          subText: 'Cost of Pleurisy /pig',
          subValue: `${
            this.addComma(
              this.totalCostOfAPP / this.numberOfAnimalHeads,
              2,
              false,
            ) == '-'
              ? ''
              : this.calculatorModalInputDataRetrieve.currencySymbol
          } ${this.addComma(
            this.totalCostOfAPP / this.numberOfAnimalHeads,
            2,
            false,
          )}`,
        },
        {
          headerText: 'Medication Program',
          headerValue: `${this.currentCurrencySymbol} ${this.addComma(
            this.totalInvestmentForGrowerProduced,
            2,
            false,
          )}`,
          subText: 'Medication Investment /pig',
          subValue: `${this.currentCurrencySymbol} ${this.addComma(
            this.totalMedicationInvestmentPerPig,
            2,
            false,
          )}`,
        },
      ];
    }
    return sectionCardDetailsList;
  }

  // Broncho Related

  get adgReductionPercentages() {
    let referenceTypeAdgReductionPercentagesResults: number[] = [];
    if (this.calculatorModalInputDataRetrieve.referenceType) {
      referenceTypeAdgReductionPercentagesResults =
        ReferenceTypeAdgReductionPercentages[
          this.calculatorModalInputDataRetrieve.referenceType
        ];
    }
    return referenceTypeAdgReductionPercentagesResults;
  }

  get adgFormula() {
    let adgFormulaArray: number[] = [];
    adgFormulaArray = this.adgReductionPercentages.map(
      (adgPercentage: number) => {
        if (
          this.calculatorModalInputDataRetrieve &&
          this.calculatorModalInputDataRetrieve.adgValue
        ) {
          return (
            this.calculatorModalInputDataRetrieve.adgValue -
            this.calculatorModalInputDataRetrieve.adgValue * adgPercentage
          );
        }
        return 0;
      },
    );

    return adgFormulaArray;
  }

  firstFinalWeight(adgValueSet: number | null, weanToMarket: number | null) {
    if (adgValueSet && weanToMarket) {
      return (adgValueSet * weanToMarket) / 1000;
    } else {
      return 0;
    }
  }

  get animalPercentages() {
    if (this.calculatorModalInputDataRetrieve.pneumoniaSeverityDict) {
      return Object.values(
        this.calculatorModalInputDataRetrieve.pneumoniaSeverityDict,
      ).map((number) => {
        try {
          return Number((number / this.animalTotal).toFixed(4));
        } catch {
          return 0;
        }
      });
    }
    return [];
  }

  get animalTotal() {
    let total = 0;
    for (const key in this.calculatorModalInputDataRetrieve
      .pneumoniaSeverityDict) {
      if (key !== 'NE') {
        total += this.calculatorModalInputDataRetrieve.pneumoniaSeverityDict[
          key
        ];
      }
    }
    return total;
  }

  get numberOfAnimalHeadsSold() {
    let animalPercentagesArray: number[] = [];

    animalPercentagesArray = this.animalPercentages.map((percentage) => {
      if (
        this.calculatorModalInputDataRetrieve !== null &&
        this.numberOfAnimalHeads !== null
      ) {
        return this.numberOfAnimalHeads * percentage;
      } else {
        return 0;
      }
    });
    return animalPercentagesArray;
  }

  get daysToReach() {
    return this.adgFormula.map((adg: number) => {
      try {
        return (
          (this.firstFinalWeight(
            this.calculatorModalInputDataRetrieve.adgValue,
            this.calculatorModalInputDataRetrieve.weanMarketDays,
          ) /
            adg) *
          1000
        );
      } catch {
        return 0;
      }
    });
  }

  get extraDaysToReach() {
    return this.daysToReach.map((days: number) => {
      try {
        return days - this.daysToReach[0];
      } catch {
        return 0;
      }
    });
  }

  get extraFeedConsumed() {
    let extraFeedConsumedArray: number[] = [];

    extraFeedConsumedArray = this.extraDaysToReach.map((extraDay: number) => {
      try {
        if (this.calculatorModalInputDataRetrieve.adfiValue !== null) {
          return extraDay * this.calculatorModalInputDataRetrieve.adfiValue;
        } else {
          return 0;
        }
      } catch {
        return 0;
      }
    });
    return extraFeedConsumedArray;
  }

  get additionalFeedCost() {
    let additionalFeedCostArray: number[] = [];
    additionalFeedCostArray = this.extraFeedConsumed.map(
      (extraFeed: number) => {
        if (
          this.calculatorModalInputDataRetrieve.additionalFeedCostPigRate !==
          null
        ) {
          try {
            return (
              extraFeed *
              this.calculatorModalInputDataRetrieve.additionalFeedCostPigRate
            );
          } catch {
            return 0;
          }
        } else {
          return 0;
        }
      },
    );
    return additionalFeedCostArray;
  }

  get additionalFeedCostPer1000() {
    return this.additionalFeedCost.map((addFeedCost: number, index: number) => {
      return addFeedCost * this.numberOfAnimalHeadsSold[index];
    });
  }

  get totalAdditionalFeedCostPer1000() {
    function reducer(total: number, value: number) {
      return total + value;
    }
    return this.additionalFeedCostPer1000.reduce(reducer);
  }

  // Pleurisy Related

  get pleurisyPrevalenceBackValue() {
    let pleurisyPrevalanceBackValueResult = 0;
    if (
      this.calculatorModalInputDataRetrieve.appPleurisyPrevalence !==
        undefined &&
      this.numberOfAnimalHeads !== null
    ) {
      pleurisyPrevalanceBackValueResult =
        (Number(
          this.calculatorModalInputDataRetrieve.appPleurisyPrevalence.toFixed(
            2,
          ),
        ) *
          this.numberOfAnimalHeads) /
        100;
    }
    return pleurisyPrevalanceBackValueResult;
  }

  get p3P4BackValue() {
    let p3P4BackValueResult = 0;
    if (
      this.calculatorModalInputDataRetrieve.p3AndP4Prevalence !== undefined &&
      this.numberOfAnimalHeads !== null
    ) {
      p3P4BackValueResult =
        (Number(
          this.calculatorModalInputDataRetrieve.p3AndP4Prevalence.toFixed(2),
        ) *
          this.numberOfAnimalHeads) /
        100;
    }
    return p3P4BackValueResult;
  }

  get postWeaningMortalityBackValue() {
    let postWeaningMortalityBackValueResult = 0;
    if (
      this.calculatorModalInputDataRetrieve.postWeaningMortality !== null &&
      this.numberOfAnimalHeads !== null
    ) {
      postWeaningMortalityBackValueResult =
        (this.calculatorModalInputDataRetrieve.postWeaningMortality *
          this.numberOfAnimalHeads) /
        100;
    }
    return postWeaningMortalityBackValueResult;
  }

  get weaningMortalityPercentage() {
    return this.calculatorModalInputDataRetrieve.postWeaningMortality;
  }

  get weaningMortalityHeads() {
    let weaningMortalityHeadsResult = 0;
    if (
      this.weaningMortalityPercentage !== null &&
      this.numberOfAnimalHeads !== null
    ) {
      weaningMortalityHeadsResult =
        (this.weaningMortalityPercentage * this.numberOfAnimalHeads) / 100;
    }
    return weaningMortalityHeadsResult;
  }

  get totalCostPostWeaning() {
    let totalCostPostWeaningResult = 0;
    if (
      this.calculatorModalInputDataRetrieve.liveWeightPrice !== null &&
      this.calculatorModalInputDataRetrieve.targetMarketWeight !== null
    ) {
      totalCostPostWeaningResult =
        this.weaningMortalityHeads *
        this.calculatorModalInputDataRetrieve.liveWeightPrice *
        this.calculatorModalInputDataRetrieve.targetMarketWeight;
    }
    return totalCostPostWeaningResult;
  }

  get effectADGPigs() {
    let effectADGPigsResult = 0;
    if (
      this.calculatorModalInputDataRetrieve.p3AndP4Prevalence !== undefined &&
      this.calculatorModalInputDataRetrieve.weanMarketDays !== null
    ) {
      effectADGPigsResult =
        (Number(
          this.calculatorModalInputDataRetrieve.p3AndP4Prevalence.toFixed(2),
        ) *
          2 *
          this.calculatorModalInputDataRetrieve.weanMarketDays) /
        1000;
    }
    return effectADGPigsResult;
  }

  get totalCostADGPigs() {
    let totalCostADGPigsResult = 0;
    if (this.calculatorModalInputDataRetrieve.liveWeightPrice !== null) {
      totalCostADGPigsResult =
        this.p3P4BackValue *
        this.effectADGPigs *
        this.calculatorModalInputDataRetrieve.liveWeightPrice;
    }
    return totalCostADGPigsResult;
  }

  get effectOnSlaughterWeight() {
    let effectOnSlaughterWeightResult = 0;
    if (
      this.calculatorModalInputDataRetrieve !== undefined &&
      this.calculatorModalInputDataRetrieve.p3AndP4Prevalence
    ) {
      effectOnSlaughterWeightResult =
        Number(
          this.calculatorModalInputDataRetrieve.p3AndP4Prevalence.toFixed(2),
        ) * 0.07;
    }
    return effectOnSlaughterWeightResult;
  }

  get totalEffectOnSlaughterWeight() {
    let totalEffectOnSlaughterWeightResult = 0;
    if (this.calculatorModalInputDataRetrieve.liveWeightPrice !== null) {
      totalEffectOnSlaughterWeightResult =
        this.p3P4BackValue *
        this.effectOnSlaughterWeight *
        this.calculatorModalInputDataRetrieve.liveWeightPrice;
    }
    return totalEffectOnSlaughterWeightResult;
  }

  get effectOnSlaughterAge() {
    let effectOnSlaughterAgeResult = 0;
    if (this.calculatorModalInputDataRetrieve.p3AndP4Prevalence !== undefined) {
      effectOnSlaughterAgeResult =
        Number(
          this.calculatorModalInputDataRetrieve.p3AndP4Prevalence.toFixed(2),
        ) * 0.26;
    }
    return effectOnSlaughterAgeResult;
  }

  get totalEffectSlaughterAge() {
    let totalEffectSlaughterAgeResult = 0;
    if (this.calculatorModalInputDataRetrieve.averageFeedPrice !== null) {
      totalEffectSlaughterAgeResult =
        this.p3P4BackValue *
        this.effectOnSlaughterAge *
        2 *
        this.calculatorModalInputDataRetrieve.averageFeedPrice;
    }
    return totalEffectSlaughterAgeResult;
  }

  get totalCostOfAPP() {
    return (
      this.totalCostPostWeaning +
      this.totalCostADGPigs +
      this.totalEffectOnSlaughterWeight +
      this.totalEffectSlaughterAge
    );
  }

  // Medication Section

  get growerHeads() {
    return this.calculatorModalInputDataRetrieve.numberOfGrowerPigs;
  }

  get costOfMedicationPerPigletsHeads() {
    return this.calculatorModalInputDataRetrieve.averageLitterSizeBornAlive;
  }

  get currentCurrencySymbol() {
    return this.calculatorModalInputDataRetrieve.currencySymbol;
  }

  // Feed Consumption
  get consumptionPerLactactingSow() {
    if (
      this.calculatorModalInputDataRetrieve.adfiAtGestation &&
      this.calculatorModalInputDataRetrieve.numberOfDaysPreFarrowing &&
      this.calculatorModalInputDataRetrieve.adfiAtLactation &&
      this.calculatorModalInputDataRetrieve.numberOfDaysPostFarrowing
    ) {
      return calculateConsumptionPerLactactingSow(
        this.calculatorModalInputDataRetrieve.adfiAtGestation,
        this.calculatorModalInputDataRetrieve.numberOfDaysPreFarrowing,
        this.calculatorModalInputDataRetrieve.adfiAtLactation,
        this.calculatorModalInputDataRetrieve.numberOfDaysPostFarrowing,
      );
    }
    return 0;
  }

  get totalConsumptionOfAllLactactingSows() {
    return calculateTotalConsumptionOfAllLactactingSows(
      this.consumptionPerLactactingSow,
      this.calculatorModalInputDataRetrieve.numberOfSowsFarrowingPerMonth,
    );
  }

  // Medication program - Clean Sow Program
  get totalMedicationUsageInCleanSowProgram() {
    if (this.calculatorModalInputDataRetrieve.medicationDoseInCleanSowProgram) {
      return calculateTotalMedicationUsageInCleanSowProgram(
        this.totalConsumptionOfAllLactactingSows,
        this.calculatorModalInputDataRetrieve.medicationDoseInCleanSowProgram,
      );
    }
    return 0;
  }

  get totalCostOfMedicationInLactation() {
    if (
      this.calculatorModalInputDataRetrieve
        .costOfMedicationPerKiloInCleanSowProgram
    ) {
      return calculateTotalCostOfMedicationInLactationInCleanSowProgram(
        this.calculatorModalInputDataRetrieve
          .costOfMedicationPerKiloInCleanSowProgram,
        this.totalMedicationUsageInCleanSowProgram,
      );
    }
    return 0;
  }

  get additionalCostPerKiloOfFeedsInCleanSowProgram() {
    return calculateAdditionalCostPerKiloOfFeedsInCleanSowProgram(
      this.totalCostOfMedicationInLactation,
      this.totalConsumptionOfAllLactactingSows,
    );
  }

  get totalCostOfMedicationPerLactactingSowsInCleanSowProgram() {
    return calculateTotalCostOfMedicationPerLactactingSowsInCleanSowProgram(
      this.totalCostOfMedicationInLactation,
      this.calculatorModalInputDataRetrieve.numberOfSowsFarrowingPerMonth,
    );
  }

  get costOfMedicationPerPiglets() {
    if (this.calculatorModalInputDataRetrieve.averageLitterSizeBornAlive) {
      return calculateCostOfMedicationPerPigletsInCleanSowProgram(
        this.totalCostOfMedicationPerLactactingSowsInCleanSowProgram,
        this.calculatorModalInputDataRetrieve.averageLitterSizeBornAlive,
      );
    }
    return 0;
  }

  // Nursery Feed
  get feedConsumptionPerNurseryPiglet() {
    if (
      this.calculatorModalInputDataRetrieve.numberOfDaysFedInNursery &&
      this.calculatorModalInputDataRetrieve.feedConsumptionPerDayInNursery
    ) {
      return calculateFeedConsumptionPerNurseryPiglet(
        this.calculatorModalInputDataRetrieve.numberOfDaysFedInNursery,
        this.calculatorModalInputDataRetrieve.feedConsumptionPerDayInNursery,
      );
    }
    return 0;
  }

  get totalFeedConsumptionOfNurseryPiglets() {
    return calculateTotalFeedConsumptionOfNurseryPiglets(
      this.feedConsumptionPerNurseryPiglet,
      this.calculatorModalInputDataRetrieve.numberOfPigletsAtNursery,
    );
  }

  // Medication Program - Early Intervention
  get totalMedicationUsageInEarlyIntervention() {
    if (
      this.calculatorModalInputDataRetrieve
        .medicationDoseInEarlyInterventionProgram
    ) {
      return calculateTotalMedicationUsageInEarlyIntervention(
        this.totalFeedConsumptionOfNurseryPiglets,
        this.calculatorModalInputDataRetrieve
          .medicationDoseInEarlyInterventionProgram,
      );
    }
    return 0;
  }

  get totalCostOfMedicationAtNurseryInEarlyIntervention() {
    if (
      this.calculatorModalInputDataRetrieve
        .costOfMedicationPerKiloInEarlyInterventionProgram
    ) {
      return calculateTotalCostOfMedicationAtNurseryInEarlyIntervention(
        this.totalMedicationUsageInEarlyIntervention,
        this.calculatorModalInputDataRetrieve
          .costOfMedicationPerKiloInEarlyInterventionProgram,
      );
    }
    return 0;
  }

  get additionalCostPerKiloOfFeedsInEarlyIntervention() {
    return calculateAdditionalCostPerKiloOfFeedsInEarlyIntervention(
      this.totalCostOfMedicationAtNurseryInEarlyIntervention,
      this.totalFeedConsumptionOfNurseryPiglets,
    );
  }

  get costOfMedicationPerNurseryPiglets() {
    return calculateCostOfMedicationPerNurseryPigletsInEarlyIntervention(
      this.totalCostOfMedicationAtNurseryInEarlyIntervention,
      this.calculatorModalInputDataRetrieve.numberOfPigletsAtNursery,
    );
  }

  // Grower Feed
  get feedConsumptionPerGrowerPig() {
    if (
      this.calculatorModalInputDataRetrieve.numberOfDaysFedInGrower &&
      this.calculatorModalInputDataRetrieve.feedConsumptionPerDayInGrower
    ) {
      return calculateFeedConsumptionPerGrowerPig(
        this.calculatorModalInputDataRetrieve.numberOfDaysFedInGrower,
        this.calculatorModalInputDataRetrieve.feedConsumptionPerDayInGrower,
      );
    }
    return 0;
  }

  get totalFeedConsumptionPerGrowerPig() {
    if (
      this.calculatorModalInputDataRetrieve.numberOfDaysFedInGrower &&
      this.calculatorModalInputDataRetrieve.feedConsumptionPerDayInGrower &&
      this.calculatorModalInputDataRetrieve.numberOfGrowerPigs
    ) {
      return calculateTotalFeedConsumptionPerGrowerPig(
        this.calculatorModalInputDataRetrieve.numberOfDaysFedInGrower,
        this.calculatorModalInputDataRetrieve.feedConsumptionPerDayInGrower,
        this.calculatorModalInputDataRetrieve.numberOfGrowerPigs,
      );
    }
    return 1;
  }

  // Medication Program - Starter / Grower
  get totalMedicationUsageInGrowerProgram() {
    if (!this.calculatorModalInputDataRetrieve.medicationDoseInGrowerProgram) {
      return 0;
    }
    return calculateTotalMedicationUsageInGrowerProgram(
      this.totalFeedConsumptionPerGrowerPig,
      this.calculatorModalInputDataRetrieve.medicationDoseInGrowerProgram,
    );
  }

  get totalCostOfMedicationAtGrower() {
    if (
      !this.calculatorModalInputDataRetrieve
        .costOfMedicationPerKiloInGrowerProgram
    ) {
      return 0;
    }

    return calculateTotalCostOfMedicationAtGrowerInGrowerProgram(
      this.totalMedicationUsageInGrowerProgram,
      this.calculatorModalInputDataRetrieve
        .costOfMedicationPerKiloInGrowerProgram,
    );
  }

  get additionalCostPerKiloOfFeedsInGrower() {
    return calculateAdditionalCostPerKiloOfFeedsInGrowerProgram(
      this.totalCostOfMedicationAtGrower,
      this.totalFeedConsumptionPerGrowerPig,
    );
  }

  get costOfMedicationPerGrowerPigs() {
    return calculateCostOfMedicationPerGrowerPigInGrowerProgram(
      this.totalCostOfMedicationAtGrower,
      this.calculatorModalInputDataRetrieve.numberOfGrowerPigs,
    );
  }

  // Medical Investment Section
  get totalMedicationInvestmentPerPig() {
    return calculateTotalMedicationInvestmentPerPig(
      this.costOfMedicationPerPiglets,
      this.costOfMedicationPerNurseryPiglets,
      this.costOfMedicationPerGrowerPigs,
    );
  }

  get totalInvestmentForGrowerProduced() {
    return calculateTotalInvestmentForGrowerPigsProduced(
      this.totalMedicationInvestmentPerPig,
      this.calculatorModalInputDataRetrieve.numberOfGrowerPigs,
    );
  }

  addComma(targetNumber: number, toFixedNumber: number, onlyToString: boolean) {
    if (isNaN(targetNumber)) {
      return '-';
    } else if (!onlyToString) {
      const roundedNumber = targetNumber.toFixed(toFixedNumber);
      return addCommas(roundedNumber);
    } else if (onlyToString) {
      const numberToString = targetNumber.toString();
      return addCommas(numberToString);
    }
  }

  toggleUpdateAssumption() {
    this.assumptionModalStatus = !this.assumptionModalStatus;
  }

  toggleAddAssumptionButton() {
    this.addAssumptionButton = false;
  }

  toggleUpdateMedicationModal() {
    this.updateMedicationModalStatus = !this.updateMedicationModalStatus;
  }

  updateMedicationProgramReportData(
    updateMedicationModalData: PulmotilCalculationModalData,
  ) {
    this.$emit('update', updateMedicationModalData);
    this.updateMedicationModalStatus = !this.updateMedicationModalStatus;
  }

  updateAssumptionReportData(
    assumptionModalInputObject: PulmotilCalculationModalData,
  ) {
    this.$emit('update', assumptionModalInputObject);
    this.assumptionModalStatus = !this.assumptionModalStatus;
    this.noAssumption = false;
  }
}
