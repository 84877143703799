import { EconomicCalculationModalDataType } from '@/models/swine/economic-calculation.model';
import { DisplayData } from '@/models/swine/report/report.model';
import { EconomicCalculationType } from '@/utils/constants';
import {
  blue,
  darkGrey,
  lightGrey,
  pink,
  red,
} from '@/utils/download-pdf/colors';
import {
  calculateConsumptionPerLactactingSow,
  calculateTotalConsumptionOfAllLactactingSows,
  calculateTotalMedicationUsageInCleanSowProgram,
  calculateTotalCostOfMedicationInLactationInCleanSowProgram,
  calculateAdditionalCostPerKiloOfFeedsInCleanSowProgram,
  calculateTotalCostOfMedicationPerLactactingSowsInCleanSowProgram,
  calculateCostOfMedicationPerPigletsInCleanSowProgram,
  calculateFeedConsumptionPerNurseryPiglet,
  calculateTotalFeedConsumptionOfNurseryPiglets,
  calculateTotalMedicationUsageInEarlyIntervention,
  calculateTotalCostOfMedicationAtNurseryInEarlyIntervention,
  calculateAdditionalCostPerKiloOfFeedsInEarlyIntervention,
  calculateCostOfMedicationPerNurseryPigletsInEarlyIntervention,
  calculateFeedConsumptionPerGrowerPig,
  calculateTotalFeedConsumptionPerGrowerPig,
  calculateTotalMedicationUsageInGrowerProgram,
  calculateTotalCostOfMedicationAtGrowerInGrowerProgram,
  calculateAdditionalCostPerKiloOfFeedsInGrowerProgram,
  calculateCostOfMedicationPerGrowerPigInGrowerProgram,
  calculateTotalMedicationInvestmentPerPig,
  calculateTotalInvestmentForGrowerPigsProduced,
} from '@/utils/economic-calculation/pulmotil/formula';
import { addCommas } from '@/utils/formats';
import { roundTo } from 'round-to';
import { imgElancoLogo } from '@/utils/download-pdf/images';
class MedicationProgram {
  transferredModalData: EconomicCalculationModalDataType;
  currentCurrencyDetails: any;

  constructor(
    transferredModalData: EconomicCalculationModalDataType,
    currentCurrencyDetails: any
  ) {
    this.transferredModalData = transferredModalData;
    this.currentCurrencyDetails = currentCurrencyDetails;
  }

  /* General */
  // This is important as Standard and Malaysia uses this to perform their calculation
  get numberOfAnimalHeads(): number {
    // Malaysia Calculation
    if (
      'numberOfAnimalHeads' in this.transferredModalData &&
      this.transferredModalData.economicCalculationType ===
        EconomicCalculationType.pleurisy
    ) {
      return this.transferredModalData.numberOfAnimalHeads
        ? this.transferredModalData.numberOfAnimalHeads
        : 0;
    }

    // Standard Calculation
    if (
      'numberOfGrowerPigs' in this.transferredModalData &&
      this.transferredModalData.economicCalculationType ===
        EconomicCalculationType.pulmotil
    ) {
      return this.transferredModalData.numberOfGrowerPigs;
    }

    return 0;
  }

  /*
    Malaysia Economic Calculation Section
  */
  get sectionCardDetailsMedication() {
    return [
      {
        headerText: `Medication Investment /pig`,
        subText: ``,
        value: this.medicationInvestmentPig,
      },
      {
        headerText: `Total Investment`,
        subText: ``,
        value: this.totalInvestment,
      },
    ];
  }

  get cleanSowDosageValue() {
    if (
      'cleanSowDosage' in this.transferredModalData &&
      this.transferredModalData.cleanSowDosage
    ) {
      return this.transferredModalData.cleanSowDosage;
    }

    return null;
  }

  get cleanSowDosage() {
    if (this.cleanSowDosageValue === null) {
      return '-';
    } else {
      return this.addComma(Number(this.cleanSowDosageValue).toFixed(2));
    }
  }

  get cleanSowMedicinePriceValue() {
    if (
      'cleanSowMedicinePrice' in this.transferredModalData &&
      this.transferredModalData.cleanSowMedicinePrice
    ) {
      return this.transferredModalData.cleanSowMedicinePrice;
    }
    return null;
  }

  get cleanSowMedicinePrice() {
    if (this.cleanSowMedicinePriceValue === null) {
      return '-';
    } else {
      return this.addComma(Number(this.cleanSowMedicinePriceValue).toFixed(2));
    }
  }

  get cleanSowMedicationCostFeedFormula() {
    if (
      'cleanSowMedicinePrice' in this.transferredModalData &&
      this.transferredModalData.cleanSowMedicinePrice &&
      this.cleanSowDosageValue
    ) {
      return (
        this.cleanSowDosageValue *
        this.transferredModalData.cleanSowMedicinePrice
      );
    }
    return 0;
  }

  get cleanSowMedicationCostFeed() {
    if (this.cleanSowMedicationCostFeedFormula === 0) {
      return '-';
    } else {
      return this.addComma(
        Number(this.cleanSowMedicationCostFeedFormula).toFixed(2)
      );
    }
  }

  get cleanSowMedicationCostHeadFormula() {
    if (
      'cleanSowPSRatio' in this.transferredModalData &&
      this.transferredModalData.cleanSowPSRatio &&
      this.transferredModalData.cleanSowFeedIntake &&
      this.transferredModalData.cleanSowDuration
    ) {
      return (
        (((this.numberOfAnimalHeads /
          this.transferredModalData.cleanSowPSRatio) *
          0.2 *
          this.transferredModalData.cleanSowFeedIntake *
          this.transferredModalData.cleanSowDuration) /
          1000) *
        this.cleanSowMedicationCostFeedFormula
      );
    }
    return 0;
  }

  get cleanSowMedicationCostHead() {
    if (this.cleanSowMedicationCostHeadFormula === 0) {
      return '-';
    } else {
      return this.addComma(
        Number(this.cleanSowMedicationCostHeadFormula).toFixed(2)
      );
    }
  }

  get cleanSowCostPerPigFormula() {
    return this.cleanSowMedicationCostHeadFormula / this.numberOfAnimalHeads;
  }

  get cleanSowCostPerPig() {
    if (this.cleanSowCostPerPigFormula === 0) {
      return '-';
    } else {
      return this.addComma(Number(this.cleanSowCostPerPigFormula).toFixed(2));
    }
  }

  get startStrongDosageValue() {
    if (
      'startStrongDosage' in this.transferredModalData &&
      this.transferredModalData.startStrongDosage
    ) {
      return this.transferredModalData.startStrongDosage;
    }
    return 0;
  }

  get startStrongDosage() {
    if (this.startStrongDosageValue === null) {
      return '-';
    } else {
      return this.addComma(Number(this.startStrongDosageValue).toFixed(2));
    }
  }

  get startStrongMedicinePriceValue() {
    if (
      'startStrongMedicinePrice' in this.transferredModalData &&
      this.transferredModalData.startStrongMedicinePrice
    ) {
      return this.transferredModalData.startStrongMedicinePrice;
    }
    return 0;
  }

  get startStrongMedicinePrice() {
    if (this.startStrongMedicinePriceValue === null) {
      return '-';
    } else {
      return this.addComma(
        Number(this.startStrongMedicinePriceValue).toFixed(2)
      );
    }
  }

  get startStrongMedicationCostFeedFormula() {
    if (
      'startStrongMedicinePrice' in this.transferredModalData &&
      this.transferredModalData.startStrongMedicinePrice
    ) {
      return (
        this.startStrongDosageValue *
        this.transferredModalData.startStrongMedicinePrice
      );
    }
    return 0;
  }

  get startStrongMedicationCostFeed() {
    if (this.startStrongMedicationCostFeedFormula === 0) {
      return '-';
    } else {
      return this.addComma(
        Number(this.startStrongMedicationCostFeedFormula).toFixed(2)
      );
    }
  }

  get startStrongMedicationCostHeadFormula() {
    if (
      'startStrongFeedIntake' in this.transferredModalData &&
      this.transferredModalData.startStrongFeedIntake &&
      this.transferredModalData.startStrongDuration
    ) {
      return (
        ((this.numberOfAnimalHeads *
          this.transferredModalData.startStrongFeedIntake *
          this.transferredModalData.startStrongDuration) /
          1000) *
        this.startStrongMedicationCostFeedFormula
      );
    }
    return 0;
  }

  get startStrongMedicationCostHead() {
    if (this.startStrongMedicationCostHeadFormula === 0) {
      return '-';
    } else {
      return this.addComma(
        Number(this.startStrongMedicationCostHeadFormula).toFixed(2)
      );
    }
  }

  get startStrongCostPerPigFormula() {
    return this.startStrongMedicationCostHeadFormula / this.numberOfAnimalHeads;
  }

  get startStrongCostPerPig() {
    if (this.startStrongCostPerPigFormula === 0) {
      return '-';
    } else {
      return this.addComma(
        Number(this.startStrongCostPerPigFormula).toFixed(2)
      );
    }
  }

  get strategicProgramDosageValue() {
    if (
      'strategicProgramDosage' in this.transferredModalData &&
      this.transferredModalData.strategicProgramDosage
    ) {
      return this.transferredModalData.strategicProgramDosage;
    }
    return 0;
  }

  get strategicProgramDosage() {
    if (this.strategicProgramDosageValue === null) {
      return '-';
    } else {
      return this.addComma(Number(this.strategicProgramDosageValue).toFixed(2));
    }
  }

  get strategicProgramMedicinePriceValue() {
    if (
      'strategicProgramMedicinePrice' in this.transferredModalData &&
      this.transferredModalData.strategicProgramMedicinePrice
    ) {
      return this.transferredModalData.strategicProgramMedicinePrice;
    }
    return 0;
  }

  get strategicProgramMedicinePrice() {
    if (this.strategicProgramMedicinePriceValue === null) {
      return '-';
    } else {
      return this.addComma(
        Number(this.strategicProgramMedicinePriceValue).toFixed(2)
      );
    }
  }

  get strategicProgramMedicationCostFeedFormula() {
    if (
      'strategicProgramMedicinePrice' in this.transferredModalData &&
      this.transferredModalData.strategicProgramMedicinePrice
    ) {
      return (
        this.strategicProgramDosageValue *
        this.transferredModalData.strategicProgramMedicinePrice
      );
    }
    return 0;
  }

  get strategicProgramMedicationCostFeed() {
    if (this.strategicProgramMedicationCostFeedFormula === 0) {
      return '-';
    } else {
      return this.addComma(
        Number(this.strategicProgramMedicationCostFeedFormula).toFixed(2)
      );
    }
  }

  get strategicProgramMedicationCostHeadFormula() {
    if (
      'strategicProgramFeedIntake' in this.transferredModalData &&
      this.transferredModalData.strategicProgramFeedIntake &&
      this.transferredModalData.strategicProgramDuration
    ) {
      return (
        ((this.numberOfAnimalHeads *
          this.transferredModalData.strategicProgramFeedIntake *
          this.transferredModalData.strategicProgramDuration) /
          1000) *
        this.strategicProgramMedicationCostFeedFormula
      );
    }
    return 0;
  }

  get strategicProgramMedicationCostHead() {
    if (this.strategicProgramMedicationCostHeadFormula === 0) {
      return '-';
    } else {
      return this.addComma(
        Number(this.strategicProgramMedicationCostHeadFormula).toFixed(2)
      );
    }
  }

  get strategicProgramCostPerPigFormula() {
    return (
      this.strategicProgramMedicationCostHeadFormula / this.numberOfAnimalHeads
    );
  }

  get strategicProgramCostPerPig() {
    if (this.strategicProgramCostPerPigFormula === 0) {
      return '-';
    } else {
      return this.addComma(
        Number(this.strategicProgramCostPerPigFormula).toFixed(2)
      );
    }
  }

  get totalInvestment() {
    if (
      this.cleanSowMedicationCostHeadFormula +
        this.startStrongMedicationCostHeadFormula +
        this.strategicProgramMedicationCostHeadFormula ===
      0
    ) {
      return '-';
    } else {
      return `${
        this.currentCurrencyDetails.currentSelectedCurrency.text
      } ${this.addComma(
        Number(
          this.cleanSowMedicationCostHeadFormula +
            this.startStrongMedicationCostHeadFormula +
            this.strategicProgramMedicationCostHeadFormula
        ).toFixed(2)
      )}`;
    }
  }

  get medicationInvestmentPig() {
    if (
      (this.cleanSowMedicationCostHeadFormula +
        this.startStrongMedicationCostHeadFormula +
        this.strategicProgramMedicationCostHeadFormula) /
        this.numberOfAnimalHeads ===
      0
    ) {
      return '-';
    } else {
      return `${
        this.currentCurrencyDetails.currentSelectedCurrency.text
      } ${this.addComma(
        Number(
          (this.cleanSowMedicationCostHeadFormula +
            this.startStrongMedicationCostHeadFormula +
            this.strategicProgramMedicationCostHeadFormula) /
            this.numberOfAnimalHeads
        ).toFixed(2)
      )}`;
    }
  }

  /*
    Standard Economic Calculation Section
  */

  get lactationFeedCollapseTitle() {
    if ('numberOfSowsFarrowingPerMonth' in this.transferredModalData) {
      return `Lactation Feed ~ ${roundTo(
        this.transferredModalData.numberOfSowsFarrowingPerMonth,
        0
      )} heads`;
    }
    return `Lactation Feed ~ - heads`;
  }

  get nurseryFeedCollapseTitle() {
    if ('numberOfPigletsAtNursery' in this.transferredModalData) {
      return `Nursery Feed ~ ${roundTo(
        this.transferredModalData.numberOfPigletsAtNursery,
        0
      )} heads`;
    }
    return `Nursery Feed ~ - heads`;
  }

  get growerFeedCollapseTitle() {
    if ('numberOfGrowerPigs' in this.transferredModalData) {
      return `Grower Feed ~ ${roundTo(
        this.transferredModalData.numberOfGrowerPigs,
        0
      )} heads`;
    }
    return `G Feed ~ - heads`;
  }

  get cleanSowMedicineBrand() {
    return this.transferredModalData.cleanSowMedicineBrand || '-';
  }

  get earlyInterventionMedicineBrand() {
    return 'earlyInterventionMedicineBrand' in this.transferredModalData
      ? this.transferredModalData.earlyInterventionMedicineBrand
      : '-';
  }

  get growerMedicineBrand() {
    return 'growerMedicineBrand' in this.transferredModalData
      ? this.transferredModalData.growerMedicineBrand
      : '-';
  }

  get growerHeads() {
    return 'numberOfGrowerPigs' in this.transferredModalData
      ? roundTo(this.transferredModalData.numberOfGrowerPigs, 0)
      : '-';
  }

  get costOfMedicationPerPigletsHeads() {
    return 'averageLitterSizeBornAlive' in this.transferredModalData
      ? this.transferredModalData.averageLitterSizeBornAlive
      : 0;
  }

  get currentCurrencySymbol() {
    return this.transferredModalData.currencySymbol;
  }

  get sectionCardDetails(): DisplayData[] {
    return [
      {
        headerText: `Total Medication Investment / pig`,
        subText: ``,
        value: this.totalMedicationInvestmentPerPig
          ? `${this.currentCurrencySymbol} ${this.addComma(
              this.totalMedicationInvestmentPerPig.toFixed(2)
            )}`
          : `-`,
      },
      {
        headerText: `Total Investment for ${this.growerHeads} Grower Produced`,
        subText: ``,
        value: this.totalInvestmentForGrowerProduced
          ? `${this.currentCurrencySymbol} ${this.addComma(
              this.totalInvestmentForGrowerProduced.toFixed(2)
            )}`
          : `-`,
      },
    ];
  }
  // Feed Consumption
  get consumptionPerLactactingSow() {
    const adfiAtGestation =
      'adfiAtGestation' in this.transferredModalData &&
      this.transferredModalData.adfiAtGestation
        ? this.transferredModalData.adfiAtGestation
        : null;
    const numberOfDaysPreFarrowing =
      'numberOfDaysPreFarrowing' in this.transferredModalData &&
      this.transferredModalData.numberOfDaysPreFarrowing
        ? this.transferredModalData.numberOfDaysPreFarrowing
        : null;
    const adfiAtLactation =
      'adfiAtLactation' in this.transferredModalData &&
      this.transferredModalData.adfiAtLactation
        ? this.transferredModalData.adfiAtLactation
        : null;
    const numberOfDaysPostFarrowing =
      'numberOfDaysPostFarrowing' in this.transferredModalData &&
      this.transferredModalData.numberOfDaysPostFarrowing
        ? this.transferredModalData.numberOfDaysPostFarrowing
        : null;

    if (
      !adfiAtGestation &&
      !numberOfDaysPreFarrowing &&
      !adfiAtLactation &&
      !numberOfDaysPostFarrowing
    ) {
      return null;
    }

    return calculateConsumptionPerLactactingSow(
      adfiAtGestation || 0,
      numberOfDaysPreFarrowing || 0,
      adfiAtLactation || 0,
      numberOfDaysPostFarrowing || 0
    );
  }

  get totalConsumptionOfAllLactactingSows() {
    if (
      'numberOfSowsFarrowingPerMonth' in this.transferredModalData &&
      this.consumptionPerLactactingSow
    ) {
      return calculateTotalConsumptionOfAllLactactingSows(
        this.consumptionPerLactactingSow,
        this.transferredModalData.numberOfSowsFarrowingPerMonth
      );
    }
    return null;
  }

  // Medication program - Clean Sow Program
  get totalMedicationUsageInCleanSowProgram() {
    if (
      'medicationDoseInCleanSowProgram' in this.transferredModalData &&
      this.transferredModalData.medicationDoseInCleanSowProgram &&
      this.totalConsumptionOfAllLactactingSows
    ) {
      return calculateTotalMedicationUsageInCleanSowProgram(
        this.totalConsumptionOfAllLactactingSows,
        this.transferredModalData.medicationDoseInCleanSowProgram
      );
    }
    return null;
  }

  get totalCostOfMedicationInLactation() {
    if (
      'costOfMedicationPerKiloInCleanSowProgram' in this.transferredModalData &&
      this.transferredModalData.costOfMedicationPerKiloInCleanSowProgram &&
      this.totalMedicationUsageInCleanSowProgram
    ) {
      return calculateTotalCostOfMedicationInLactationInCleanSowProgram(
        this.transferredModalData.costOfMedicationPerKiloInCleanSowProgram,
        this.totalMedicationUsageInCleanSowProgram
      );
    }
    return null;
  }

  get additionalCostPerKiloOfFeedsInCleanSowProgram() {
    if (
      this.totalCostOfMedicationInLactation &&
      this.totalConsumptionOfAllLactactingSows
    ) {
      return calculateAdditionalCostPerKiloOfFeedsInCleanSowProgram(
        this.totalCostOfMedicationInLactation,
        this.totalConsumptionOfAllLactactingSows
      );
    }
    return null;
  }

  get totalCostOfMedicationPerLactactingSowsInCleanSowProgram() {
    if (
      'numberOfSowsFarrowingPerMonth' in this.transferredModalData &&
      this.transferredModalData.numberOfSowsFarrowingPerMonth &&
      this.totalCostOfMedicationInLactation
    ) {
      return calculateTotalCostOfMedicationPerLactactingSowsInCleanSowProgram(
        this.totalCostOfMedicationInLactation,
        this.transferredModalData.numberOfSowsFarrowingPerMonth
      );
    }
    return null;
  }

  get costOfMedicationPerPiglets() {
    if (
      'averageLitterSizeBornAlive' in this.transferredModalData &&
      this.transferredModalData.averageLitterSizeBornAlive &&
      this.totalCostOfMedicationPerLactactingSowsInCleanSowProgram
    ) {
      return calculateCostOfMedicationPerPigletsInCleanSowProgram(
        this.totalCostOfMedicationPerLactactingSowsInCleanSowProgram,
        this.transferredModalData.averageLitterSizeBornAlive
      );
    }
    return null;
  }

  // Nursery Feed
  get feedConsumptionPerNurseryPiglet() {
    if (
      'numberOfDaysFedInNursery' in this.transferredModalData &&
      this.transferredModalData.numberOfDaysFedInNursery &&
      this.transferredModalData.feedConsumptionPerDayInNursery
    ) {
      return calculateFeedConsumptionPerNurseryPiglet(
        this.transferredModalData.numberOfDaysFedInNursery,
        this.transferredModalData.feedConsumptionPerDayInNursery
      );
    }
    return null;
  }

  get totalFeedConsumptionOfNurseryPiglets() {
    if (
      'numberOfPigletsAtNursery' in this.transferredModalData &&
      this.feedConsumptionPerNurseryPiglet
    ) {
      return calculateTotalFeedConsumptionOfNurseryPiglets(
        this.feedConsumptionPerNurseryPiglet,
        this.transferredModalData.numberOfPigletsAtNursery
      );
    }
    return null;
  }

  // Medication Program - Early Intervention
  get totalMedicationUsageInEarlyIntervention() {
    if (
      'medicationDoseInEarlyInterventionProgram' in this.transferredModalData &&
      this.transferredModalData.medicationDoseInEarlyInterventionProgram &&
      this.totalFeedConsumptionOfNurseryPiglets
    ) {
      return calculateTotalMedicationUsageInEarlyIntervention(
        this.totalFeedConsumptionOfNurseryPiglets,
        this.transferredModalData.medicationDoseInEarlyInterventionProgram
      );
    }
    return null;
  }

  get totalCostOfMedicationAtNurseryInEarlyIntervention() {
    if (
      'costOfMedicationPerKiloInEarlyInterventionProgram' in
        this.transferredModalData &&
      this.transferredModalData
        .costOfMedicationPerKiloInEarlyInterventionProgram &&
      this.totalMedicationUsageInEarlyIntervention
    ) {
      return calculateTotalCostOfMedicationAtNurseryInEarlyIntervention(
        this.totalMedicationUsageInEarlyIntervention,
        this.transferredModalData
          .costOfMedicationPerKiloInEarlyInterventionProgram
      );
    }
    return null;
  }

  get additionalCostPerKiloOfFeedsInEarlyIntervention() {
    if (
      this.totalCostOfMedicationAtNurseryInEarlyIntervention &&
      this.totalFeedConsumptionOfNurseryPiglets
    ) {
      return calculateAdditionalCostPerKiloOfFeedsInEarlyIntervention(
        this.totalCostOfMedicationAtNurseryInEarlyIntervention,
        this.totalFeedConsumptionOfNurseryPiglets
      );
    }

    return null;
  }

  get costOfMedicationPerNurseryPiglets() {
    if (
      'numberOfPigletsAtNursery' in this.transferredModalData &&
      this.totalCostOfMedicationAtNurseryInEarlyIntervention
    ) {
      return calculateCostOfMedicationPerNurseryPigletsInEarlyIntervention(
        this.totalCostOfMedicationAtNurseryInEarlyIntervention,
        this.transferredModalData.numberOfPigletsAtNursery
      );
    }
    return null;
  }

  // Grower Feed
  get feedConsumptionPerGrowerPig() {
    if (
      'numberOfDaysFedInGrower' in this.transferredModalData &&
      this.transferredModalData.numberOfDaysFedInGrower &&
      this.transferredModalData.feedConsumptionPerDayInGrower
    ) {
      return calculateFeedConsumptionPerGrowerPig(
        this.transferredModalData.numberOfDaysFedInGrower,
        this.transferredModalData.feedConsumptionPerDayInGrower
      );
    }
    return null;
  }

  get totalFeedConsumptionPerGrowerPig() {
    if (
      'numberOfDaysFedInGrower' in this.transferredModalData &&
      this.transferredModalData.numberOfDaysFedInGrower &&
      this.transferredModalData.feedConsumptionPerDayInGrower &&
      this.transferredModalData.numberOfGrowerPigs
    ) {
      return calculateTotalFeedConsumptionPerGrowerPig(
        this.transferredModalData.numberOfDaysFedInGrower,
        this.transferredModalData.feedConsumptionPerDayInGrower,
        this.transferredModalData.numberOfGrowerPigs
      );
    }
    return null;
  }

  // Medication Program - Starter / Grower
  get totalMedicationUsageInGrowerProgram() {
    if (
      'medicationDoseInGrowerProgram' in this.transferredModalData &&
      this.transferredModalData.medicationDoseInGrowerProgram &&
      this.totalFeedConsumptionPerGrowerPig
    ) {
      return calculateTotalMedicationUsageInGrowerProgram(
        this.totalFeedConsumptionPerGrowerPig,
        this.transferredModalData.medicationDoseInGrowerProgram
      );
    }
    return null;
  }

  get totalCostOfMedicationAtGrower() {
    if (
      'costOfMedicationPerKiloInGrowerProgram' in this.transferredModalData &&
      this.transferredModalData.costOfMedicationPerKiloInGrowerProgram &&
      this.totalMedicationUsageInGrowerProgram
    ) {
      return calculateTotalCostOfMedicationAtGrowerInGrowerProgram(
        this.totalMedicationUsageInGrowerProgram,
        this.transferredModalData.costOfMedicationPerKiloInGrowerProgram
      );
    }

    return null;
  }

  get additionalCostPerKiloOfFeedsInGrower() {
    if (
      this.totalCostOfMedicationAtGrower &&
      this.totalFeedConsumptionPerGrowerPig
    ) {
      return calculateAdditionalCostPerKiloOfFeedsInGrowerProgram(
        this.totalCostOfMedicationAtGrower,
        this.totalFeedConsumptionPerGrowerPig
      );
    }
    return null;
  }

  get costOfMedicationPerGrowerPigs() {
    if (
      'numberOfGrowerPigs' in this.transferredModalData &&
      this.totalCostOfMedicationAtGrower
    ) {
      return calculateCostOfMedicationPerGrowerPigInGrowerProgram(
        this.totalCostOfMedicationAtGrower,
        this.transferredModalData.numberOfGrowerPigs
      );
    }
    return 0;
  }

  // Medical Investment Section
  get totalMedicationInvestmentPerPig() {
    if (
      this.costOfMedicationPerPiglets ||
      this.costOfMedicationPerNurseryPiglets ||
      this.costOfMedicationPerGrowerPigs
    ) {
      return calculateTotalMedicationInvestmentPerPig(
        this.costOfMedicationPerPiglets || 0,
        this.costOfMedicationPerNurseryPiglets || 0,
        this.costOfMedicationPerGrowerPigs || 0
      );
    }

    return null;
  }

  get totalInvestmentForGrowerProduced() {
    if (
      'numberOfGrowerPigs' in this.transferredModalData &&
      this.totalMedicationInvestmentPerPig
    ) {
      return calculateTotalInvestmentForGrowerPigsProduced(
        this.totalMedicationInvestmentPerPig,
        this.transferredModalData.numberOfGrowerPigs
      );
    }
    return null;
  }

  addComma(targetNumber: string) {
    return addCommas(targetNumber);
  }

  private getMalaysiaMedicationProgramDetails() {
    return [
      {
        style: ['lightGreyBg', 'textCenter'],
        table: {
          widths: ['*', '*'],
          body: [
            [
              {
                stack: [
                  {
                    text: 'Medication Investment /pig',
                    color: `${darkGrey}`,
                    fontSize: 8,
                    marginTop: 5,
                  },
                  {
                    text: `${this.sectionCardDetailsMedication[0].value}`,
                    color: `${red}`,
                    fontSize: 20,
                    bold: true,
                    marginBottom: 5,
                  },
                ],
                border: [false, false, true, false],
              },
              {
                stack: [
                  {
                    text: 'Total Investment',
                    color: `${darkGrey}`,
                    fontSize: 8,
                    marginTop: 5,
                  },
                  {
                    text: `${this.sectionCardDetailsMedication[1].value}`,
                    color: `${red}`,
                    fontSize: 20,
                    bold: true,
                    marginBottom: 5,
                  },
                ],
                border: [false, false, false, false],
              },
            ],
          ],
        },
        layout: 'allGreyBorder',
        marginBottom: 5,
      },
      {
        columns: [
          {
            style: ['fontSize8'],
            table: {
              widths: ['*', '*'],
              body: [
                [
                  {
                    text: '# of Piglets Weaned (heads)',
                    bold: true,
                    fillColor: `${lightGrey}`,
                    border: [false, false, false, true],
                  },
                  {
                    text: `${this.addComma(
                      this.numberOfAnimalHeads.toString()
                    )}`,
                    border: [false, false, false, true],
                  },
                ],
                [
                  {
                    text: `Live Weight Price /kg (${this.currentCurrencyDetails.currentSelectedCurrency.text})`,
                    bold: true,
                    fillColor: `${lightGrey}`,
                    border: [false, false, false, true],
                  },
                  {
                    text: `${this.addComma(
                      Number(this.transferredModalData.liveWeightPrice).toFixed(
                        2
                      )
                    )}`,
                    fillColor: `${pink}`,
                    border: [false, false, false, true],
                  },
                ],
                [
                  {
                    text: 'Target Market Wt (kg)',
                    bold: true,
                    fillColor: `${lightGrey}`,
                    border: [false, false, false, true],
                  },
                  {
                    text: this.transferredModalData.targetMarketWeight
                      ? `${this.addComma(
                          this.transferredModalData.targetMarketWeight.toString()
                        )}`
                      : '-',
                    fillColor: `${pink}`,
                    border: [false, false, false, true],
                  },
                ],
                [
                  {
                    text: `Age of Weaning (${this.transferredModalData.ageOfWeaning} days) Wean to Market (${this.transferredModalData.weanMarketDays} days) ~ ${this.transferredModalData.weekNumber} weeks`,
                    bold: true,
                    fillColor: `${lightGrey}`,
                    border: [false, false, false, true],
                  },
                  {
                    text: this.transferredModalData.weanMarketDays
                      ? `${this.addComma(
                          this.transferredModalData.weanMarketDays.toString()
                        )}`
                      : '-',
                    border: [false, false, false, true],
                  },
                ],
                [
                  {
                    text: `Avg Feed Price /kg (${this.currentCurrencyDetails.currentSelectedCurrency.text})`,
                    bold: true,
                    fillColor: `${lightGrey}`,
                    border: [false, false, false, true],
                  },
                  {
                    text: `${this.addComma(
                      Number(
                        this.transferredModalData.averageFeedPrice
                      ).toFixed(2)
                    )}`,
                    fillColor: `${pink}`,
                    border: [false, false, false, true],
                  },
                ],
              ],
            },
            layout: 'allGreyBorder',
          },
          {
            style: ['fontSize8'],
            table: {
              widths: ['*', '*'],
              body: [
                [
                  {
                    text: 'Clean Sow Program (Lactation Feed)',
                    border: [false, false, false, false],
                    colSpan: 2,
                  },
                  {
                    text: '',
                  },
                ],
                [
                  {
                    text: 'Feed Intake /day (kg)',
                    bold: true,
                    fillColor: `${lightGrey}`,
                    border: [false, false, false, true],
                  },
                  {
                    text:
                      'cleanSowFeedIntake' in this.transferredModalData &&
                      this.transferredModalData.cleanSowFeedIntake
                        ? `${this.addComma(
                            this.transferredModalData.cleanSowFeedIntake.toFixed(
                              1
                            )
                          )}`
                        : '-',
                    fillColor: `${pink}`,
                    border: [false, false, false, true],
                  },
                ],
                [
                  {
                    text: 'Duration (days)',
                    bold: true,
                    fillColor: `${lightGrey}`,
                    border: [false, false, false, true],
                  },
                  {
                    text:
                      'cleanSowDuration' in this.transferredModalData &&
                      this.transferredModalData.cleanSowDuration
                        ? `${this.addComma(
                            this.transferredModalData.cleanSowDuration.toFixed(
                              0
                            )
                          )}`
                        : '-',
                    border: [false, false, false, true],
                    fillColor: `${pink}`,
                  },
                ],
                [
                  {
                    text: 'P:S Ratio * (Piglets sold per sow per month)',
                    bold: true,
                    fillColor: `${lightGrey}`,
                    border: [false, false, false, true],
                  },
                  {
                    text:
                      'cleanSowPSRatio' in this.transferredModalData &&
                      this.transferredModalData.cleanSowPSRatio
                        ? `${this.addComma(
                            this.transferredModalData.cleanSowPSRatio.toFixed(1)
                          )}`
                        : '-',
                    border: [false, false, false, false],
                    fillColor: `${pink}`,
                  },
                ],
                [
                  {
                    text: 'Dosage (kg per ton)',
                    bold: true,
                    fillColor: `${lightGrey}`,
                    border: [false, false, false, true],
                  },
                  {
                    text: `${this.cleanSowDosage}`,
                    fillColor: `${pink}`,
                    border: [false, false, false, true],
                  },
                ],
                [
                  {
                    text: `Price of Medicine (${this.currentCurrencyDetails.currentSelectedCurrency.text} /kg) ~ ${this.transferredModalData.cleanSowMedicineBrand}`,
                    bold: true,
                    fillColor: `${lightGrey}`,
                    border: [false, false, false, true],
                  },
                  {
                    text: `${this.cleanSowMedicinePrice}`,
                    fillColor: `${pink}`,
                    border: [false, false, false, true],
                  },
                ],
                [
                  {
                    text: `Medication cost /ton of feed (${this.currentCurrencyDetails.currentSelectedCurrency.text})`,
                    bold: true,
                    fillColor: `${lightGrey}`,
                    border: [false, false, false, true],
                  },
                  {
                    text: `${this.cleanSowMedicationCostFeed}`,
                    border: [false, false, false, true],
                  },
                ],
                [
                  {
                    text: `Cost of medication /${this.numberOfAnimalHeads} heads of animals (${this.currentCurrencyDetails.currentSelectedCurrency.text})`,
                    bold: true,
                    fillColor: `${lightGrey}`,
                    border: [false, false, false, true],
                  },
                  {
                    text: `${this.cleanSowMedicationCostHead}`,
                    border: [false, false, false, true],
                  },
                ],
                [
                  {
                    text: `Cost /pig (${this.currentCurrencyDetails.currentSelectedCurrency.text})`,
                    bold: true,
                    fillColor: `${lightGrey}`,
                    border: [false, false, false, true],
                  },
                  {
                    text: `${this.cleanSowCostPerPig}`,
                    border: [false, false, false, true],
                  },
                ],
                [
                  {
                    text: '',
                    border: [false, false, false, false],
                  },
                  {
                    text: '',
                    border: [false, false, false, false],
                  },
                ],
                [
                  {
                    text: 'Start strong (Pre-starter Program)',
                    border: [false, false, false, false],
                    colSpan: 2,
                    marginTop: 15,
                  },
                  {
                    text: '',
                    marginTop: 15,
                  },
                ],
                [
                  {
                    text: 'Feed intake /day (kg)',
                    bold: true,
                    fillColor: `${lightGrey}`,
                    border: [false, false, false, true],
                  },
                  {
                    text:
                      'startStrongFeedIntake' in this.transferredModalData &&
                      this.transferredModalData.startStrongFeedIntake
                        ? `${this.transferredModalData.startStrongFeedIntake}`
                        : '-',
                    fillColor: `${pink}`,
                    border: [false, false, false, true],
                  },
                ],
                [
                  {
                    text: 'Duration (days)',
                    bold: true,
                    fillColor: `${lightGrey}`,
                    border: [false, false, false, true],
                  },
                  {
                    text:
                      'startStrongDuration' in this.transferredModalData &&
                      this.transferredModalData.startStrongDuration
                        ? `${this.transferredModalData.startStrongDuration}`
                        : '-',
                    fillColor: `${pink}`,
                    border: [false, false, false, true],
                  },
                ],
                [
                  {
                    text: 'Dosage (kg per ton)',
                    bold: true,
                    fillColor: `${lightGrey}`,
                    border: [false, false, false, true],
                  },
                  {
                    text: `${this.startStrongDosage}`,
                    fillColor: `${pink}`,
                    border: [false, false, false, true],
                  },
                ],
                [
                  {
                    text: `Price of medication (${
                      this.currentCurrencyDetails.currentSelectedCurrency.text
                    } /g) ~ ${
                      'strategicProgramMedicineBrand' in
                        this.transferredModalData &&
                      this.transferredModalData.strategicProgramMedicineBrand
                        ? this.transferredModalData
                            .strategicProgramMedicineBrand
                        : '-'
                    }`,
                    bold: true,
                    fillColor: `${lightGrey}`,
                    border: [false, false, false, true],
                  },
                  {
                    text: `${this.startStrongMedicinePrice}`,
                    fillColor: `${pink}`,
                    border: [false, false, false, true],
                  },
                ],
                [
                  {
                    text: `Medication cost /ton of feed (${this.currentCurrencyDetails.currentSelectedCurrency.text})`,
                    bold: true,
                    fillColor: `${lightGrey}`,
                    border: [false, false, false, true],
                  },
                  {
                    text: `${this.startStrongMedicationCostFeed}`,
                    border: [false, false, false, true],
                  },
                ],
                [
                  {
                    text: `Cost of medication /${this.numberOfAnimalHeads} heads of animals (${this.currentCurrencyDetails.currentSelectedCurrency.text})`,
                    bold: true,
                    fillColor: `${lightGrey}`,
                    border: [false, false, false, true],
                  },
                  {
                    text: `${this.startStrongMedicationCostHead}`,
                    border: [false, false, false, true],
                  },
                ],
                [
                  {
                    text: `Cost /pig (${this.currentCurrencyDetails.currentSelectedCurrency.text})`,
                    bold: true,
                    fillColor: `${lightGrey}`,
                    border: [false, false, false, true],
                  },
                  {
                    text: `${this.startStrongCostPerPig}`,
                    border: [false, false, false, true],
                  },
                ],
              ],
            },
            layout: 'allGreyBorder',
          },
        ],
        columnGap: 15,
        pageBreak: 'after',
      },
      {
        svg: imgElancoLogo,
        width: 100,
        margin: [0, 0, 0, -30],
        border: [false, false, false, false],
      },
      {
        text: 'Economic Calculation (Medication Program)',
        style: 'sectionHeader',
      },
      {
        style: ['lightGreyBg', 'fontSize8'],
        table: {
          widths: ['auto', 'auto', '*'],
          body: [
            [
              {
                text: 'Current Exchange Rate:',
                color: `${darkGrey}`,
                border: [false, false, false, false],
                margin: 5,
              },
              {
                text: `${this.currentCurrencyDetails.baseCurrency.text} 1 = ${
                  this.currentCurrencyDetails.currentSelectedCurrency.text
                } ${
                  this.currentCurrencyDetails.currentSelectedCurrency.value /
                  this.currentCurrencyDetails.baseCurrency.value
                }`,
                color: `${blue}`,
                border: [false, false, false, false],
                margin: 5,
              },
              {
                text: `${this.currentCurrencyDetails.currentSelectedCurrency.text}`,
                color: `${darkGrey}`,
                alignment: 'right',
                border: [false, false, false, false],
                margin: 5,
              },
            ],
          ],
        },
        marginBottom: 5,
      },
      {
        style: ['lightGreyBg', 'textCenter'],
        table: {
          widths: ['*', '*'],
          body: [
            [
              {
                stack: [
                  {
                    text: 'Medication Investment /pig',
                    color: `${darkGrey}`,
                    fontSize: 8,
                    marginTop: 5,
                  },
                  {
                    text: `${this.sectionCardDetailsMedication[0].value}`,
                    color: `${red}`,
                    fontSize: 20,
                    bold: true,
                    marginBottom: 5,
                  },
                ],
                border: [false, false, true, false],
              },
              {
                stack: [
                  {
                    text: 'Total Investment',
                    color: `${darkGrey}`,
                    fontSize: 8,
                    marginTop: 5,
                  },
                  {
                    text: `${this.sectionCardDetailsMedication[1].value}`,
                    color: `${red}`,
                    fontSize: 20,
                    bold: true,
                    marginBottom: 5,
                  },
                ],
                border: [false, false, false, false],
              },
            ],
          ],
        },
        layout: 'allGreyBorder',
        marginBottom: 5,
      },
      {
        columns: [
          {
            style: ['fontSize8'],
            table: {
              widths: ['*', '*'],
              body: [
                [
                  {
                    text: '# of Piglets Weaned (heads)',
                    bold: true,
                    fillColor: `${lightGrey}`,
                    border: [false, false, false, true],
                  },
                  {
                    text: `${this.addComma(
                      this.numberOfAnimalHeads.toString()
                    )}`,
                    border: [false, false, false, true],
                  },
                ],
                [
                  {
                    text: `Live Weight Price /kg (${this.currentCurrencyDetails.currentSelectedCurrency.text})`,
                    bold: true,
                    fillColor: `${lightGrey}`,
                    border: [false, false, false, true],
                  },
                  {
                    text: `${this.addComma(
                      Number(this.transferredModalData.liveWeightPrice).toFixed(
                        2
                      )
                    )}`,
                    fillColor: `${pink}`,
                    border: [false, false, false, true],
                  },
                ],
                [
                  {
                    text: 'Target Market Wt (kg)',
                    bold: true,
                    fillColor: `${lightGrey}`,
                    border: [false, false, false, true],
                  },
                  {
                    text: this.transferredModalData.targetMarketWeight
                      ? `${this.addComma(
                          this.transferredModalData.targetMarketWeight.toString()
                        )}`
                      : '-',
                    fillColor: `${pink}`,
                    border: [false, false, false, true],
                  },
                ],
                [
                  {
                    text: `Age of Weaning (${this.transferredModalData.ageOfWeaning} days) Wean to Market (${this.transferredModalData.weanMarketDays} days) ~ ${this.transferredModalData.weekNumber} weeks`,
                    bold: true,
                    fillColor: `${lightGrey}`,
                    border: [false, false, false, true],
                  },
                  {
                    text: this.transferredModalData.weanMarketDays
                      ? `${this.addComma(
                          this.transferredModalData.weanMarketDays.toString()
                        )}`
                      : '-',
                    border: [false, false, false, true],
                  },
                ],
                [
                  {
                    text: `Avg Feed Price /kg (${this.currentCurrencyDetails.currentSelectedCurrency.text})`,
                    bold: true,
                    fillColor: `${lightGrey}`,
                    border: [false, false, false, true],
                  },
                  {
                    text: `${this.addComma(
                      Number(
                        this.transferredModalData.averageFeedPrice
                      ).toFixed(2)
                    )}`,
                    fillColor: `${pink}`,
                    border: [false, false, false, true],
                  },
                ],
              ],
            },
            layout: 'allGreyBorder',
          },
          {
            style: ['fontSize8'],
            table: {
              widths: ['*', '*'],
              body: [
                [
                  {
                    text: 'Strategic Program - Treatment (Nursery-Grower)',
                    border: [false, false, false, false],
                    colSpan: 2,
                  },
                  {
                    text: '',
                  },
                ],
                [
                  {
                    text: 'Feed Intake /day (kg)',
                    bold: true,
                    fillColor: `${lightGrey}`,
                    border: [false, false, false, true],
                  },
                  {
                    text:
                      'strategicProgramFeedIntake' in
                        this.transferredModalData &&
                      this.transferredModalData.strategicProgramFeedIntake
                        ? `${this.transferredModalData.strategicProgramFeedIntake}`
                        : '-',
                    fillColor: `${pink}`,
                    border: [false, false, false, true],
                  },
                ],
                [
                  {
                    text: 'Duration (days)',
                    bold: true,
                    fillColor: `${lightGrey}`,
                    border: [false, false, false, true],
                  },
                  {
                    text:
                      'strategicProgramDuration' in this.transferredModalData &&
                      this.transferredModalData.strategicProgramDuration
                        ? `${this.transferredModalData.strategicProgramDuration}`
                        : '-',
                    border: [false, false, false, true],
                    fillColor: `${pink}`,
                  },
                ],
                [
                  {
                    text: 'Dosage (kg per ton)',
                    bold: true,
                    fillColor: `${lightGrey}`,
                    border: [false, false, false, true],
                  },
                  {
                    text: `${this.strategicProgramDosage}`,
                    border: [false, false, false, false],
                    fillColor: `${pink}`,
                  },
                ],
                [
                  {
                    text: `Price of medication (${
                      this.currentCurrencyDetails.currentSelectedCurrency.text
                    } /g) ~ ${
                      'strategicProgramMedicineBrand' in
                        this.transferredModalData &&
                      this.transferredModalData.strategicProgramMedicineBrand
                        ? this.transferredModalData
                            .strategicProgramMedicineBrand
                        : '-'
                    }`,
                    bold: true,
                    fillColor: `${lightGrey}`,
                    border: [false, false, false, true],
                  },
                  {
                    text: `${this.strategicProgramMedicinePrice}`,
                    fillColor: `${pink}`,
                    border: [false, false, false, true],
                  },
                ],
                [
                  {
                    text: `Medication cost /ton of feed (${this.currentCurrencyDetails.currentSelectedCurrency.text})`,
                    bold: true,
                    fillColor: `${lightGrey}`,
                    border: [false, false, false, true],
                  },
                  {
                    text: `${this.strategicProgramMedicationCostFeed}`,
                    border: [false, false, false, true],
                  },
                ],
                [
                  {
                    text: `Cost of medication /${this.numberOfAnimalHeads} heads of animals (${this.currentCurrencyDetails.currentSelectedCurrency.text})`,
                    bold: true,
                    fillColor: `${lightGrey}`,
                    border: [false, false, false, true],
                  },
                  {
                    text: `${this.strategicProgramMedicationCostHead}`,
                    border: [false, false, false, true],
                  },
                ],
                [
                  {
                    text: `Cost /pig (${this.currentCurrencyDetails.currentSelectedCurrency.text})`,
                    bold: true,
                    fillColor: `${lightGrey}`,
                    border: [false, false, false, true],
                  },
                  {
                    text: `${this.strategicProgramCostPerPig}`,
                    border: [false, false, false, true],
                  },
                ],
              ],
            },
            layout: 'allGreyBorder',
          },
        ],
        columnGap: 15,
        pageBreak: 'after',
      },
    ];
  }

  private getStandardMedicationProgramDetails() {
    return [
      {
        style: ['lightGreyBg', 'textCenter'],
        table: {
          widths: ['*', '*'],
          body: [
            [
              {
                stack: [
                  {
                    text: 'Total Medication Investment /pig',
                    color: `${darkGrey}`,
                    fontSize: 8,
                    marginTop: 5,
                  },
                  {
                    text: this.sectionCardDetails[0].value,
                    color: `${red}`,
                    fontSize: 20,
                    bold: true,
                    marginBottom: 5,
                  },
                ],
                border: [false, false, true, false],
              },
              {
                stack: [
                  {
                    text: `Total Investment for 
                      ${this.numberOfAnimalHeads.toFixed(0)} Grower Produced`,
                    color: `${darkGrey}`,
                    fontSize: 8,
                    marginTop: 5,
                  },
                  {
                    text: this.sectionCardDetails[1].value,
                    color: `${red}`,
                    fontSize: 20,
                    bold: true,
                    marginBottom: 5,
                  },
                ],
                border: [false, false, false, false],
              },
            ],
          ],
        },
        layout: 'allGreyBorder',
        marginBottom: 5,
      },
      {
        columns: [
          {
            style: ['fontSize8'],
            table: {
              widths: ['*', '*'],
              body: [
                [
                  {
                    text: this.lactationFeedCollapseTitle,
                    border: [false, false, false, false],
                    colSpan: 2,
                  },
                  {
                    text: '',
                  },
                ],
                [
                  {
                    text: 'No. of Days Fed Pre Farrowing (days)',
                    bold: true,
                    fillColor: `${lightGrey}`,
                    border: [false, false, false, true],
                  },
                  {
                    text:
                      'numberOfDaysPreFarrowing' in this.transferredModalData &&
                      this.transferredModalData.numberOfDaysPreFarrowing
                        ? `${this.addComma(
                            this.transferredModalData.numberOfDaysPreFarrowing.toFixed(
                              0
                            )
                          )}`
                        : '-',
                    fillColor: `${pink}`,
                    border: [false, false, false, true],
                  },
                ],
                [
                  {
                    text: `No.of Days Fed Post Farrowing (days)`,
                    bold: true,
                    fillColor: `${lightGrey}`,
                    border: [false, false, false, true],
                  },
                  {
                    text:
                      'numberOfDaysPostFarrowing' in
                        this.transferredModalData &&
                      this.transferredModalData.numberOfDaysPostFarrowing
                        ? `${this.addComma(
                            this.transferredModalData.numberOfDaysPostFarrowing.toFixed(
                              0
                            )
                          )}`
                        : '-',
                    fillColor: `${pink}`,
                    border: [false, false, false, true],
                  },
                ],
                [
                  {
                    text: 'ADFI at Gestation (kg)',
                    bold: true,
                    fillColor: `${lightGrey}`,
                    border: [false, false, false, true],
                  },
                  {
                    text:
                      'adfiAtGestation' in this.transferredModalData &&
                      this.transferredModalData.adfiAtGestation
                        ? `${this.addComma(
                            this.transferredModalData.adfiAtGestation.toFixed(2)
                          )}`
                        : '-',
                    fillColor: `${pink}`,
                    border: [false, false, false, true],
                  },
                ],
                [
                  {
                    text: 'ADFI at Lactation (kg)',
                    bold: true,
                    fillColor: `${lightGrey}`,
                    border: [false, false, false, true],
                  },
                  {
                    text:
                      'adfiAtLactation' in this.transferredModalData &&
                      this.transferredModalData.adfiAtLactation
                        ? `${this.addComma(
                            this.transferredModalData.adfiAtLactation.toFixed(2)
                          )}`
                        : '-',
                    fillColor: `${pink}`,
                    border: [false, false, false, true],
                  },
                ],
                [
                  {
                    text: `Consumption /lacting sow (kg)`,
                    bold: true,
                    fillColor: `${lightGrey}`,
                    border: [false, false, false, true],
                  },
                  {
                    text: this.consumptionPerLactactingSow
                      ? `${this.addComma(
                          this.consumptionPerLactactingSow.toFixed(2)
                        )}`
                      : '-',
                    border: [false, false, false, true],
                  },
                ],
                [
                  {
                    text: `Total Consumption of all Lactacting Sows (kg/tons)`,
                    bold: true,
                    fillColor: `${lightGrey}`,
                    border: [false, false, false, true],
                  },
                  {
                    text: this.totalConsumptionOfAllLactactingSows
                      ? `${this.addComma(
                          (
                            this.totalConsumptionOfAllLactactingSows * 1000
                          ).toFixed(0)
                        )} / ${this.addComma(
                          this.totalConsumptionOfAllLactactingSows.toFixed(2)
                        )}`
                      : `- / -`,
                    border: [false, false, false, true],
                  },
                ],
                [
                  {
                    text: this.nurseryFeedCollapseTitle,
                    border: [false, false, false, false],
                    colSpan: 2,
                    marginTop: 15,
                  },
                  {
                    text: '',
                  },
                ],
                [
                  {
                    text: 'No. of Days Fed (days)',
                    bold: true,
                    fillColor: `${lightGrey}`,
                    border: [false, false, false, true],
                  },
                  {
                    text:
                      'numberOfDaysFedInNursery' in this.transferredModalData &&
                      this.transferredModalData.numberOfDaysFedInNursery
                        ? `${this.addComma(
                            this.transferredModalData.numberOfDaysFedInNursery.toFixed(
                              0
                            )
                          )}`
                        : '-',
                    fillColor: `${pink}`,
                    border: [false, false, false, true],
                  },
                ],
                [
                  {
                    text: `Feed Consumption /day (kg)`,
                    bold: true,
                    fillColor: `${lightGrey}`,
                    border: [false, false, false, true],
                  },
                  {
                    text:
                      'feedConsumptionPerDayInNursery' in
                        this.transferredModalData &&
                      this.transferredModalData.feedConsumptionPerDayInNursery
                        ? `${this.addComma(
                            this.transferredModalData.feedConsumptionPerDayInNursery.toFixed(
                              2
                            )
                          )}`
                        : '-',
                    fillColor: `${pink}`,
                    border: [false, false, false, true],
                  },
                ],
                [
                  {
                    text: `Feed Consumption /nursery piglet (kg)`,
                    bold: true,
                    fillColor: `${lightGrey}`,
                    border: [false, false, false, true],
                  },
                  {
                    text: this.feedConsumptionPerNurseryPiglet
                      ? `${this.addComma(
                          this.feedConsumptionPerNurseryPiglet.toFixed(2)
                        )}`
                      : '-',
                    border: [false, false, false, true],
                  },
                ],
                [
                  {
                    text: `Total Feed Consumption of all Nursery Piglets (kg/tons)`,
                    bold: true,
                    fillColor: `${lightGrey}`,
                    border: [false, false, false, true],
                  },
                  {
                    text: this.totalFeedConsumptionOfNurseryPiglets
                      ? `${this.addComma(
                          (
                            this.totalFeedConsumptionOfNurseryPiglets * 1000
                          ).toFixed(0)
                        )} / ${this.addComma(
                          this.totalFeedConsumptionOfNurseryPiglets.toFixed(2)
                        )}`
                      : '- / -',
                    border: [false, false, false, true],
                  },
                ],
              ],
            },
            layout: 'allGreyBorder',
          },
          {
            style: ['fontSize8'],
            table: {
              widths: ['*', '*'],
              body: [
                [
                  {
                    text: 'Clean Sow Program (Lactation Feed)',
                    border: [false, false, false, false],
                    colSpan: 2,
                  },
                  {
                    text: '',
                  },
                ],
                [
                  {
                    text: `${this.cleanSowMedicineBrand} Dose (kg/ton)`,
                    bold: true,
                    fillColor: `${lightGrey}`,
                    border: [false, false, false, true],
                  },
                  {
                    text:
                      'medicationDoseInCleanSowProgram' in
                        this.transferredModalData &&
                      this.transferredModalData.medicationDoseInCleanSowProgram
                        ? `${this.addComma(
                            this.transferredModalData.medicationDoseInCleanSowProgram.toFixed(
                              2
                            )
                          )}`
                        : '-',
                    fillColor: `${pink}`,
                    border: [false, false, false, true],
                  },
                ],
                [
                  {
                    text: `Cost of ${this.cleanSowMedicineBrand} /kg`,
                    bold: true,
                    fillColor: `${lightGrey}`,
                    border: [false, false, false, true],
                  },
                  {
                    text:
                      'costOfMedicationPerKiloInCleanSowProgram' in
                        this.transferredModalData &&
                      this.transferredModalData
                        .costOfMedicationPerKiloInCleanSowProgram
                        ? `${this.addComma(
                            this.transferredModalData.costOfMedicationPerKiloInCleanSowProgram.toFixed(
                              2
                            )
                          )}`
                        : '-',
                    border: [false, false, false, true],
                    fillColor: `${pink}`,
                  },
                ],
                [
                  {
                    text: `Total (${this.cleanSowMedicineBrand}) Usage (kg)`,
                    bold: true,
                    fillColor: `${lightGrey}`,
                    border: [false, false, false, true],
                  },
                  {
                    text: this.totalMedicationUsageInCleanSowProgram
                      ? this.addComma(
                          this.totalMedicationUsageInCleanSowProgram.toFixed(2)
                        )
                      : `-`,
                    border: [false, false, false, false],
                    fillColor: `${pink}`,
                  },
                ],
                [
                  {
                    text: `Total cost of (${this.cleanSowMedicineBrand}) in Lactation (${this.currentCurrencySymbol})`,
                    bold: true,
                    fillColor: `${lightGrey}`,
                    border: [false, false, false, true],
                  },
                  {
                    text: this.totalCostOfMedicationInLactation
                      ? `${this.addComma(
                          this.totalCostOfMedicationInLactation.toFixed(2)
                        )}`
                      : `-`,
                    fillColor: `${pink}`,
                    border: [false, false, false, true],
                  },
                ],
                [
                  {
                    text: `Addiction Cost per kilo of Feeds (${this.currentCurrencySymbol})`,
                    bold: true,
                    fillColor: `${lightGrey}`,
                    border: [false, false, false, true],
                  },
                  {
                    text: this.additionalCostPerKiloOfFeedsInCleanSowProgram
                      ? `${this.addComma(
                          this.additionalCostPerKiloOfFeedsInCleanSowProgram.toFixed(
                            2
                          )
                        )}`
                      : `-`,
                    fillColor: `${pink}`,
                    border: [false, false, false, true],
                  },
                ],
                [
                  {
                    text: `Cost of (${this.cleanSowMedicineBrand}) /lactating sow (${this.currentCurrencySymbol})`,
                    bold: true,
                    fillColor: `${lightGrey}`,
                    border: [false, false, false, true],
                  },
                  {
                    text: this
                      .totalCostOfMedicationPerLactactingSowsInCleanSowProgram
                      ? `${this.addComma(
                          this.totalCostOfMedicationPerLactactingSowsInCleanSowProgram.toFixed(
                            2
                          )
                        )}`
                      : `-`,
                    border: [false, false, false, true],
                  },
                ],
                [
                  {
                    text: `Cost of (${this.cleanSowMedicineBrand}) /piglets (${this.costOfMedicationPerPigletsHeads}) heads (${this.currentCurrencySymbol})`,
                    bold: true,
                    fillColor: `${lightGrey}`,
                    border: [false, false, false, true],
                  },
                  {
                    text: this.costOfMedicationPerPiglets
                      ? `${this.costOfMedicationPerPiglets.toFixed(2)}`
                      : `-`,
                    border: [false, false, false, true],
                  },
                ],
                [
                  {
                    text: 'Early Intervention (Prestarter)',
                    border: [false, false, false, false],
                    colSpan: 2,
                    marginTop: 15,
                  },
                  {
                    text: '',
                    marginTop: 15,
                  },
                ],
                [
                  {
                    text: `${this.earlyInterventionMedicineBrand} Dose (kg/ton)`,
                    bold: true,
                    fillColor: `${lightGrey}`,
                    border: [false, false, false, true],
                  },
                  {
                    text:
                      'medicationDoseInEarlyInterventionProgram' in
                        this.transferredModalData &&
                      this.transferredModalData
                        .medicationDoseInEarlyInterventionProgram
                        ? `${this.addComma(
                            this.transferredModalData.medicationDoseInEarlyInterventionProgram.toFixed(
                              2
                            )
                          )}`
                        : '-',
                    fillColor: `${pink}`,
                    border: [false, false, false, true],
                  },
                ],
                [
                  {
                    text: `Cost of ${this.earlyInterventionMedicineBrand} /kg`,
                    bold: true,
                    fillColor: `${lightGrey}`,
                    border: [false, false, false, true],
                  },
                  {
                    text:
                      'costOfMedicationPerKiloInEarlyInterventionProgram' in
                        this.transferredModalData &&
                      this.transferredModalData
                        .costOfMedicationPerKiloInEarlyInterventionProgram
                        ? `${this.addComma(
                            this.transferredModalData.costOfMedicationPerKiloInEarlyInterventionProgram.toFixed(
                              2
                            )
                          )}`
                        : '-',
                    fillColor: `${pink}`,
                    border: [false, false, false, true],
                  },
                ],
                [
                  {
                    text: `Total (${this.earlyInterventionMedicineBrand}) Usage (kg)`,
                    bold: true,
                    fillColor: `${lightGrey}`,
                    border: [false, false, false, true],
                  },
                  {
                    text: this.totalMedicationUsageInEarlyIntervention
                      ? `${this.addComma(
                          this.totalMedicationUsageInEarlyIntervention.toFixed(
                            2
                          )
                        )}`
                      : '-',
                    fillColor: `${pink}`,
                    border: [false, false, false, true],
                  },
                ],
                [
                  {
                    text: `Total cost of (${this.earlyInterventionMedicineBrand}) at Nursery
                    (${this.currentCurrencySymbol})`,
                    bold: true,
                    fillColor: `${lightGrey}`,
                    border: [false, false, false, true],
                  },
                  {
                    text: this.totalCostOfMedicationAtNurseryInEarlyIntervention
                      ? `${this.addComma(
                          this.totalCostOfMedicationAtNurseryInEarlyIntervention.toFixed(
                            2
                          )
                        )}`
                      : '-',
                    fillColor: `${pink}`,
                    border: [false, false, false, true],
                  },
                ],
                [
                  {
                    text: `Addition Cost per kilo of Feeds (${this.currentCurrencySymbol})`,
                    bold: true,
                    fillColor: `${lightGrey}`,
                    border: [false, false, false, true],
                  },
                  {
                    text: this.additionalCostPerKiloOfFeedsInEarlyIntervention
                      ? `${this.addComma(
                          this.additionalCostPerKiloOfFeedsInEarlyIntervention.toFixed(
                            2
                          )
                        )}`
                      : '-',
                    border: [false, false, false, true],
                  },
                ],
                [
                  {
                    text: `Cost of (${this.earlyInterventionMedicineBrand}) / nursery piglets
                    (${this.currentCurrencySymbol})`,
                    bold: true,
                    fillColor: `${lightGrey}`,
                    border: [false, false, false, true],
                  },
                  {
                    text: this.costOfMedicationPerNurseryPiglets
                      ? `${this.addComma(
                          this.costOfMedicationPerNurseryPiglets.toFixed(2)
                        )}`
                      : '-',
                    border: [false, false, false, true],
                  },
                ],
              ],
            },
            layout: 'allGreyBorder',
          },
        ],
        columnGap: 15,
        pageBreak: 'after',
      },
      {
        svg: imgElancoLogo,
        width: 100,
        margin: [0, 0, 0, -30],
        border: [false, false, false, false],
      },
      {
        text: 'Economic Calculation (Medication Program)',
        style: 'sectionHeader',
      },
      {
        style: ['lightGreyBg', 'fontSize8'],
        table: {
          widths: ['auto', 'auto', '*'],
          body: [
            [
              {
                text: 'Current Exchange Rate:',
                color: `${darkGrey}`,
                border: [false, false, false, false],
                margin: 5,
              },
              {
                text: `${this.currentCurrencyDetails.baseCurrency.text} 1 = ${
                  this.currentCurrencyDetails.currentSelectedCurrency.text
                } ${
                  this.currentCurrencyDetails.currentSelectedCurrency.value /
                  this.currentCurrencyDetails.baseCurrency.value
                }`,
                color: `${blue}`,
                border: [false, false, false, false],
                margin: 5,
              },
              {
                text: `${this.currentCurrencyDetails.currentSelectedCurrency.text}`,
                color: `${darkGrey}`,
                alignment: 'right',
                border: [false, false, false, false],
                margin: 5,
              },
            ],
          ],
        },
        marginBottom: 5,
      },
      {
        style: ['lightGreyBg', 'textCenter'],
        table: {
          widths: ['*', '*'],
          body: [
            [
              {
                stack: [
                  {
                    text: 'Total Medication Investment /pig',
                    color: `${darkGrey}`,
                    fontSize: 8,
                    marginTop: 5,
                  },
                  {
                    text: this.sectionCardDetails[0].value,
                    color: `${red}`,
                    fontSize: 20,
                    bold: true,
                    marginBottom: 5,
                  },
                ],
                border: [false, false, true, false],
              },
              {
                stack: [
                  {
                    text: `Total Investment for 
                      ${this.numberOfAnimalHeads.toFixed(0)} Grower Produced`,
                    color: `${darkGrey}`,
                    fontSize: 8,
                    marginTop: 5,
                  },
                  {
                    text: this.sectionCardDetails[1].value,
                    color: `${red}`,
                    fontSize: 20,
                    bold: true,
                    marginBottom: 5,
                  },
                ],
                border: [false, false, false, false],
              },
            ],
          ],
        },
        layout: 'allGreyBorder',
        marginBottom: 5,
      },
      {
        columns: [
          {
            style: ['fontSize8'],
            table: {
              widths: ['*', '*'],
              body: [
                [
                  {
                    text: this.growerFeedCollapseTitle,
                    border: [false, false, false, false],
                    colSpan: 2,
                  },
                  {
                    text: '',
                  },
                ],
                [
                  {
                    text: 'No. of Days Fed (days)',
                    bold: true,
                    fillColor: `${lightGrey}`,
                    border: [false, false, false, true],
                  },
                  {
                    text:
                      'numberOfDaysFedInGrower' in this.transferredModalData &&
                      this.transferredModalData.numberOfDaysFedInGrower
                        ? `${this.addComma(
                            this.transferredModalData.numberOfDaysFedInGrower.toFixed(
                              0
                            )
                          )}`
                        : '-',
                    fillColor: `${pink}`,
                    border: [false, false, false, true],
                  },
                ],
                [
                  {
                    text: `Feed Consumption /day (kg)`,
                    bold: true,
                    fillColor: `${lightGrey}`,
                    border: [false, false, false, true],
                  },
                  {
                    text:
                      'feedConsumptionPerDayInGrower' in
                        this.transferredModalData &&
                      this.transferredModalData.feedConsumptionPerDayInGrower
                        ? `${this.addComma(
                            this.transferredModalData.feedConsumptionPerDayInGrower.toFixed(
                              2
                            )
                          )}`
                        : '-',
                    fillColor: `${pink}`,
                    border: [false, false, false, true],
                  },
                ],
                [
                  {
                    text: `Feed Consumption /grower piglet (kg)`,
                    bold: true,
                    fillColor: `${lightGrey}`,
                    border: [false, false, false, true],
                  },
                  {
                    text: this.feedConsumptionPerGrowerPig
                      ? `${this.addComma(
                          this.feedConsumptionPerGrowerPig.toFixed(2)
                        )}`
                      : '-',
                    border: [false, false, false, true],
                  },
                ],
                [
                  {
                    text: `Total Feed Consumption of all Grower Piglets (kg/tons)`,
                    bold: true,
                    fillColor: `${lightGrey}`,
                    border: [false, false, false, true],
                  },
                  {
                    text: this.totalFeedConsumptionPerGrowerPig
                      ? `${this.addComma(
                          (
                            this.totalFeedConsumptionPerGrowerPig * 1000
                          ).toFixed(0)
                        )} / ${this.addComma(
                          this.totalFeedConsumptionPerGrowerPig.toFixed(2)
                        )}`
                      : '- / -',
                    border: [false, false, false, true],
                  },
                ],
              ],
            },
            layout: 'allGreyBorder',
          },
          {
            style: ['fontSize8'],
            table: {
              widths: ['*', '*'],
              body: [
                [
                  {
                    text: 'Starter / Grower',
                    border: [false, false, false, false],
                    colSpan: 2,
                  },
                  {
                    text: '',
                  },
                ],
                [
                  {
                    text: `${this.growerMedicineBrand} Dose (kg/ton)`,
                    bold: true,
                    fillColor: `${lightGrey}`,
                    border: [false, false, false, true],
                  },
                  {
                    text:
                      'medicationDoseInGrowerProgram' in
                        this.transferredModalData &&
                      this.transferredModalData.medicationDoseInGrowerProgram
                        ? `${this.addComma(
                            this.transferredModalData.medicationDoseInGrowerProgram.toFixed(
                              2
                            )
                          )}`
                        : '-',
                    fillColor: `${pink}`,
                    border: [false, false, false, true],
                  },
                ],
                [
                  {
                    text: `Cost of ${this.growerMedicineBrand} /kg`,
                    bold: true,
                    fillColor: `${lightGrey}`,
                    border: [false, false, false, true],
                  },
                  {
                    text:
                      'costOfMedicationPerKiloInGrowerProgram' in
                        this.transferredModalData &&
                      this.transferredModalData
                        .costOfMedicationPerKiloInGrowerProgram
                        ? `${this.addComma(
                            this.transferredModalData.costOfMedicationPerKiloInGrowerProgram.toFixed(
                              2
                            )
                          )}`
                        : '-',
                    fillColor: `${pink}`,
                    border: [false, false, false, true],
                  },
                ],
                [
                  {
                    text: `Total (${this.growerMedicineBrand}) Usage (kg)`,
                    bold: true,
                    fillColor: `${lightGrey}`,
                    border: [false, false, false, true],
                  },
                  {
                    text: this.totalMedicationUsageInGrowerProgram
                      ? `${this.addComma(
                          this.totalMedicationUsageInGrowerProgram.toFixed(2)
                        )}`
                      : '-',
                    fillColor: `${pink}`,
                    border: [false, false, false, true],
                  },
                ],
                [
                  {
                    text: `Total cost of (${this.growerMedicineBrand}) at Nursery
                    (${this.currentCurrencySymbol})`,
                    bold: true,
                    fillColor: `${lightGrey}`,
                    border: [false, false, false, true],
                  },
                  {
                    text: this.totalCostOfMedicationAtGrower
                      ? `${this.addComma(
                          this.totalCostOfMedicationAtGrower.toFixed(2)
                        )}`
                      : '-',
                    fillColor: `${pink}`,
                    border: [false, false, false, true],
                  },
                ],
                [
                  {
                    text: `Addition Cost per kilo of Feeds (${this.currentCurrencySymbol})`,
                    bold: true,
                    fillColor: `${lightGrey}`,
                    border: [false, false, false, true],
                  },
                  {
                    text: this.additionalCostPerKiloOfFeedsInGrower
                      ? `${this.addComma(
                          this.additionalCostPerKiloOfFeedsInGrower.toFixed(2)
                        )}`
                      : '-',
                    border: [false, false, false, true],
                  },
                ],
                [
                  {
                    text: `Cost of (${this.growerMedicineBrand}) / nursery piglets
                    (${this.currentCurrencySymbol})`,
                    bold: true,
                    fillColor: `${lightGrey}`,
                    border: [false, false, false, true],
                  },
                  {
                    text: this.costOfMedicationPerGrowerPigs
                      ? `${this.addComma(
                          this.costOfMedicationPerGrowerPigs.toFixed(2)
                        )}`
                      : '-',
                    border: [false, false, false, true],
                  },
                ],
              ],
            },
            layout: 'allGreyBorder',
          },
        ],
        columnGap: 15,
        pageBreak: 'after',
      },
    ];
  }

  getMedicationProgram() {
    const contentDetails =
      this.transferredModalData.economicCalculationType ===
      EconomicCalculationType.pleurisy
        ? this.getMalaysiaMedicationProgramDetails()
        : this.getStandardMedicationProgramDetails();

    const content = [
      {
        svg: imgElancoLogo,
        width: 100,
        margin: [0, 0, 0, -30],
        border: [false, false, false, false],
      },
      {
        text: 'Economic Calculation (Medication Program)',
        style: 'sectionHeader',
      },
      {
        style: ['lightGreyBg', 'fontSize8'],
        table: {
          widths: ['auto', 'auto', '*'],
          body: [
            [
              {
                text: 'Current Exchange Rate:',
                color: `${darkGrey}`,
                border: [false, false, false, false],
                margin: 5,
              },
              {
                text: `${this.currentCurrencyDetails.baseCurrency.text} 1 = ${
                  this.currentCurrencyDetails.currentSelectedCurrency.text
                } ${
                  this.currentCurrencyDetails.currentSelectedCurrency.value /
                  this.currentCurrencyDetails.baseCurrency.value
                }`,
                color: `${blue}`,
                border: [false, false, false, false],
                margin: 5,
              },
              {
                text: `${this.currentCurrencyDetails.currentSelectedCurrency.text}`,
                color: `${darkGrey}`,
                alignment: 'right',
                border: [false, false, false, false],
                margin: 5,
              },
            ],
          ],
        },
        marginBottom: 5,
      },
    ];

    return content.concat(contentDetails as any);
  }
}

export { MedicationProgram };
