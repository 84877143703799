import { addDecimalPointToNumbers } from '@/utils/formats';
import { GenerateChartImage } from '@/utils/generate-chart/generate-chart-image';
import { brown, red } from '../../colors';
import { imgIcIndex, imgIcPrevalence } from '../../images';
import { getPneumoniaIndexColor, getReportRanking } from '../../utils/utils';

export function getLungLesionScoreSummary(generalDetails: any) {
  const chartData = {
    labels: [`B.L.`, `A.P.`, `C.P.`, `D.P.`, `Absc.`, `Scar.`, `Peri.`],
    datasets: [
      {
        data: [
          generalDetails.bronchopneumoniaLungsPercentage,
          generalDetails.averageOfAffectedSurfaceOutOfLungsWithActivePneumoniaPercentage,
          generalDetails.pigsWithCranialPleurisyPercentage,
          generalDetails.pigsWithDorsocaudalPleurisyPercentage,
          generalDetails.totalPigsWithAbscess,
          generalDetails.totalPigsWithScarring,
          generalDetails.totalPigsWithPericarditis,
        ],
        backgroundColor: [
          `#D6FFA4`,
          `#99E895`,
          `#FFF5C9`,
          `#FFD16A`,
          `#FFEAEA`,
          `#FFC1C1`,
          `#D9F2FF`,
        ],
        borderColor: [
          `#6C9D31`,
          `#6C9D31`,
          `#B99913`,
          `#B99913`,
          `#F15453`,
          `#F15453`,
          `#00A5E1`,
        ],
        borderWidth: 1,
        barPercentage: 0.2,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: true,
    legend: {
      display: false,
    },
    plugins: {
      datalabels: {
        display: false,
      },
    },
    scales: {
      yAxes: [
        {
          gridLines: {
            drawBorder: false,
          },
          ticks: {
            beginAtZero: true,
            min: 0,
            max: 120,
            callback: function (tick: string | number) {
              return tick.toString() + '%';
            },
          },
        },
      ],
      xAxes: [
        {
          gridLines: {
            display: false,
          },
        },
      ],
    },
    animation: false
  };

  const gci = new GenerateChartImage(chartData, options);

  const content = [
    {
      text: 'Lung Lesion Score Summary',
      style: 'sectionHeader',
    },
    {
      image: gci.generateImage(),
      width: 741,
      height: 200,
      alignment: 'center',
      marginBottom: 10,
    },
    {
      canvas: [
        {
          type: `rect`,
          w: 290,
          h: 60,
          r: 10,
          x: 0,
          y: 0,
          lineColor: `#E3E3E3`,
        },
      ],
      absolutePosition: { x: 460, y: 300 },
    },
    {
      canvas: [
        {
          type: `rect`,
          w: 289,
          h: 60,
          r: 10,
          x: 0,
          y: 0,
          lineColor: `#E3E3E3`,
        },
      ],
      absolutePosition: { x: 460, y: 365 },
    },
    {
      columns: [
        {
          style: ['fontSize8'],
          table: {
            widths: ['auto', '*', 'auto'],
            body: [
              [
                {
                  text: 'B.L',
                  fillColor: '#ceff94',
                  color: '#446225',
                  alignment: 'center',
                  margin: 5,
                  border: [false, false, false, true],
                },
                {
                  text: '% of Bronchopneumonic Lungs (Mild to Severe)',
                  bold: true,
                  fillColor: '#f2f2f2',
                  margin: 5,
                  border: [false, false, false, true],
                },
                {
                  text: `${addDecimalPointToNumbers(
                    generalDetails.bronchopneumoniaLungsPercentage,
                  )}%`,
                  margin: 5,
                  border: [false, false, false, true],
                },
              ],
              [
                {
                  text: 'A.P',
                  fillColor: '#8be783',
                  color: '#446427',
                  alignment: 'center',
                  margin: 5,
                  border: [false, false, false, true],
                },
                {
                  text:
                    'Avg. % of Affected Surface out of Lungs with Active Pneumonia',
                  bold: true,
                  fillColor: '#f2f2f2',
                  margin: 5,
                  border: [false, false, false, true],
                },
                {
                  text: `${addDecimalPointToNumbers(
                    generalDetails.averageOfAffectedSurfaceOutOfLungsWithActivePneumoniaPercentage,
                  )}%`,
                  margin: 5,
                  border: [false, false, false, true],
                },
              ],
              [
                {
                  text: 'C.P',
                  fillColor: '#fff4be',
                  color: '#715d19',
                  alignment: 'center',
                  margin: 5,
                  border: [false, false, false, true],
                },
                {
                  text: '% of Lungs with Cranial Pleurisy (P1)',
                  bold: true,
                  fillColor: '#f2f2f2',
                  margin: 5,
                  border: [false, false, false, true],
                },
                {
                  text: `${addDecimalPointToNumbers(
                    generalDetails.pigsWithCranialPleurisyPercentage,
                  )}%`,
                  margin: 5,
                  border: [false, false, false, true],
                },
              ],
              [
                {
                  text: 'D.P',
                  fillColor: '#fdc958',
                  color: '#634e0a',
                  alignment: 'center',
                  margin: 5,
                  border: [false, false, false, true],
                },
                {
                  text: '% of Lungs with Dorsocaudal Pleurisy (P2+P3+P4)',
                  bold: true,
                  fillColor: '#f2f2f2',
                  margin: 5,
                  border: [false, false, false, true],
                },
                {
                  text: `${addDecimalPointToNumbers(
                    generalDetails.pigsWithDorsocaudalPleurisyPercentage,
                  )}%`,
                  margin: 5,
                  border: [false, false, false, true],
                },
              ],
              [
                {
                  text: 'Absc.',
                  fillColor: '#ffe5e4',
                  color: '#fa464c',
                  alignment: 'center',
                  margin: 5,
                  border: [false, false, false, true],
                },
                {
                  text: '% of Lungs with Abscess',
                  bold: true,
                  fillColor: '#f2f2f2',
                  margin: 5,
                  border: [false, false, false, true],
                },
                {
                  text: `${addDecimalPointToNumbers(
                    generalDetails.totalPigsWithAbscess,
                  )}%`,
                  margin: 5,
                  border: [false, false, false, true],
                },
              ],
              [
                {
                  text: 'Scar.',
                  fillColor: '#feb1b4',
                  color: '#fb353d',
                  alignment: 'center',
                  margin: 5,
                  border: [false, false, false, true],
                },
                {
                  text: '% of Lungs with Scarring/Chronic',
                  bold: true,
                  fillColor: '#f2f2f2',
                  margin: 5,
                  border: [false, false, false, true],
                },
                {
                  text: `${addDecimalPointToNumbers(
                    generalDetails.totalPigsWithScarring,
                  )}%`,
                  margin: 5,
                  border: [false, false, false, true],
                },
              ],
              [
                {
                  text: 'Peri.',
                  fillColor: '#d2eeff',
                  color: '#1997db',
                  alignment: 'center',
                  margin: 5,
                  border: [false, false, false, false],
                },
                {
                  text: '% of Hearts with Pericarditis',
                  bold: true,
                  fillColor: '#f2f2f2',
                  margin: 5,
                  border: [false, false, false, false],
                },
                {
                  text: `${addDecimalPointToNumbers(
                    generalDetails.totalPigsWithPericarditis,
                  )}%`,
                  margin: 5,
                  border: [false, false, false, false],
                },
              ],
            ],
          },
          layout: 'allGreyBorder',
        },
        {
          table: {
            body: [
              [
                {
                  style: ['cellPadding', 'marginB10'],
                  table: {
                    widths: ['auto', '*', 'auto', '*'],
                    body: [
                      [
                        {
                          svg: imgIcIndex,
                          width: 41,
                          rowSpan: 2,
                          border: [false, false, false, false],
                        },
                        {
                          text: 'Pneumonia Index',
                          bold: true,
                          border: [false, false, false, false],
                          fontSize: 8,
                        },
                        {
                          svg: imgIcPrevalence,
                          width: 41,
                          rowSpan: 2,
                          border: [false, false, false, false],
                        },
                        {
                          text: 'Pneumonia Prevalence',
                          bold: true,
                          border: [false, false, false, false],
                          fontSize: 8,
                        },
                      ],
                      [
                        '',
                        {
                          text: `${generalDetails.pneumoniaIndex}`,
                          bold: true,
                          color: getPneumoniaIndexColor(generalDetails),
                          border: [false, false, false, false],
                          fontSize: 20,
                        },
                        '',
                        {
                          text: `${generalDetails.pneumoniaPrevalence}%`,
                          bold: true,
                          color: `${red}`,
                          border: [false, false, false, false],
                          fontSize: 20,
                        },
                      ],
                    ],
                  },
                  layout: 'noBorders',
                },
              ],
              [
                {
                  style: 'cellPadding',
                  table: {
                    widths: ['auto', '*', 'auto', '*'],
                    body: [
                      [
                        {
                          svg: imgIcIndex,
                          width: 41,
                          rowSpan: 2,
                          border: [false, false, false, false],
                        },
                        {
                          text: 'App Index',
                          bold: true,
                          border: [false, false, false, false],
                          fontSize: 8,
                        },
                        {
                          text: '',
                          border: [false, false, false, false],
                        },
                        {
                          stack: [
                            {
                              text: getReportRanking(generalDetails.appIndex) !== 'No Ranking' ? `${getReportRanking(generalDetails.appIndex)} out of ${generalDetails.appIndex.totalReports}` : '',
                              color: '#929292',
                              border: [false, false, false, false],
                              alignment: 'right',
                              fontSize: 8,
                            },
                            {
                              text: `${
                                getReportRanking(generalDetails.appIndex) ===
                                'No ranking'
                                  ? ''
                                  : `${generalDetails.appIndex.country.name} Total Reports`
                              }`,
                              color: '#929292',
                              border: [false, false, false, false],
                              alignment: 'right',
                              fontSize: 8,
                            },
                          ],
                          rowSpan: 2,
                          marginTop: 10,
                          border: [false, false, false, false],
                        },
                      ],
                      [
                        {
                          text: '',
                          border: [false, false, false, false],
                        },
                        {
                          text: `${generalDetails.appIndex.appIndex}`,
                          bold: true,
                          border: [false, false, false, false],
                          fontSize: 20,
                          color: getReportRanking(generalDetails.appIndex) !== 'No Ranking' ? generalDetails.appIndex.appIndexBackgroundColor : `${red}`,
                        },
                        {
                          text: '',
                          border: [false, false, false, false],
                        },
                        {
                          text: '',
                          border: [false, false, false, false],
                        },
                      ],
                    ],
                  },
                  layout: 'noBorders',
                },
              ],
            ],
          },
          layout: 'noBorders',
        },
      ],
      columnGap: 75,
      pageBreak: 'after',
    },
    {
      text: 'Lung Lesion Score Summary',
      style: 'sectionHeader',
    },
    {
      text: 'Summary Breakdown',
      bold: true,
      margin: [15, 0, 0, 15],
    },
    {
      style: ['cellPadding', 'reportDetails'],
      table: {
        widths: ['auto', '*', 'auto'],
        body: [
          [
            {
              text: 'Bronchopneumonia',
              bold: true,
              rowSpan: 3,
              fillColor: '#F5F5F5',
              border: [false, false, false, true],
              margin: [5, 15, 0, 15],
            },
            {
              text: 'Normal (%)',
              bold: true,
              fillColor: '#D6FFA4',
              color: '#5C7A36',
              border: [false, false, false, true],
            },
            {
              text: `${addDecimalPointToNumbers(generalDetails.normalScore)}`,
              border: [false, false, false, true],
              fillColor: '#D6FFA4',
              color: '#5C7A36',
            },
          ],
          [
            '',
            {
              text: 'Pneumonia (%)',
              bold: true,
              fillColor: '#FFF5C9',
              border: [false, false, false, true],
              color: `${brown}`,
            },
            {
              text: `${addDecimalPointToNumbers(
                generalDetails.pneumoniaScore,
              )}`,
              border: [false, false, false, true],
              fillColor: '#FFF5C9',
              color: `${brown}`,
            },
          ],
          [
            '',
            {
              text: 'Severe Pneumonia (%)',
              bold: true,
              fillColor: '#FFEAEA',
              border: [false, false, false, true],
              color: `${red}`,
            },
            {
              text: `${addDecimalPointToNumbers(
                generalDetails.severePneumoniaScore,
              )}`,
              border: [false, false, false, true],
              fillColor: '#FFEAEA',
              color: `${red}`,
            },
          ],
          [
            {
              text: 'Pleuritis',
              bold: true,
              rowSpan: 2,
              fillColor: '#F5F5F5',
              border: [false, false, false, true],
              margin: [5, 0, 0, 0],
            },
            {
              text: 'P1, SPES 1 (n)',
              border: [false, false, false, true],
            },
            {
              text: `${generalDetails.p1PleuritisScore}`,
              border: [false, false, false, true],
            },
          ],
          [
            '',
            {
              text: 'P2, SPES 2, 3, 4 (n)',
              border: [false, false, false, true],
            },
            {
              text: `${generalDetails.otherPleuritisScore}`,
              border: [false, false, false, true],
            },
          ],
          [
            {
              text: 'Other Lesions Observed',
              bold: true,
              rowSpan: 3,
              fillColor: '#F5F5F5',
              border: [false, false, false, false],
              margin: [5, 15, 0, 15],
            },
            {
              text: 'Abscess (n)',
              border: [false, false, false, true],
            },
            {
              text: `${generalDetails.abscessCount}`,
              border: [false, false, false, true],
            },
          ],
          [
            '',
            {
              text: 'Scarring/Chronic (n)',
              border: [false, false, false, true],
            },
            {
              text: `${generalDetails.scarringCount}`,
              border: [false, false, false, true],
            },
          ],
          [
            '',
            {
              text: 'Pericarditis (n)',
              border: [false, false, false, false],
            },
            {
              text: `${generalDetails.pericarditisCount}`,
              border: [false, false, false, false],
            },
          ],
        ],
      },
      layout: 'allGreyBorder',
      pageBreak: 'after',
    },
  ];

  gci.clear();
  return content;
}
