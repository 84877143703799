export function addCommas(targetNumber: string) {
  return targetNumber.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function addDecimalPointToNumbers(data: number) {
  if (!data) {
    return '0.0'
  }

  let output = data.toString();
  if (output.includes('.')) {
    if (output.split('.')[1].length > 2) {
      return data.toFixed(2);
    }
    return output;
  } else {
    output += '.0';
    return output;
  }
}
