






















































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import {
  LungLesionScoreParseAttachmentDetails,
  ScoreAttachmentObject,
} from '@/models/swine/report/report.model';
import AttachmentImageComponent from '@/components/swine/reports/AttachmentImageComponent.vue';
@Component({
  components: {
    AttachmentImageComponent,
  },
})
export default class BronchopneumoniaScoreAttachmentsTable extends Vue {
  @Prop({
    type: Array,
    default: null,
  })
  data!: LungLesionScoreParseAttachmentDetails[];
  attachmentMaximumCounter = 3;

  setDataStyle(details: ScoreAttachmentObject) {
    return `color: ${details.textColor}; background-color: ${details.backgroundColor}`;
  }
}
