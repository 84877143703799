

































































































import moment from 'moment';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ReportSummaryData } from '@/models/swine/report/report.model';
@Component({})
export default class DetailsCard extends Vue {
  @Prop({
    type: Object,
    default: null,
  })
  reportValues!: ReportSummaryData;

  get currentDate() {
    return moment(this.reportValues.date).local().format('DD/MM/YYYY hh:mm A');
  }

  get currentWeaningDate() {
    let output = '-';

    if (this.reportValues.weaningDate) {
      output = moment(this.reportValues.weaningDate)
        .local()
        .format('DD/MM/YYYY hh:mm A');
    }
    return output;
  }

  get currentMarketDate() {
    let output = '-';

    if (this.reportValues.weaningDate) {
      output = moment(this.reportValues.marketDate)
        .local()
        .format('DD/MM/YYYY hh:mm A');
    }
    return output;
  }
}
