import { render, staticRenderFns } from "./FarmProductionPerformance.vue?vue&type=template&id=670ca93b&scoped=true&"
import script from "./FarmProductionPerformance.vue?vue&type=script&lang=ts&"
export * from "./FarmProductionPerformance.vue?vue&type=script&lang=ts&"
import style0 from "./FarmProductionPerformance.vue?vue&type=style&index=0&id=670ca93b&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "670ca93b",
  null
  
)

export default component.exports