








































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import FormTemplate from '@/components/swine/layout/FormTemplate.vue';
import ReferenceTypeDropdown from '../../ReferenceTypeDropdown.vue';
import CalculatorInputField from '../../CalculatorInputField.vue';
import {
  EconomicCalculationModalDataType,
  UpdateAnimalNumberModalDetails,
} from '@/models/swine/economic-calculation.model';
import { validateInputNumber } from '@/utils/validation';
import { EconomicCalculationType } from '@/utils/constants';

@Component({
  components: {
    FormTemplate,
    ReferenceTypeDropdown,
    CalculatorInputField,
  },
})
export default class UpdateAnimalNumberModal extends Vue {
  formTitle = 'Update General Details';

  isDisabled = true;

  updateModalInputObject: UpdateAnimalNumberModalDetails = {
    numberOfAnimalHeads: null,
    weanMarketDays: null,
    weekNumber: null,
    ageOfWeaning: null,
  };

  @Prop({ default: false })
  isActive!: boolean;

  @Prop()
  transferredModalData!: EconomicCalculationModalDataType;

  get isPulmotilValueCalculationTypeSelected() {
    return (
      this.transferredModalData.economicCalculationType ===
      EconomicCalculationType.pulmotil
    );
  }

  handleUpdateModalClosed() {
    this.$emit('close');
    this.updateInputObject();
  }

  transferNewAnimalNumber() {
    this.$emit('update', {
      numberOfAnimalHeads: Number(
        this.updateModalInputObject.numberOfAnimalHeads
      ),
      weanMarketDays: Number(this.updateModalInputObject.weanMarketDays),
      weekNumber: this.updateModalInputObject.weekNumber,
      ageOfWeaning: this.updateModalInputObject.ageOfWeaning,
    });
  }

  calculateWeekNumber() {
    let numberOfDays = 0;

    if (
      this.updateModalInputObject.weanMarketDays &&
      Number(this.updateModalInputObject.weanMarketDays)
    ) {
      numberOfDays += Number(this.updateModalInputObject.weanMarketDays);
    }

    if (
      this.updateModalInputObject.ageOfWeaning &&
      Number(this.updateModalInputObject.ageOfWeaning)
    ) {
      numberOfDays += Number(this.updateModalInputObject.ageOfWeaning);
    }

    const remainderDays = numberOfDays % 7;
    const noOfWeeks = Math.floor(numberOfDays / 7);
    if (remainderDays >= 4 || noOfWeeks === 0) {
      this.updateModalInputObject.weekNumber = noOfWeeks + 1;
    } else {
      this.updateModalInputObject.weekNumber = noOfWeeks;
    }
    this.disableHandler();
  }

  @Watch('updateModalInputObject')
  disableHandler() {
    let hasError = false;
    for (const detail in this.updateModalInputObject) {
      const newKey = detail as keyof UpdateAnimalNumberModalDetails;
      if (
        detail !== 'referenceType' &&
        !validateInputNumber(String(this.updateModalInputObject[newKey]))
      ) {
        // If validation fails
        hasError = true;
      }
    }
    this.isDisabled = hasError;
  }

  @Watch('transferredModalData', { deep: true, immediate: true })
  updateInputObject() {
    if (
      this.isPulmotilValueCalculationTypeSelected &&
      'numberOfGrowerPigs' in this.transferredModalData
    ) {
      this.updateModalInputObject = {
        numberOfAnimalHeads: this.transferredModalData.numberOfGrowerPigs,
        weanMarketDays: this.transferredModalData.weanMarketDays,
        weekNumber: this.transferredModalData.weekNumber,
        ageOfWeaning: this.transferredModalData.ageOfWeaning,
      };
    }

    if (
      !this.isPulmotilValueCalculationTypeSelected &&
      'numberOfAnimalHeads' in this.transferredModalData
    ) {
      this.updateModalInputObject = {
        numberOfAnimalHeads: this.transferredModalData.numberOfAnimalHeads,
        weanMarketDays: this.transferredModalData.weanMarketDays,
        weekNumber: this.transferredModalData.weekNumber,
        ageOfWeaning: this.transferredModalData.ageOfWeaning,
      };
    }
  }
}
