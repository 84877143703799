



































































































































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import FormTemplate from '@/components/swine/layout/FormTemplate.vue';
import ReferenceTypeDropdown from '../../ReferenceTypeDropdown.vue';
import CalculatorInputField from '../../CalculatorInputField.vue';
import { PleurisyCalculatorModalData } from '@/models/swine/economic-calculation.model';
import { validateInputNumber } from '@/utils/validation';
import { EconomicCalculationWithReportData } from '@/models/swine/report/report.model';
import AssumptionInputField from './AssumptionInputField.vue';

@Component({
  components: {
    FormTemplate,
    ReferenceTypeDropdown,
    CalculatorInputField,
    AssumptionInputField,
  },
})
export default class AssumptionModal extends Vue {
  formTitle = 'Update Assumption';

  isDisabled = true;

  initialAssumption = true;

  assumptionModalInputObject: Partial<PleurisyCalculatorModalData> = {
    bronchopneumoniaAssumptionNormalLungsLowPercentage: null,
    bronchopneumoniaAssumptionNormalLungsHighPercentage: null,
    bronchopneumoniaAssumptionLowPneumonia: null,
    bronchopneumoniaAssumptionNormalPneumonia: null,
    bronchopneumoniaAssumptionHighPneumonia: null,
    bronchopneumoniaAssumptionLowSeverePneumonia: null,
    bronchopneumoniaAssumptionHighSeverePneumonia: null,
    p3AndP4PrevalenceAssumption: null,
    appPleurisyPrevalenceAssumption: null,
    postWeaningMortalityAssumption: null,
  };

  @Prop({})
  calculatorModalInputDataRetrieve!: PleurisyCalculatorModalData;

  @Prop({
    type: Object,
    default: null,
  })
  currentReportDetails!: EconomicCalculationWithReportData;

  @Prop({
    default: true,
  })
  firstAssumption!: boolean;

  @Prop({ default: false })
  isActive!: boolean;

  get animalPercentageFalse() {
    return this.percentageAnimalSum !== 100;
  }

  get animalPercentageTrue() {
    return this.percentageAnimalSum === 100;
  }

  get animalPercentages() {
    const { pneumoniaSeverityDict } = this.calculatorModalInputDataRetrieve;
    let animalTotal = 0;
    if (pneumoniaSeverityDict) {
      animalTotal = Object.values(pneumoniaSeverityDict)
        .slice(0, 7)
        .reduce((total, value) => {
          return total + value;
        });
      return Object.values(pneumoniaSeverityDict).map((number) => {
        try {
          return Number(((number / animalTotal) * 100).toFixed(2));
        } catch {
          return 0;
        }
      });
    }
    return [];
  }

  get bronchopneumoniaNormalLungsLowPercentage() {
    return this.animalPercentages[0].toFixed(2);
  }

  get bronchopneumoniaNormalLungsHighPercentage() {
    return this.animalPercentages[1].toFixed(2);
  }

  get bronchopneumoniaLowPneumonia() {
    return this.animalPercentages[2].toFixed(2);
  }

  get bronchopneumoniaNormalPneumonia() {
    return this.animalPercentages[3].toFixed(2);
  }

  get bronchopneumoniaHighPneumonia() {
    return this.animalPercentages[4].toFixed(2);
  }

  get bronchopneumoniaLowSeverePneumonia() {
    return this.animalPercentages[5].toFixed(2);
  }

  get bronchopneumoniaHighSeverePneumonia() {
    return this.animalPercentages[6].toFixed(2);
  }

  get originalPercentageAnimalSum() {
    return (
      this.animalPercentages
        .slice(0, 7)
        .reduce((total, value) => {
          return total + value;
        })
        .toFixed(1) || 0
    );
  }

  get appPleurisyPrevalence() {
    return this.calculatorModalInputDataRetrieve.appPleurisyPrevalence
      ? this.calculatorModalInputDataRetrieve.appPleurisyPrevalence.toFixed(2)
      : 0;
  }

  get p3AndP4Prevalence() {
    return this.calculatorModalInputDataRetrieve.p3AndP4Prevalence
      ? this.calculatorModalInputDataRetrieve.p3AndP4Prevalence.toFixed(2)
      : 0;
  }

  get postWeaningMortality() {
    return this.calculatorModalInputDataRetrieve.postWeaningMortality
      ? this.calculatorModalInputDataRetrieve.postWeaningMortality.toFixed(1)
      : 0;
  }

  handleAssumptionModalClosed() {
    this.populateValues();
    this.$emit('close');
  }

  createAssumptionPage() {
    let newData: Partial<PleurisyCalculatorModalData> | {} = {};
    for (const [key, value] of Object.entries(
      this.assumptionModalInputObject,
    )) {
      newData = {
        ...newData,
        [key]: Number(value),
      };
    }
    this.assumptionModalInputObject = {
      ...this.assumptionModalInputObject,
      ...newData,
    };
    this.$emit('create', this.assumptionModalInputObject);
    this.$emit('added');
    this.initialAssumption = false;
    this.$buefy.toast.open({
      message: `Assumption has been added.`,
      type: `is-dark`,
      duration: 3000,
    });
  }

  mounted() {
    this.populateValues();
  }

  populateValues() {
    const {
      bronchopneumoniaAssumptionNormalLungsLowPercentage,
      bronchopneumoniaAssumptionNormalLungsHighPercentage,
      bronchopneumoniaAssumptionLowPneumonia,
      bronchopneumoniaAssumptionNormalPneumonia,
      bronchopneumoniaAssumptionHighPneumonia,
      bronchopneumoniaAssumptionLowSeverePneumonia,
      bronchopneumoniaAssumptionHighSeverePneumonia,
      p3AndP4PrevalenceAssumption,
      appPleurisyPrevalenceAssumption,
      postWeaningMortalityAssumption,
    } = this.calculatorModalInputDataRetrieve;
    const valuesExist = [
      bronchopneumoniaAssumptionNormalLungsLowPercentage,
      bronchopneumoniaAssumptionNormalLungsHighPercentage,
      bronchopneumoniaAssumptionLowPneumonia,
      bronchopneumoniaAssumptionNormalPneumonia,
      bronchopneumoniaAssumptionHighPneumonia,
      bronchopneumoniaAssumptionLowSeverePneumonia,
      bronchopneumoniaAssumptionHighSeverePneumonia,
      p3AndP4PrevalenceAssumption,
      appPleurisyPrevalenceAssumption,
      postWeaningMortalityAssumption,
    ];
    if (valuesExist.every((value) => value === undefined)) {
      //for undefined values initially
      this.setInitialAnimalPercentages();
    } else {
      this.unloadAssumptionModalValues();
    }
  }

  setInitialAnimalPercentages() {
    if (
      this.calculatorModalInputDataRetrieve.p3AndP4Prevalence &&
      this.calculatorModalInputDataRetrieve.appPleurisyPrevalence
    ) {
      const updateObject = {
        bronchopneumoniaAssumptionNormalLungsLowPercentage:
          Number(this.animalPercentages[0]) || 0,
        bronchopneumoniaAssumptionNormalLungsHighPercentage:
          Number(this.animalPercentages[1]) || 0,
        bronchopneumoniaAssumptionLowPneumonia:
          Number(this.animalPercentages[2]) || 0,
        bronchopneumoniaAssumptionNormalPneumonia:
          Number(this.animalPercentages[3]) || 0,
        bronchopneumoniaAssumptionHighPneumonia:
          Number(this.animalPercentages[4]) || 0,
        bronchopneumoniaAssumptionLowSeverePneumonia:
          Number(this.animalPercentages[5]) || 0,
        bronchopneumoniaAssumptionHighSeverePneumonia:
          Number(this.animalPercentages[6]) || 0,
        p3AndP4PrevalenceAssumption: Number(
          Number(
            this.calculatorModalInputDataRetrieve.p3AndP4Prevalence.toFixed(2),
          ),
        ),
        appPleurisyPrevalenceAssumption: Number(
          Number(
            this.calculatorModalInputDataRetrieve.appPleurisyPrevalence.toFixed(
              2,
            ),
          ),
        ),
        postWeaningMortalityAssumption: Number(
          this.calculatorModalInputDataRetrieve.postWeaningMortality,
        ),
      };
      this.assumptionModalInputObject = updateObject;
    }
  }

  @Watch('calculatorModalInputDataRetrieve', { deep: true })
  unloadAssumptionModalValues() {
    const {
      bronchopneumoniaAssumptionNormalLungsLowPercentage,
      bronchopneumoniaAssumptionNormalLungsHighPercentage,
      bronchopneumoniaAssumptionLowPneumonia,
      bronchopneumoniaAssumptionNormalPneumonia,
      bronchopneumoniaAssumptionHighPneumonia,
      bronchopneumoniaAssumptionLowSeverePneumonia,
      bronchopneumoniaAssumptionHighSeverePneumonia,
      p3AndP4PrevalenceAssumption,
      appPleurisyPrevalenceAssumption,
      postWeaningMortalityAssumption,
    } = this.calculatorModalInputDataRetrieve;
    const valuesExist = [
      bronchopneumoniaAssumptionNormalLungsLowPercentage,
      bronchopneumoniaAssumptionNormalLungsHighPercentage,
      bronchopneumoniaAssumptionLowPneumonia,
      bronchopneumoniaAssumptionNormalPneumonia,
      bronchopneumoniaAssumptionHighPneumonia,
      bronchopneumoniaAssumptionLowSeverePneumonia,
      bronchopneumoniaAssumptionHighSeverePneumonia,
      p3AndP4PrevalenceAssumption,
      appPleurisyPrevalenceAssumption,
      postWeaningMortalityAssumption,
    ];
    if (valuesExist.every((value) => value !== undefined)) {
      this.assumptionModalInputObject = {
        bronchopneumoniaAssumptionNormalLungsLowPercentage: this
          .calculatorModalInputDataRetrieve
          .bronchopneumoniaAssumptionNormalLungsLowPercentage,
        bronchopneumoniaAssumptionNormalLungsHighPercentage: this
          .calculatorModalInputDataRetrieve
          .bronchopneumoniaAssumptionNormalLungsHighPercentage,
        bronchopneumoniaAssumptionLowPneumonia: this
          .calculatorModalInputDataRetrieve
          .bronchopneumoniaAssumptionLowPneumonia,
        bronchopneumoniaAssumptionNormalPneumonia: this
          .calculatorModalInputDataRetrieve
          .bronchopneumoniaAssumptionNormalPneumonia,
        bronchopneumoniaAssumptionHighPneumonia: this
          .calculatorModalInputDataRetrieve
          .bronchopneumoniaAssumptionHighPneumonia,
        bronchopneumoniaAssumptionLowSeverePneumonia: this
          .calculatorModalInputDataRetrieve
          .bronchopneumoniaAssumptionLowSeverePneumonia,
        bronchopneumoniaAssumptionHighSeverePneumonia: this
          .calculatorModalInputDataRetrieve
          .bronchopneumoniaAssumptionHighSeverePneumonia,
        p3AndP4PrevalenceAssumption: this.calculatorModalInputDataRetrieve
          .p3AndP4PrevalenceAssumption,
        appPleurisyPrevalenceAssumption: this.calculatorModalInputDataRetrieve
          .appPleurisyPrevalenceAssumption,
        postWeaningMortalityAssumption: this.calculatorModalInputDataRetrieve
          .postWeaningMortalityAssumption,
      };
    } else if (valuesExist.every((value) => value === undefined)) {
      this.populateValues();
    } else {
      // if there are some undefined values, replace with dash.
      let newAssumptionModalInputObject:
        | Partial<PleurisyCalculatorModalData>
        | {} = {};
      const includedKeys = [
        'bronchopneumoniaAssumptionNormalLungsLowPercentage',
        'bronchopneumoniaAssumptionNormalLungsHighPercentage',
        'bronchopneumoniaAssumptionLowPneumonia',
        'bronchopneumoniaAssumptionNormalPneumonia',
        'bronchopneumoniaAssumptionHighPneumonia',
        'bronchopneumoniaAssumptionLowSeverePneumonia',
        'bronchopneumoniaAssumptionHighSeverePneumonia',
        'p3AndP4PrevalenceAssumption',
        'appPleurisyPrevalenceAssumption',
        'postWeaningMortalityAssumption',
      ];
      for (const [key, value] of Object.entries(
        this.calculatorModalInputDataRetrieve,
      )) {
        if (includedKeys.includes(key)) {
          value !== undefined
            ? (newAssumptionModalInputObject = {
                ...newAssumptionModalInputObject,
                [key]: value,
              })
            : (newAssumptionModalInputObject = {
                ...newAssumptionModalInputObject,
                [key]: '-',
              });
        }
      }
      this.assumptionModalInputObject = {
        ...this.assumptionModalInputObject,
        ...newAssumptionModalInputObject,
      };
    }
  }

  get percentageAnimalSum() {
    const animalSum = Number(
      (
        Number(
          this.assumptionModalInputObject
            .bronchopneumoniaAssumptionNormalLungsLowPercentage,
        ) +
        Number(
          this.assumptionModalInputObject
            .bronchopneumoniaAssumptionNormalLungsHighPercentage,
        ) +
        Number(
          this.assumptionModalInputObject
            .bronchopneumoniaAssumptionLowPneumonia,
        ) +
        Number(
          this.assumptionModalInputObject
            .bronchopneumoniaAssumptionNormalPneumonia,
        ) +
        Number(
          this.assumptionModalInputObject
            .bronchopneumoniaAssumptionHighPneumonia,
        ) +
        Number(
          this.assumptionModalInputObject
            .bronchopneumoniaAssumptionLowSeverePneumonia,
        ) +
        Number(
          this.assumptionModalInputObject
            .bronchopneumoniaAssumptionHighSeverePneumonia,
        )
      ).toFixed(1),
    );
    if (isNaN(animalSum)) {
      return '-';
    } else {
      return animalSum;
    }
  }

  get formHasError() {
    // validate input
    for (const detail in this.assumptionModalInputObject) {
      const newKey = detail as keyof Partial<PleurisyCalculatorModalData>;
      if (!validateInputNumber(String(this.assumptionModalInputObject[newKey])))
        return true;
    }
    // validate if sum of all percentages is not equal to 100
    if (this.percentageAnimalSum !== 100) return true;
    return false;
  }
}
