import { CurrencyDropdownOption } from '@/models/swine/dropdown.model';
import {
  AssumptionModalData,
  PulmotilCalculationModalData,
  ReferenceType,
} from '@/models/swine/economic-calculation.model';
import { DisplayDataAssumption } from '@/models/swine/report/report.model';
import { ReferenceTypeAdgReductionPercentages } from '@/utils/constants';
import {
  blue,
  brown,
  cream,
  darkGrey,
  darkPink,
  green,
  lightBlue,
  lightGreen,
  lightGrey,
  limeGreen,
  orange,
  pink,
  red,
  yellow,
} from '@/utils/download-pdf/colors';
import {
  calculateConsumptionPerLactactingSow,
  calculateTotalConsumptionOfAllLactactingSows,
  calculateTotalMedicationUsageInCleanSowProgram,
  calculateTotalCostOfMedicationInLactationInCleanSowProgram,
  calculateAdditionalCostPerKiloOfFeedsInCleanSowProgram,
  calculateTotalCostOfMedicationPerLactactingSowsInCleanSowProgram,
  calculateCostOfMedicationPerPigletsInCleanSowProgram,
  calculateFeedConsumptionPerNurseryPiglet,
  calculateTotalFeedConsumptionOfNurseryPiglets,
  calculateTotalMedicationUsageInEarlyIntervention,
  calculateTotalCostOfMedicationAtNurseryInEarlyIntervention,
  calculateAdditionalCostPerKiloOfFeedsInEarlyIntervention,
  calculateCostOfMedicationPerNurseryPigletsInEarlyIntervention,
  calculateFeedConsumptionPerGrowerPig,
  calculateTotalFeedConsumptionPerGrowerPig,
  calculateTotalMedicationUsageInGrowerProgram,
  calculateTotalCostOfMedicationAtGrowerInGrowerProgram,
  calculateAdditionalCostPerKiloOfFeedsInGrowerProgram,
  calculateCostOfMedicationPerGrowerPigInGrowerProgram,
  calculateTotalMedicationInvestmentPerPig,
  calculateTotalInvestmentForGrowerPigsProduced,
} from '@/utils/economic-calculation/pulmotil/formula';
import { addCommas, addDecimalPointToNumbers } from '@/utils/formats';
import { GenerateChartImage } from '@/utils/generate-chart/generate-chart-image';
import { roundTo } from 'round-to';
import { imgElancoLogo } from '@/utils/download-pdf/images';

function addDecimalPointToRoundOffNumbers(data: number) {
  const value = roundTo(data, 1);
  return addDecimalPointToNumbers(value);
}
class StandardEconomicCalculationAssumption {
  transferredModalData: PulmotilCalculationModalData;
  assumptionReportSourceOfTruth: AssumptionModalData = {
    bronchopneumoniaAssumptionNormalLungsLowPercentage: null,
    bronchopneumoniaAssumptionNormalLungsHighPercentage: null,
    bronchopneumoniaAssumptionLowPneumonia: null,
    bronchopneumoniaAssumptionNormalPneumonia: null,
    bronchopneumoniaAssumptionHighPneumonia: null,
    bronchopneumoniaAssumptionLowSeverePneumonia: null,
    bronchopneumoniaAssumptionHighSeverePneumonia: null,
    p3AndP4PrevalenceAssumption: 0,
    appPleurisyPrevalenceAssumption: null,
    postWeaningMortalityAssumption: null,
  };
  gci: any;
  gci2: any;
  currentCurrencyDetails: {
    baseCurrency: CurrencyDropdownOption;
    currentSelectedCurrency: CurrencyDropdownOption;
  };

  constructor(
    transferredModalData: PulmotilCalculationModalData,
    currentCurrencyDetails: {
      baseCurrency: CurrencyDropdownOption;
      currentSelectedCurrency: CurrencyDropdownOption;
    }
  ) {
    this.transferredModalData = transferredModalData;
    this.currentCurrencyDetails = currentCurrencyDetails;

    const { p3AndP4PrevalenceAssumption, appPleurisyPrevalenceAssumption } =
      this.transferredModalData;
    if (p3AndP4PrevalenceAssumption) {
      this.assumptionReportSourceOfTruth.p3AndP4PrevalenceAssumption = Number(
        p3AndP4PrevalenceAssumption.toFixed(2)
      );
    }
    if (appPleurisyPrevalenceAssumption) {
      this.assumptionReportSourceOfTruth.appPleurisyPrevalenceAssumption =
        Number(appPleurisyPrevalenceAssumption.toFixed(2));
    }

    this.assumptionReportSourceOfTruth.bronchopneumoniaAssumptionNormalLungsLowPercentage =
      Number(this.animalPercentages[0] * 100);
    this.assumptionReportSourceOfTruth.bronchopneumoniaAssumptionNormalLungsHighPercentage =
      Number(this.animalPercentages[1] * 100);
    this.assumptionReportSourceOfTruth.bronchopneumoniaAssumptionLowPneumonia =
      Number(this.animalPercentages[2] * 100);
    this.assumptionReportSourceOfTruth.bronchopneumoniaAssumptionNormalPneumonia =
      Number(this.animalPercentages[3] * 100);
    this.assumptionReportSourceOfTruth.bronchopneumoniaAssumptionHighPneumonia =
      Number(this.animalPercentages[4] * 100);
    this.assumptionReportSourceOfTruth.bronchopneumoniaAssumptionLowSeverePneumonia =
      Number(this.animalPercentages[5] * 100);
    this.assumptionReportSourceOfTruth.bronchopneumoniaAssumptionHighSeverePneumonia =
      Number(this.animalPercentages[6] * 100);
  }

  // Standard's numberOfAnimalHeads value
  get numberOfAnimalHeads(): number {
    return this.transferredModalData.numberOfGrowerPigs;
  }

  // Broncho Related

  get adgReductionPercentages() {
    return this.transferredModalData.referenceType
      ? ReferenceTypeAdgReductionPercentages[
          this.transferredModalData.referenceType
        ]
      : ReferenceTypeAdgReductionPercentages[ReferenceType.E_STERVERMER];
  }

  get animalPercentages() {
    if (this.transferredModalData.pneumoniaSeverityDict) {
      return Object.values(this.transferredModalData.pneumoniaSeverityDict).map(
        (number) => {
          try {
            return Number((number / this.animalTotal).toFixed(4));
          } catch {
            return 0;
          }
        }
      );
    }
    return [];
  }

  get animalTotal() {
    let total = 0;
    for (const key in this.transferredModalData.pneumoniaSeverityDict) {
      if (key !== 'NE') {
        total += this.transferredModalData.pneumoniaSeverityDict[key];
      }
    }
    return total;
  }

  get numberOfAnimalHeadsSold() {
    return this.animalPercentages.map((percentage) => {
      if (this.numberOfAnimalHeads !== null) {
        return this.numberOfAnimalHeads * percentage;
      }
      return 0;
    });
  }

  get daysToReach() {
    return this.adgFormula.map((adg) => {
      if (
        this.transferredModalData.adgValue !== null &&
        this.transferredModalData.weanMarketDays !== null
      ) {
        return (
          (this.firstFinalWeight(
            this.transferredModalData.adgValue,
            this.transferredModalData.weanMarketDays
          ) /
            adg) *
          1000
        );
      }
      return 0;
    });
  }

  get extraDaysToReach() {
    return this.daysToReach.map((days) => {
      try {
        return days - this.daysToReach[0];
      } catch {
        return 0;
      }
    });
  }

  get extraFeedConsumed() {
    return this.extraDaysToReach.map((extraDay) => {
      if (this.transferredModalData.adfiValue !== null) {
        return extraDay * this.transferredModalData.adfiValue;
      }
      return 0;
    });
  }

  get additionalFeedCost() {
    return this.extraFeedConsumed.map((extraFeed) => {
      if (this.transferredModalData.additionalFeedCostPigRate !== null) {
        return extraFeed * this.transferredModalData.additionalFeedCostPigRate;
      }
      return 0;
    });
  }

  get additionalFeedCostPer1000() {
    return this.additionalFeedCost.map((addFeedCost, index) => {
      return addFeedCost * this.numberOfAnimalHeadsSold[index];
    });
  }

  get totalAdditionalFeedCostPer1000() {
    function reducer(total: number, value: number) {
      return total + value;
    }
    return this.additionalFeedCostPer1000.reduce(reducer);
  }

  // Pleurisy Related

  get pleurisyPrevalenceBackValue() {
    if (
      this.transferredModalData.appPleurisyPrevalence !== undefined &&
      this.numberOfAnimalHeads !== null
    ) {
      return (
        (Number(this.transferredModalData.appPleurisyPrevalence.toFixed(2)) *
          this.numberOfAnimalHeads) /
        100
      );
    }
    return 0;
  }

  get p3P4BackValue() {
    if (
      this.transferredModalData.p3AndP4Prevalence !== undefined &&
      this.numberOfAnimalHeads !== null
    ) {
      return (
        (Number(this.transferredModalData.p3AndP4Prevalence.toFixed(2)) *
          this.numberOfAnimalHeads) /
        100
      );
    }
    return 0;
  }

  get postWeaningMortalityBackValue() {
    if (
      this.transferredModalData.postWeaningMortality !== null &&
      this.numberOfAnimalHeads !== null
    ) {
      return (
        (this.transferredModalData.postWeaningMortality *
          this.numberOfAnimalHeads) /
        100
      );
    }
    return 0;
  }

  get weaningMortalityPercentage() {
    return this.transferredModalData.postWeaningMortality;
  }

  get weaningMortalityHeads() {
    if (
      this.weaningMortalityPercentage !== null &&
      this.numberOfAnimalHeads !== null
    ) {
      return (this.weaningMortalityPercentage * this.numberOfAnimalHeads) / 100;
    }
    return 0;
  }

  get totalCostPostWeaning() {
    if (
      this.transferredModalData.liveWeightPrice !== null &&
      this.transferredModalData.targetMarketWeight !== null
    ) {
      return (
        this.weaningMortalityHeads *
        this.transferredModalData.liveWeightPrice *
        this.transferredModalData.targetMarketWeight
      );
    }
    return 0;
  }

  get effectADGPigs() {
    if (
      this.transferredModalData.p3AndP4Prevalence !== undefined &&
      this.transferredModalData.weanMarketDays !== null
    ) {
      return (
        (Number(this.transferredModalData.p3AndP4Prevalence.toFixed(2)) *
          2 *
          this.transferredModalData.weanMarketDays) /
        1000
      );
    }
    return 0;
  }

  get totalCostADGPigs() {
    if (this.transferredModalData.liveWeightPrice !== null) {
      return (
        this.p3P4BackValue *
        this.effectADGPigs *
        this.transferredModalData.liveWeightPrice
      );
    }
    return 0;
  }

  get effectOnSlaughterWeight() {
    if (this.transferredModalData.p3AndP4Prevalence !== undefined) {
      return (
        Number(this.transferredModalData.p3AndP4Prevalence.toFixed(2)) * 0.07
      );
    }
    return 0;
  }

  get totalEffectOnSlaughterWeight() {
    if (this.transferredModalData.liveWeightPrice !== null) {
      return (
        this.p3P4BackValue *
        this.effectOnSlaughterWeight *
        this.transferredModalData.liveWeightPrice
      );
    }
    return 0;
  }

  get effectOnSlaughterAge() {
    if (this.transferredModalData.p3AndP4Prevalence !== undefined) {
      return (
        Number(this.transferredModalData.p3AndP4Prevalence.toFixed(2)) * 0.26
      );
    }
    return 0;
  }

  get totalEffectSlaughterAge() {
    if (this.transferredModalData.averageFeedPrice !== null) {
      return (
        this.p3P4BackValue *
        this.effectOnSlaughterAge *
        2 *
        this.transferredModalData.averageFeedPrice
      );
    }
    return 0;
  }

  get totalCostOfAPP() {
    return (
      this.totalCostPostWeaning +
      this.totalCostADGPigs +
      this.totalEffectOnSlaughterWeight +
      this.totalEffectSlaughterAge
    );
  }

  // Medication Section

  /*
    Standard Economic Calculation Section
  */

  get lactationFeedCollapseTitle() {
    if ('numberOfSowsFarrowingPerMonth' in this.transferredModalData) {
      return `Lactation Feed ~ ${roundTo(
        this.transferredModalData.numberOfSowsFarrowingPerMonth,
        0
      )} heads`;
    }
    return `Lactation Feed ~ - heads`;
  }

  get nurseryFeedCollapseTitle() {
    if ('numberOfPigletsAtNursery' in this.transferredModalData) {
      return `Nursery Feed ~ ${roundTo(
        this.transferredModalData.numberOfPigletsAtNursery,
        0
      )} heads`;
    }
    return `Nursery Feed ~ - heads`;
  }

  get growerFeedCollapseTitle() {
    if ('numberOfGrowerPigs' in this.transferredModalData) {
      return `Grower Feed ~ ${roundTo(
        this.transferredModalData.numberOfGrowerPigs,
        0
      )} heads`;
    }
    return `G Feed ~ - heads`;
  }

  get cleanSowMedicineBrand() {
    return this.transferredModalData.cleanSowMedicineBrand || '-';
  }

  get earlyInterventionMedicineBrand() {
    return 'earlyInterventionMedicineBrand' in this.transferredModalData
      ? this.transferredModalData.earlyInterventionMedicineBrand
      : '-';
  }

  get growerMedicineBrand() {
    return 'growerMedicineBrand' in this.transferredModalData
      ? this.transferredModalData.growerMedicineBrand
      : '-';
  }

  get growerHeads() {
    return 'numberOfGrowerPigs' in this.transferredModalData
      ? roundTo(this.transferredModalData.numberOfGrowerPigs, 0)
      : '-';
  }

  get costOfMedicationPerPigletsHeads() {
    return 'averageLitterSizeBornAlive' in this.transferredModalData
      ? this.transferredModalData.averageLitterSizeBornAlive
      : 0;
  }

  get currentCurrencySymbol() {
    return this.transferredModalData.currencySymbol;
  }

  // Feed Consumption
  get consumptionPerLactactingSow() {
    const adfiAtGestation =
      'adfiAtGestation' in this.transferredModalData &&
      this.transferredModalData.adfiAtGestation
        ? this.transferredModalData.adfiAtGestation
        : null;
    const numberOfDaysPreFarrowing =
      'numberOfDaysPreFarrowing' in this.transferredModalData &&
      this.transferredModalData.numberOfDaysPreFarrowing
        ? this.transferredModalData.numberOfDaysPreFarrowing
        : null;
    const adfiAtLactation =
      'adfiAtLactation' in this.transferredModalData &&
      this.transferredModalData.adfiAtLactation
        ? this.transferredModalData.adfiAtLactation
        : null;
    const numberOfDaysPostFarrowing =
      'numberOfDaysPostFarrowing' in this.transferredModalData &&
      this.transferredModalData.numberOfDaysPostFarrowing
        ? this.transferredModalData.numberOfDaysPostFarrowing
        : null;

    if (
      !adfiAtGestation &&
      !numberOfDaysPreFarrowing &&
      !adfiAtLactation &&
      !numberOfDaysPostFarrowing
    ) {
      return null;
    }

    return calculateConsumptionPerLactactingSow(
      adfiAtGestation || 0,
      numberOfDaysPreFarrowing || 0,
      adfiAtLactation || 0,
      numberOfDaysPostFarrowing || 0
    );
  }

  get totalConsumptionOfAllLactactingSows() {
    if (
      'numberOfSowsFarrowingPerMonth' in this.transferredModalData &&
      this.consumptionPerLactactingSow
    ) {
      return calculateTotalConsumptionOfAllLactactingSows(
        this.consumptionPerLactactingSow,
        this.transferredModalData.numberOfSowsFarrowingPerMonth
      );
    }
    return null;
  }

  // Medication program - Clean Sow Program
  get totalMedicationUsageInCleanSowProgram() {
    if (
      'medicationDoseInCleanSowProgram' in this.transferredModalData &&
      this.transferredModalData.medicationDoseInCleanSowProgram &&
      this.totalConsumptionOfAllLactactingSows
    ) {
      return calculateTotalMedicationUsageInCleanSowProgram(
        this.totalConsumptionOfAllLactactingSows,
        this.transferredModalData.medicationDoseInCleanSowProgram
      );
    }
    return null;
  }

  get totalCostOfMedicationInLactation() {
    if (
      'costOfMedicationPerKiloInCleanSowProgram' in this.transferredModalData &&
      this.transferredModalData.costOfMedicationPerKiloInCleanSowProgram &&
      this.totalMedicationUsageInCleanSowProgram
    ) {
      return calculateTotalCostOfMedicationInLactationInCleanSowProgram(
        this.transferredModalData.costOfMedicationPerKiloInCleanSowProgram,
        this.totalMedicationUsageInCleanSowProgram
      );
    }
    return null;
  }

  get additionalCostPerKiloOfFeedsInCleanSowProgram() {
    if (
      this.totalCostOfMedicationInLactation &&
      this.totalConsumptionOfAllLactactingSows
    ) {
      return calculateAdditionalCostPerKiloOfFeedsInCleanSowProgram(
        this.totalCostOfMedicationInLactation,
        this.totalConsumptionOfAllLactactingSows
      );
    }
    return null;
  }

  get totalCostOfMedicationPerLactactingSowsInCleanSowProgram() {
    if (
      'numberOfSowsFarrowingPerMonth' in this.transferredModalData &&
      this.transferredModalData.numberOfSowsFarrowingPerMonth &&
      this.totalCostOfMedicationInLactation
    ) {
      return calculateTotalCostOfMedicationPerLactactingSowsInCleanSowProgram(
        this.totalCostOfMedicationInLactation,
        this.transferredModalData.numberOfSowsFarrowingPerMonth
      );
    }
    return null;
  }

  get costOfMedicationPerPiglets() {
    if (
      'averageLitterSizeBornAlive' in this.transferredModalData &&
      this.transferredModalData.averageLitterSizeBornAlive &&
      this.totalCostOfMedicationPerLactactingSowsInCleanSowProgram
    ) {
      return calculateCostOfMedicationPerPigletsInCleanSowProgram(
        this.totalCostOfMedicationPerLactactingSowsInCleanSowProgram,
        this.transferredModalData.averageLitterSizeBornAlive
      );
    }
    return null;
  }

  // Nursery Feed
  get feedConsumptionPerNurseryPiglet() {
    if (
      'numberOfDaysFedInNursery' in this.transferredModalData &&
      this.transferredModalData.numberOfDaysFedInNursery &&
      this.transferredModalData.feedConsumptionPerDayInNursery
    ) {
      return calculateFeedConsumptionPerNurseryPiglet(
        this.transferredModalData.numberOfDaysFedInNursery,
        this.transferredModalData.feedConsumptionPerDayInNursery
      );
    }
    return null;
  }

  get totalFeedConsumptionOfNurseryPiglets() {
    if (
      'numberOfPigletsAtNursery' in this.transferredModalData &&
      this.feedConsumptionPerNurseryPiglet
    ) {
      return calculateTotalFeedConsumptionOfNurseryPiglets(
        this.feedConsumptionPerNurseryPiglet,
        this.transferredModalData.numberOfPigletsAtNursery
      );
    }
    return null;
  }

  // Medication Program - Early Intervention
  get totalMedicationUsageInEarlyIntervention() {
    if (
      'medicationDoseInEarlyInterventionProgram' in this.transferredModalData &&
      this.transferredModalData.medicationDoseInEarlyInterventionProgram &&
      this.totalFeedConsumptionOfNurseryPiglets
    ) {
      return calculateTotalMedicationUsageInEarlyIntervention(
        this.totalFeedConsumptionOfNurseryPiglets,
        this.transferredModalData.medicationDoseInEarlyInterventionProgram
      );
    }
    return null;
  }

  get totalCostOfMedicationAtNurseryInEarlyIntervention() {
    if (
      'costOfMedicationPerKiloInEarlyInterventionProgram' in
        this.transferredModalData &&
      this.transferredModalData
        .costOfMedicationPerKiloInEarlyInterventionProgram &&
      this.totalMedicationUsageInEarlyIntervention
    ) {
      return calculateTotalCostOfMedicationAtNurseryInEarlyIntervention(
        this.totalMedicationUsageInEarlyIntervention,
        this.transferredModalData
          .costOfMedicationPerKiloInEarlyInterventionProgram
      );
    }
    return null;
  }

  get additionalCostPerKiloOfFeedsInEarlyIntervention() {
    if (
      this.totalCostOfMedicationAtNurseryInEarlyIntervention &&
      this.totalFeedConsumptionOfNurseryPiglets
    ) {
      return calculateAdditionalCostPerKiloOfFeedsInEarlyIntervention(
        this.totalCostOfMedicationAtNurseryInEarlyIntervention,
        this.totalFeedConsumptionOfNurseryPiglets
      );
    }

    return null;
  }

  get costOfMedicationPerNurseryPiglets() {
    if (
      'numberOfPigletsAtNursery' in this.transferredModalData &&
      this.totalCostOfMedicationAtNurseryInEarlyIntervention
    ) {
      return calculateCostOfMedicationPerNurseryPigletsInEarlyIntervention(
        this.totalCostOfMedicationAtNurseryInEarlyIntervention,
        this.transferredModalData.numberOfPigletsAtNursery
      );
    }
    return null;
  }

  // Grower Feed
  get feedConsumptionPerGrowerPig() {
    if (
      'numberOfDaysFedInGrower' in this.transferredModalData &&
      this.transferredModalData.numberOfDaysFedInGrower &&
      this.transferredModalData.feedConsumptionPerDayInGrower
    ) {
      return calculateFeedConsumptionPerGrowerPig(
        this.transferredModalData.numberOfDaysFedInGrower,
        this.transferredModalData.feedConsumptionPerDayInGrower
      );
    }
    return null;
  }

  get totalFeedConsumptionPerGrowerPig() {
    if (
      'numberOfDaysFedInGrower' in this.transferredModalData &&
      this.transferredModalData.numberOfDaysFedInGrower &&
      this.transferredModalData.feedConsumptionPerDayInGrower &&
      this.transferredModalData.numberOfGrowerPigs
    ) {
      return calculateTotalFeedConsumptionPerGrowerPig(
        this.transferredModalData.numberOfDaysFedInGrower,
        this.transferredModalData.feedConsumptionPerDayInGrower,
        this.transferredModalData.numberOfGrowerPigs
      );
    }
    return null;
  }

  // Medication Program - Starter / Grower
  get totalMedicationUsageInGrowerProgram() {
    if (
      'medicationDoseInGrowerProgram' in this.transferredModalData &&
      this.transferredModalData.medicationDoseInGrowerProgram &&
      this.totalFeedConsumptionPerGrowerPig
    ) {
      return calculateTotalMedicationUsageInGrowerProgram(
        this.totalFeedConsumptionPerGrowerPig,
        this.transferredModalData.medicationDoseInGrowerProgram
      );
    }
    return null;
  }

  get totalCostOfMedicationAtGrower() {
    if (
      'costOfMedicationPerKiloInGrowerProgram' in this.transferredModalData &&
      this.transferredModalData.costOfMedicationPerKiloInGrowerProgram &&
      this.totalMedicationUsageInGrowerProgram
    ) {
      return calculateTotalCostOfMedicationAtGrowerInGrowerProgram(
        this.totalMedicationUsageInGrowerProgram,
        this.transferredModalData.costOfMedicationPerKiloInGrowerProgram
      );
    }

    return null;
  }

  get additionalCostPerKiloOfFeedsInGrower() {
    if (
      this.totalCostOfMedicationAtGrower &&
      this.totalFeedConsumptionPerGrowerPig
    ) {
      return calculateAdditionalCostPerKiloOfFeedsInGrowerProgram(
        this.totalCostOfMedicationAtGrower,
        this.totalFeedConsumptionPerGrowerPig
      );
    }
    return null;
  }

  get costOfMedicationPerGrowerPigs() {
    if (
      'numberOfGrowerPigs' in this.transferredModalData &&
      this.totalCostOfMedicationAtGrower
    ) {
      return calculateCostOfMedicationPerGrowerPigInGrowerProgram(
        this.totalCostOfMedicationAtGrower,
        this.transferredModalData.numberOfGrowerPigs
      );
    }
    return 0;
  }

  // Medical Investment Section
  get totalMedicationInvestmentPerPig() {
    if (
      this.costOfMedicationPerPiglets ||
      this.costOfMedicationPerNurseryPiglets ||
      this.costOfMedicationPerGrowerPigs
    ) {
      return calculateTotalMedicationInvestmentPerPig(
        this.costOfMedicationPerPiglets || 0,
        this.costOfMedicationPerNurseryPiglets || 0,
        this.costOfMedicationPerGrowerPigs || 0
      );
    }

    return null;
  }

  get totalInvestmentForGrowerProduced() {
    if (
      'numberOfGrowerPigs' in this.transferredModalData &&
      this.totalMedicationInvestmentPerPig
    ) {
      return calculateTotalInvestmentForGrowerPigsProduced(
        this.totalMedicationInvestmentPerPig,
        this.transferredModalData.numberOfGrowerPigs
      );
    }
    return null;
  }

  get sectionCardValues() {
    const totalAdditionalFeedCost = `${
      this.addComma(this.totalAdditionalFeedCostPer1000, 2, false) == '-' ||
      this.numberOfAnimalHeads === 0
        ? '-'
        : this.transferredModalData.currencySymbol +
          ' ' +
          this.addComma(this.totalAdditionalFeedCostPer1000, 2, false)
    }`;
    const totalAdditionalFeedCostAssumption = `${
      this.addComma(
        this.totalAdditionalFeedCostPerNumberAssumption,
        2,
        false
      ) == '-' || this.numberOfAnimalHeads === 0
        ? '-'
        : this.transferredModalData.currencySymbol +
          ' ' +
          this.addComma(
            this.totalAdditionalFeedCostPerNumberAssumption,
            2,
            false
          )
    }`;
    const totalInvestment = this.totalInvestmentForGrowerProduced
      ? this.transferredModalData.currencySymbol +
        ' ' +
        this.addComma(this.totalInvestmentForGrowerProduced, 2, false)
      : '-';
    const totalCostOfAPP = `${
      this.addComma(this.totalCostOfAPP, 2, false) == '-' ||
      this.numberOfAnimalHeads === 0
        ? '-'
        : this.transferredModalData.currencySymbol +
          ' ' +
          this.addComma(this.totalCostOfAPP, 2, false)
    }`;
    const totalCostOfAPPAssumption = `${
      this.addComma(this.totalCostOfAPPAssumption, 2, false) == '-' ||
      this.numberOfAnimalHeads === 0
        ? '-'
        : this.transferredModalData.currencySymbol +
          ' ' +
          this.addComma(this.totalCostOfAPPAssumption, 2, false)
    }`;
    const capturedOpportunityLoss = `${
      this.addComma(this.capturedOpportunityLoss, 2, false) == '-' ||
      this.numberOfAnimalHeads === 0
        ? '-'
        : this.transferredModalData.currencySymbol +
          ' ' +
          this.addComma(this.capturedOpportunityLoss, 2, false)
    }`;
    return {
      totalAdditionalFeedCost,
      totalAdditionalFeedCostAssumption,
      totalCostOfAPPAssumption,
      totalCostOfAPP,
      totalInvestment,
      capturedOpportunityLoss,
    };
  }

  get sectionCardDetails(): DisplayDataAssumption[] {
    let sectionCardDetailsResults: DisplayDataAssumption[] = [];
    if (this.numberOfAnimalHeads !== null) {
      sectionCardDetailsResults = [
        {
          headerText: 'Bronchopneumonia Economic Loss',
          assumptionValue:
            this.sectionCardValues.totalAdditionalFeedCostAssumption,
          headerValue: this.sectionCardValues.totalAdditionalFeedCost,
          subText: 'Additional Feed Cost /pig',
          subValue: `${
            this.addComma(
              this.totalAdditionalFeedCostPer1000 / this.numberOfAnimalHeads,
              2,
              false
            ) == '-'
              ? ''
              : this.transferredModalData.currencySymbol
          } ${this.addComma(
            this.totalAdditionalFeedCostPer1000 / this.numberOfAnimalHeads,
            2,
            false
          )}`,
          subAssumptionValue: `${
            this.addComma(
              this.totalAdditionalFeedCostPerNumberAssumption /
                this.numberOfAnimalHeads,
              2,
              false
            ) == '-'
              ? ''
              : this.transferredModalData.currencySymbol
          } ${this.addComma(
            this.totalAdditionalFeedCostPerNumberAssumption /
              this.numberOfAnimalHeads,
            2,
            false
          )}`,
        },
        {
          headerText: 'Pleurisy Economic Loss',
          assumptionValue: this.sectionCardValues.totalCostOfAPPAssumption,
          headerValue: this.sectionCardValues.totalCostOfAPP,
          subText: 'Cost of Pleurisy /pig',
          subValue: `${
            this.addComma(
              this.totalCostOfAPP / this.numberOfAnimalHeads,
              2,
              false
            ) == '-'
              ? ''
              : this.transferredModalData.currencySymbol
          } ${this.addComma(
            this.totalCostOfAPP / this.numberOfAnimalHeads,
            2,
            false
          )}`,
          subAssumptionValue: `${
            this.addComma(
              this.totalCostOfAPPAssumption / this.numberOfAnimalHeads,
              2,
              false
            ) == '-'
              ? ''
              : this.transferredModalData.currencySymbol
          } ${this.addComma(
            this.totalCostOfAPPAssumption / this.numberOfAnimalHeads,
            2,
            false
          )}`,
        },
        {
          headerText: 'Medication Program',
          headerValue: this.totalInvestmentForGrowerProduced
            ? `${this.currentCurrencySymbol} ${this.addComma(
                this.totalInvestmentForGrowerProduced,
                2,
                false
              )}`
            : '-',
          subText: 'Medication Investment /pig',
          subValue: this.totalMedicationInvestmentPerPig
            ? `${this.currentCurrencySymbol} ${this.addComma(
                this.totalMedicationInvestmentPerPig,
                2,
                false
              )}`
            : '-',
        },
        {
          headerText: 'Captured Opportunity Loss',
          headerValue: this.sectionCardValues.capturedOpportunityLoss,
          subText: 'Captured Opportunity Loss /Pig',
          subValue: this.sectionCardValues.capturedOpportunityLoss
            ? this.transferredModalData.currencySymbol +
              ' ' +
              this.addComma(
                this.capturedOpportunityLoss / this.numberOfAnimalHeads,
                2,
                false
              )
            : '-',
        },
      ];
    }
    return sectionCardDetailsResults;
  }

  // Broncho Related

  get adgFormula() {
    return this.adgReductionPercentages.map((adgPercentage) => {
      if (this.transferredModalData.adgValue !== null) {
        return (
          this.transferredModalData.adgValue -
          this.transferredModalData.adgValue * adgPercentage
        );
      }
      return 0;
    });
  }

  firstFinalWeight(adgValueSet: number, weanToMarket: number) {
    return (adgValueSet * weanToMarket) / 1000;
  }

  get assumptionUpdateObject() {
    return {
      bronchopneumoniaAssumptionNormalLungsLowPercentage:
        this.transferredModalData
          .bronchopneumoniaAssumptionNormalLungsLowPercentage,
      bronchopneumoniaAssumptionNormalLungsHighPercentage:
        this.transferredModalData
          .bronchopneumoniaAssumptionNormalLungsHighPercentage,
      bronchopneumoniaAssumptionLowPneumonia:
        this.transferredModalData.bronchopneumoniaAssumptionLowPneumonia,
      bronchopneumoniaAssumptionNormalPneumonia:
        this.transferredModalData.bronchopneumoniaAssumptionNormalPneumonia,
      bronchopneumoniaAssumptionHighPneumonia:
        this.transferredModalData.bronchopneumoniaAssumptionHighPneumonia,
      bronchopneumoniaAssumptionLowSeverePneumonia:
        this.transferredModalData.bronchopneumoniaAssumptionLowSeverePneumonia,
      bronchopneumoniaAssumptionHighSeverePneumonia:
        this.transferredModalData.bronchopneumoniaAssumptionHighSeverePneumonia,
      p3AndP4PrevalenceAssumption:
        this.transferredModalData.p3AndP4PrevalenceAssumption,
      appPleurisyPrevalenceAssumption:
        this.transferredModalData.appPleurisyPrevalenceAssumption,
      postWeaningMortalityAssumption:
        this.transferredModalData.postWeaningMortalityAssumption,
    };
  }

  get animalPercentagesAssumptions() {
    return Object.values(this.assumptionUpdateObject)
      .slice(0, 7)
      .map((number) => {
        if (number !== null && number !== undefined) {
          return number / 100;
        }
        return 0;
      });
  }

  get numberOfAnimalHeadsSoldAssumptions() {
    return this.animalPercentagesAssumptions.map((percentage) => {
      if (this.numberOfAnimalHeads) {
        return this.numberOfAnimalHeads * percentage;
      }
      return 0;
    });
  }

  get additionalFeedCostPerNumberAssumptions() {
    return this.additionalFeedCost.map((addFeedCost, index) => {
      return addFeedCost * this.numberOfAnimalHeadsSoldAssumptions[index];
    });
  }

  get totalAdditionalFeedCostPerNumberAssumption() {
    function reducer(total: number, value: number) {
      return total + value;
    }
    if (
      this.numberOfAnimalHeadsSoldAssumptions.every(
        (animalNumber) => animalNumber === 0
      )
    ) {
      return NaN;
    }
    return this.additionalFeedCostPerNumberAssumptions.reduce(reducer);
  }

  // Pleurisy Related

  get pleurisyPrevalenceBackValueAssumption() {
    if (
      this.transferredModalData.appPleurisyPrevalenceAssumption !== null &&
      this.transferredModalData.appPleurisyPrevalenceAssumption !== undefined &&
      this.numberOfAnimalHeads !== null
    ) {
      return (
        (this.transferredModalData.appPleurisyPrevalenceAssumption *
          this.numberOfAnimalHeads) /
        100
      );
    }
    return NaN;
  }

  get p3P4BackValueAssumption() {
    if (
      this.transferredModalData.p3AndP4PrevalenceAssumption !== null &&
      this.transferredModalData.p3AndP4PrevalenceAssumption !== undefined &&
      this.numberOfAnimalHeads !== null
    ) {
      return (
        (this.transferredModalData.p3AndP4PrevalenceAssumption *
          this.numberOfAnimalHeads) /
        100
      );
    }
    return NaN;
  }

  get postWeaningMortalityAssumptionBackValue() {
    if (
      this.transferredModalData.postWeaningMortalityAssumption !== null &&
      this.transferredModalData.postWeaningMortalityAssumption !== undefined &&
      this.numberOfAnimalHeads !== null
    ) {
      return (
        (this.transferredModalData.postWeaningMortalityAssumption *
          this.numberOfAnimalHeads) /
        100
      );
    }
    return 0;
  }

  get weaningMortalityPercentageAssumption() {
    return this.transferredModalData.postWeaningMortalityAssumption;
  }

  get weaningMortalityHeadsAssumption() {
    if (
      this.weaningMortalityPercentageAssumption !== null &&
      this.weaningMortalityPercentageAssumption !== undefined &&
      this.numberOfAnimalHeads !== null
    ) {
      return (
        (this.weaningMortalityPercentageAssumption * this.numberOfAnimalHeads) /
        100
      );
    }
    return 0;
  }

  get totalCostPostWeaningAssumption() {
    if (
      this.transferredModalData.liveWeightPrice !== null &&
      this.transferredModalData.targetMarketWeight !== null
    ) {
      return (
        this.weaningMortalityHeadsAssumption *
        this.transferredModalData.liveWeightPrice *
        this.transferredModalData.targetMarketWeight
      );
    }
    return 0;
  }

  get effectADGPigsAssumption() {
    if (
      this.transferredModalData.p3AndP4PrevalenceAssumption !== null &&
      this.transferredModalData.p3AndP4PrevalenceAssumption !== undefined &&
      this.transferredModalData.weanMarketDays !== null
    ) {
      return (
        (this.transferredModalData.p3AndP4PrevalenceAssumption *
          2 *
          this.transferredModalData.weanMarketDays) /
        1000
      );
    }
    return 0;
  }

  get totalCostADGPigsAssumption() {
    if (this.transferredModalData.liveWeightPrice !== null) {
      return (
        this.p3P4BackValueAssumption *
        this.effectADGPigsAssumption *
        this.transferredModalData.liveWeightPrice
      );
    }
    return 0;
  }

  get effectOnSlaughterWeightAssumption() {
    if (
      this.transferredModalData.p3AndP4PrevalenceAssumption !== undefined &&
      this.transferredModalData.p3AndP4PrevalenceAssumption !== null
    ) {
      return this.transferredModalData.p3AndP4PrevalenceAssumption * 0.07;
    }
    return 0;
  }

  get totalEffectOnSlaughterWeightAssumption() {
    if (this.transferredModalData.liveWeightPrice !== null) {
      return (
        this.p3P4BackValueAssumption *
        this.effectOnSlaughterWeightAssumption *
        this.transferredModalData.liveWeightPrice
      );
    }
    return 0;
  }

  get effectOnSlaughterAgeAssumption() {
    if (
      this.transferredModalData.p3AndP4PrevalenceAssumption !== null &&
      this.transferredModalData.p3AndP4PrevalenceAssumption !== undefined
    ) {
      return this.transferredModalData.p3AndP4PrevalenceAssumption * 0.26;
    }
    return 0;
  }

  get totalEffectSlaughterAgeAssumption() {
    if (this.transferredModalData.averageFeedPrice !== null) {
      return (
        this.p3P4BackValueAssumption *
        this.effectOnSlaughterAgeAssumption *
        2 *
        this.transferredModalData.averageFeedPrice
      );
    }
    return 0;
  }

  get totalCostOfAPPAssumption() {
    return (
      this.totalCostPostWeaningAssumption +
      this.totalCostADGPigsAssumption +
      this.totalEffectOnSlaughterWeightAssumption +
      this.totalEffectSlaughterAgeAssumption
    );
  }

  get capturedOpportunityLoss() {
    if (
      !isNaN(this.totalAdditionalFeedCostPerNumberAssumption) &&
      !isNaN(this.totalCostOfAPPAssumption)
    ) {
      if (
        !this.totalAdditionalFeedCostPer1000 &&
        !this.totalAdditionalFeedCostPerNumberAssumption
      ) {
        return this.totalCostOfAPP - this.totalCostOfAPPAssumption;
      } else {
        return (
          this.totalAdditionalFeedCostPer1000 +
          this.totalCostOfAPP -
          (this.totalAdditionalFeedCostPerNumberAssumption +
            this.totalCostOfAPPAssumption)
        );
      }
    } else {
      return NaN;
    }
  }

  addComma(targetNumber: number, toFixedNumber: number, onlyToString: boolean) {
    if (isNaN(targetNumber)) {
      return '-';
    } else if (!onlyToString) {
      const roundedNumber = targetNumber.toFixed(toFixedNumber);
      return addCommas(roundedNumber);
    } else if (onlyToString) {
      const numberToString = targetNumber.toString();
      return addCommas(numberToString);
    }
  }

  get computedChartData() {
    return {
      labels: ['Economic Loss', 'Medication Program'],
      datasets: [
        {
          label: 'Bronchpneumonic Economic Loss',
          data: [Number(this.totalAdditionalFeedCostPer1000.toFixed(2))],
          backgroundColor: [`#FFEAEA`],
          borderColor: [`#FF4C4B`],
          borderWidth: 1,
          barPercentage: 0.2,
        },
        {
          label: 'Pleurisy Economic Loss',
          data: [Number(this.totalCostOfAPP.toFixed(2))],
          backgroundColor: [`#FFC1C1`],
          borderColor: [`#FF4C4B`],
          borderWidth: 1,
          barPercentage: 0.2,
        },
        {
          data: [0, Number(this.totalInvestmentForGrowerProduced)],
          backgroundColor: '#FFF5C9',
          borderColor: '#745E05',
          borderWidth: 1,
          barPercentage: 0.2,
        },
      ],
    };
  }

  get computedChartDataUpdated() {
    return {
      labels: [
        'Economic Loss',
        'Assume Improvement on Economic Loss',
        'Medication Program',
        'Captured Opportunity Loss',
      ],
      datasets: [
        {
          label: 'Bronchopneumonia Economic Loss',
          data: [Number(this.totalAdditionalFeedCostPer1000.toFixed(2))],
          backgroundColor: [`#FFEAEA`],
          borderColor: [`#FF4C4B`],
          borderWidth: 1,
          barPercentage: 0.2,
        },
        {
          label: 'Pleurisy Economic Loss',
          data: [Number(this.totalCostOfAPP.toFixed(2))],
          backgroundColor: [`#FFC1C1`],
          borderColor: [`#FF4C4B`],
          borderWidth: 1,
          barPercentage: 0.2,
        },
        {
          label: 'Bronchopneumonia Economic Loss',
          data: [
            0,
            Number(this.totalAdditionalFeedCostPerNumberAssumption.toFixed(2)),
          ],
          backgroundColor: `#FFEAEA`,
          borderColor: `#00A5E1`,
          borderWidth: 1,
          barPercentage: 0.2,
        },
        {
          label: 'Pleurisy Economic Loss',
          data: [0, Number(this.totalCostOfAPPAssumption.toFixed(2))],
          backgroundColor: `#FFC1C1`,
          borderColor: `#00A5E1`,
          borderWidth: 1,
          barPercentage: 0.2,
        },
        {
          data: [0, 0, Number(this.totalInvestmentForGrowerProduced)],
          backgroundColor: '#FFF5C9',
          borderColor: '#745E05',
          borderWidth: 1,
          barPercentage: 0.2,
        },
        {
          data: [0, 0, 0, Number(this.capturedOpportunityLoss.toFixed(2))],
          backgroundColor: `#D9F2FF`,
          borderColor: `#00A5E1`,
          borderWidth: 1,
          barPercentage: 0.2,
        },
      ],
    };
  }

  get computedChartOptions() {
    const currencyText =
      this.currentCurrencyDetails.currentSelectedCurrency.text;
    return {
      legend: {
        display: false,
      },
      maintainAspectRatio: true,
      animation: false,
      showLines: false,
      scales: {
        yAxes: [
          {
            gridLines: {
              drawBorder: false,
            },
            ticks: {
              min: 0,
              beginAtZero: true,
              padding: 16,
              callback: (tick: any) => {
                return `${currencyText} ${addCommas(tick.toString())}`;
              },
            },
            stacked: true,
          },
        ],
        xAxes: [
          {
            gridLines: {
              display: false,
              drawBorder: false,
            },
            stacked: true,
          },
        ],
      },
      plugins: {
        datalabels: {
          anchor: 'end',
          align: 'end',
          display: false,
          formatter: function (value: any) {
            return `${currencyText} ${addCommas(value.toString())}`;
          },
        },
      },
    };
  }

  getAssumption() {
    this.gci = new GenerateChartImage(
      this.computedChartData,
      this.computedChartOptions
    );
    this.gci2 = new GenerateChartImage(
      this.computedChartDataUpdated,
      this.computedChartOptions
    );
    const content = [
      {
        svg: imgElancoLogo,
        width: 100,
        margin: [0, 0, 0, -30],
        border: [false, false, false, false],
      },
      {
        text: 'Economic Calculation (Assumption)',
        style: 'sectionHeader',
      },
      {
        style: ['lightGreyBg', 'fontSize8'],
        table: {
          widths: ['auto', 'auto', '*'],
          body: [
            [
              {
                text: 'Current Exchange Rate:',
                color: `${darkGrey}`,
                border: [false, false, false, false],
                margin: 5,
              },
              {
                text: `${this.currentCurrencyDetails.baseCurrency.text} 1 = ${
                  this.currentCurrencyDetails.currentSelectedCurrency.text
                } ${
                  this.currentCurrencyDetails.currentSelectedCurrency.value /
                  this.currentCurrencyDetails.baseCurrency.value
                }`,
                color: `${blue}`,
                border: [false, false, false, false],
                margin: 5,
              },
              {
                text: `${this.currentCurrencyDetails.currentSelectedCurrency.text}`,
                color: `${darkGrey}`,
                alignment: 'right',
                border: [false, false, false, false],
                margin: 5,
              },
            ],
          ],
        },
        marginBottom: 10,
      },
      {
        text: `Economic Loss VS Medication Program (${this.numberOfAnimalHeads.toFixed(
          0
        )} heads)`,
        fontSize: 12,
        alignment: 'center',
        marginBottom: 10,
      },
      {
        image: this.gci.generateImage(),
        width: 741,
        height: 200,
        alignment: 'center',
        marginBottom: 5,
      },
      {
        style: ['fontSize8', 'textCenter'],
        table: {
          widths: '*',
          body: [
            [
              {
                stack: [
                  {
                    text: 'Bronchopneumonia Economic Loss',
                    color: `${red}`,
                  },
                  {
                    text: '(Additional Feed Cost)',
                  },
                ],
                margin: [20, 10, 20, 10],
                fillColor: `${pink}`,
                border: [false, false, false, false],
              },
              {
                stack: [
                  {
                    text: 'Pleurisy Economic Loss',
                    color: `${red}`,
                  },
                  {
                    text: '(Total Opportunity Lost)',
                  },
                ],
                margin: [20, 10, 20, 10],
                fillColor: `${darkPink}`,
                border: [false, false, false, false],
              },
              {
                text: 'Medication Program',
                color: `${brown}`,
                margin: [20, 10, 20, 10],
                fillColor: `${yellow}`,
                border: [false, false, false, false],
              },
            ],
            [
              {
                text: `${this.sectionCardDetails[0].headerValue}`,
                color: `${red}`,
                border: [false, false, false, false],
              },
              {
                text: `${this.sectionCardDetails[1].headerValue}`,
                color: `${red}`,
                border: [false, false, false, false],
              },
              {
                text: `${this.sectionCardDetails[2].headerValue}`,
                color: `${brown}`,
                border: [false, false, false, false],
              },
            ],
          ],
        },
        pageBreak: 'after',
      },
      {
        svg: imgElancoLogo,
        width: 100,
        margin: [0, 0, 0, -30],
        border: [false, false, false, false],
      },
      {
        text: 'Economic Calculation (Assumption)',
        style: 'sectionHeader',
      },
      {
        style: ['lightGreyBg', 'fontSize8'],
        table: {
          widths: ['auto', 'auto', '*'],
          body: [
            [
              {
                text: 'Current Exchange Rate:',
                color: `${darkGrey}`,
                border: [false, false, false, false],
                margin: 5,
              },
              {
                text: `${this.currentCurrencyDetails.baseCurrency.text} 1 = ${
                  this.currentCurrencyDetails.currentSelectedCurrency.text
                } ${
                  this.currentCurrencyDetails.currentSelectedCurrency.value /
                  this.currentCurrencyDetails.baseCurrency.value
                }`,
                color: `${blue}`,
                border: [false, false, false, false],
                margin: 5,
              },
              {
                text: `${this.currentCurrencyDetails.currentSelectedCurrency.text}`,
                color: `${darkGrey}`,
                alignment: 'right',
                border: [false, false, false, false],
                margin: 5,
              },
            ],
          ],
        },
        marginBottom: 5,
      },
      {
        style: ['lightGreyBg', 'textCenter'],
        table: {
          widths: ['*', '*', '*'],
          body: [
            [
              {
                stack: [
                  {
                    text: 'Bronchopneumonia Economic Loss',
                    color: `${darkGrey}`,
                    fontSize: 8,
                    marginTop: 5,
                  },
                  {
                    text: `${this.sectionCardDetails[0].headerValue}`,
                    color: `${red}`,
                    fontSize: 20,
                    bold: true,
                    marginBottom: 5,
                  },
                  {
                    text: 'Additional Feed Cost /pig',
                    fontSize: 8,
                    color: `${darkGrey}`,
                    marginTop: 5,
                    marginBottom: 5,
                  },
                  {
                    text: `${this.sectionCardDetails[0].subValue}`,
                    color: `${red}`,
                    marginBottom: 5,
                  },
                ],
                border: [false, false, true, false],
              },
              {
                stack: [
                  {
                    text: 'Pleurisy Economic Loss',
                    color: `${darkGrey}`,
                    fontSize: 8,
                    marginTop: 5,
                  },
                  {
                    text: `${this.sectionCardDetails[1].headerValue}`,
                    color: `${red}`,
                    fontSize: 20,
                    bold: true,
                    marginBottom: 5,
                  },
                  {
                    text: 'Cost of Pleurisy /pig',
                    fontSize: 8,
                    color: `${darkGrey}`,
                    marginTop: 5,
                    marginBottom: 5,
                  },
                  {
                    text: `${this.sectionCardDetails[1].subValue}`,
                    color: `${red}`,
                    marginBottom: 5,
                  },
                ],
                border: [false, false, false, false],
              },
              {
                stack: [
                  {
                    text: 'Medication Program',
                    color: `${darkGrey}`,
                    fontSize: 8,
                    marginTop: 5,
                  },
                  {
                    text: `${this.sectionCardDetails[2].headerValue}`,
                    color: `${brown}`,
                    fontSize: 20,
                    bold: true,
                    marginBottom: 5,
                  },
                  {
                    text: 'Medication Investment /pig',
                    fontSize: 8,
                    color: `${darkGrey}`,
                    marginTop: 5,
                    marginBottom: 5,
                  },
                  {
                    text: `${this.sectionCardDetails[2].subValue}`,
                    color: `${brown}`,
                    marginBottom: 5,
                  },
                ],
                border: [false, false, false, false],
              },
            ],
          ],
        },
        layout: 'allGreyBorder',
        marginBottom: 15,
      },
      {
        text: 'Bronchopneumonia Economic Loss',
        border: [false, false, false, false],
        marginBottom: 10,
      },
      {
        style: ['fontSize8', 'textCenter'],
        table: {
          widths: '*',
          body: [
            [
              {
                text: 'Parameters',
                bold: true,
                border: [false, false, false, false],
                rowSpan: 2,
                fillColor: `${lightGrey}`,
              },
              {
                text: 'Feed Cost Opportunity Loss',
                bold: true,
                border: [false, false, true, false],
                alignment: 'center',
                colSpan: 8,
                fillColor: `${lightGrey}`,
              },
              {
                text: '',
              },
              {
                text: '',
              },
              {
                text: '',
              },
              {
                text: '',
              },
              {
                text: '',
              },
              {
                text: '',
              },
              {
                text: '',
              },
            ],
            [
              {
                text: '',
                border: [false, false, false, false],
                fillColor: `${lightGrey}`,
              },
              {
                text: 'Normal Lung',
                bold: true,
                fillColor: `${limeGreen}`,
                color: `${green}`,
                alignment: 'center',
                border: [false, false, false, true],
                colSpan: 2,
              },
              {
                text: '',
              },
              {
                text: 'Pneumonia',
                bold: true,
                fillColor: `${yellow}`,
                color: `${brown}`,
                alignment: 'center',
                border: [false, false, false, true],
                colSpan: 3,
              },
              {
                text: '',
              },
              {
                text: '',
              },
              {
                text: 'Severe Pneumonia',
                bold: true,
                fillColor: `${pink}`,
                color: `${red}`,
                alignment: 'center',
                border: [false, false, false, true],
                colSpan: 2,
              },
              {
                text: '',
              },
              {
                text: 'Total',
                bold: true,
                alignment: 'center',
                border: [false, false, false, true],
                fillColor: `${lightGrey}`,
              },
            ],
            [
              {
                text: '% of Lung Affected',
                fillColor: `${lightGrey}`,
                border: [false, false, false, true],
              },
              {
                text: `0%`,
                fillColor: `${limeGreen}`,
                color: `${brown}`,
                border: [false, false, false, true],
              },
              {
                text: `1-10%`,
                fillColor: `${lightGreen}`,
                color: `${brown}`,
                border: [false, false, false, true],
              },
              {
                text: `11-20%`,
                fillColor: `${cream}`,
                color: `${brown}`,
                border: [false, false, false, true],
              },
              {
                text: `21-30%`,
                fillColor: `${yellow}`,
                color: `${brown}`,
                border: [false, false, false, true],
              },
              {
                text: `31-40%`,
                fillColor: `${orange}`,
                color: `${brown}`,
                border: [false, false, false, true],
              },
              {
                text: `41-50%`,
                fillColor: `${pink}`,
                color: `${red}`,
                border: [false, false, false, true],
              },
              {
                text: `>50%`,
                fillColor: `${darkPink}`,
                color: `${red}`,
                border: [false, false, false, true],
              },
              {
                text: '',
                fillColor: `${lightGrey}`,
                border: [false, false, false, true],
              },
            ],
            [
              {
                text: '% ADG Reduction',
                fillColor: `${lightGrey}`,
                border: [false, false, false, true],
              },
              {
                text: `${addDecimalPointToNumbers(
                  this.adgReductionPercentages[0] * 100
                )}%`,
                fillColor: `${limeGreen}`,
                color: `${brown}`,
                border: [false, false, false, true],
              },
              {
                text: `${addDecimalPointToNumbers(
                  this.adgReductionPercentages[1] * 100
                )}%`,
                fillColor: `${lightGreen}`,
                color: `${brown}`,
                border: [false, false, false, true],
              },
              {
                text: `${addDecimalPointToNumbers(
                  this.adgReductionPercentages[2] * 100
                )}%`,
                fillColor: `${cream}`,
                color: `${brown}`,
                border: [false, false, false, true],
              },
              {
                text: `${addDecimalPointToNumbers(
                  this.adgReductionPercentages[3] * 100
                )}%`,
                fillColor: `${yellow}`,
                color: `${brown}`,
                border: [false, false, false, true],
              },
              {
                text: `${addDecimalPointToNumbers(
                  this.adgReductionPercentages[4] * 100
                )}%`,
                fillColor: `${orange}`,
                color: `${brown}`,
                border: [false, false, false, true],
              },
              {
                text: `${addDecimalPointToNumbers(
                  this.adgReductionPercentages[5] * 100
                )}%`,
                fillColor: `${pink}`,
                color: `${red}`,
                border: [false, false, false, true],
              },
              {
                text: `${addDecimalPointToNumbers(
                  this.adgReductionPercentages[6] * 100
                )}%`,
                fillColor: `${darkPink}`,
                color: `${red}`,
                border: [false, false, false, true],
              },
              {
                text: '',
                fillColor: `${lightGrey}`,
                border: [false, false, false, true],
              },
            ],
            [
              {
                text: '% of Animals',
                fillColor: `${lightGrey}`,
                border: [false, false, false, true],
              },
              {
                text: `${addDecimalPointToRoundOffNumbers(
                  this.animalPercentages[0] * 100
                )}`,
                fillColor: `${limeGreen}`,
                color: `${brown}`,
                border: [false, false, false, true],
              },
              {
                text: `${addDecimalPointToRoundOffNumbers(
                  this.animalPercentages[1] * 100
                )}%`,
                fillColor: `${lightGreen}`,
                color: `${brown}`,
                border: [false, false, false, true],
              },
              {
                text: `${addDecimalPointToRoundOffNumbers(
                  this.animalPercentages[2] * 100
                )}%`,
                fillColor: `${cream}`,
                color: `${brown}`,
                border: [false, false, false, true],
              },
              {
                text: `${addDecimalPointToRoundOffNumbers(
                  this.animalPercentages[3] * 100
                )}%`,
                fillColor: `${yellow}`,
                color: `${brown}`,
                border: [false, false, false, true],
              },
              {
                text: `${addDecimalPointToRoundOffNumbers(
                  this.animalPercentages[4] * 100
                )}%`,
                fillColor: `${orange}`,
                color: `${brown}`,
                border: [false, false, false, true],
              },
              {
                text: `${addDecimalPointToRoundOffNumbers(
                  this.animalPercentages[5] * 100
                )}%`,
                fillColor: `${pink}`,
                color: `${red}`,
                border: [false, false, false, true],
              },
              {
                text: `${addDecimalPointToRoundOffNumbers(
                  this.animalPercentages[6] * 100
                )}%`,
                fillColor: `${darkPink}`,
                color: `${red}`,
                border: [false, false, false, true],
              },
              {
                text: '',
                fillColor: `${lightGrey}`,
                border: [false, false, false, true],
              },
            ],
            [
              {
                text: '# of Animals Sold /month (heads)',
                fillColor: `${lightGrey}`,
                border: [false, false, false, true],
              },
              {
                text: `${
                  this.numberOfAnimalHeadsSold[0].toFixed(0) === '0'
                    ? '-'
                    : this.addComma(this.numberOfAnimalHeadsSold[0], 0, false)
                }`,
                fillColor: `${limeGreen}`,
                color: `${brown}`,
                border: [false, false, false, true],
              },
              {
                text: `${
                  this.numberOfAnimalHeadsSold[1].toFixed(0) === '0'
                    ? '-'
                    : this.addComma(this.numberOfAnimalHeadsSold[1], 0, false)
                }`,
                fillColor: `${lightGreen}`,
                color: `${brown}`,
                border: [false, false, false, true],
              },
              {
                text: `${
                  this.numberOfAnimalHeadsSold[2].toFixed(0) === '0'
                    ? '-'
                    : this.addComma(this.numberOfAnimalHeadsSold[2], 0, false)
                }`,
                fillColor: `${cream}`,
                color: `${brown}`,
                border: [false, false, false, true],
              },
              {
                text: `${
                  this.numberOfAnimalHeadsSold[3].toFixed(0) === '0'
                    ? '-'
                    : this.addComma(this.numberOfAnimalHeadsSold[3], 0, false)
                }`,
                fillColor: `${yellow}`,
                color: `${brown}`,
                border: [false, false, false, true],
              },
              {
                text: `${
                  this.numberOfAnimalHeadsSold[4].toFixed(0) === '0'
                    ? '-'
                    : this.addComma(this.numberOfAnimalHeadsSold[4], 0, false)
                }`,
                fillColor: `${orange}`,
                color: `${brown}`,
                border: [false, false, false, true],
              },
              {
                text: `${
                  this.numberOfAnimalHeadsSold[5].toFixed(0) === '0'
                    ? '-'
                    : this.addComma(this.numberOfAnimalHeadsSold[5], 0, false)
                }`,
                fillColor: `${pink}`,
                color: `${red}`,
                border: [false, false, false, true],
              },
              {
                text: `${
                  this.numberOfAnimalHeadsSold[6].toFixed(0) === '0'
                    ? '-'
                    : this.addComma(this.numberOfAnimalHeadsSold[6], 0, false)
                }`,
                fillColor: `${darkPink}`,
                color: `${red}`,
                border: [false, false, false, true],
              },
              {
                text: this.numberOfAnimalHeads
                  ? `${this.addComma(this.numberOfAnimalHeads, 0, false)}`
                  : '',
                fillColor: `${lightGrey}`,
                border: [false, false, false, true],
              },
            ],
          ],
        },
        layout: 'regularTable',
        marginBottom: 15,
      },
      {
        text: 'Pleurisy Economic Loss',
        border: [false, false, false, false],
        marginBottom: 10,
      },
      {
        style: ['fontSize8'],
        table: {
          widths: '*',
          body: [
            [
              {
                text: 'Pleurisy (% Prevalence)',
                fillColor: `${lightGrey}`,
                bold: true,
                border: [false, false, false, false],
              },
              {
                text: this.transferredModalData.appPleurisyPrevalence
                  ? `${this.addComma(
                      this.transferredModalData.appPleurisyPrevalence,
                      2,
                      false
                    )}`
                  : '',
                border: [false, false, false, false],
              },
              {
                text: `${this.addComma(
                  this.pleurisyPrevalenceBackValue,
                  0,
                  false
                )} heads`,
                border: [false, false, false, false],
                color: `${darkGrey}`,
              },
              {
                text: ``,
                border: [false, false, false, false],
              },
              {
                text: 'Improvement of Post Weaning Mortality',
                fillColor: `${lightGrey}`,
                bold: true,
                border: [false, false, false, false],
              },
              {
                text: ``,
                border: [false, false, false, false],
              },
              {
                text: ``,
                border: [false, false, false, false],
              },
            ],
            [
              {
                text: 'P3 and P4 * (% Prevalence)',
                fillColor: `${lightGrey}`,
                bold: true,
                border: [false, false, false, false],
              },
              {
                text: this.transferredModalData.p3AndP4Prevalence
                  ? `${this.addComma(
                      this.transferredModalData.p3AndP4Prevalence,
                      2,
                      false
                    )}`
                  : '',
                border: [false, false, false, false],
              },
              {
                text: `${this.addComma(this.p3P4BackValue, 0, false)} heads`,
                border: [false, false, false, false],
                color: `${darkGrey}`,
              },
              {
                text: ``,
                border: [false, false, false, false],
              },
              {
                text: '% of Post Weaning Mortality',
                fillColor: `${lightGrey}`,
                bold: true,
                border: [false, false, false, false],
              },
              {
                text: this.transferredModalData.postWeaningMortality
                  ? `${this.addComma(
                      this.transferredModalData.postWeaningMortality,
                      2,
                      false
                    )}`
                  : '',
                border: [false, false, false, false],
              },
              {
                text: `${this.addComma(
                  this.postWeaningMortalityBackValue,
                  0,
                  false
                )} heads`,
                border: [false, false, false, false],
                color: `${darkGrey}`,
              },
            ],
          ],
        },
        pageBreak: 'after',
      },
      {
        svg: imgElancoLogo,
        width: 100,
        margin: [0, 0, 0, -30],
        border: [false, false, false, false],
      },
      {
        text: 'Economic Calculation (Assumption)',
        style: 'sectionHeader',
      },
      {
        style: ['lightGreyBg', 'fontSize8'],
        table: {
          widths: ['auto', 'auto', '*'],
          body: [
            [
              {
                text: 'Current Exchange Rate:',
                color: `${darkGrey}`,
                border: [false, false, false, false],
                margin: 5,
              },
              {
                text: `${this.currentCurrencyDetails.baseCurrency.text} 1 = ${
                  this.currentCurrencyDetails.currentSelectedCurrency.text
                } ${
                  this.currentCurrencyDetails.currentSelectedCurrency.value /
                  this.currentCurrencyDetails.baseCurrency.value
                }`,
                color: `${blue}`,
                border: [false, false, false, false],
                margin: 5,
              },
              {
                text: `${this.currentCurrencyDetails.currentSelectedCurrency.text}`,
                color: `${darkGrey}`,
                alignment: 'right',
                border: [false, false, false, false],
                margin: 5,
              },
            ],
          ],
        },
        marginBottom: 5,
      },
      {
        text: `Economic Loss VS Medication Program (${this.numberOfAnimalHeads.toFixed(
          0
        )} heads)`,
        fontSize: 12,
        alignment: 'center',
        marginBottom: 10,
      },
      {
        image: this.gci2.generateImage(),
        width: 741,
        height: 200,
        alignment: 'center',
        marginBottom: 5,
      },
      {
        style: ['fontSize8', 'textCenter'],
        table: {
          widths: '*',
          body: [
            [
              {
                stack: [
                  {
                    text: 'Bronchopneumonia Economic Loss',
                    color: `${red}`,
                  },
                  {
                    text: '(Additional Feed Cost)',
                  },
                ],
                margin: [20, 10, 20, 10],
                fillColor: `${pink}`,
                border: [false, false, false, false],
              },
              {
                text: 'Assume Improvement on Bronchopneumonia Economic Loss',
                color: `${blue}`,
                margin: [20, 10, 20, 10],
                fillColor: `${lightBlue}`,
                border: [false, false, false, false],
              },
              {
                stack: [
                  {
                    text: 'Pleurisy Economic Loss',
                    color: `${red}`,
                  },
                  {
                    text: '(Total Opportunity Lost)',
                  },
                ],
                margin: [20, 10, 20, 10],
                fillColor: `${darkPink}`,
                border: [false, false, false, false],
              },
              {
                text: 'Assume Improvement on Pleurisy Economic Loss',
                color: `${blue}`,
                margin: [20, 10, 20, 10],
                fillColor: `${lightBlue}`,
                border: [false, false, false, false],
              },
              {
                text: 'Medication Program',
                color: `${brown}`,
                margin: [20, 10, 20, 10],
                fillColor: `${yellow}`,
                border: [false, false, false, false],
              },
              {
                text: 'Captured Opportunity Loss',
                color: `${blue}`,
                margin: [20, 10, 20, 10],
                fillColor: `${lightBlue}`,
                border: [false, false, false, false],
              },
            ],
            [
              {
                text: `${this.sectionCardValues.totalAdditionalFeedCost}`,
                color: `${red}`,
                border: [false, false, false, false],
              },
              {
                text: `${this.sectionCardValues.totalAdditionalFeedCostAssumption}`,
                color: `${blue}`,
                border: [false, false, false, false],
              },
              {
                text: `${this.sectionCardValues.totalCostOfAPP}`,
                color: `${red}`,
                border: [false, false, false, false],
              },
              {
                text: `${this.sectionCardValues.totalCostOfAPPAssumption}`,
                color: `${blue}`,
                border: [false, false, false, false],
              },
              {
                text: `${this.sectionCardValues.totalInvestment}`,
                color: `${brown}`,
                border: [false, false, false, false],
              },
              {
                text: `${this.sectionCardValues.capturedOpportunityLoss}`,
                color: `${blue}`,
                border: [false, false, false, false],
              },
            ],
          ],
        },
        pageBreak: 'after',
      },
      {
        svg: imgElancoLogo,
        width: 100,
        margin: [0, 0, 0, -30],
        border: [false, false, false, false],
      },
      {
        text: 'Economic Calculation (Assumption)',
        style: 'sectionHeader',
      },
      {
        style: ['lightGreyBg', 'fontSize8'],
        table: {
          widths: ['auto', 'auto', '*'],
          body: [
            [
              {
                text: 'Current Exchange Rate:',
                color: `${darkGrey}`,
                border: [false, false, false, false],
                margin: 5,
              },
              {
                text: `${this.currentCurrencyDetails.baseCurrency.text} 1 = ${
                  this.currentCurrencyDetails.currentSelectedCurrency.text
                } ${
                  this.currentCurrencyDetails.currentSelectedCurrency.value /
                  this.currentCurrencyDetails.baseCurrency.value
                }`,
                color: `${blue}`,
                border: [false, false, false, false],
                margin: 5,
              },
              {
                text: `${this.currentCurrencyDetails.currentSelectedCurrency.text}`,
                color: `${darkGrey}`,
                alignment: 'right',
                border: [false, false, false, false],
                margin: 5,
              },
            ],
          ],
        },
        marginBottom: 5,
      },
      {
        style: ['lightGreyBg', 'textCenter'],
        table: {
          widths: ['*', '*', '*', '*'],
          body: [
            [
              {
                stack: [
                  {
                    text: 'Bronchopneumonia Economic Loss',
                    color: `${darkGrey}`,
                    fontSize: 8,
                    marginTop: 5,
                  },
                  {
                    text: `${this.sectionCardDetails[0].headerValue}`,
                    color: `${red}`,
                    fontSize: 16,
                    bold: true,
                    marginBottom: 5,
                  },
                  {
                    text: `${this.sectionCardDetails[0].assumptionValue}`,
                    color: `${blue}`,
                    fontSize: 16,
                    bold: true,
                    marginBottom: 5,
                  },
                  {
                    text: 'Additional Feed Cost /pig',
                    fontSize: 8,
                    color: `${darkGrey}`,
                    marginTop: 5,
                    marginBottom: 5,
                  },
                  {
                    text: `${this.sectionCardDetails[0].subValue}`,
                    color: `${red}`,
                    marginBottom: 5,
                  },
                  {
                    text: `${this.sectionCardDetails[0].subAssumptionValue}`,
                    color: `${blue}`,
                    marginBottom: 5,
                  },
                ],
                border: [false, false, true, false],
              },
              {
                stack: [
                  {
                    text: 'Pleurisy Economic Loss',
                    color: `${darkGrey}`,
                    fontSize: 8,
                    marginTop: 5,
                  },
                  {
                    text: `${this.sectionCardDetails[1].headerValue}`,
                    color: `${red}`,
                    fontSize: 16,
                    bold: true,
                    marginBottom: 5,
                  },
                  {
                    text: `${this.sectionCardDetails[1].assumptionValue}`,
                    color: `${blue}`,
                    fontSize: 16,
                    bold: true,
                    marginBottom: 5,
                  },
                  {
                    text: 'Cost of Pleurisy /pig',
                    fontSize: 8,
                    color: `${darkGrey}`,
                    marginTop: 5,
                    marginBottom: 5,
                  },
                  {
                    text: `${this.sectionCardDetails[1].subValue}`,
                    color: `${red}`,
                    marginBottom: 5,
                  },
                  {
                    text: `${this.sectionCardDetails[1].subAssumptionValue}`,
                    color: `${blue}`,
                    marginBottom: 5,
                  },
                ],
                border: [false, false, false, false],
              },
              {
                stack: [
                  {
                    text: 'Medication Program',
                    color: `${darkGrey}`,
                    fontSize: 8,
                    marginTop: 5,
                  },
                  {
                    text: `${this.sectionCardDetails[2].headerValue}`,
                    color: `${brown}`,
                    fontSize: 20,
                    bold: true,
                    marginBottom: 5,
                  },
                  {
                    text: 'Medication Investment /pig',
                    fontSize: 8,
                    color: `${darkGrey}`,
                    marginTop: 5,
                    marginBottom: 5,
                  },
                  {
                    text: `${this.sectionCardDetails[2].subValue}`,
                    color: `${brown}`,
                    marginBottom: 5,
                  },
                ],
                border: [false, false, false, false],
              },
              {
                stack: [
                  {
                    text: 'Captured Opportunity Loss',
                    color: `${blue}`,
                    fontSize: 8,
                    marginTop: 5,
                  },
                  {
                    text: `${this.sectionCardDetails[3].headerValue}`,
                    color: `${blue}`,
                    fontSize: 20,
                    bold: true,
                    marginBottom: 5,
                  },
                  {
                    text: 'transferredModalData',
                    fontSize: 8,
                    color: `${darkGrey}`,
                    marginTop: 5,
                    marginBottom: 5,
                  },
                  {
                    text: `${this.sectionCardDetails[3].subValue}`,
                    color: `${blue}`,
                    marginBottom: 5,
                  },
                ],
                border: [false, false, false, false],
              },
            ],
          ],
        },
        layout: 'allGreyBorder',
        marginBottom: 15,
      },
      {
        text: 'Bronchopneumonia Economic Loss',
        border: [false, false, false, false],
        marginBottom: 10,
      },
      {
        style: ['fontSize8', 'textCenter'],
        table: {
          widths: '*',
          body: [
            [
              {
                text: 'Parameters',
                bold: true,
                border: [false, false, false, false],
                rowSpan: 2,
                fillColor: `${lightGrey}`,
              },
              {
                text: 'Feed Cost Opportunity Loss',
                bold: true,
                border: [false, false, true, false],
                alignment: 'center',
                colSpan: 8,
                fillColor: `${lightGrey}`,
              },
              {
                text: '',
              },
              {
                text: '',
              },
              {
                text: '',
              },
              {
                text: '',
              },
              {
                text: '',
              },
              {
                text: '',
              },
              {
                text: '',
              },
            ],
            [
              {
                text: '',
                border: [false, false, false, false],
                fillColor: `${lightGrey}`,
              },
              {
                text: 'Normal Lung',
                bold: true,
                fillColor: `${limeGreen}`,
                color: `${green}`,
                alignment: 'center',
                border: [false, false, false, true],
                colSpan: 2,
              },
              {
                text: '',
              },
              {
                text: 'Pneumonia',
                bold: true,
                fillColor: `${yellow}`,
                color: `${brown}`,
                alignment: 'center',
                border: [false, false, false, true],
                colSpan: 3,
              },
              {
                text: '',
              },
              {
                text: '',
              },
              {
                text: 'Severe Pneumonia',
                bold: true,
                fillColor: `${pink}`,
                color: `${red}`,
                alignment: 'center',
                border: [false, false, false, true],
                colSpan: 2,
              },
              {
                text: '',
              },
              {
                text: 'Total',
                bold: true,
                alignment: 'center',
                border: [false, false, false, true],
                fillColor: `${lightGrey}`,
              },
            ],
            [
              {
                text: '% of Lung Affected',
                fillColor: `${lightGrey}`,
                border: [false, false, false, true],
              },
              {
                text: `0%`,
                fillColor: `${limeGreen}`,
                color: `${brown}`,
                border: [false, false, false, true],
              },
              {
                text: `1-10%`,
                fillColor: `${lightGreen}`,
                color: `${brown}`,
                border: [false, false, false, true],
              },
              {
                text: `11-20%`,
                fillColor: `${cream}`,
                color: `${brown}`,
                border: [false, false, false, true],
              },
              {
                text: `21-30%`,
                fillColor: `${yellow}`,
                color: `${brown}`,
                border: [false, false, false, true],
              },
              {
                text: `31-40%`,
                fillColor: `${orange}`,
                color: `${brown}`,
                border: [false, false, false, true],
              },
              {
                text: `41-50%`,
                fillColor: `${pink}`,
                color: `${red}`,
                border: [false, false, false, true],
              },
              {
                text: `>50%`,
                fillColor: `${darkPink}`,
                color: `${red}`,
                border: [false, false, false, true],
              },
              {
                text: '',
                fillColor: `${lightGrey}`,
                border: [false, false, false, true],
              },
            ],
            [
              {
                text: '% ADG Reduction',
                fillColor: `${lightGrey}`,
                border: [false, false, false, true],
              },
              {
                text: `${addDecimalPointToNumbers(
                  this.adgReductionPercentages[0] * 100
                )}%`,
                fillColor: `${limeGreen}`,
                color: `${brown}`,
                border: [false, false, false, true],
              },
              {
                text: `${addDecimalPointToNumbers(
                  this.adgReductionPercentages[1] * 100
                )}%`,
                fillColor: `${lightGreen}`,
                color: `${brown}`,
                border: [false, false, false, true],
              },
              {
                text: `${addDecimalPointToNumbers(
                  this.adgReductionPercentages[2] * 100
                )}%`,
                fillColor: `${cream}`,
                color: `${brown}`,
                border: [false, false, false, true],
              },
              {
                text: `${addDecimalPointToNumbers(
                  this.adgReductionPercentages[3] * 100
                )}%`,
                fillColor: `${yellow}`,
                color: `${brown}`,
                border: [false, false, false, true],
              },
              {
                text: `${addDecimalPointToNumbers(
                  this.adgReductionPercentages[4] * 100
                )}%`,
                fillColor: `${orange}`,
                color: `${brown}`,
                border: [false, false, false, true],
              },
              {
                text: `${addDecimalPointToNumbers(
                  this.adgReductionPercentages[5] * 100
                )}%`,
                fillColor: `${pink}`,
                color: `${red}`,
                border: [false, false, false, true],
              },
              {
                text: `${addDecimalPointToNumbers(
                  this.adgReductionPercentages[6] * 100
                )}%`,
                fillColor: `${darkPink}`,
                color: `${red}`,
                border: [false, false, false, true],
              },
              {
                text: '',
                fillColor: `${lightGrey}`,
                border: [false, false, false, true],
              },
            ],
            [
              {
                text: '% of Animals',
                fillColor: `${lightGrey}`,
                border: [false, false, false, true],
              },
              {
                text: `${addDecimalPointToRoundOffNumbers(
                  this.animalPercentages[0] * 100
                )}%`,
                fillColor: `${limeGreen}`,
                color: `${brown}`,
                border: [false, false, false, true],
              },
              {
                text: `${addDecimalPointToRoundOffNumbers(
                  this.animalPercentages[1] * 100
                )}%`,
                fillColor: `${lightGreen}`,
                color: `${brown}`,
                border: [false, false, false, true],
              },
              {
                text: `${addDecimalPointToRoundOffNumbers(
                  this.animalPercentages[2] * 100
                )}%`,
                fillColor: `${cream}`,
                color: `${brown}`,
                border: [false, false, false, true],
              },
              {
                text: `${addDecimalPointToRoundOffNumbers(
                  this.animalPercentages[3] * 100
                )}%`,
                fillColor: `${yellow}`,
                color: `${brown}`,
                border: [false, false, false, true],
              },
              {
                text: `${addDecimalPointToRoundOffNumbers(
                  this.animalPercentages[4] * 100
                )}%`,
                fillColor: `${orange}`,
                color: `${brown}`,
                border: [false, false, false, true],
              },
              {
                text: `${addDecimalPointToRoundOffNumbers(
                  this.animalPercentages[5] * 100
                )}%`,
                fillColor: `${pink}`,
                color: `${red}`,
                border: [false, false, false, true],
              },
              {
                text: `${addDecimalPointToRoundOffNumbers(
                  this.animalPercentages[6] * 100
                )}%`,
                fillColor: `${darkPink}`,
                color: `${red}`,
                border: [false, false, false, true],
              },
              {
                text: '',
                fillColor: `${lightGrey}`,
                border: [false, false, false, true],
              },
            ],
            [
              {
                text: '% of Animals (Assumption)',
                fillColor: `${lightBlue}`,
                color: `${blue}`,
                border: [false, false, false, true],
              },
              {
                text:
                  this.transferredModalData
                    .bronchopneumoniaAssumptionNormalLungsLowPercentage !==
                    null &&
                  this.transferredModalData
                    .bronchopneumoniaAssumptionNormalLungsLowPercentage !==
                    undefined
                    ? `${addDecimalPointToNumbers(
                        this.transferredModalData
                          .bronchopneumoniaAssumptionNormalLungsLowPercentage
                      )}%`
                    : '-',
                fillColor: `${lightBlue}`,
                color: `${blue}`,
                border: [false, false, false, true],
              },
              {
                text:
                  this.transferredModalData
                    .bronchopneumoniaAssumptionNormalLungsHighPercentage !==
                    null &&
                  this.transferredModalData
                    .bronchopneumoniaAssumptionNormalLungsHighPercentage !==
                    undefined
                    ? `${addDecimalPointToNumbers(
                        this.transferredModalData
                          .bronchopneumoniaAssumptionNormalLungsHighPercentage
                      )}%`
                    : '-',
                fillColor: `${lightBlue}`,
                color: `${blue}`,
                border: [false, false, false, true],
              },
              {
                text:
                  this.transferredModalData
                    .bronchopneumoniaAssumptionLowPneumonia !== null &&
                  this.transferredModalData
                    .bronchopneumoniaAssumptionLowPneumonia !== undefined
                    ? `${addDecimalPointToNumbers(
                        this.transferredModalData
                          .bronchopneumoniaAssumptionLowPneumonia
                      )}%`
                    : '-',
                fillColor: `${lightBlue}`,
                color: `${blue}`,
                border: [false, false, false, true],
              },
              {
                text:
                  this.transferredModalData
                    .bronchopneumoniaAssumptionNormalPneumonia !== null &&
                  this.transferredModalData
                    .bronchopneumoniaAssumptionNormalPneumonia !== undefined
                    ? `${addDecimalPointToNumbers(
                        this.transferredModalData
                          .bronchopneumoniaAssumptionNormalPneumonia
                      )}%`
                    : '-',
                fillColor: `${lightBlue}`,
                color: `${blue}`,
                border: [false, false, false, true],
              },
              {
                text: this.transferredModalData
                  .bronchopneumoniaAssumptionHighPneumonia
                  ? `${addDecimalPointToNumbers(
                      this.transferredModalData
                        .bronchopneumoniaAssumptionHighPneumonia
                    )}%`
                  : '-',
                fillColor: `${lightBlue}`,
                color: `${blue}`,
                border: [false, false, false, true],
              },
              {
                text:
                  this.transferredModalData
                    .bronchopneumoniaAssumptionLowSeverePneumonia !== null &&
                  this.transferredModalData
                    .bronchopneumoniaAssumptionLowSeverePneumonia !== undefined
                    ? `${addDecimalPointToNumbers(
                        this.transferredModalData
                          .bronchopneumoniaAssumptionLowSeverePneumonia
                      )}%`
                    : '-',
                fillColor: `${lightBlue}`,
                color: `${blue}`,
                border: [false, false, false, true],
              },
              {
                text:
                  this.transferredModalData
                    .bronchopneumoniaAssumptionHighSeverePneumonia !== null &&
                  this.transferredModalData
                    .bronchopneumoniaAssumptionHighSeverePneumonia !== undefined
                    ? `${addDecimalPointToNumbers(
                        this.transferredModalData
                          .bronchopneumoniaAssumptionHighSeverePneumonia
                      )}%`
                    : '-',
                fillColor: `${lightBlue}`,
                color: `${blue}`,
                border: [false, false, false, true],
              },
              {
                text: '',
                fillColor: `${lightBlue}`,
                color: `${blue}`,
                border: [false, false, false, true],
              },
            ],
            [
              {
                text: '# of Animals Sold /month (heads)',
                fillColor: `${lightGrey}`,
                border: [false, false, false, true],
              },
              {
                text: `${
                  this.numberOfAnimalHeadsSold[0].toFixed(0) === '0'
                    ? '-'
                    : this.addComma(this.numberOfAnimalHeadsSold[0], 0, false)
                }`,
                fillColor: `${limeGreen}`,
                color: `${brown}`,
                border: [false, false, false, true],
              },
              {
                text: `${
                  this.numberOfAnimalHeadsSold[1].toFixed(0) === '0'
                    ? '-'
                    : this.addComma(this.numberOfAnimalHeadsSold[1], 0, false)
                }`,
                fillColor: `${lightGreen}`,
                color: `${brown}`,
                border: [false, false, false, true],
              },
              {
                text: `${
                  this.numberOfAnimalHeadsSold[2].toFixed(0) === '0'
                    ? '-'
                    : this.addComma(this.numberOfAnimalHeadsSold[2], 0, false)
                }`,
                fillColor: `${cream}`,
                color: `${brown}`,
                border: [false, false, false, true],
              },
              {
                text: `${
                  this.numberOfAnimalHeadsSold[3].toFixed(0) === '0'
                    ? '-'
                    : this.addComma(this.numberOfAnimalHeadsSold[3], 0, false)
                }`,
                fillColor: `${yellow}`,
                color: `${brown}`,
                border: [false, false, false, true],
              },
              {
                text: `${
                  this.numberOfAnimalHeadsSold[4].toFixed(0) === '0'
                    ? '-'
                    : this.addComma(this.numberOfAnimalHeadsSold[4], 0, false)
                }`,
                fillColor: `${orange}`,
                color: `${brown}`,
                border: [false, false, false, true],
              },
              {
                text: `${
                  this.numberOfAnimalHeadsSold[5].toFixed(0) === '0'
                    ? '-'
                    : this.addComma(this.numberOfAnimalHeadsSold[5], 0, false)
                }`,
                fillColor: `${pink}`,
                color: `${red}`,
                border: [false, false, false, true],
              },
              {
                text: `${
                  this.numberOfAnimalHeadsSold[6].toFixed(0) === '0'
                    ? '-'
                    : this.addComma(this.numberOfAnimalHeadsSold[6], 0, false)
                }`,
                fillColor: `${darkPink}`,
                color: `${red}`,
                border: [false, false, false, true],
              },
              {
                text: this.numberOfAnimalHeads
                  ? `${this.addComma(this.numberOfAnimalHeads, 0, false)}`
                  : '',
                fillColor: `${lightGrey}`,
                border: [false, false, false, true],
              },
            ],
            [
              {
                text: '# of Animals Sold /month (heads) (Assumption)',
                fillColor: `${lightBlue}`,
                color: `${blue}`,
                border: [false, false, false, true],
              },
              {
                text: `${
                  this.numberOfAnimalHeadsSoldAssumptions[0].toFixed(0) === '0'
                    ? '-'
                    : this.addComma(
                        this.numberOfAnimalHeadsSoldAssumptions[0],
                        0,
                        false
                      )
                }`,
                fillColor: `${lightBlue}`,
                color: `${blue}`,
                border: [false, false, false, true],
              },
              {
                text: `${
                  this.numberOfAnimalHeadsSoldAssumptions[1].toFixed(0) === '0'
                    ? '-'
                    : this.addComma(
                        this.numberOfAnimalHeadsSoldAssumptions[1],
                        0,
                        false
                      )
                }`,
                fillColor: `${lightBlue}`,
                color: `${blue}`,
                border: [false, false, false, true],
              },
              {
                text: `${
                  this.numberOfAnimalHeadsSoldAssumptions[2].toFixed(0) === '0'
                    ? '-'
                    : this.addComma(
                        this.numberOfAnimalHeadsSoldAssumptions[2],
                        0,
                        false
                      )
                }`,
                fillColor: `${lightBlue}`,
                color: `${blue}`,
                border: [false, false, false, true],
              },
              {
                text: `${
                  this.numberOfAnimalHeadsSoldAssumptions[3].toFixed(0) === '0'
                    ? '-'
                    : this.addComma(
                        this.numberOfAnimalHeadsSoldAssumptions[3],
                        0,
                        false
                      )
                }`,
                fillColor: `${lightBlue}`,
                color: `${blue}`,
                border: [false, false, false, true],
              },
              {
                text: `${
                  this.numberOfAnimalHeadsSoldAssumptions[4].toFixed(0) === '0'
                    ? '-'
                    : this.addComma(
                        this.numberOfAnimalHeadsSoldAssumptions[4],
                        0,
                        false
                      )
                }`,
                fillColor: `${lightBlue}`,
                color: `${blue}`,
                border: [false, false, false, true],
              },
              {
                text: `${
                  this.numberOfAnimalHeadsSoldAssumptions[5].toFixed(0) === '0'
                    ? '-'
                    : this.addComma(
                        this.numberOfAnimalHeadsSoldAssumptions[5],
                        0,
                        false
                      )
                }`,
                fillColor: `${lightBlue}`,
                color: `${blue}`,
                border: [false, false, false, true],
              },
              {
                text: `${
                  this.numberOfAnimalHeadsSoldAssumptions[6].toFixed(0) === '0'
                    ? '-'
                    : this.addComma(
                        this.numberOfAnimalHeadsSoldAssumptions[6],
                        0,
                        false
                      )
                }`,
                fillColor: `${lightBlue}`,
                color: `${blue}`,
                border: [false, false, false, true],
              },
              {
                text: this.numberOfAnimalHeads
                  ? `${this.addComma(this.numberOfAnimalHeads, 0, false)}`
                  : '',
                fillColor: `${lightBlue}`,
                color: `${blue}`,
                border: [false, false, false, true],
              },
            ],
          ],
        },
        layout: 'regularTable',
        marginBottom: 15,
      },
      {
        pageBreak: 'before',
        text: 'Pleurisy Economic Loss',
        border: [false, false, false, false],
        marginBottom: 10,
      },
      {
        style: ['fontSize8'],
        table: {
          widths: '*',
          body: [
            [
              {
                text: 'Pleurisy (% Prevalence)',
                fillColor: `${lightGrey}`,
                bold: true,
                border: [false, false, false, false],
              },
              {
                text: this.transferredModalData.appPleurisyPrevalence
                  ? `${this.addComma(
                      this.transferredModalData.appPleurisyPrevalence,
                      2,
                      false
                    )}`
                  : '',
                border: [false, false, false, false],
              },
              {
                text: `${this.addComma(
                  this.pleurisyPrevalenceBackValue,
                  0,
                  false
                )} heads`,
                border: [false, false, false, false],
                color: `${darkGrey}`,
              },
              {
                text: ``,
                border: [false, false, false, false],
              },
              {
                text: 'Improvement of Post Weaning Mortality',
                fillColor: `${lightGrey}`,
                bold: true,
                border: [false, false, false, false],
              },
              {
                text: ``,
                border: [false, false, false, false],
              },
              {
                text: ``,
                border: [false, false, false, false],
              },
            ],
            [
              {
                text: 'Pleurisy (% Prevalence) (Assumption)',
                fillColor: `${lightBlue}`,
                color: `${blue}`,
                bold: true,
                border: [false, false, false, false],
              },
              {
                text: this.transferredModalData.appPleurisyPrevalenceAssumption
                  ? `${this.addComma(
                      this.transferredModalData.appPleurisyPrevalenceAssumption,
                      2,
                      false
                    )}`
                  : '',
                fillColor: `${lightBlue}`,
                color: `${blue}`,
                border: [false, false, false, false],
              },
              {
                text: `${this.addComma(
                  this.pleurisyPrevalenceBackValueAssumption,
                  0,
                  false
                )} heads`,
                fillColor: `${lightBlue}`,
                color: `${darkGrey}`,
                border: [false, false, false, false],
              },
              {
                text: ``,
                border: [false, false, false, false],
              },
              {
                text: '% of Post Weaning Mortality',
                fillColor: `${lightGrey}`,
                bold: true,
                border: [false, false, false, false],
              },
              {
                text: this.transferredModalData.postWeaningMortality
                  ? `${this.addComma(
                      this.transferredModalData.postWeaningMortality,
                      2,
                      false
                    )}`
                  : '',
                border: [false, false, false, false],
              },
              {
                text: `${this.addComma(
                  this.postWeaningMortalityBackValue,
                  0,
                  false
                )} heads`,
                border: [false, false, false, false],
                color: `${darkGrey}`,
              },
            ],
            [
              {
                text: 'P3 and P4 * (% Prevalence)',
                fillColor: `${lightGrey}`,
                bold: true,
                border: [false, false, false, false],
              },
              {
                text: this.transferredModalData.p3AndP4Prevalence
                  ? `${this.addComma(
                      this.transferredModalData.p3AndP4Prevalence,
                      2,
                      false
                    )}`
                  : '',
                border: [false, false, false, false],
              },
              {
                text: `${this.addComma(this.p3P4BackValue, 0, false)} heads`,
                border: [false, false, false, false],
                color: `${darkGrey}`,
              },
              {
                text: ``,
                border: [false, false, false, false],
              },
              {
                text: '% of Post Weaning Mortality (Assumption)',
                color: `${blue}`,
                fillColor: `${lightBlue}`,
                bold: true,
                border: [false, false, false, false],
              },
              {
                text:
                  this.transferredModalData.postWeaningMortalityAssumption ||
                  this.transferredModalData.postWeaningMortalityAssumption === 0
                    ? `${this.addComma(
                        this.transferredModalData
                          .postWeaningMortalityAssumption,
                        2,
                        false
                      )}`
                    : '',
                fillColor: `${lightBlue}`,
                color: `${blue}`,
                border: [false, false, false, false],
              },
              {
                text: `${this.addComma(
                  this.postWeaningMortalityAssumptionBackValue,
                  0,
                  false
                )} heads`,
                fillColor: `${lightBlue}`,
                color: `${darkGrey}`,
                border: [false, false, false, false],
              },
            ],
            [
              {
                text: 'P3 and P4 * (% Prevalence)',
                fillColor: `${lightBlue}`,
                color: `${blue}`,
                bold: true,
                border: [false, false, false, false],
              },
              {
                text: this.transferredModalData.p3AndP4PrevalenceAssumption
                  ? `${this.addComma(
                      this.transferredModalData.p3AndP4PrevalenceAssumption,
                      2,
                      false
                    )}`
                  : '',
                fillColor: `${lightBlue}`,
                color: `${blue}`,
                border: [false, false, false, false],
              },
              {
                text: `${this.addComma(
                  this.p3P4BackValueAssumption,
                  0,
                  false
                )} heads`,
                fillColor: `${lightBlue}`,
                border: [false, false, false, false],
                color: `${darkGrey}`,
              },
              {
                text: ``,
                border: [false, false, false, false],
              },
              {
                text: ``,
                border: [false, false, false, false],
              },
              {
                text: ``,
                border: [false, false, false, false],
              },
              {
                text: ``,
                border: [false, false, false, false],
              },
            ],
          ],
        },
      },
    ];

    this.gci.clear();
    this.gci2.clear();
    return content;
  }
}

export { StandardEconomicCalculationAssumption };
