

































import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { EconomicCalculationModalDataType } from '@/models/swine/economic-calculation.model';
import MedicationUpdateModal from './MedicationUpdateModal.vue';
import { EconomicCalculationType } from '@/utils/constants';
import PleurisyMedicationReportForm from './PleurisyMedicationReportForm.vue';
import PulmotilMedicationReportForm from './PulmotilMedicationReportForm.vue';
import PulmotilMedicationUpdateModal from './PulmotilMedicationUpdateModal.vue';
@Component({
  components: {
    MedicationUpdateModal,
    PleurisyMedicationReportForm,
    PulmotilMedicationReportForm,
    PulmotilMedicationUpdateModal,
  },
})
export default class MedicationProgramReport extends Vue {
  updateMedicationModalStatus = false;

  @Prop({ required: true })
  calculatorModalInputDataRetrieve!: EconomicCalculationModalDataType;

  get isPulmotilValueCalculationTypeSelected() {
    return (
      this.calculatorModalInputDataRetrieve.economicCalculationType ===
      EconomicCalculationType.pulmotil
    );
  }

  toggleUpdateMedicationModal() {
    this.updateMedicationModalStatus = !this.updateMedicationModalStatus;
  }

  updateMedicationProgramReportData(
    updateMedicationModalData: EconomicCalculationModalDataType,
  ) {
    this.$emit('update', updateMedicationModalData);
    this.updateMedicationModalStatus = !this.updateMedicationModalStatus;
  }

  @Emit(`update`)
  updateAnimalModal(payload: {
    numberOfAnimalHeads: number;
    weanMarketDays: number;
    weekNumber: number;
  }) {
    return payload;
  }
}
