export function getReportDetails(auditReportLungDataArray: any) {
  const content = [
    {
      text: 'Report Details',
      style: 'sectionHeader',
    },
    {
      style: ['fontSize8', 'textCenter', 'fontBold'],
      table: {
        width: 'auto',
        body: [
          [
            {
              text: 'Lung ID',
              bold: true,
              border: [false, false, true, false],
              rowSpan: 2,
              fillColor: '#f2f2f2',
            },
            {
              text: 'Right Apical',
              bold: true,
              border: [false, false, false, true],
              fillColor: '#f2f2f2',
            },
            {
              text: 'Right Cardiac',
              bold: true,
              border: [false, false, false, true],
              fillColor: '#f2f2f2',
            },
            {
              text: 'Right Diaphragmatic',
              bold: true,
              border: [false, false, false, true],
              fillColor: '#f2f2f2',
            },
            {
              text: 'Left Apical',
              bold: true,
              border: [false, false, false, true],
              fillColor: '#f2f2f2',
            },
            {
              text: 'Left Cardiac',
              bold: true,
              border: [false, false, false, true],
              fillColor: '#f2f2f2',
            },
            {
              text: 'Left Diaphragmatic',
              bold: true,
              border: [false, false, false, true],
              fillColor: '#f2f2f2',
            },
            {
              text: 'Accessory',
              bold: true,
              border: [false, false, false, true],
              fillColor: '#f2f2f2',
            },
            {
              text: 'Total Bronchopneumonia Score',
              bold: true,
              border: [false, false, false, true],
              fillColor: '#f2f2f2',
            },
            {
              text: 'Pleurisy Score',
              bold: true,
              border: [true, false, false, true],
              fillColor: '#f2f2f2',
            },
            {
              text: 'Absc.',
              bold: true,
              border: [true, false, false, true],
              fillColor: '#f2f2f2',
            },
            {
              text: 'Scar./Chro.',
              bold: true,
              border: [false, false, false, true],
              fillColor: '#f2f2f2',
            },
            {
              text: 'Peri',
              bold: true,
              border: [false, false, false, true],
              fillColor: '#f2f2f2',
            },
            {
              text: 'Attachment',
              bold: true,
              border: [false, false, false, true],
              rowSpan: 2,
              fillColor: '#f2f2f2',
            },
            {
              text: 'General Remarks',
              bold: true,
              border: [true, false, false, true],
              rowSpan: 2,
              fillColor: '#f2f2f2',
            },
          ],
          [
            '',
            {
              text: '10%',
              border: [false, false, false, false],
              fillColor: '#f2f2f2',
            },
            {
              text: '10%',
              border: [false, false, false, false],
              fillColor: '#f2f2f2',
            },
            {
              text: '25%',
              border: [false, false, false, false],
              fillColor: '#f2f2f2',
            },
            {
              text: '10%',
              border: [false, false, false, false],
              fillColor: '#f2f2f2',
            },
            {
              text: '10%',
              border: [false, false, false, false],
              fillColor: '#f2f2f2',
            },
            {
              text: '25%',
              border: [false, false, false, false],
              fillColor: '#f2f2f2',
            },
            {
              text: '10%',
              border: [false, false, false, false],
              fillColor: '#f2f2f2',
            },
            {
              text: '100%',
              border: [false, false, false, false],
              fillColor: '#f2f2f2',
            },
            {
              text: 'P0 - P4',
              border: [true, false, true, false],
              fillColor: '#f2f2f2',
            },
            {
              text: 'Y/N/NE',
              border: [false, false, false, false],
              fillColor: '#f2f2f2',
            },
            {
              text: 'Y/N/NE',
              border: [false, false, false, false],
              fillColor: '#f2f2f2',
            },
            {
              text: 'Y/N/NE',
              border: [false, false, false, false],
              fillColor: '#f2f2f2',
            },
            '',
            '',
          ],
          ...auditReportLungDataArray,
        ],
      },
      layout: 'allGreyBorder',
      pageBreak: 'after',
    },
  ];

  return content;
}
