





















































































































































































































































































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { DisplayData } from '@/models/swine/report/report.model';
import SectionLayoutCard from '@/components/swine/reports/SectionLayoutCard.vue';
import PleurisyTooltip from './PleurisyTooltip.vue';
import PleurisyUpdateModal from './PleurisyUpdateModal.vue';
import {
  PleurisyCalculatorModalData,
  EconomicCalculationModalDataType,
} from '@/models/swine/economic-calculation.model';
import { addCommas } from '@/utils/formats';
import UpdateAnimalNumberModal from './UpdateAnimalNumberModal.vue';
import { EconomicCalculationType } from '@/utils/constants';

const tooltipValues = [
  {
    header: 'P3 and P4 * (% Prevalence)',
    description:
      'The percentage and number of pigs with pleurisy involving in excess of approximately 20% of the total lung area - severe pleurisy approximately 20% of the total lung area, is classed as extensive.',
  },
  {
    header: 'Effect on Post Weaning Mortality',
    description: 'Average 3.3% increase in mortality for acute APP cases',
  },
  {
    header: 'Effect on ADG on Pigs with Pleurisy (Lifetime)',
    description: '2g/day lost of daily gain for each 1% increase in pleurisy',
  },
  {
    header: 'Effect on Slaugther Weight on Pigs with Pleurisy',
    description: 'Reduction 0.07kg/pig for each 1% increase in pleurisy',
  },
  {
    header: 'Effect on Slaugther Age on Pigs with Pleurisy',
    description:
      'Increase in age of 0.26 days pig for each 1% increase in pleurisy',
  },
];

@Component({
  components: {
    SectionLayoutCard,
    PleurisyTooltip,
    PleurisyUpdateModal,
    UpdateAnimalNumberModal,
  },
})
export default class PleurisyEconomicLossReport extends Vue {
  updatePleurisyModalStatus = false;

  updatePDFModalStatus = false;

  updateAnimalModalStatus = false;

  updateWeanModalStatus = false;

  @Prop({ required: true })
  calculatorModalInputDataRetrieve!: EconomicCalculationModalDataType;

  pdfLink = process.env.VUE_APP_PDF_REFERENCE_LINK;

  get isPulmotilValueCalculationTypeSelected() {
    return (
      this.calculatorModalInputDataRetrieve.economicCalculationType ===
      EconomicCalculationType.pulmotil
    );
  }

  get numberOfPigletsWeanedText() {
    if (
      this.isPulmotilValueCalculationTypeSelected &&
      'numberOfPigletsAtNursery' in this.calculatorModalInputDataRetrieve
    ) {
      return this.addComma(
        this.calculatorModalInputDataRetrieve.numberOfPigletsAtNursery,
        0,
        false,
      );
    }

    if (
      'numberOfAnimalHeads' in this.calculatorModalInputDataRetrieve &&
      !this.isPulmotilValueCalculationTypeSelected &&
      this.calculatorModalInputDataRetrieve.numberOfAnimalHeads !== null
    ) {
      return this.addComma(
        this.calculatorModalInputDataRetrieve.numberOfAnimalHeads,
        0,
        true,
      );
    }
    return this.addComma(0, 0, true);
  }

  get pleurisyPrevalenceBackValue() {
    let pleurisyPrevalenceBackValueResults = 0;
    if (
      !this.isPulmotilValueCalculationTypeSelected &&
      'numberOfAnimalHeads' in this.calculatorModalInputDataRetrieve &&
      this.calculatorModalInputDataRetrieve.appPleurisyPrevalence !==
        undefined &&
      this.calculatorModalInputDataRetrieve.numberOfAnimalHeads !== null
    ) {
      pleurisyPrevalenceBackValueResults =
        (Number(
          this.calculatorModalInputDataRetrieve.appPleurisyPrevalence.toFixed(
            2,
          ),
        ) *
          this.calculatorModalInputDataRetrieve.numberOfAnimalHeads) /
        100;
    }

    if (
      this.isPulmotilValueCalculationTypeSelected &&
      this.calculatorModalInputDataRetrieve.appPleurisyPrevalence !==
        undefined &&
      'numberOfGrowerPigs' in this.calculatorModalInputDataRetrieve &&
      this.calculatorModalInputDataRetrieve.numberOfGrowerPigs
    ) {
      pleurisyPrevalenceBackValueResults =
        (Number(
          this.calculatorModalInputDataRetrieve.appPleurisyPrevalence.toFixed(
            2,
          ),
        ) *
          this.calculatorModalInputDataRetrieve.numberOfGrowerPigs) /
        100;
    }
    return pleurisyPrevalenceBackValueResults;
  }

  get p3P4BackValue() {
    let p3P4BackValueResult = 0;
    if (
      !this.isPulmotilValueCalculationTypeSelected &&
      'numberOfAnimalHeads' in this.calculatorModalInputDataRetrieve &&
      this.calculatorModalInputDataRetrieve.p3AndP4Prevalence !== undefined &&
      this.calculatorModalInputDataRetrieve.numberOfAnimalHeads !== null
    ) {
      p3P4BackValueResult =
        (Number(
          this.calculatorModalInputDataRetrieve.p3AndP4Prevalence.toFixed(2),
        ) *
          this.calculatorModalInputDataRetrieve.numberOfAnimalHeads) /
        100;
    }

    if (
      this.isPulmotilValueCalculationTypeSelected &&
      this.calculatorModalInputDataRetrieve.p3AndP4Prevalence !== undefined &&
      'numberOfGrowerPigs' in this.calculatorModalInputDataRetrieve &&
      this.calculatorModalInputDataRetrieve.numberOfGrowerPigs !== null
    ) {
      p3P4BackValueResult =
        (Number(
          this.calculatorModalInputDataRetrieve.p3AndP4Prevalence.toFixed(2),
        ) *
          this.calculatorModalInputDataRetrieve.numberOfGrowerPigs) /
        100;
    }
    return p3P4BackValueResult;
  }

  get weaningMortalityPercentage() {
    return this.calculatorModalInputDataRetrieve.postWeaningMortality;
  }

  get weaningMortalityHeads() {
    let weaningMortalityHeadsResult = 0;
    if (
      !this.isPulmotilValueCalculationTypeSelected &&
      this.weaningMortalityPercentage !== null &&
      'numberOfAnimalHeads' in this.calculatorModalInputDataRetrieve &&
      this.calculatorModalInputDataRetrieve.numberOfAnimalHeads !== null
    ) {
      weaningMortalityHeadsResult =
        (this.weaningMortalityPercentage *
          this.calculatorModalInputDataRetrieve.numberOfAnimalHeads) /
        100;
    }

    if (
      this.isPulmotilValueCalculationTypeSelected &&
      this.weaningMortalityPercentage !== null &&
      'numberOfGrowerPigs' in this.calculatorModalInputDataRetrieve &&
      this.calculatorModalInputDataRetrieve.numberOfGrowerPigs !== null
    ) {
      weaningMortalityHeadsResult =
        (this.weaningMortalityPercentage *
          this.calculatorModalInputDataRetrieve.numberOfGrowerPigs) /
        100;
    }
    return weaningMortalityHeadsResult;
  }

  get totalCostPostWeaning() {
    let totalCostPostWeaningResult = 0;
    if (
      this.calculatorModalInputDataRetrieve.liveWeightPrice !== null &&
      this.calculatorModalInputDataRetrieve.targetMarketWeight !== null
    ) {
      totalCostPostWeaningResult =
        this.weaningMortalityHeads *
        this.calculatorModalInputDataRetrieve.liveWeightPrice *
        this.calculatorModalInputDataRetrieve.targetMarketWeight;
    }
    return totalCostPostWeaningResult;
  }

  get effectADGPigs() {
    let effectADGPigsResult = 0;
    if (
      this.calculatorModalInputDataRetrieve.p3AndP4Prevalence !== undefined &&
      this.calculatorModalInputDataRetrieve.weanMarketDays !== null
    ) {
      effectADGPigsResult =
        (Number(
          this.calculatorModalInputDataRetrieve.p3AndP4Prevalence.toFixed(2),
        ) *
          2 *
          this.calculatorModalInputDataRetrieve.weanMarketDays) /
        1000;
    }
    return effectADGPigsResult;
  }

  get totalCostADGPigs() {
    let totalCostADGPigsResult = 0;
    if (this.calculatorModalInputDataRetrieve.liveWeightPrice !== null) {
      totalCostADGPigsResult =
        this.p3P4BackValue *
        this.effectADGPigs *
        this.calculatorModalInputDataRetrieve.liveWeightPrice;
    }
    return totalCostADGPigsResult;
  }

  get effectOnSlaughterWeight() {
    let effectOnSlaughterWeight = 0;
    if (this.calculatorModalInputDataRetrieve.p3AndP4Prevalence !== undefined) {
      effectOnSlaughterWeight =
        Number(
          this.calculatorModalInputDataRetrieve.p3AndP4Prevalence.toFixed(2),
        ) * 0.07;
    }
    return effectOnSlaughterWeight;
  }

  get totalEffectOnSlaughterWeight() {
    let totalEffectOnSlaughterWeightResult = 0;
    if (this.calculatorModalInputDataRetrieve.liveWeightPrice !== null) {
      totalEffectOnSlaughterWeightResult =
        this.p3P4BackValue *
        this.effectOnSlaughterWeight *
        this.calculatorModalInputDataRetrieve.liveWeightPrice;
    }
    return totalEffectOnSlaughterWeightResult;
  }

  get effectOnSlaughterAge() {
    let effectOnSlaughterAgeResult = 0;
    if (this.calculatorModalInputDataRetrieve.p3AndP4Prevalence !== undefined) {
      effectOnSlaughterAgeResult =
        Number(
          this.calculatorModalInputDataRetrieve.p3AndP4Prevalence.toFixed(2),
        ) * 0.26;
    }
    return effectOnSlaughterAgeResult;
  }

  get totalEffectSlaughterAge() {
    let totalEffectSlaughterAge = 0;
    if (this.calculatorModalInputDataRetrieve.averageFeedPrice !== null) {
      totalEffectSlaughterAge =
        this.p3P4BackValue *
        this.effectOnSlaughterAge *
        2 *
        this.calculatorModalInputDataRetrieve.averageFeedPrice;
    }
    return totalEffectSlaughterAge;
  }

  get totalCostOfAPP() {
    return (
      this.totalCostPostWeaning +
      this.totalCostADGPigs +
      this.totalEffectOnSlaughterWeight +
      this.totalEffectSlaughterAge
    );
  }

  get sectionCardDetails(): DisplayData[] {
    let sectionCardDetailsResult: DisplayData[] = [];
    sectionCardDetailsResult = [
      {
        headerText: `Cost of Pleurisy /pig`,
        subText: ``,
        value: `${
          this.addComma(this.totalCostOfAPP / this.headsCounter, 2, false) ===
          '-'
            ? ''
            : this.calculatorModalInputDataRetrieve.currencySymbol
        } ${this.addComma(this.totalCostOfAPP / this.headsCounter, 2, false)}`,
      },
      {
        headerText: `Total Opportunity Loss due to Pleurisy (${this.headsCounter.toFixed(
          0,
        )} heads)`,
        subText: ``,
        value: `${
          this.addComma(this.totalCostOfAPP, 2, false) === '-' ||
          this.headsCounter === 0
            ? '-'
            : this.calculatorModalInputDataRetrieve.currencySymbol +
              ' ' +
              this.addComma(this.totalCostOfAPP, 2, false)
        } `,
      },
    ];
    return sectionCardDetailsResult;
  }

  get toolTipValues() {
    return tooltipValues;
  }

  get headsCounter(): number {
    if (
      this.isPulmotilValueCalculationTypeSelected &&
      'numberOfGrowerPigs' in this.calculatorModalInputDataRetrieve &&
      this.calculatorModalInputDataRetrieve.numberOfGrowerPigs !== null
    ) {
      return this.calculatorModalInputDataRetrieve.numberOfGrowerPigs;
    }

    if (
      !this.isPulmotilValueCalculationTypeSelected &&
      'numberOfAnimalHeads' in this.calculatorModalInputDataRetrieve &&
      this.calculatorModalInputDataRetrieve.numberOfAnimalHeads !== null
    ) {
      return this.calculatorModalInputDataRetrieve.numberOfAnimalHeads;
    }

    return 1;
  }

  toggleUpdatePleurisyModal() {
    this.updatePleurisyModalStatus = !this.updatePleurisyModalStatus;
  }

  updatePleurisyEconomicLossData(
    updatePleurisyModalData: PleurisyCalculatorModalData,
  ) {
    this.$emit('update', updatePleurisyModalData);
    this.updatePleurisyModalStatus = !this.updatePleurisyModalStatus;
  }

  openAnimalUpdateModal() {
    this.updateAnimalModalStatus = !this.updateAnimalModalStatus;
  }

  updateAnimalNumber({
    numberOfAnimalHeads,
    weanMarketDays,
    weekNumber,
  }: {
    numberOfAnimalHeads: number;
    weanMarketDays: number;
    weekNumber: number;
  }) {
    this.$emit('update', { numberOfAnimalHeads, weanMarketDays, weekNumber });
    this.updateAnimalModalStatus = !this.updateAnimalModalStatus;
  }

  openWeanUpdateModal() {
    this.updateWeanModalStatus = !this.updateWeanModalStatus;
  }

  updateWeanNumber({
    numberOfAnimalHeads,
    weanMarketDays,
    weekNumber,
  }: {
    numberOfAnimalHeads: number;
    weanMarketDays: number;
    weekNumber: number;
  }) {
    this.$emit('update', { numberOfAnimalHeads, weanMarketDays, weekNumber });
    this.updateWeanModalStatus = !this.updateWeanModalStatus;
  }

  addComma(targetNumber: number, toFixedNumber: number, onlyToString: boolean) {
    if (isNaN(targetNumber) && targetNumber !== 0) {
      return '-';
    } else if (!onlyToString) {
      const roundedNumber = targetNumber.toFixed(toFixedNumber);
      return addCommas(roundedNumber);
    } else if (onlyToString) {
      const numberToString = targetNumber.toString();
      return addCommas(numberToString);
    }
  }
}
