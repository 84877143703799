






























































































































































import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import {
  Attachment,
  AuditReportUpdateQueries,
  LungAssessmentIdWithAttachment,
  LungAssessmentIdWithShowModal,
  LungLesionScoreParseAttachmentDetails,
  ScoreAttachmentObject,
  UpdateType,
} from '@/models/swine/report/report.model';
import { ReportStores } from '@/store/swine/reports';
import { AuditReportSections } from '@/utils/constants';
import AttachmentImageComponent from './AttachmentImageComponent.vue';
import AttachmentImageModalForm from './AttachmentImageModalForm.vue';
@Component({
  components: { AttachmentImageComponent, AttachmentImageModalForm },
})
export default class AttachmentSelectionTable extends Vue {
  @Prop({
    type: Array,
    default: [],
  })
  data!: LungLesionScoreParseAttachmentDetails[];
  isSelected: { [key: number]: boolean } = {};
  @Prop({
    type: Boolean,
    default: false,
  })
  isDisabled!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  isEditAllowed!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  isSelectionReset!: boolean;

  @Prop({
    type: String,
    default: null,
  })
  currentActiveTab!: string;

  selectedDetails: LungLesionScoreParseAttachmentDetails[] = [];
  attachmentMaximumCounter = 3;
  isModalActive = false;
  store = ReportStores.detail;
  formData: FormData = new FormData();
  selectedLungAssessmentId = -1;
  currentAttachments: Attachment[] = [];
  currentCounter = 0;

  setDataStyle(details: ScoreAttachmentObject) {
    return `color: ${details.textColor}; background-color: ${details.backgroundColor}`;
  }

  mounted() {
    if (this.data) {
      this.createSelectedDict();
    }
    if (
      this.currentActiveTab === AuditReportSections.BRONCHOPNEUMONIA_SCORE &&
      this.store.bronchopneumoniaScoreAttachments &&
      this.store.bronchopneumoniaScoreAttachments.length > 0
    ) {
      this.store.bronchopneumoniaScoreAttachments.map((item) => {
        this.isSelected[item.id] = true;
      });
      this.selectedDetails = this.store.bronchopneumoniaScoreAttachments;
    }
    if (
      this.currentActiveTab === AuditReportSections.PLEURISY &&
      this.store.pleurisyScoreAttachments &&
      this.store.pleurisyScoreAttachments.length > 0
    ) {
      this.store.pleurisyScoreAttachments.map((item) => {
        this.isSelected[item.id] = true;
      });
      this.selectedDetails = this.store.pleurisyScoreAttachments;
    }
    if (
      this.currentActiveTab ===
        AuditReportSections.ABSCESS_SCARRING_PERICARDITIS &&
      this.store.abscessScarringPericarditisScoreAttachments &&
      this.store.abscessScarringPericarditisScoreAttachments.length > 0
    ) {
      this.store.abscessScarringPericarditisScoreAttachments.map((item) => {
        this.isSelected[item.id] = true;
      });
      this.selectedDetails = this.store.abscessScarringPericarditisScoreAttachments;
    }
  }

  createSelectedDict() {
    let index = 0;
    while (index < this.data.length) {
      this.isSelected[index] = false;
      index++;
    }
  }

  @Watch('isSelectionReset')
  @Emit('reset')
  resetSelection() {
    if (this.isSelectionReset) {
      let index = 0;
      while (index < this.data.length) {
        this.isSelected[index] = false;
        index++;
      }
      this.selectedDetails = [];
      this.currentCounter++;
      this.returnSelection();
      return false;
    }
  }

  selectDetails(
    details: LungLesionScoreParseAttachmentDetails,
    isSelected: boolean,
  ) {
    if (this.selectedDetails.length < 5 && isSelected) {
      this.selectedDetails.push(details);
    }

    if (!isSelected) {
      this.selectedDetails = this.selectedDetails.filter((item) => {
        return details.id !== item.id;
      });
    }
    this.returnSelection();
  }

  @Emit(`updateSelectedAttachments`)
  returnSelection() {
    return this.selectedDetails;
  }

  showModal(value: LungAssessmentIdWithShowModal) {
    this.isModalActive = value.showModal;
    this.selectedLungAssessmentId = value.lungAssessmentId;
    if (
      this.data[this.selectedLungAssessmentId] &&
      this.data[this.selectedLungAssessmentId].generalRemarks &&
      this.data[this.selectedLungAssessmentId].generalRemarks.attachments
    ) {
      this.currentAttachments = this.data[this.selectedLungAssessmentId]
        .generalRemarks.attachments as Attachment[];
    }
  }

  uploadImages(value: File[]) {
    this.isModalActive = false;
    this.formData = new FormData();
    value.forEach((item) => {
      this.formData.append('file', item);
    });
    this.store.uploadAttachment(this.formData);
  }

  @Watch('store.fileAttachmentResponse')
  updateAuditReportAttachments() {
    // We need to find the index of the data within the array
    const dataIndex = this.data.findIndex((item) => {
      return item.id === this.selectedLungAssessmentId;
    });
    if (
      this.store.fileAttachmentResponse !== null &&
      this.store.fileAttachmentResponse.length > 0 &&
      this.selectedLungAssessmentId > -1 &&
      dataIndex > -1
    ) {
      const latestAttachments: Attachment[] = [];
      this.data[dataIndex].generalRemarks.attachments.forEach((item) => {
        latestAttachments.push(item);
      });
      this.store.fileAttachmentResponse.forEach((item) => {
        latestAttachments.push(item);
      });
      const params: AuditReportUpdateQueries = {
        lungAssessmentId: this.selectedLungAssessmentId,
        attachments: latestAttachments,
        updateType: UpdateType.UPDATE,
      };
      this.store.updateReportAttachmentDetails(params);
    }
  }

  @Watch('store.auditReportUpdateResponse')
  @Emit('update')
  updateCurrentTableData() {
    if (this.store.auditReportUpdateResponse) {
      return;
    }
  }

  deleteSpecifiedAttachment(value: LungAssessmentIdWithAttachment) {
    const { lungAssessmentId, attachment } = value;
    // We need to find the index of the data within the array
    const dataIndex = this.data.findIndex((item) => {
      return item.id === this.selectedLungAssessmentId;
    });
    if (this.data !== null && this.data[dataIndex]) {
      const filteredLungAttachments = this.data[dataIndex].generalRemarks.attachments.filter((details) => {
        return details.id !== attachment.id;
      });
      const params: AuditReportUpdateQueries = {
        lungAssessmentId: lungAssessmentId,
        attachments: filteredLungAttachments,
        updateType: UpdateType.DELETE,
      };
      this.store.updateReportAttachmentDetails(params);
    }
  }
}
