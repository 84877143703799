import { AbscessScarringAndPericarditisWithReportData } from '@/models/swine/report/report.model';
import { getBase64ImageFromURL } from '@/utils/base64ImageFromUrl';
import { addDecimalPointToNumbers } from '@/utils/formats';
import { GenerateChartImage } from '@/utils/generate-chart/generate-chart-image';
import {
  brown,
  lightGrey,
  green,
  limeGreen,
  pink,
  red,
  yellow,
} from '../../colors';

export async function getAbscessScarringPericarditisAndAttachments(
  abscessScarringPericarditis: AbscessScarringAndPericarditisWithReportData,
  abscessScarringPericarditisAttachments: any,
) {
  let maxValue = 0;
  if (
    abscessScarringPericarditis.abscess &&
    abscessScarringPericarditis.abscess['percentage'] &&
    maxValue < abscessScarringPericarditis.abscess['percentage']
  ) {
    maxValue = abscessScarringPericarditis.abscess['percentage'];
  }
  if (
    abscessScarringPericarditis.scarring &&
    abscessScarringPericarditis.scarring['percentage'] &&
    maxValue < abscessScarringPericarditis.scarring['percentage']
  ) {
    maxValue = abscessScarringPericarditis.scarring['percentage'];
  }
  if (
    abscessScarringPericarditis.pericarditis &&
    abscessScarringPericarditis.pericarditis['percentage'] &&
    maxValue < abscessScarringPericarditis.pericarditis['percentage']
  ) {
    maxValue = abscessScarringPericarditis.pericarditis['percentage'];
  }
  // Generate ChartData and Options
  const chartData = {
    labels: [`Abscess`, `Scarring/Chronic`, `Pericarditis`],
    datasets: [
      {
        data: [
          abscessScarringPericarditis.abscess[`percentage`],
          abscessScarringPericarditis.scarring[`percentage`],
          abscessScarringPericarditis.pericarditis[`percentage`],
        ],
        backgroundColor: [`#D6FFA4`, `#FFF5C9`, `#FFEAEA`],
        borderColor: [`#6C9D31`, `#B99913`, `#F15453`],
        borderWidth: 1,
        barPercentage: 0.2,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: true,
    legend: {
      display: false,
    },
    plugins: {
      datalabels: {
        display: false,
      },
    },
    scales: {
      yAxes: [
        {
          gridLines: {
            drawBorder: false,
          },
          ticks: {
            beginAtZero: true,
            min: 0,
            max: 120,
            callback: function (tick: string | number) {
              return tick.toString() + '%';
            },
          },
        },
      ],
      xAxes: [
        {
          gridLines: {
            display: false,
          },
        },
      ],
    },
    animation: false
  };

  const gci = new GenerateChartImage(chartData, options);

  const abscessScarringPericarditisDataLayout: any[] = [];

  if (abscessScarringPericarditisAttachments.length) {
    for (let abscessScarringPericarditisData of abscessScarringPericarditisAttachments) {
      let attachments = abscessScarringPericarditisData.pop();

      abscessScarringPericarditisDataLayout.push({
        layout: 'regularTable',
        margin: [0, 30, 0, 0],
        style: ['fontSize8', 'textCenter'],
        table: {
          widths: ['auto', '*', '*', '*'],
          body: [
            [
              {
                text: 'Lung ID',
                bold: true,
                margin: [30, 25, 30, 0],
                border: [false, false, true, false],
                rowSpan: 2,
                fillColor: '#f2f2f2',
              },
              {
                text: 'Abscess',
                bold: true,
                margin: [0, 10, 0, 10],
                border: [false, false, true, false],
                fillColor: '#f2f2f2',
              },
              {
                text: 'Scarring/Chronic',
                bold: true,
                margin: [0, 10, 0, 10],
                border: [false, false, true, false],
                fillColor: '#f2f2f2',
              },
              {
                text: 'Pericarditis',
                bold: true,
                margin: [0, 10, 0, 10],
                border: [false, false, false, false],
                fillColor: '#f2f2f2',
              },
            ],
            [
              '',
              {
                text: 'Y/N/NE',
                bold: true,
                margin: [0, 5, 0, 5],
                border: [false, true, true, false],
                fillColor: '#f2f2f2',
              },
              {
                text: 'Y/N/NE',
                bold: true,
                margin: [0, 5, 0, 5],
                border: [false, true, true, false],
                fillColor: '#f2f2f2',
              },
              {
                text: 'Y/N/NE',
                bold: true,
                margin: [0, 5, 0, 5],
                border: [false, true, false, false],
                fillColor: '#f2f2f2',
              },
            ],
            abscessScarringPericarditisData,
          ],
        },
      })

      let attachmentLayout: any[] = []
      let attachmentBase64Function: any[] = []

      for (let attachment of attachments)  {
        attachmentBase64Function.push(getBase64ImageFromURL(attachment.link))
      }

      const attachmentBase64 = await Promise.all(attachmentBase64Function)

      for (let index = 0; index < attachments.length; index++)  {
        let _attachment: any[] = []

        _attachment.push({
          width: 200,
          height: 200,
          image: attachmentBase64[index],
        })
        _attachment.push({
          color: '#1697db',
          text: `Attachment ${index + 1}`,
          link: attachments[index].link,
          margin: [0, 10, 0, 0],
        })

        attachmentLayout.push({
          stack: _attachment,
          alignment: 'center',
          width: '33%',
        })
      }

      abscessScarringPericarditisDataLayout.push({
        columns: attachmentLayout,
        margin: [0, 50, 0, 0],
      })
      abscessScarringPericarditisDataLayout.push({
        text: '',
        pageBreak: 'after',
      })
    }
  } else {
    abscessScarringPericarditisDataLayout.push({
      text: '',
      pageBreak: 'after',
    })
  }

  const content = [
    {
      text: 'Abscess, Scarring/Chronic, Pericarditis',
      style: 'sectionHeader',
    },
    {
      image: gci.generateImage(),
      width: 741,
      height: 200,
      alignment: 'center',
      marginBottom: 5,
    },
    {
      style: 'fontSize8',
      table: {
        widths: '*',
        body: [
          [
            {
              text: '',
              fillColor: `${lightGrey}`,
              border: [false, false, false, true],
            },
            {
              text: 'Abscess',
              fillColor: `${limeGreen}`,
              color: `${green}`,
              bold: true,
              border: [false, false, false, true],
            },
            {
              text: 'Scarring/Chronic',
              fillColor: `${yellow}`,
              color: `${brown}`,
              bold: true,
              border: [false, false, false, true],
            },
            {
              text: 'Pericarditis',
              fillColor: `${pink}`,
              color: `${red}`,
              bold: true,
              border: [false, false, false, true],
            },
          ],
          [
            {
              text: 'No. of Lung / Heart Examined',
              fillColor: `${lightGrey}`,
              bold: true,
              border: [false, false, false, true],
            },
            {
              text: `${abscessScarringPericarditis.abscess.count}`,
              fillColor: `${limeGreen}`,
              color: `${green}`,
              border: [false, false, false, true],
            },
            {
              text: `${abscessScarringPericarditis.scarring.count}`,
              fillColor: `${yellow}`,
              color: `${brown}`,
              border: [false, false, false, true],
            },
            {
              text: `${abscessScarringPericarditis.pericarditis.count}`,
              fillColor: `${pink}`,
              color: `${red}`,
              border: [false, false, false, true],
            },
          ],
          [
            {
              text: 'No. of Lung / Heart with Lesion',
              fillColor: `${lightGrey}`,
              bold: true,
              border: [false, false, false, true],
            },
            {
              text: `${abscessScarringPericarditis.abscess.Abscess}`,
              fillColor: `${limeGreen}`,
              color: `${green}`,
              border: [false, false, false, true],
            },
            {
              text: `${abscessScarringPericarditis.scarring.Scarring}`,
              fillColor: `${yellow}`,
              color: `${brown}`,
              border: [false, false, false, true],
            },
            {
              text: `${abscessScarringPericarditis.pericarditis.Pericarditis}`,
              fillColor: `${pink}`,
              color: `${red}`,
              border: [false, false, false, true],
            },
          ],
          [
            {
              text: '% of Lung / Heart with Lesion',
              fillColor: `${lightGrey}`,
              bold: true,
              border: [false, false, false, false],
            },
            {
              text: `${addDecimalPointToNumbers(
                abscessScarringPericarditis.abscess.percentage,
              )}`,
              fillColor: `${limeGreen}`,
              color: `${green}`,
              border: [false, false, false, false],
            },
            {
              text: `${addDecimalPointToNumbers(
                abscessScarringPericarditis.scarring.percentage,
              )}`,
              fillColor: `${yellow}`,
              color: `${brown}`,
              border: [false, false, false, false],
            },
            {
              text: `${addDecimalPointToNumbers(
                abscessScarringPericarditis.pericarditis.percentage,
              )}`,
              fillColor: `${pink}`,
              color: `${red}`,
              border: [false, false, false, false],
            },
          ],
        ],
      },
      layout: 'regularTable',
      alignment: 'center',
      pageBreak: 'after',
    },
    {
      text: 'Abscess, Scarring/Chronic, Pericarditis Attachments',
      style: 'sectionHeader',
    },
    ...abscessScarringPericarditisDataLayout,
  ];

  gci.clear();
  return content;
}
