






























































































































































































































































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import SectionLayoutCardAssumption from '@/components/swine/reports/SectionLayoutCardAssumption.vue';
import { PleurisyCalculatorModalData } from '@/models/swine/economic-calculation.model';
import {
  DisplayDataAssumption,
  EconomicCalculationWithReportData,
} from '@/models/swine/report/report.model';
import {
  addCommas,
  addDecimalPointToNumbers,
} from '@/utils/formats';
import BasicBarChart from '@/components/swine/reports/BasicBarChart.vue';
import AssumptionReportLegendCard from './AssumptionReportLegendCard.vue';
import { ChartData, ChartOptions } from 'chart.js';
import MedicationUpdateModal from './MedicationUpdateModal.vue';
import AssumptionModal from './AssumptionModal.vue';
import AssumptionReportUpdated from './AssumptionReportUpdated.vue';
import { ReferenceTypeAdgReductionPercentages } from '@/utils/constants';
import { roundTo } from 'round-to';

@Component({
  components: {
    SectionLayoutCardAssumption,
    BasicBarChart,
    AssumptionReportLegendCard,
    MedicationUpdateModal,
    AssumptionModal,
    AssumptionReportUpdated,
  },
})
export default class PleurisyAssumptionReport extends Vue {
  noAssumption = true;
  chartData: Chart.ChartData | null = null;
  options: Chart.ChartOptions | null = null;
  updateMedicationModalStatus = false;
  assumptionModalStatus = false;
  addAssumptionButton = true;

  @Prop({
    type: Object,
    required: true,
    default: {},
  })
  calculatorModalInputDataRetrieve!: PleurisyCalculatorModalData;

  @Prop({
    type: Object,
    default: null,
  })
  currentReportDetails!: EconomicCalculationWithReportData;

  standardizeValue(data: number) {
    const value = roundTo(data, 1);
    return addDecimalPointToNumbers(value);
  }

  get computedChartOptions(): ChartOptions {
    return {
      maintainAspectRatio: false,
      legend: {
        display: false,
      },
      scales: {
        yAxes: [
          {
            gridLines: {
              drawBorder: false,
            },
            ticks: {
              min: 0,
              beginAtZero: true,
              padding: 16,
              callback: (tick) => {
                //arrow function to unscope "this" from this.calculatorModalInputDataRetrieve.currencySymbol
                return `${
                  this.calculatorModalInputDataRetrieve.currencySymbol
                } ${addCommas(tick.toString())}`;
              },
            },
            stacked: true,
          },
        ],
        xAxes: [
          {
            gridLines: {
              display: false,
              drawBorder: false,
            },
            stacked: true,
          },
        ],
      },
      plugins: {
        datalabels: {
          anchor: 'end',
          align: 'end',
          formatter: function (value) {
            return `${value}`;
          },
          display: false,
        },
      },
    };
  }

  get computedChartData(): ChartData {
    return {
      labels: ['Economic Loss', 'Medication Program'],
      datasets: [
        {
          label: 'Bronchpneumonic Economic Loss',
          data: [Number(this.totalAdditionalFeedCostPer1000.toFixed(2))],
          backgroundColor: [`#FFEAEA`],
          borderColor: [`#FF4C4B`],
          borderWidth: 1,
          barPercentage: 0.2,
        },
        {
          label: 'Pleurisy Economic Loss',
          data: [Number(this.totalCostOfAPP.toFixed(2))],
          backgroundColor: [`#FFC1C1`],
          borderColor: [`#FF4C4B`],
          borderWidth: 1,
          barPercentage: 0.2,
        },
        {
          data: [0, Number(this.totalInvestment)],
          backgroundColor: '#FFF5C9',
          borderColor: '#745E05',
          borderWidth: 1,
          barPercentage: 0.2,
        },
      ],
    };
  }

  get assumptionValuesPresent() {
    const economicCalculationDetails = this.calculatorModalInputDataRetrieve;
    if (economicCalculationDetails) {
      const {
        bronchopneumoniaAssumptionNormalLungsLowPercentage,
        bronchopneumoniaAssumptionNormalLungsHighPercentage,
        bronchopneumoniaAssumptionLowPneumonia,
        bronchopneumoniaAssumptionNormalPneumonia,
        bronchopneumoniaAssumptionHighPneumonia,
        bronchopneumoniaAssumptionLowSeverePneumonia,
        bronchopneumoniaAssumptionHighSeverePneumonia,
        p3AndP4PrevalenceAssumption,
        appPleurisyPrevalenceAssumption,
      } = economicCalculationDetails;
      const valuesExist = [
        bronchopneumoniaAssumptionNormalLungsLowPercentage,
        bronchopneumoniaAssumptionNormalLungsHighPercentage,
        bronchopneumoniaAssumptionLowPneumonia,
        bronchopneumoniaAssumptionNormalPneumonia,
        bronchopneumoniaAssumptionHighPneumonia,
        bronchopneumoniaAssumptionLowSeverePneumonia,
        bronchopneumoniaAssumptionHighSeverePneumonia,
        p3AndP4PrevalenceAssumption,
        appPleurisyPrevalenceAssumption,
      ];
      const assumptionValuesBoolean = valuesExist.every(
        (value) => Boolean(value) === true || value === 0,
      ); //if values are truthy or 0 will return true
      return assumptionValuesBoolean;
    } else {
      return false;
    }
  }

  get firstAssumption() {
    if (this.assumptionValuesPresent) {
      return false;
    } else {
      return true;
    }
  }

  get sectionCardValues() {
    const totalAdditionalFeedCost = `${
      this.addComma(this.totalAdditionalFeedCostPer1000, 2, false) == '-' ||
      this.calculatorModalInputDataRetrieve.numberOfAnimalHeads == 0
        ? '-'
        : this.calculatorModalInputDataRetrieve.currencySymbol +
          ' ' +
          this.addComma(this.totalAdditionalFeedCostPer1000, 2, false)
    }`;
    const totalInvestment = this.totalInvestment
      ? `${
          this.calculatorModalInputDataRetrieve.currencySymbol
        } ${this.addComma(this.totalInvestment, 2, false)}`
      : '-';
    const totalCostOfAPP = `${
      this.addComma(this.totalCostOfAPP, 2, false) == '-' ||
      this.calculatorModalInputDataRetrieve.numberOfAnimalHeads == 0
        ? '-'
        : this.calculatorModalInputDataRetrieve.currencySymbol +
          ' ' +
          this.addComma(this.totalCostOfAPP, 2, false)
    }`;
    return {
      totalAdditionalFeedCost,
      totalCostOfAPP,
      totalInvestment,
    };
  }

  get sectionCardDetails(): DisplayDataAssumption[] {
    let sectionCardDetailsList: DisplayDataAssumption[] = [];
    if (this.calculatorModalInputDataRetrieve.numberOfAnimalHeads !== null) {
      sectionCardDetailsList = [
        {
          headerText: 'Bronchopneumonia Economic Loss',
          headerValue: this.sectionCardValues.totalAdditionalFeedCost,
          subText: 'Additional Feed Cost /pig',
          subValue: `${
            this.addComma(
              this.totalAdditionalFeedCostPer1000 /
                this.calculatorModalInputDataRetrieve.numberOfAnimalHeads,
              2,
              false,
            ) == '-'
              ? ''
              : this.calculatorModalInputDataRetrieve.currencySymbol
          } ${this.addComma(
            this.totalAdditionalFeedCostPer1000 /
              this.calculatorModalInputDataRetrieve.numberOfAnimalHeads,
            2,
            false,
          )}`,
        },
        {
          headerText: 'Pleurisy Economic Loss',
          headerValue: this.sectionCardValues.totalCostOfAPP,
          subText: 'Cost of Pleurisy /pig',
          subValue: `${
            this.addComma(
              this.totalCostOfAPP /
                this.calculatorModalInputDataRetrieve.numberOfAnimalHeads,
              2,
              false,
            ) == '-'
              ? ''
              : this.calculatorModalInputDataRetrieve.currencySymbol
          } ${this.addComma(
            this.totalCostOfAPP /
              this.calculatorModalInputDataRetrieve.numberOfAnimalHeads,
            2,
            false,
          )}`,
        },
        {
          headerText: 'Medication Program',
          headerValue: this.sectionCardValues.totalInvestment,
          subText: 'Medication Investment /pig',
          subValue: `${this.medicationInvestmentPig}`,
        },
      ];
    }
    return sectionCardDetailsList;
  }

  // Broncho Related

  get adgReductionPercentages() {
    let referenceTypeAdgReductionPercentagesResults: number[] = [];
    if (this.calculatorModalInputDataRetrieve.referenceType) {
      referenceTypeAdgReductionPercentagesResults =
        ReferenceTypeAdgReductionPercentages[
          this.calculatorModalInputDataRetrieve.referenceType
        ];
    }
    return referenceTypeAdgReductionPercentagesResults;
  }

  get adgFormula() {
    let adgFormulaArray: number[] = [];
    adgFormulaArray = this.adgReductionPercentages.map(
      (adgPercentage: number) => {
        if (
          this.calculatorModalInputDataRetrieve &&
          this.calculatorModalInputDataRetrieve.adgValue
        ) {
          return (
            this.calculatorModalInputDataRetrieve.adgValue -
            this.calculatorModalInputDataRetrieve.adgValue * adgPercentage
          );
        }
        return 0;
      },
    );

    return adgFormulaArray;
  }

  firstFinalWeight(adgValueSet: number | null, weanToMarket: number | null) {
    if (adgValueSet && weanToMarket) {
      return (adgValueSet * weanToMarket) / 1000;
    } else {
      return 0;
    }
  }

  get animalPercentages() {
    if (this.calculatorModalInputDataRetrieve.pneumoniaSeverityDict) {
      return Object.values(
        this.calculatorModalInputDataRetrieve.pneumoniaSeverityDict,
      ).map((number) => {
        try {
          return Number((number / this.animalTotal).toFixed(4));
        } catch {
          return 0;
        }
      });
    }
    return [];
  }

  get animalTotal() {
    let total = 0;
    for (const key in this.calculatorModalInputDataRetrieve
      .pneumoniaSeverityDict) {
      if (key !== 'NE') {
        total += this.calculatorModalInputDataRetrieve.pneumoniaSeverityDict[
          key
        ];
      }
    }
    return total;
  }

  get numberOfAnimalHeadsSold() {
    let animalPercentagesArray: number[] = [];

    animalPercentagesArray = this.animalPercentages.map((percentage) => {
      if (
        this.calculatorModalInputDataRetrieve !== null &&
        this.calculatorModalInputDataRetrieve.numberOfAnimalHeads !== null
      ) {
        return (
          this.calculatorModalInputDataRetrieve.numberOfAnimalHeads * percentage
        );
      } else {
        return 0;
      }
    });
    return animalPercentagesArray;
  }

  get daysToReach() {
    return this.adgFormula.map((adg: number) => {
      try {
        return (
          (this.firstFinalWeight(
            this.calculatorModalInputDataRetrieve.adgValue,
            this.calculatorModalInputDataRetrieve.weanMarketDays,
          ) /
            adg) *
          1000
        );
      } catch {
        return 0;
      }
    });
  }

  get extraDaysToReach() {
    return this.daysToReach.map((days: number) => {
      try {
        return days - this.daysToReach[0];
      } catch {
        return 0;
      }
    });
  }

  get extraFeedConsumed() {
    let extraFeedConsumedArray: number[] = [];

    extraFeedConsumedArray = this.extraDaysToReach.map((extraDay: number) => {
      try {
        if (this.calculatorModalInputDataRetrieve.adfiValue !== null) {
          return extraDay * this.calculatorModalInputDataRetrieve.adfiValue;
        } else {
          return 0;
        }
      } catch {
        return 0;
      }
    });
    return extraFeedConsumedArray;
  }

  get additionalFeedCost() {
    let additionalFeedCostArray: number[] = [];
    additionalFeedCostArray = this.extraFeedConsumed.map(
      (extraFeed: number) => {
        if (
          this.calculatorModalInputDataRetrieve.additionalFeedCostPigRate !==
          null
        ) {
          try {
            return (
              extraFeed *
              this.calculatorModalInputDataRetrieve.additionalFeedCostPigRate
            );
          } catch {
            return 0;
          }
        } else {
          return 0;
        }
      },
    );
    return additionalFeedCostArray;
  }

  get additionalFeedCostPer1000() {
    return this.additionalFeedCost.map((addFeedCost: number, index: number) => {
      return addFeedCost * this.numberOfAnimalHeadsSold[index];
    });
  }

  get totalAdditionalFeedCostPer1000() {
    function reducer(total: number, value: number) {
      return total + value;
    }
    return this.additionalFeedCostPer1000.reduce(reducer);
  }

  // Pleurisy Related

  get pleurisyPrevalenceBackValue() {
    let pleurisyPrevalanceBackValueResult = 0;
    if (
      this.calculatorModalInputDataRetrieve.appPleurisyPrevalence !==
        undefined &&
      this.calculatorModalInputDataRetrieve.numberOfAnimalHeads !== null
    ) {
      pleurisyPrevalanceBackValueResult =
        (Number(
          this.calculatorModalInputDataRetrieve.appPleurisyPrevalence.toFixed(
            2,
          ),
        ) *
          this.calculatorModalInputDataRetrieve.numberOfAnimalHeads) /
        100;
    }
    return pleurisyPrevalanceBackValueResult;
  }

  get p3P4BackValue() {
    let p3P4BackValueResult = 0;
    if (
      this.calculatorModalInputDataRetrieve.p3AndP4Prevalence !== undefined &&
      this.calculatorModalInputDataRetrieve.numberOfAnimalHeads !== null
    ) {
      p3P4BackValueResult =
        (Number(
          this.calculatorModalInputDataRetrieve.p3AndP4Prevalence.toFixed(2),
        ) *
          this.calculatorModalInputDataRetrieve.numberOfAnimalHeads) /
        100;
    }
    return p3P4BackValueResult;
  }

  get postWeaningMortalityBackValue() {
    let postWeaningMortalityBackValueResult = 0;
    if (
      this.calculatorModalInputDataRetrieve.postWeaningMortality !== null &&
      this.calculatorModalInputDataRetrieve.numberOfAnimalHeads !== null
    ) {
      postWeaningMortalityBackValueResult =
        (this.calculatorModalInputDataRetrieve.postWeaningMortality *
          this.calculatorModalInputDataRetrieve.numberOfAnimalHeads) /
        100;
    }
    return postWeaningMortalityBackValueResult;
  }

  get weaningMortalityPercentage() {
    return this.calculatorModalInputDataRetrieve.postWeaningMortality;
  }

  get weaningMortalityHeads() {
    let weaningMortalityHeadsResult = 0;
    if (
      this.weaningMortalityPercentage !== null &&
      this.calculatorModalInputDataRetrieve.numberOfAnimalHeads !== null
    ) {
      weaningMortalityHeadsResult =
        (this.weaningMortalityPercentage *
          this.calculatorModalInputDataRetrieve.numberOfAnimalHeads) /
        100;
    }
    return weaningMortalityHeadsResult;
  }

  get totalCostPostWeaning() {
    let totalCostPostWeaningResult = 0;
    if (
      this.calculatorModalInputDataRetrieve.liveWeightPrice !== null &&
      this.calculatorModalInputDataRetrieve.targetMarketWeight !== null
    ) {
      totalCostPostWeaningResult =
        this.weaningMortalityHeads *
        this.calculatorModalInputDataRetrieve.liveWeightPrice *
        this.calculatorModalInputDataRetrieve.targetMarketWeight;
    }
    return totalCostPostWeaningResult;
  }

  get effectADGPigs() {
    let effectADGPigsResult = 0;
    if (
      this.calculatorModalInputDataRetrieve.p3AndP4Prevalence !== undefined &&
      this.calculatorModalInputDataRetrieve.weanMarketDays !== null
    ) {
      effectADGPigsResult =
        (Number(
          this.calculatorModalInputDataRetrieve.p3AndP4Prevalence.toFixed(2),
        ) *
          2 *
          this.calculatorModalInputDataRetrieve.weanMarketDays) /
        1000;
    }
    return effectADGPigsResult;
  }

  get totalCostADGPigs() {
    let totalCostADGPigsResult = 0;
    if (this.calculatorModalInputDataRetrieve.liveWeightPrice !== null) {
      totalCostADGPigsResult =
        this.p3P4BackValue *
        this.effectADGPigs *
        this.calculatorModalInputDataRetrieve.liveWeightPrice;
    }
    return totalCostADGPigsResult;
  }

  get effectOnSlaughterWeight() {
    let effectOnSlaughterWeightResult = 0;
    if (
      this.calculatorModalInputDataRetrieve !== undefined &&
      this.calculatorModalInputDataRetrieve.p3AndP4Prevalence
    ) {
      effectOnSlaughterWeightResult =
        Number(
          this.calculatorModalInputDataRetrieve.p3AndP4Prevalence.toFixed(2),
        ) * 0.07;
    }
    return effectOnSlaughterWeightResult;
  }

  get totalEffectOnSlaughterWeight() {
    let totalEffectOnSlaughterWeightResult = 0;
    if (this.calculatorModalInputDataRetrieve.liveWeightPrice !== null) {
      totalEffectOnSlaughterWeightResult =
        this.p3P4BackValue *
        this.effectOnSlaughterWeight *
        this.calculatorModalInputDataRetrieve.liveWeightPrice;
    }
    return totalEffectOnSlaughterWeightResult;
  }

  get effectOnSlaughterAge() {
    let effectOnSlaughterAgeResult = 0;
    if (this.calculatorModalInputDataRetrieve.p3AndP4Prevalence !== undefined) {
      effectOnSlaughterAgeResult =
        Number(
          this.calculatorModalInputDataRetrieve.p3AndP4Prevalence.toFixed(2),
        ) * 0.26;
    }
    return effectOnSlaughterAgeResult;
  }

  get totalEffectSlaughterAge() {
    let totalEffectSlaughterAgeResult = 0;
    if (this.calculatorModalInputDataRetrieve.averageFeedPrice !== null) {
      totalEffectSlaughterAgeResult =
        this.p3P4BackValue *
        this.effectOnSlaughterAge *
        2 *
        this.calculatorModalInputDataRetrieve.averageFeedPrice;
    }
    return totalEffectSlaughterAgeResult;
  }

  get totalCostOfAPP() {
    return (
      this.totalCostPostWeaning +
      this.totalCostADGPigs +
      this.totalEffectOnSlaughterWeight +
      this.totalEffectSlaughterAge
    );
  }

  // Medication Cost Related

  get cleanSowDosageValue() {
    return this.calculatorModalInputDataRetrieve.cleanSowDosage;
  }

  get cleanSowDosage() {
    if (this.cleanSowDosageValue === null) {
      return '-';
    } else {
      return this.addComma(Number(this.cleanSowDosageValue), 2, false);
    }
  }

  get cleanSowMedicinePriceValue() {
    return this.calculatorModalInputDataRetrieve.cleanSowMedicinePrice;
  }

  get cleanSowMedicinePrice() {
    if (this.cleanSowMedicinePriceValue === null) {
      return '-';
    } else {
      return this.addComma(Number(this.cleanSowMedicinePriceValue), 2, false);
    }
  }

  get cleanSowMedicationCostFeedFormula() {
    let cleanSowMedicationCostFeedFormulaResult = 0;
    if (
      this.cleanSowDosageValue !== null &&
      this.cleanSowDosageValue !== undefined &&
      this.calculatorModalInputDataRetrieve.cleanSowMedicinePrice !== null &&
      this.calculatorModalInputDataRetrieve.cleanSowMedicinePrice !== undefined
    ) {
      cleanSowMedicationCostFeedFormulaResult =
        this.cleanSowDosageValue *
        this.calculatorModalInputDataRetrieve.cleanSowMedicinePrice;
    }
    return cleanSowMedicationCostFeedFormulaResult;
  }

  get cleanSowMedicationCostFeed() {
    if (this.cleanSowMedicationCostFeedFormula === 0) {
      return '-';
    } else {
      return this.addComma(
        Number(this.cleanSowMedicationCostFeedFormula),
        2,
        false,
      );
    }
  }

  get cleanSowMedicationCostHeadFormula() {
    let cleanSowMedicationCostHeadFormulaResult = 0;
    if (
      this.calculatorModalInputDataRetrieve.numberOfAnimalHeads !== null &&
      this.calculatorModalInputDataRetrieve.cleanSowPSRatio &&
      this.calculatorModalInputDataRetrieve.cleanSowDuration &&
      this.calculatorModalInputDataRetrieve.cleanSowFeedIntake
    ) {
      cleanSowMedicationCostHeadFormulaResult =
        (((this.calculatorModalInputDataRetrieve.numberOfAnimalHeads /
          this.calculatorModalInputDataRetrieve.cleanSowPSRatio) *
          0.2 *
          this.calculatorModalInputDataRetrieve.cleanSowFeedIntake *
          this.calculatorModalInputDataRetrieve.cleanSowDuration) /
          1000) *
        this.cleanSowMedicationCostFeedFormula;
    }
    return cleanSowMedicationCostHeadFormulaResult;
  }

  get cleanSowMedicationCostHead() {
    if (this.cleanSowMedicationCostHeadFormula === 0) {
      return '-';
    } else {
      return this.addComma(
        Number(this.cleanSowMedicationCostHeadFormula),
        2,
        false,
      );
    }
  }

  get cleanSowCostPerPigFormula() {
    let cleanSowCostPerPigFormulaResult = 0;
    if (this.calculatorModalInputDataRetrieve.numberOfAnimalHeads !== null) {
      cleanSowCostPerPigFormulaResult =
        this.cleanSowMedicationCostHeadFormula /
        this.calculatorModalInputDataRetrieve.numberOfAnimalHeads;
    }
    return cleanSowCostPerPigFormulaResult;
  }

  get cleanSowCostPerPig() {
    if (this.cleanSowCostPerPigFormula === 0) {
      return '-';
    } else {
      return this.addComma(Number(this.cleanSowCostPerPigFormula), 2, false);
    }
  }

  get startStrongDosageValue() {
    return this.calculatorModalInputDataRetrieve.startStrongDosage;
  }

  get startStrongDosage() {
    if (this.startStrongDosageValue === null) {
      return '-';
    } else {
      return this.addComma(Number(this.startStrongDosageValue), 2, false);
    }
  }

  get startStrongMedicinePriceValue() {
    return this.calculatorModalInputDataRetrieve.startStrongMedicinePrice;
  }

  get startStrongMedicinePrice() {
    if (this.startStrongMedicinePriceValue === null) {
      return '-';
    } else {
      return this.addComma(
        Number(this.startStrongMedicinePriceValue),
        2,
        false,
      );
    }
  }

  get startStrongMedicationCostFeedFormula() {
    let startStrongMedicationCostFeedFormulaResult = 0;
    if (
      this.startStrongDosageValue !== null &&
      this.startStrongDosageValue !== undefined &&
      this.calculatorModalInputDataRetrieve.startStrongMedicinePrice !== null &&
      this.calculatorModalInputDataRetrieve.startStrongMedicinePrice !==
        undefined
    )
      startStrongMedicationCostFeedFormulaResult =
        this.startStrongDosageValue *
        this.calculatorModalInputDataRetrieve.startStrongMedicinePrice;
    return startStrongMedicationCostFeedFormulaResult;
  }

  get startStrongMedicationCostFeed() {
    if (this.startStrongMedicationCostFeedFormula === 0) {
      return '-';
    } else {
      return this.addComma(
        Number(this.startStrongMedicationCostFeedFormula),
        2,
        false,
      );
    }
  }

  get startStrongMedicationCostHeadFormula() {
    let startStrongMedicationCostHeadFormulaResult = 0;
    if (
      this.calculatorModalInputDataRetrieve.numberOfAnimalHeads !== null &&
      this.calculatorModalInputDataRetrieve.startStrongFeedIntake &&
      this.calculatorModalInputDataRetrieve.startStrongDuration
    ) {
      startStrongMedicationCostHeadFormulaResult =
        ((this.calculatorModalInputDataRetrieve.numberOfAnimalHeads *
          this.calculatorModalInputDataRetrieve.startStrongFeedIntake *
          this.calculatorModalInputDataRetrieve.startStrongDuration) /
          1000) *
        this.startStrongMedicationCostFeedFormula;
    }
    return startStrongMedicationCostHeadFormulaResult;
  }

  get startStrongMedicationCostHead() {
    if (this.startStrongMedicationCostHeadFormula === 0) {
      return '-';
    } else {
      return this.addComma(
        Number(this.startStrongMedicationCostHeadFormula),
        2,
        false,
      );
    }
  }

  get startStrongCostPerPigFormula() {
    let startStrongCostPerPigFormulaResult = 0;
    if (this.calculatorModalInputDataRetrieve.numberOfAnimalHeads !== null) {
      startStrongCostPerPigFormulaResult =
        this.startStrongMedicationCostHeadFormula /
        this.calculatorModalInputDataRetrieve.numberOfAnimalHeads;
    }
    return startStrongCostPerPigFormulaResult;
  }

  get startStrongCostPerPig() {
    if (this.startStrongCostPerPigFormula === 0) {
      return '-';
    } else {
      return this.addComma(Number(this.startStrongCostPerPigFormula), 2, false);
    }
  }

  get strategicProgramDosageValue() {
    return this.calculatorModalInputDataRetrieve.strategicProgramDosage;
  }

  get strategicProgramDosage() {
    if (this.strategicProgramDosageValue === null) {
      return '-';
    } else {
      return this.addComma(Number(this.strategicProgramDosageValue), 2, false);
    }
  }

  get strategicProgramMedicinePriceValue() {
    return this.calculatorModalInputDataRetrieve.strategicProgramMedicinePrice;
  }

  get strategicProgramMedicinePrice() {
    if (this.strategicProgramMedicinePriceValue === null) {
      return '-';
    } else {
      return this.addComma(
        Number(this.strategicProgramMedicinePriceValue),
        2,
        false,
      );
    }
  }

  get strategicProgramMedicationCostFeedFormula() {
    let strategicProgramMedicationCostFeedFormulaResult = 0;
    if (
      this.strategicProgramDosageValue !== null &&
      this.strategicProgramDosageValue !== undefined &&
      this.calculatorModalInputDataRetrieve.strategicProgramMedicinePrice !==
        null &&
      this.calculatorModalInputDataRetrieve.strategicProgramMedicinePrice !==
        undefined
    ) {
      strategicProgramMedicationCostFeedFormulaResult =
        this.strategicProgramDosageValue *
        this.calculatorModalInputDataRetrieve.strategicProgramMedicinePrice;
    }
    return strategicProgramMedicationCostFeedFormulaResult;
  }

  get strategicProgramMedicationCostFeed() {
    if (this.strategicProgramMedicationCostFeedFormula === 0) {
      return '-';
    } else {
      return this.addComma(
        Number(this.strategicProgramMedicationCostFeedFormula),
        2,
        false,
      );
    }
  }

  get strategicProgramMedicationCostHeadFormula() {
    let strategicProgramMedicationCostHeadFormulaResult = 0;
    if (
      this.calculatorModalInputDataRetrieve.numberOfAnimalHeads !== null &&
      this.calculatorModalInputDataRetrieve.strategicProgramFeedIntake &&
      this.calculatorModalInputDataRetrieve.strategicProgramDuration
    ) {
      strategicProgramMedicationCostHeadFormulaResult =
        ((this.calculatorModalInputDataRetrieve.numberOfAnimalHeads *
          this.calculatorModalInputDataRetrieve.strategicProgramFeedIntake *
          this.calculatorModalInputDataRetrieve.strategicProgramDuration) /
          1000) *
        this.strategicProgramMedicationCostFeedFormula;
    }
    return strategicProgramMedicationCostHeadFormulaResult;
  }

  get strategicProgramMedicationCostHead() {
    if (this.strategicProgramMedicationCostHeadFormula === 0) {
      return '-';
    } else {
      return this.addComma(
        Number(this.strategicProgramMedicationCostHeadFormula),
        2,
        false,
      );
    }
  }

  get strategicProgramCostPerPigFormula() {
    let strategicProgramCostPerPigFormulaResult = 0;
    if (this.calculatorModalInputDataRetrieve.numberOfAnimalHeads !== null) {
      strategicProgramCostPerPigFormulaResult =
        this.strategicProgramMedicationCostHeadFormula /
        this.calculatorModalInputDataRetrieve.numberOfAnimalHeads;
    }
    return strategicProgramCostPerPigFormulaResult;
  }

  get strategicProgramCostPerPig() {
    if (this.strategicProgramCostPerPigFormula === 0) {
      return '-';
    } else {
      return this.addComma(
        Number(this.strategicProgramCostPerPigFormula),
        2,
        false,
      );
    }
  }

  get totalInvestment() {
    return (
      this.cleanSowMedicationCostHeadFormula +
      this.startStrongMedicationCostHeadFormula +
      this.strategicProgramMedicationCostHeadFormula
    );
  }

  get medicationInvestmentPig() {
    let medicationInvestmentPigResult = '';
    if (this.calculatorModalInputDataRetrieve.numberOfAnimalHeads !== null) {
      if (
        (this.cleanSowMedicationCostHeadFormula +
          this.startStrongMedicationCostHeadFormula +
          this.strategicProgramMedicationCostHeadFormula) /
          this.calculatorModalInputDataRetrieve.numberOfAnimalHeads ===
          0 ||
        isNaN(
          (this.cleanSowMedicationCostHeadFormula +
            this.startStrongMedicationCostHeadFormula +
            this.strategicProgramMedicationCostHeadFormula) /
            this.calculatorModalInputDataRetrieve.numberOfAnimalHeads,
        )
      ) {
        medicationInvestmentPigResult = '-';
      } else {
        medicationInvestmentPigResult = `${
          this.calculatorModalInputDataRetrieve.currencySymbol
        } ${this.addComma(
          Number(
            (this.cleanSowMedicationCostHeadFormula +
              this.startStrongMedicationCostHeadFormula +
              this.strategicProgramMedicationCostHeadFormula) /
              this.calculatorModalInputDataRetrieve.numberOfAnimalHeads,
          ),
          2,
          false,
        )}`;
      }
    }
    return medicationInvestmentPigResult;
  }

  addComma(targetNumber: number, toFixedNumber: number, onlyToString: boolean) {
    if (isNaN(targetNumber)) {
      return '-';
    } else if (!onlyToString) {
      const roundedNumber = targetNumber.toFixed(toFixedNumber);
      return addCommas(roundedNumber);
    } else if (onlyToString) {
      const numberToString = targetNumber.toString();
      return addCommas(numberToString);
    }
  }

  toggleUpdateAssumption() {
    this.assumptionModalStatus = !this.assumptionModalStatus;
  }

  toggleAddAssumptionButton() {
    this.addAssumptionButton = false;
  }

  toggleUpdateMedicationModal() {
    this.updateMedicationModalStatus = !this.updateMedicationModalStatus;
  }

  updateMedicationProgramReportData(
    updateMedicationModalData: PleurisyCalculatorModalData,
  ) {
    this.$emit('update', updateMedicationModalData);
    this.updateMedicationModalStatus = !this.updateMedicationModalStatus;
  }

  updateAssumptionReportData(
    assumptionModalInputObject: PleurisyCalculatorModalData,
  ) {
    this.$emit('update', assumptionModalInputObject);
    this.assumptionModalStatus = !this.assumptionModalStatus;
    this.noAssumption = false;
  }
}
